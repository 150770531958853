import React, { useState } from "react";
import MDSpinner from "react-md-spinner";

const OSCELoginForm = ({
  route,
  handleLogin,
  showTos,
  showForgotPassword,
  successMessage,
  defaultLoginPage,
  accountType,
  setAccountType,
}) => {
  const [studentId, setStudentId] = useState("");
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const fetchLogin = (loginRoute, bodyData) => {
    setFetchOutstanding(true);
    fetch(loginRoute, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify(bodyData),
    })
      .then((response) => response.text())
      .then((result) => {
        setFetchOutstanding(false);

        if (result.includes("Token")) {
          let parsedResult = JSON.parse(result);
          if (accountType === "Student/Preceptor")
            handleLogin(parsedResult, "Student");
          else handleLogin(parsedResult, "Admin");
        } else {
          result = result.replace("{", "");
          result = result.replace("}", "");
          console.log(result);
          setErrorText(result);
        }
      })
      .catch((error) => {
        setFetchOutstanding(false);
        console.log(error);
        setErrorText(error);
      });
  };

  const loginHandler = (e) => {
    e.preventDefault();
    if (accountType === "Student/Preceptor") {
      fetchLogin(`${route}/loginosce.webapi`, {
        UniqueSignInId: studentId,
      });
    } else {
      fetchLogin(`${route}/loginosceadmin.webapi`, {
        UserName: email,
        Password: password,
      });
    }
  };

  return (
    <form
      className="loginContainer"
      onSubmit={loginHandler}
      key="loginContainer"
    >
      <h1 className="loginHeader">Login</h1>
      <label className="loginLabel">Account Type</label>
      <select
        className="loginSelect"
        value={accountType}
        onChange={(e) => setAccountType(e.target.value)}
      >
        <option value={"Student/Preceptor"}>Student/Preceptor</option>
        <option value={"Admin"}>Admin</option>
      </select>
      {accountType === "Student/Preceptor" ? (
        <>
          <label className="loginLabel">Student Id</label>
          <input
            type="text"
            autoComplete="text"
            className="loginInput"
            required
            value={studentId}
            onChange={(e) => setStudentId(e.target.value)}
          />
        </>
      ) : (
        <>
          <label className="loginLabel">Email</label>
          <input
            type="text"
            autoComplete="email"
            className="loginInput"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label className="loginLabel">Password</label>
          <input
            type="password"
            autoComplete="password"
            className="loginInput"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <a
            className="forgotPasswordText"
            type="button"
            onClick={showForgotPassword}
          >
            Forgot Password?
          </a>
        </>
      )}
      {!fetchOutstanding ? (
        <div
          className="loginButtonWrapper"
          style={{ gridTemplateColumns: "1fr" }}
        >
          <button className="loginButton loginPrimaryButton" type="submit">
            Login
          </button>
        </div>
      ) : (
        <div className="loginButtonLoaderWrapper">
          <MDSpinner size={35} singleColor={"#0B335D"} />
        </div>
      )}
      <a className="loginTermsOfServiceText" type="button" onClick={showTos}>
        Terms of Service
      </a>
      {errorText !== "" && <p className="loginErrorText">{errorText}</p>}
      {successMessage !== "" && (
        <p className="loginSuccessText">{successMessage}</p>
      )}
    </form>
  );
};

export default OSCELoginForm;
