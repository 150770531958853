import React, { useContext, useState } from "react";
import "./SchoolOverviewSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import SchoolLogo from "../../../Images/UNMLogo.jpg";
import SchoolImage from "../../../Images/SchoolImage.jpg";
import ModifySchoolNameModal from "../../Components/Modals/ModifySchoolNameModal/ModifySchoolNameModal";
import ModifyUniversityLogoModal from "../../Components/Modals/ModifyUniversityLogoModal/ModifyUniversityLogoModal";
import ModifyBackgroundImageModal from "../../Components/Modals/ModifyBackgroundImageModal/ModifyBackgroundImageModal";
import { OSCEAdminContext } from "../..";

const SchoolOverviewSection = () => {
  const [showModifySchoolName, setShowModifySchoolName] = useState(false);
  const [showModifySchoolLogo, setShowModifySchoolLogo] = useState(false);
  const [showModifyBreakBackgroundImage, setShowModifyBreakBackgroundImage] =
    useState(false);
  const { userData } = useContext(OSCEAdminContext);

  return (
    <>
      <div className="adminPortalMySchoolOverviewWrapper">
        <h2 className="adminPortalMySchoolTitleText">My School</h2>
        <div className="adminPortalMySchoolTextBoxContainer">
          <div className="adminPortalMySchoolTextBoxTitleWrapper">
            <h3 className="adminPortalMySchoolTextBoxTitleText">School Name</h3>
            <button
              className="adminPortalMySchoolEditButton"
              onClick={() => setShowModifySchoolName(true)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </button>
          </div>
          <p className="adminPortalMySchoolTextBoxText">
            {userData.SchoolName}
          </p>
        </div>
        <div className="adminPortalMySchoolTextBoxContainer">
          <h3 className="adminPortalMySchoolTextBoxTitleText">
            Credits Available
          </h3>
          <p
            className="adminPortalMySchoolTextBoxText"
            style={{ marginBottom: 5 }}
          >
            {userData.OsceCredits}
          </p>
          <a className="adminPortalMySchoolTextBoxLinkText">+ Add Credits</a>
        </div>
        <div className="adminPortalMySchoolImageContainer">
          <h3 className="adminPortalMySchoolImageTitleText">University Logo</h3>
          <button
            className="adminPortalMySchoolEditButton"
            onClick={() => setShowModifySchoolLogo(true)}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <div className="adminPortalMySchoolImageWrapper">
            <img
              className="adminPortalMySchoolImage"
              src={`https://osce.ccscases.com/logos/${userData.SchoolLogo}`}
            />
            {/* <div className="adminPortalMySchoolImageTitleContainer">
              <div className="adminPortalMySchoolImageColor" />
              <p className="adminPortalMySchoolImageLinkText">
                Sample Logo Name.jpg
              </p>
            </div> */}
          </div>
        </div>
        <div className="adminPortalMySchoolImageContainer">
          <h3 className="adminPortalMySchoolImageTitleText">
            Break Background Image
          </h3>
          <button
            className="adminPortalMySchoolEditButton"
            onClick={() => setShowModifyBreakBackgroundImage(true)}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>
          <div className="adminPortalMySchoolImageWrapper">
            <img
              className="adminPortalMySchoolImage"
              src={`https://osce.ccscases.com/logos/${userData.SchoolBreakBackgroundImage}`}
            />
          </div>
        </div>
      </div>
      {showModifySchoolName && (
        <ModifySchoolNameModal
          setShowModifySchoolName={setShowModifySchoolName}
          schoolName={userData.SchoolName}
        />
      )}
      {showModifySchoolLogo && (
        <ModifyUniversityLogoModal
          setShowModifySchoolLogo={setShowModifySchoolLogo}
        />
      )}
      {showModifyBreakBackgroundImage && (
        <ModifyBackgroundImageModal
          setShowModifyBreakBackgroundImage={setShowModifyBreakBackgroundImage}
        />
      )}
    </>
  );
};

export default SchoolOverviewSection;
