import React from 'react';
import './CaseList.css';
import CaseSelect from './CaseSelect';
import IncompleteCasePopup from './IncompleteCasePopup';
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import MDSpinner from 'react-md-spinner';

class CaseList extends React.Component {
	
	constructor(props) {
		super(props);

		let selectedCase = 1;
		if (this.props.selectedCase) {
			selectedCase = this.props.selectedCase;
		}
		
		this.state = {
			selectedCase: selectedCase,			// default selected case
			yield: false,				// show high yield count column, hard coded toggle
			displayIncompleteCases: false,
			incompleteCaseList: [],
			selectedIncompleteCase: 0,
			startNewCaseFunction: null,
			filteredCaseList: [],
			scrollIntoViewCallback: () => null
		}

		this.viewCaseGradesClick = this.viewCaseGradesClick.bind(this);
		this.caseSelectClick = this.caseSelectClick.bind(this);
		this.selectRandomClick = this.selectRandomClick.bind(this);
		this.selectRandomIncompleteClick = this.selectRandomIncompleteClick.bind(this);
		this.runCaseClick = this.runCaseClick.bind(this);
		this.reviewLaterToggle = this.reviewLaterToggle.bind(this);
	}

	componentDidMount() {
		if (this.props.unfilteredCaseList.length !== 0) {
			this.sortTableClick(this.props.caseListSort, true)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.unfilteredCaseList !== prevProps.unfilteredCaseList) {
			this.sortTableClick(this.props.caseListSort, true)
		}
	}

	componentWillUnmount() {
		let table = document.querySelector('#caseListTable')
		this.props.setCurrentScrollPosition(table.scrollTop)
	}

	viewCaseGradesClick() {
		// Get case index from unfilteredCaseList by comparing the ID with this.props.selectedCase
		let selectedCaseIndex = 0;
		for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
			if (this.props.unfilteredCaseList[i].ID === this.props.selectedCase) {
				selectedCaseIndex = i;
				break;
			}
		}

		if (!this.props.unfilteredCaseList[selectedCaseIndex].COMPLETED) {
			return
		}

		let reviewLater = this.props.unfilteredCaseList[selectedCaseIndex].ReviewLaterFlag;

		this.props.viewCaseGrades(this.props.selectedCase, reviewLater)
	}

	/*
	toggle the Review Later column checkbox
	searches through array by id to toggle review later
	*/
	reviewLaterToggle(id) {
		let tempJson = this.props.unfilteredCaseList;
		for (let i = 0; i < tempJson.length; i++) {
			if (tempJson[i].ID === id) {
				tempJson[i].ReviewLaterFlag = !tempJson[i].ReviewLaterFlag;
			}
		}
		this.setState({filteredCaseList: tempJson});
	}
	
	/*
	randomly generate a case id to select
	*/
	selectRandomClick() {
		// get a random case from the unfiltered case list
		let randomCase = this.props.unfilteredCaseList[Math.floor(Math.random()*this.props.unfilteredCaseList.length)];
		this.props.setSelectedCase(randomCase.ID, () => {
			// Scroll case into view
			let selectedCase = document.getElementById(`case-${this.props.selectedCase}`);
			if (selectedCase) {
				// smooth scroll case into center of view
				selectedCase.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
		})
	}
	
	/*
	randomly select a case from the incomplete cases array
	*/
	selectRandomIncompleteClick() {
		// get a random case from the unfiltered case list and check if it is incomplete
		let randomCase
		do {
			randomCase = this.props.unfilteredCaseList[Math.floor(Math.random()*this.props.unfilteredCaseList.length)];
		} while (randomCase.COMPLETED)

		this.props.setSelectedCase(randomCase.ID, () => {
			// Scroll case into view
			let selectedCase = document.getElementById(`case-${this.props.selectedCase}`);
			if (selectedCase) {
				// smooth scroll case into center of view
				selectedCase.scrollIntoView({behavior: 'smooth', block: 'center'});
			}
		})
	}
	
	/*
	run the case simulation
	passes the caseData Json up to App
	*/
	runCaseClick() {
		if (this.props.selectedCase) {
			for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
				if (this.props.unfilteredCaseList[i].ID === this.props.selectedCase) {
					let table = document.querySelector('#caseListTable')
					this.props.fetchStartCase(this.props.unfilteredCaseList[i], this.incompleteStartDisplayHandler);
				}
			}
		}
	}

	incompleteStartDisplayHandler = (ialist, callBack) => {
		this.setState({displayIncompleteCases: true, startNewCaseFunction: callBack, incompleteCaseList: ialist})
	}

	/*
	changes the selected case
	this is the case that is highlighted and will be run when run case is clicked
	*/
	caseSelectClick(id) {
		this.setState({
			selectedCase: id,
		});
	}

	/*
	sort cases in table by column
	every column uses the same sorting algorithm except for review
	*/
	sortTableClick = (sortBy, mounting = false) => {
		let tempArray = [...this.props.unfilteredCaseList]
		let reverse = false

		if (sortBy.includes(' - reverse')) {
			sortBy = sortBy.replace(' - reverse', '')
			reverse = true
		}

		switch(sortBy) {
			// case 'id':
			// 	tempArray.sort((a, b) => a.ID - b.ID)
			// 	break;
			case 'review':
				tempArray.sort((a, b) => b.ReviewLaterFlag - a.ReviewLaterFlag)
				break;
			case 'title':
				tempArray.sort((a, b) => {
					if(a.TITLE < b.TITLE) { return -1; }
					if(a.TITLE > b.TITLE) { return 1; }
					return 0;
				})
				break;
			case 'category':
				tempArray.sort((a, b) => {
					if(a.CASECATEGORYDESC < b.CASECATEGORYDESC) { return -1; }
					if(a.CASECATEGORYDESC > b.CASECATEGORYDESC) { return 1; }
					return 0;
				})
				break;
			case 'diagnosis':
				tempArray.sort((a, b) => {
					if(a.DIAGNOSIS < b.DIAGNOSIS) { return -1; }
					if(a.DIAGNOSIS > b.DIAGNOSIS) { return 1; }
					return 0;
				})
				break;
			case 'time':
				tempArray.sort((a, b) => {
					if(a.TIMEMODEDESC < b.TIMEMODEDESC) { return -1; }
					if(a.TIMEMODEDESC > b.TIMEMODEDESC) { return 1; }
					return 0;
				})
				break;
			case 'completed':
				tempArray.sort((a, b) => new Date(b.LastCompletionDate) - new Date(a.LastCompletionDate))
				break;
			case 'yield':
				tempArray.sort((a, b) => b.HIGHYIELDCOUNT - a.HIGHYIELDCOUNT)
				break;
			case 'grade':
				tempArray.sort((a, b) => b.AvgGradeForCase - a.AvgGradeForCase)
				break;
			default:
				break;
		}

		if ((sortBy === this.props.caseListSort && !mounting) || reverse) {
			tempArray.reverse()
			sortBy = `${sortBy} - reverse`
		}

		this.props.changeCaseListSort(sortBy)
		this.setState({filteredCaseList: tempArray}, () => {
			if (mounting) {
				let table = document.querySelector('#caseListTable')
				table.scrollTop = this.props.scrollPosition
			}
		})
	}

	isSelectedCaseCompleted = () => {
		if (this.props.selectedCase < 0)
			return false

		for (let i = 0; i < this.props.unfilteredCaseList.length; i++) {
			if (this.props.unfilteredCaseList[i].ID === this.props.selectedCase) {
				return this.props.unfilteredCaseList[i].COMPLETED
			}
		}

		return false
	}

	render() {

		let yieldColumn;
		let titleColumn;
		let diagnosisColumn;

		if (!this.props.userProfile.hideHighYield) {
			yieldColumn = <div className='column yield' onClick={() => this.sortTableClick('yield')}>High Yield</div>
		}

		if (this.props.showCaseTitle) {
			titleColumn = <div className='column title' onClick={() => this.sortTableClick('title')}>Title</div>;
		}

		if (this.props.showCaseDiagnosis) {
			diagnosisColumn = <div className='column diagnosis' onClick={() => this.sortTableClick('diagnosis')}>Diagnosis</div>;
		}

		let caseList;
		if (this.state.filteredCaseList !== undefined) {
			caseList = this.state.filteredCaseList.map((json) =>
				json.TITLE ?
					<CaseSelect 
						data={json} 
						title={this.props.showCaseTitle} 
						diagnosis={this.props.showCaseDiagnosis} 
						yield={json.HIGHYIELDCOUNT}
						key={json.ID}
						selectedCase={this.props.selectedCase}
						caseSelectClick={this.props.setSelectedCase}
						setAuthenticationError={this.props.setAuthenticationError}
						userData={this.props.userData}
						userProfile={this.props.userProfile}
						reviewLaterToggle={this.reviewLaterToggle}
						route={this.props.route}
						setFetchOutstanding={this.props.setFetchOutstanding}
						hideHighYield={this.props.userProfile.hideHighYield}
					/>
				: null
			);
		}

		return (
			<div className='case-list'>
				{this.state.displayIncompleteCases &&
					<IncompleteCasePopup
						userProfile={this.props.userProfile}
						selectedIncompleteCase={this.state.selectedIncompleteCase}
						startNewCaseFunction={this.state.startNewCaseFunction}
						closeIncompleteCases={() => this.setState({displayIncompleteCases: false})}
						incompleteCaseList={this.state.incompleteCaseList}
						changeSelectedCase={(newIndex) => this.setState({selectedIncompleteCase: newIndex})}
						confirmIncompleteCaseStart={this.props.confirmIncompleteCaseStart}
					/>
				}
				<div className='page-header'>
					<div className='page-title'>Step 3 CCS Case Selection</div>
					<p className='page-error'>{this.props.caseListHeaderError}</p>
					<div><input type='button' value='Account Settings' onClick={this.props.accountSettingsClick}/></div>
				</div>
				{this.props.showSubscriptionEndWarning ?
					<p className='subscriptionEndWarningText'>Your subscription ends in {this.props.subscriptionDaysRemaining} {this.props.subscriptionDaysRemaining === 1 ? 'Day' : 'Days'}.</p>
				:
					<div />
				}
				<div className='table' style={{display: 'grid', gridTemplateRows: 'auto 1fr'}}>
					<div className='table-row header' style={{height: 'auto', minHeight: '21px'}}>
						<div className='column id' onClick={() => this.sortTableClick('id')}>Id</div>
						<div className='wrapper title-category-diagnosis-time'>
							{titleColumn}
							<div className='column category' onClick={() => this.sortTableClick('category')}>Category</div>
							{diagnosisColumn}
							<div className='column time' onClick={() => this.sortTableClick('time')}>Time Limit</div>
						</div>
						<div className='wrapper completed-review-grade'>
							<div className='column completed' onClick={() => this.sortTableClick('completed')}>Completed</div>
							{yieldColumn}
							<div className='column review' onClick={() => this.sortTableClick('review')}>Review Later</div>
							<div className='column grade' onClick={() => this.sortTableClick('grade')}>Average Grade</div>
						</div>
						{/* <div className='spacer'></div> */}
					</div>
					<div className='table-content' id='caseListTable' style={{height: '100%'}}>
						{/* {false ? */}
						{this.props.unfilteredCaseList.length > 0 ?
							caseList
						:
							<div className='table-loading-container'>
								<MDSpinner
									size={50}
									singleColor={this.props.primaryColor}
									className='table-loading-spinner'
								/>
								<p className='table-loading-text'>Loading Case List</p>
							</div>
						}
					</div>
				</div>
				<div className='table-options'>
					<div onClick={this.props.toggleShowCaseTitle}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseTitle}/>
						<label>Show Case Title</label>
					</div>
					<div onClick={this.props.toggleShowCaseDiagnosis}>
						<input className='table-option-checkbox' type='checkbox' checked={this.props.showCaseDiagnosis}/>
						<label>Show Case Diagnosis</label>
					</div>
				</div>
				<div className='options'>
					<div className='simulation-menu-options'>
						<div onClick={this.props.timedExamToggle}>
							<input type='checkbox' checked={this.props.timedExam}/>
							<label>Timed Exam</label>
						</div>
						<div>
							<span>Custom Time Limit: </span>
							<select value={this.props.customTimeLimit} onChange={this.props.customTimeLimitChange}>
								<option value='none'>None</option>
								<option value='8'>8 mins</option>
								<option value='9'>9 mins</option>
								<option value='10'>10 mins</option>
								<option value='15'>15 mins</option>
								<option value='18'>18 mins</option>
								<option value='19'>19 mins</option>
								<option value='20'>20 mins</option>
								<option value='25'>25 mins</option>
								<option value='30'>30 mins</option>
								<option value='40'>40 mins</option>
							</select>
						</div>
						<div>
							<span>Simulate Network Lag: </span>
							<select value={this.props.networkLag} onChange={this.props.networkLagChange}>
								<option value='none'>None</option>
								<option value='1'>1 second</option>
								<option value='2'>2 seconds</option>
								<option value='3'>3 seconds</option>
								<option value='4'>4 seconds</option>
								<option value='5'>5 seconds</option>
								<option value='6'>6 seconds</option>
								<option value='random'>Random</option>
							</select>
						</div>

					</div>
					<div className='buttons'>
						<div className='program-options'>
							<input className='button' type='button' value='Select Random Case' onClick={this.selectRandomClick}/>
							<input className='button' type='button' value='Select Random Incomplete Case' onClick={this.selectRandomIncompleteClick}/>
						</div>
						<div className='program-options'>
							<input className='button' type='button' value='Report Card' onClick={this.props.viewReportCard}/>
							<input className={`button ${this.isSelectedCaseCompleted() ? '' : 'viewCaseGradeButtonHidden'}`} type='button' value='View Case Grades' onClick={this.viewCaseGradesClick}/>
							<input className='button' type='button' value='Run Case' onClick={this.runCaseClick}/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CaseList;
