import React, { useEffect, useState } from "react";
import LoginPage from "../../NewLogin/LoginPage";
import ExamTransitionPage from "./ExamTransitionPage";
import { fetchStartCase } from "../../Functions/FetchStartCase";
import Simulation from "../../CCSCasesSimulation/Simulation";
import { updateLocation } from "../../Functions/Simulation/UpdateLocation";

const WEB_SERVER_ROUTE = "https://osce.ccscases.com";
const OSCEStudent = ({ userData }) => {
  const [pageDisplayed, setPageDisplayed] = useState("Simulation Transition");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [breakTimePassed, setBreakTimePassed] = useState(0);
  const [startCaseData, setStartCaseData] = useState(null);
  const [simulationData, setSimulationData] = useState(null);
  const [caseData, setCaseData] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", updatePageHeight);
    return () => {
      window.removeEventListener("resize", updatePageHeight);
    };
  });

  const updatePageHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  const handleStartExam = async () => {
    console.log("Exam Starting");
    const startCaseResult = await fetchStartCase(
      "3",
      userData.CustomerId,
      userData.Token
    );

    setStartCaseData(startCaseResult.startCaseData);
    setSimulationData(startCaseResult.simulationData);
    setCaseData(startCaseResult.caseData);
    setPageDisplayed("Simulation");
  };

  const handleUpdateLocation = (locationId) => {
    const simulationDataWithUpdatedLocation = updateLocation(
      simulationData,
      locationId
    );
    setSimulationData(simulationDataWithUpdatedLocation);
  };

  const handleFinishExam = () => {
    // setPageDisplayed("Exam Completed");
    setPageDisplayed("Simulation Transition");
  };

  const handlePrematureExamExit = () => {
    // setPageDisplayed("Exam Paused");
    setPageDisplayed("Simulation Transition");
  };

  let applicationView = null;

  switch (pageDisplayed) {
    case "Simulation":
      applicationView = (
        <Simulation
          caseData={caseData}
          setAuthenticationError={() => null}
          simulationData={simulationData}
          userData={userData}
          userProfile={null} //userProfile is only used for calls to logFetchData and submitLogs. Most of this data is contained in the userData object
          updateLocation={handleUpdateLocation}
          fetchGrading={handleFinishExam}
          timedExam={true} // Likely won't support the untimed exam feature
          customTimeLimit={"none"} // Likely won't support the untimed exam feature, so this will remain set to none
          closeGrading={handlePrematureExamExit}
          networkLag={"none"} // Same as untimed exams, network lag is unliked to be supported
          startCaseData={startCaseData}
          route={WEB_SERVER_ROUTE}
          fetchOutstanding={false}
          primaryColor={"#0B335D"}
        />
      );
      break;
    default:
      applicationView = (
        <ExamTransitionPage
          breakTimePassed={breakTimePassed}
          setBreakTimePassed={setBreakTimePassed}
          handleStartExam={handleStartExam}
        />
      );
      break;
  }

  return <div style={{ height: windowHeight }}>{applicationView}</div>;
};

export default OSCEStudent;
