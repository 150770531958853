import React, { useContext } from "react";
import "./ExamRules.css";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OSCEAdminContext } from "../..";
import mapCategoryIdToCategory from "../../Util/MapCategoryIdToCategory";
import getCategoryIconFromString from "../../Util/getCategoryIconFromString";

const ExamRules = ({ student }) => {
  const { handleModifyUsersExamRules } = useContext(OSCEAdminContext);

  return (
    <div className="studentProfileExamRulesWrapper">
      <div className="studentProfilePageSectionHeaderWrapper">
        <div className="studentProfilePageExamRulesSectionHeaderWrapper">
          <h2 className="studentProfilePageSectionHeader">Exam Rules</h2>
          <p className="studentProfilePageExamRulesSectionHeaderSubtext">
            Break Time Allowed: {student.TotalBreakTime} Minutes
          </p>
        </div>
        <button
          className="studentProfilePageSectionHeaderButton"
          onClick={() => handleModifyUsersExamRules(student.CustomerId)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
          Modify Rules
        </button>
      </div>
      <div className="studentProfileExamRulesCasesContainer">
        <h3 className="studentProfileExamRulesCasesHeader">
          Cases Assigned ({student.SpecficCasesOut.length})
        </h3>
        <div className="studentProfileExamRulesCaseListContainer">
          {student.SpecficCasesOut.map((value) => (
            <div
              className="studentProfileExamRulesCaseCard"
              key={value.CaseSequence}
            >
              <FontAwesomeIcon
                icon={getCategoryIconFromString(
                  mapCategoryIdToCategory[value.CaseCategoryId]
                )}
              />
              <p className="studentProfileExamRulesCaseCardText">
                Case {value.CaseId} - {value.Diagnosis}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExamRules;
