import React from "react";
import "./MySchool.css";
import AccountsTableSection from "./AccountsTableSection/AccountsTableSection";
import StudentGroupsSection from "./StudentGroupsSection/StudentGroupsSection";
import SchoolOverviewSection from "./SchoolOverviewSection/SchoolOverviewSection";

const MySchool = () => {
  return (
    <div className="adminPortalMySchoolContainer">
      <SchoolOverviewSection />
      <StudentGroupsSection />
      <AccountsTableSection />
    </div>
  );
};

export default MySchool;
