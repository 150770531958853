const formatStudentSubtext = (student) => {
  let subtext = student.CustomerEmail;

  if (subtext !== "" && student.GroupId) {
    subtext += " | ";
  }

  if (student.GroupId) {
    subtext += `Group ${student.GroupId}`;
    if (student.GroupName) {
      subtext += ` - ${student.GroupName}`;
    }
  }

  if (subtext === "") {
    subtext = "No email or group assigned";
  }

  return subtext;
};

export default formatStudentSubtext;
