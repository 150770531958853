import calculatePerformance from "../calculatePerformance";

const getGroupCasesArray = (analyticsDataGroup, userData, analyticsData) => {
  const caseIdMap = {};
  userData.CaseLists.forEach((caseData) => {
    caseIdMap[caseData.CaseId] = caseData;
  });

  const casesArray = analyticsDataGroup.CompletedCases.map((caseData) => {
    const caseDetail = caseIdMap[caseData.CaseId];

    return {
      caseId: caseData.CaseId,
      caseTitle: caseDetail ? caseDetail.CaseTitle : "Unknown Case",
      caseDiagnosis: caseData.CaseDiagnosis,
      category: caseDetail ? caseDetail.CaseCategory : "Unknown Category",
      completionCount: caseData.CompletionCount,
      groupAverageGrade: parseFloat(caseData.AverageScore.toFixed(2)),
      schoolAverageGrade: parseFloat(
        analyticsData.SchoolSection.CategoryAverages[
          caseDetail.CaseCategory
        ].toFixed(2) || 0
      ),
      performance: calculatePerformance(
        caseData.AverageScore,
        analyticsData.SchoolSection.CategoryAverages[caseDetail.CaseCategory] ||
          0
      ),
    };
  }).sort((a, b) => a.caseId - b.caseId);

  return casesArray;
};

export default getGroupCasesArray;
