import React, { useContext, useState } from "react";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ChangePasswordModal = ({ closeModal }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const { displayNotification, route, userData, handleExpiredUserSession } =
    useContext(OSCEAdminContext);

  const handleConfirmClick = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/changepassword.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerId: userData.CustomerId,
        OldPassword: currentPassword,
        NewPassword: newPassword,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
        } else {
          throw new Error("Failed to change password");
        }
      })
      .then((data) => {
        if (data === "{Password updated}") {
          displayNotification("Password successfully updated");
          closeModal();
        } else displayNotification("Failed to change password");
      })
      .catch((error) => {
        displayNotification(error.message);
      });
    setFetchOutstanding(false);
  };

  const isConfirmDisabled = () => {
    return (
      currentPassword === "" ||
      newPassword === "" ||
      confirmNewPassword === "" ||
      newPassword !== confirmNewPassword
    );
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Change Password</h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel" htmlFor="currentPassword">
            Current Password
          </label>
          <input
            className="modalInput"
            type="password"
            id="currentPassword"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label className="modalInputLabel" htmlFor="newPassword">
            New Password
          </label>
          <input
            className="modalInput"
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label className="modalInputLabel" htmlFor="confirmNewPassword">
            Confirm New Password
          </label>
          <input
            className="modalInput"
            type="password"
            id="confirmNewPassword"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button className="modalCancelButton" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={handleConfirmClick}
              disabled={isConfirmDisabled()}
            >
              Confirm Change Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChangePasswordModal;
