import React from 'react';

class PhysicalOptions extends React.Component {

	constructor(props) {
		super(props);

		this.okClick = this.okClick.bind(this);
	}

	okClick() {
		if (this.props.physicalCheckboxes.interval || 
			this.props.physicalCheckboxes.appearance || 
			this.props.physicalCheckboxes.skin || 
			this.props.physicalCheckboxes.breasts || 
			this.props.physicalCheckboxes.lymph || 
			this.props.physicalCheckboxes.neck || 
			this.props.physicalCheckboxes.chest || 
			this.props.physicalCheckboxes.heart || 
			this.props.physicalCheckboxes.abdomen || 
			this.props.physicalCheckboxes.genitalia || 
			this.props.physicalCheckboxes.rectal || 
			this.props.physicalCheckboxes.spine || 
			this.props.physicalCheckboxes.neuro) {

			this.props.setPopup('confirmPhysical');
		}
	}

	render() {
		return (
			<div className='physical-options'>
				<div className='options-box'>
					<div className='popup-header'>History</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' 
							onClick={this.props.physicalCheckboxToggle.bind(this, 'interval')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.interval}/>
							<label className='options-box-label' >Interval / follow-up</label>
						</div>
					</div>
				</div>
				<div className='options-box'>
					<div className='popup-header'>Physical Examination</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' 
							onClick={this.props.physicalCheckboxToggle.bind(this, 'appearance')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.appearance}/>
							<label className='options-box-label' >Physical Appearance</label>
						</div>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'heart')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.heart}/>
							<label className='options-box-label' >Heart / Cardiovascular</label>
						</div>
					</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'skin')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.skin}/>
							<label className='options-box-label' >Skin</label>
						</div>
						<div className='options-box-checkbox' 
							onClick={this.props.physicalCheckboxToggle.bind(this, 'abdomen')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.abdomen}/>
							<label className='options-box-label' >Abdomen</label>
						</div>
					</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' 
							onClick={this.props.physicalCheckboxToggle.bind(this, 'breasts')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.breasts}/>
							<label className='options-box-label' >Breasts</label>
						</div>
						<div className='options-box-checkbox' 
							onClick={this.props.physicalCheckboxToggle.bind(this, 'genitalia')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.genitalia}/>
							<label className='options-box-label' >Genitalia</label>
						</div>
					</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'lymph')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.lymph}/>
							<label className='options-box-label' >Lymph Nodes</label>
						</div>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'rectal')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.rectal}/>
							<label className='options-box-label' >Rectal</label>
						</div>
					</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'neck')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.neck}/>
							<label className='options-box-label' >HEENT / Neck</label>
						</div>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'spine')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.spine}/>
							<label className='options-box-label' >Extremities / Spine</label>
						</div>
					</div>
					<div className='options-box-row'>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'chest')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.chest}/>
							<label className='options-box-label' >Chest / Lungs</label>
						</div>
						<div className='options-box-checkbox' onClick={this.props.physicalCheckboxToggle.bind(this, 'neuro')}>
							<input type='checkbox' checked={this.props.physicalCheckboxes.neuro}/>
							<label className='options-box-label' >Neuro / Psych</label>
						</div>
					</div>
					<div className='physical-options-footer'>
						<input className='simulation-button' type='button' value='OK' onClick={this.okClick}/>
						<input className='simulation-button' type='button' value='Clear' onClick={this.props.physicalCheckboxesClear}/>
					</div>
				</div>
			</div>
		)
	}
}

export default PhysicalOptions
