import React from "react";
import ReactDOM from "react-dom";
import CCSCases from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import OSCE from "./OSCE/OSCEIndex";

const APP_NAME = process.env.REACT_APP_APP_NAME;

if (APP_NAME === "OSCE") {
  ReactDOM.render(<OSCE />, document.getElementById("root"));
} else {
  ReactDOM.render(<CCSCases />, document.getElementById("root"));
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
