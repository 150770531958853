import React, { useContext } from "react";
import "./GradesSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faChevronRight,
  faLineChart,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";
import InformationCard from "../Components/InformationCard/InformationCard";
import { OSCEAdminContext } from "../..";

const GradesSection = ({ student }) => {
  const { handleChangePage } = useContext(OSCEAdminContext);

  const handleStudentGradesView = () => {
    const studentId = student.CustomerId;
    const studentName = `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`;

    handleChangePage("Student Grades", {
      studentId: studentId,
      backButtonText: `Back to ${studentName}`,
      prevPage: "Student",
    });
  };

  return (
    <div className="studentProfilePageGradesSectionWrapper">
      <div className="studentProfilePageSectionHeaderWrapper">
        <h2 className="studentProfilePageSectionHeader">Grades</h2>
      </div>
      <div className="studentProfilePageGradesInfoCardsWrapper">
        <InformationCard title="Exams Completed" text="1" />
        <InformationCard title="Cases Completed" text="4" />
        <InformationCard title="Avg Exam Grade" text="77.8%" />
      </div>
      <div className="studentProfilePageGradesDivider" />
      <div
        className="studentProfilePageGradesCardContainer"
        onClick={handleStudentGradesView}
      >
        <div className="studentProfilePageGradesExamNumberContainer">
          <h3 className="studentProfilePageGradesExamNumber">1</h3>
        </div>
        <div className="studentProfilePageGradesInfoContainer">
          <FontAwesomeIcon icon={faCalendar} />
          <h3 className="studentProfilePageGradesInfoTitle">Completion Date</h3>
          <p className="studentProfilePageGradesInfoText">Sept 7th, 2024</p>
        </div>
        <div className="studentProfilePageGradesInfoContainer">
          <FontAwesomeIcon icon={faLineChart} />
          <h3 className="studentProfilePageGradesInfoTitle">Exam Grade</h3>
          <p className="studentProfilePageGradesInfoText">77.8%</p>
        </div>
        <div className="studentProfilePageGradesCasesWrapper">
          {[1, 2, 3, 4].map((value) => (
            <div className="studentProfilePageGradesCasesItem" key={value}>
              <FontAwesomeIcon icon={faStethoscope} />
              <p className="studentProfilePageGradesCasesText">
                Case 7 - Myasthenia Gravis
              </p>
            </div>
          ))}
        </div>
        <button className="studentProfilePageGradesViewButton">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default GradesSection;
