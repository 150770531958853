import React, { useContext, useEffect, useState } from "react";
import StudentsHeader from "./ExamRulesDesktopModificationSection/StudentsHeader/StudentsHeader";
import StudentsContainer from "./ExamRulesDesktopModificationSection/StudentsContainer/StudentsContainer";
import filterStudents from "../../Home/StudentsFilter";
import { OSCEAdminContext } from "../..";
import { ExamRulesContext } from "../ExamRulesPage";

const ExamRulesMobileStudents = () => {
  const { userData } = useContext(OSCEAdminContext);
  const { setPageDisplayed } = useContext(ExamRulesContext);
  const [studentsSearchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("All Groups");
  const [filteredStudents, setFilteredStudents] = useState(userData.Students);

  useEffect(() => {
    setFilteredStudents(
      filterStudents(userData.Students, selectedGroup, studentsSearchTerm)
    );
  }, [studentsSearchTerm, selectedGroup]);

  return (
    <div className="examRulesMobilePageWrapper">
      <button
        className="examRulesMobileBackButton"
        onClick={() => setPageDisplayed("overview")}
      >
        Back to Rules Overview
      </button>
      <StudentsHeader
        searchTerm={studentsSearchTerm}
        setSearchTerm={setSearchTerm}
        selectedGroup={""}
        setSelectedGroup={() => null}
      />
      <StudentsContainer
        studentsList={filteredStudents}
        searchTerm={studentsSearchTerm}
        selectedGroup={selectedGroup}
      />
    </div>
  );
};

export default ExamRulesMobileStudents;
