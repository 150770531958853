import React, { useContext, useEffect, useState } from "react";
import "./AccountsTableSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import DesktopTableBody from "../../Components/StudentTable/TableBody/DesktopTableBody/DesktopTableBody";
import MobileTableBody from "../../Components/StudentTable/TableBody/MobileTableBody/MobileTableBody";
import ModifyAdminModal from "../../Components/Modals/ModifyAdminModal/ModifyAdminModal";
import { adminTableColumns, adminMobileTableData } from "./AdminTableData";
import {
  preceptorTableColumns,
  preceptorMobileTableData,
} from "./PreceptorTableData";
import ModifyPreceptorModal from "../../Components/Modals/ModifyPreceptorModal/ModifyPreceptorModal";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal/ConfirmationModal";
import SuccessModal from "../../Components/Modals/SuccessModal/SuccessModal";
import { OSCEAdminContext } from "../..";

const AccountsTableSection = () => {
  const {
    userData,
    mousePosition,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [tableDisplayed, setTableDisplayed] = useState(
    userData.CustType === "SuperAdmin" ? "Administrators" : "Preceptors"
  );
  const [showEditAdminModal, setShowEditAdminModal] = useState(false);
  const [showEditPreceptorModal, setShowEditPreceptorModal] = useState(false);
  const [showDeleteAdminModal, setShowDeleteAdminModal] = useState(false);
  const [showDeletePreceptorModal, setShowDeletePreceptorModal] =
    useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedPreceptor, setSelectedPreceptor] = useState(null);
  const [showAdminTableOptionsPopup, setShowAdminTableOptionsPopup] =
    useState(false);
  const [showPreceptorTableOptionsPopup, setShowPreceptorTableOptionsPopup] =
    useState(false);

  const handleAddAdmin = () => {
    setSelectedAdmin(null);
    setShowEditAdminModal(true);
  };

  const handleAddPreceptor = () => {
    setSelectedPreceptor(null);
    setShowEditPreceptorModal(true);
  };

  const handleDesktopTableClick = (index, event, value) => {
    if (tableDisplayed === "Administrators") {
      setSelectedAdmin(userData.Admins[index]);
      setShowAdminTableOptionsPopup(true);
    } else {
      setSelectedPreceptor(userData.Preceptors[index]);
      setShowPreceptorTableOptionsPopup(true);
    }
  };

  const handleEditAdminClick = () => {
    setShowEditAdminModal(true);
    setShowAdminTableOptionsPopup(false);
  };

  const handleDeleteAdminClick = () => {
    setShowDeleteAdminModal(true);
    setShowAdminTableOptionsPopup(false);
  };

  const handleEditPreceptorClick = () => {
    setShowEditPreceptorModal(true);
    setShowPreceptorTableOptionsPopup(false);
  };

  const handleDeletePreceptorClick = () => {
    setShowDeletePreceptorModal(true);
    setShowPreceptorTableOptionsPopup(false);
  };

  useEffect(() => {
    if (!showEditAdminModal) setSelectedAdmin(null);
  }, [showEditAdminModal]);

  useEffect(() => {
    if (!showDeleteAdminModal) setSelectedAdmin(null);
  }, [showDeleteAdminModal]);

  useEffect(() => {
    if (!showEditPreceptorModal) setSelectedPreceptor(null);
  }, [showEditPreceptorModal]);

  useEffect(() => {
    if (!showDeletePreceptorModal) setSelectedPreceptor(null);
  }, [showDeletePreceptorModal]);

  const fetchDeletePreceptor = async () => {
    return await fetch(`${route}/deleteaccount.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerIds: [selectedPreceptor.PreceptorId],
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return false;
        console.log(data);
        updateUserData({
          ...userData,
          Preceptors: userData.Preceptors.filter(
            (preceptor) =>
              preceptor.PreceptorId !== selectedPreceptor.PreceptorId
          ),
        });
        displayNotification(
          `Preceptor ${selectedPreceptor.PreceptorFirstName} ${selectedPreceptor.PreceptorLastName} deleted successfully.`
        );
        setSelectedPreceptor(null);
        return true;
      })
      .catch((error) => {
        console.error("Error:", error);
        displayNotification(
          `Error deleting preceptor ${selectedPreceptor.PreceptorFirstName} ${selectedPreceptor.PreceptorLastName}.`
        );
        return false;
      });
  };

  const fetchDeleteAdmin = async () => {
    return await fetch(`${route}/deleteaccount.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerIds: [selectedAdmin.AdminId],
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return false;
        console.log(data);
        updateUserData({
          ...userData,
          Admins: userData.Admins.filter(
            (admin) => admin.AdminId !== selectedAdmin.AdminId
          ),
        });
        displayNotification(
          `Admin ${selectedAdmin.AdminFirstName} ${selectedAdmin.AdminLastName} deleted successfully.`
        );
        setSelectedAdmin(null);
        return true;
      })
      .catch((error) => {
        console.error("Error:", error);
        displayNotification(
          `Error deleting admin ${selectedAdmin.AdminFirstName} ${selectedAdmin.AdminLastName}.`
        );
        return false;
      });
  };

  return (
    <>
      <div className="adminPortalMySchoolTableWrapper">
        <h2 className="adminPortalMySchoolTableTitleText">
          {userData.CustType === "SuperAdmin"
            ? "Non-Student Accounts"
            : "Preceptor Accounts"}
        </h2>
        <div className="adminPortalMySchoolTableOptionsWrapper">
          {userData.CustType === "SuperAdmin" && (
            <select
              className="adminPortalMySchoolTableSelect"
              value={tableDisplayed}
              onChange={(e) => setTableDisplayed(e.target.value)}
            >
              <option value="Administrators">Administrators</option>
              <option value="Preceptors">Preceptors</option>
            </select>
          )}
          {tableDisplayed === "Administrators" ? (
            <button
              className="adminPortalMySchoolTableAddButton"
              onClick={handleAddAdmin}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              Add Administrator
            </button>
          ) : (
            <button
              className="adminPortalMySchoolTableAddButton"
              onClick={handleAddPreceptor}
            >
              <FontAwesomeIcon icon={faUserPlus} />
              Add Preceptor
            </button>
          )}
        </div>
        {tableDisplayed === "Administrators" ? (
          <DesktopTableBody
            valuesList={userData.Admins}
            tableColumns={adminTableColumns}
            componentKey={"AdminId"}
            tableName={"Desktop Admin Accounts"}
            onValueClick={handleDesktopTableClick}
          />
        ) : (
          <DesktopTableBody
            valuesList={userData.Preceptors}
            tableColumns={preceptorTableColumns}
            componentKey={"PreceptorId"}
            tableName={"Desktop Preceptor Accounts"}
            onValueClick={handleDesktopTableClick}
          />
        )}
        {tableDisplayed === "Administrators" ? (
          <MobileTableBody
            valuesList={userData.Admins}
            mobileTableData={adminMobileTableData}
            componentKey={"AdminId"}
            tableName={"Mobile Admin Accounts"}
            onButtonsClick={handleDesktopTableClick}
          />
        ) : (
          <MobileTableBody
            valuesList={userData.Preceptors}
            mobileTableData={preceptorMobileTableData}
            componentKey={"PreceptorId"}
            tableName={"Mobile Preceptor Accounts"}
            onButtonsClick={handleDesktopTableClick}
          />
        )}
      </div>
      {showEditAdminModal && (
        <ModifyAdminModal
          setShowModifyAdminModal={setShowEditAdminModal}
          selectedAdmin={selectedAdmin}
        />
      )}
      {showEditPreceptorModal && (
        <ModifyPreceptorModal
          setShowModifyPreceptorModal={setShowEditPreceptorModal}
          selectedPreceptor={selectedPreceptor}
        />
      )}
      {showDeleteAdminModal && (
        <ConfirmationModal
          header="Delete Administrator?"
          text={`Delete administrator ${selectedAdmin?.AdminFirstName} ${selectedAdmin?.AdminMiddleName} ${selectedAdmin?.AdminLastName}?`}
          closeModal={() => setShowDeleteAdminModal(false)}
          confirmFunction={fetchDeleteAdmin}
        />
      )}
      {showDeletePreceptorModal && (
        <ConfirmationModal
          header="Delete Preceptor?"
          text={`Delete preceptor ${selectedPreceptor?.PreceptorFirstName} ${selectedPreceptor?.PreceptorMiddleName} ${selectedPreceptor?.PreceptorLastName}?`}
          closeModal={() => setShowDeletePreceptorModal(false)}
          confirmFunction={fetchDeletePreceptor}
        />
      )}
      {showAdminTableOptionsPopup && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowAdminTableOptionsPopup}
          showButtonListPopup={showAdminTableOptionsPopup}
        >
          <button onClick={handleEditAdminClick}>
            <FontAwesomeIcon icon={faPenToSquare} />
            Edit Administrator
          </button>
          {!selectedAdmin?.superAdmin && (
            <button onClick={handleDeleteAdminClick}>
              <FontAwesomeIcon icon={faTrashCan} />
              Delete Administrator
            </button>
          )}
        </ButtonListPopup>
      )}
      {showPreceptorTableOptionsPopup && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowPreceptorTableOptionsPopup}
          showButtonListPopup={showPreceptorTableOptionsPopup}
        >
          <button onClick={handleEditPreceptorClick}>
            <FontAwesomeIcon icon={faPenToSquare} />
            Edit Preceptor
          </button>
          <button onClick={handleDeletePreceptorClick}>
            <FontAwesomeIcon icon={faTrashCan} />
            Delete Preceptor
          </button>
        </ButtonListPopup>
      )}
    </>
  );
};

export default AccountsTableSection;
