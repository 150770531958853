import React from "react";
import MDSpinner from "react-md-spinner";
import SimulationTime from "./SimulationTime";

class ConfirmPhysicalPopup extends React.Component {
  constructor() {
    super();
    this.state = {
      advancingPhysical: false,
    };
  }

  advancePhysical = async (time) => {
    this.setState({ advancingPhysical: true });
    await this.props.advancePhysical(time);
    this.setState({ advancingPhysical: false });
  };

  render() {
    // creates list of physical examinations with times
    // add times together and calculate time advance
    let time = 0;

    let interval;
    if (this.props.physicalCheckboxes.interval) {
      time += 2;
      interval = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Interval / Follow up</div>
          <div>2 min</div>
        </div>
      );
    }
    let appearance;
    if (this.props.physicalCheckboxes.appearance) {
      time += 1;
      appearance = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">General Appearance</div>
          <div>1 min</div>
        </div>
      );
    }
    let skin;
    if (this.props.physicalCheckboxes.skin) {
      time += 1;
      skin = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Skin</div>
          <div>1 min</div>
        </div>
      );
    }
    let breasts;
    if (this.props.physicalCheckboxes.breasts) {
      time += 1;
      breasts = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Breasts</div>
          <div>1 min</div>
        </div>
      );
    }
    let lymph;
    if (this.props.physicalCheckboxes.lymph) {
      time += 1;
      lymph = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Lymph Nodes</div>
          <div>1 min</div>
        </div>
      );
    }
    let neck;
    if (this.props.physicalCheckboxes.neck) {
      time += 2;
      neck = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">HEENT / Neck</div>
          <div>2 min</div>
        </div>
      );
    }
    let chest;
    if (this.props.physicalCheckboxes.chest) {
      time += 1;
      chest = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Chest / Lungs</div>
          <div>1 min</div>
        </div>
      );
    }
    let heart;
    if (this.props.physicalCheckboxes.heart) {
      time += 1;
      heart = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Heart / Cardiovascular</div>
          <div>1 min</div>
        </div>
      );
    }
    let abdomen;
    if (this.props.physicalCheckboxes.abdomen) {
      time += 1;
      abdomen = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Abdomen</div>
          <div>1 min</div>
        </div>
      );
    }
    let genitalia;
    if (this.props.physicalCheckboxes.genitalia) {
      time += 2;
      genitalia = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Genitalia</div>
          <div>2 min</div>
        </div>
      );
    }
    let rectal;
    if (this.props.physicalCheckboxes.rectal) {
      time += 2;
      rectal = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Rectal</div>
          <div>2 min</div>
        </div>
      );
    }
    let spine;
    if (this.props.physicalCheckboxes.spine) {
      time += 1;
      spine = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Extremities / Spine</div>
          <div>1 min</div>
        </div>
      );
    }
    let neuro;
    if (this.props.physicalCheckboxes.neuro) {
      time += 1;
      neuro = (
        <div className="confirm-choice">
          <div className="confirm-choice-name">Neuro / Psych</div>
          <div>1 min</div>
        </div>
      );
    }

    //if minutes overflow increase hours
    let simulationTime = {
      minutes: this.props.simulationTime.minutes,
      hours: this.props.simulationTime.hours,
      days: this.props.simulationTime.days,
    };
    simulationTime.minutes += time;
    if (simulationTime.minutes > 59) {
      simulationTime.minutes -= 60;
      simulationTime.hours++;
      if (simulationTime.hours > 23) {
        simulationTime.hours -= 24;
        simulationTime.days++;
      }
    }

    return (
      <div className="popup-container">
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <div className="popup-header">Confirm H&P Choices</div>
            <div className="popup-content">
              {interval}
              {appearance}
              {skin}
              {breasts}
              {lymph}
              {neck}
              {chest}
              {heart}
              {abdomen}
              {genitalia}
              {rectal}
              {spine}
              {neuro}
              <div>{"Total Minutes: " + time}</div>
              <div className="confirm-choice white-space">
                {"This will advance clock to: "}
                <SimulationTime simulationTime={simulationTime} />
              </div>
            </div>
            {this.state.advancingPhysical ? (
              <div className="options-footer-loading-container">
                <MDSpinner size={25} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <div className="options-footer">
                <input
                  className="simulation-button button-gap"
                  type="button"
                  value="OK"
                  onClick={() => this.advancePhysical(time)}
                />
                <input
                  className="simulation-button"
                  type="button"
                  value="Cancel"
                  onClick={this.props.closePopup}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmPhysicalPopup;
