import React, { useContext, useState } from "react";
import "./ModifyPreceptorModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AddAccountsModal from "../ListItemsSelectorModal/ListItemsSelectorModal";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ModifyPreceptorModal = ({
  setShowModifyPreceptorModal,
  selectedPreceptor,
}) => {
  const {
    userData,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [firstName, setFirstName] = useState(
    selectedPreceptor ? selectedPreceptor.PreceptorFirstName : ""
  );
  const [middleName, setMiddleName] = useState(
    selectedPreceptor ? selectedPreceptor.PreceptorMiddleName : ""
  );
  const [lastName, setLastName] = useState(
    selectedPreceptor ? selectedPreceptor.PreceptorLastName : ""
  );
  const [email, setEmail] = useState(
    selectedPreceptor ? selectedPreceptor.PreceptorEmail : ""
  );
  const [selectedStudents, setSelectedStudents] = useState(
    selectedPreceptor
      ? selectedPreceptor.StudentIds.map((student) => {
          return (
            userData.Students.find(
              (userDataStudent) => userDataStudent.CustomerId === student
            ) ?? false
          );
        }).filter((student) => student)
      : []
  );
  const [showAddAccountsModal, setShowAddAccountsModal] = useState(false);

  const handleUpdatePreceptorStudentsAccounts = (CustomerIds) => {
    setSelectedStudents(
      CustomerIds.map((id) =>
        userData.Students.find((student) => student.CustomerId === id)
      )
    );
    return true;
  };

  const handleRemoveStudent = (student) => {
    setSelectedStudents(
      selectedStudents.filter((s) => s.CustomerId !== student.CustomerId)
    );
  };

  const fetchModifyPreceptor = async () => {
    setFetchOutstanding(true);
    const selectedStudentIds = selectedStudents.map(
      (student) => student.CustomerId
    );
    const body = {
      CustomerId: selectedPreceptor.PreceptorId,
      CustomerFirstName: firstName,
      CustomerMiddleName: middleName,
      CustomerLastName: lastName,
      CustomerEmail: email,
      StudentIds: selectedStudentIds,
      StudentIdsToRemove: selectedPreceptor.StudentIds.filter(
        (studentId) => !selectedStudentIds.includes(studentId)
      ),
    };

    await fetch(`${route}/editadminorpreceptor.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        updateUserData({
          ...userData,
          Preceptors: userData.Preceptors.map((preceptor) =>
            preceptor.PreceptorId === selectedPreceptor.PreceptorId
              ? {
                  ...preceptor,
                  PreceptorEmail: email,
                  PreceptorFirstName: firstName,
                  PreceptorLastName: lastName,
                  PreceptorMiddleName: middleName,
                  StudentIds: selectedStudents.map(
                    (student) => student.CustomerId
                  ),
                }
              : preceptor
          ),
        });
        setShowModifyPreceptorModal(false);
        displayNotification(
          `Preceptor ${firstName} ${lastName} modified successfully.`
        );
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchOutstanding(false);
      });
  };

  const fetchAddPreceptor = async () => {
    setFetchOutstanding(true);
    const body = {
      FirstName: firstName,
      MiddleName: middleName,
      LastName: lastName,
      Email: email,
      StudentIds: selectedStudents.map((student) =>
        student ? student.CustomerId : 0
      ),
    };

    await fetch(`${route}/makenewpreceptor.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          if (response.status === 400) {
            throw new Error(await response.text());
          } else {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        setShowModifyPreceptorModal(false);
        displayNotification(
          `Preceptor ${firstName} ${lastName} added successfully.`
        );
        updateUserData({
          ...userData,
          Preceptors: [
            ...userData.Preceptors,
            {
              PreceptorEmail: email,
              PreceptorFirstName: firstName,
              PreceptorId: data.PreceptorId,
              PreceptorLastName: lastName,
              PreceptorMiddleName: middleName,
              StudentIds: selectedStudents.map((student) =>
                student ? student.CustomerId : 0
              ),
              UniqueLoginId: data.UniqueSignInId,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchOutstanding(false);
        displayNotification(`${error}`);
      });
  };

  return (
    <>
      <div className="modalPageWrapper">
        <div className="modalBackdrop" />
        <div className="modalContainerSmall">
          <h2 className="modalHeader">
            {selectedPreceptor ? "Modify" : "Add"} Preceptor
          </h2>
          <div className="modalInputWrapper">
            <label htmlFor="preceptorFirstName" className="modalInputLabel">
              First Name
            </label>
            <input
              type="text"
              id="preceptorFirstName"
              className="modalInput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="modalInputWrapper">
            <label htmlFor="preceptorMiddleName" className="modalInputLabel">
              Middle Name
            </label>
            <input
              type="text"
              id="preceptorMiddleName"
              className="modalInput"
              value={middleName}
              onChange={(e) => setMiddleName(e.target.value)}
            />
          </div>
          <div className="modalInputWrapper">
            <label htmlFor="preceptorLastName" className="modalInputLabel">
              Last Name
            </label>
            <input
              type="text"
              id="preceptorLastName"
              className="modalInput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className="modalInputWrapper">
            <label htmlFor="preceptorEmail" className="modalInputLabel">
              Email
            </label>
            <input
              type="email"
              id="preceptorEmail"
              className="modalInput"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="modalInputWrapper">
            <label htmlFor="preceptorStudents" className="modalInputLabel">
              Students ({selectedStudents.length})
            </label>
            <div className="modalStudentList">
              {selectedStudents.map((student) => (
                <div className="modalStudentListItem" key={student.CustomerId}>
                  <p className="modalStudentListItemText">
                    {student.CustomerFirstName} {student.CustomerMiddleName}{" "}
                    {student.CustomerLastName}
                  </p>
                  <button
                    className="modalStudentListRemoveButton"
                    onClick={() => handleRemoveStudent(student)}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </div>
              ))}
              <button
                className="modalStudentListAddButton"
                onClick={() => setShowAddAccountsModal(true)}
              >
                + Add Students
              </button>
            </div>
          </div>
          {fetchOutstanding ? (
            <div className="modalSpinnerWrapper">
              <MDSpinner size={35} singleColor="#2c3e50" />
            </div>
          ) : (
            <div className="modalButtonsWrapper">
              <button
                className="modalCancelButton"
                onClick={() => setShowModifyPreceptorModal(false)}
              >
                Cancel
              </button>
              <button
                className="modalConfirmButton"
                onClick={
                  selectedPreceptor ? fetchModifyPreceptor : fetchAddPreceptor
                }
              >
                Save Changes
              </button>
            </div>
          )}
        </div>
      </div>
      {showAddAccountsModal && (
        <AddAccountsModal
          closeModal={() => setShowAddAccountsModal(false)}
          confirmSelection={handleUpdatePreceptorStudentsAccounts}
          initialSelectedElements={selectedStudents.map(
            (student) => student.CustomerId
          )}
          dataLabel={"Students"}
          subtitle={`Preceptor - ${firstName} ${middleName} ${lastName}`}
          dataArray={userData.Students}
          dataValueUniqueId={"CustomerId"}
          dataValuesToDisplay={[
            "CustomerFirstName",
            "CustomerMiddleName",
            "CustomerLastName",
          ]}
        />
      )}
    </>
  );
};

export default ModifyPreceptorModal;
