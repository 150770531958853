import React from "react";
import "./InformationCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InformationCard = ({ title, text, icon, onClick }) => {
  return (
    <div className="stduentProfilePageInformationCard">
      <h2 className="studentProfilePageInformationCardTitle">{title}</h2>
      {icon && (
        <button
          className="studentProfilePageInformationCardEditButton"
          onClick={onClick}
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      )}
      <p className="studentProfilePageInformationCardData">{text}</p>
    </div>
  );
};

export default InformationCard;
