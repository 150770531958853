import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

const CategoriesLineChart = ({
  parentContainerId,
  graphData,
  scale,
  graphTitle,
}) => {
  const yScale =
    scale === "0-100"
      ? {
          type: "linear",
          display: false,
          position: "left",
          min: 0,
          max: 100,
        }
      : {
          type: "linear",
          display: false,
          position: "left",
        };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // animation: {
    //   duration: 300, // Animation duration in milliseconds
    //   easing: "easeOutExpo", // Easing function for smooth scaling
    //   onProgress: (animation) => {
    //     const { currentStep, numSteps } = animation;
    //     console.log(currentStep);
    //     const scaleFactor = 0.95 + (0.05 * currentStep) / numSteps; // Animate scale
    //     animation.chart.ctx.scale(scaleFactor, scaleFactor);
    //     animation.chart.ctx.globalAlpha = (currentStep / numSteps).toFixed(2); // Animate opacity
    //   },
    // },
    layout: {
      padding: {
        top: 10,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        position: "top", // Legend position: 'top', 'bottom', 'left', or 'right'
        align: "start",
        labels: {
          font: {
            size: 14, // Font size for legend text
            family: "'Roboto'", // Font family
          },
          color: "#333", // Text color for legend labels
          padding: 10, // Padding around the legend items
          boxWidth: 0, // Width of the color box next to each legend item
          boxHeight: 0, // Height of the color box
          usePointStyle: false, // Use point styles instead of box for the legend items
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            // Custom title for the tooltip
            const item = tooltipItems[0]; // Access the first item in the tooltip
            return `${item.label}`; // Dynamic or static title
          },
          label: (tooltipItem) => {
            // Optional: Customize the tooltip label as well
            return `Avg Score: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      y: yScale,
      x: {
        display: false,
      },
    },
  };

  const labels = graphData.map((data) => data.date);

  const gradientRef = useRef(null);

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    if (!gradientRef.current) {
      // Create the gradient only once
      const gradient = ctx.createLinearGradient(0, canvas.height, 0, 0);
      gradient.addColorStop(0, "rgba(11, 51, 93, 0.1)"); // Fully transparent at the bottom
      gradient.addColorStop(0.4, "rgba(11, 51, 93, 0.3)"); // Partially transparent at 30%
      gradient.addColorStop(0.7, "rgba(11, 51, 93, 0.8)"); // Less transparent at 70%
      gradient.addColorStop(1, "rgba(11, 51, 93, 1)"); // Fully opaque at the top
      gradientRef.current = gradient;
    }

    return {
      labels,
      datasets: [
        {
          label: graphTitle,
          data: graphData.map((data) => data.value),
          borderColor: "#0b335d",
          pointBackgroundColor: "#0b335d",
          pointBorderColor: "white",
          pointBorderWidth: 2,
          tension: 0.2,
          fill: true,
          backgroundColor: gradientRef.current,
          yAxisID: "y",
        },
      ],
    };
  };

  const [chartHeight, setChartHeight] = useState(0);

  useEffect(() => {
    const handleHeightUpdate = () => {
      const card = document.getElementById(parentContainerId);
      if (card) {
        const newHeight =
          window.innerWidth < 600 ? card.offsetHeight / 2 : card.offsetHeight;
        setChartHeight(newHeight);
      }
    };

    handleHeightUpdate();
    window.addEventListener("resize", handleHeightUpdate);
    return () => window.removeEventListener("resize", handleHeightUpdate);
  }, [setChartHeight, parentContainerId]);

  return (
    <div
      style={{
        transform: "scaleX(1.034) scaleY(1.065)",
        height: chartHeight,
        width: "100%",
        overflow: "hidden",
        borderRadius: "0px 0px 23px 0px",
      }}
    >
      <Line options={options} data={data} />
    </div>
  );
};

export default CategoriesLineChart;
