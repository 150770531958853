import React, { useContext, useState } from "react";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ModifySchoolNameModal = ({ setShowModifySchoolName, schoolName }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [newSchoolName, setNewSchoolName] = useState(schoolName);
  const {
    route,
    userData,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const fetchModifySchoolName = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/changeschoolname.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerId: userData.CustomerId,
        NewSchoolName: newSchoolName,
        LogoBackgroundColor: userData.SchoolLogoBackgroundColor,
        BreakTitleText: userData.SchoolBreakTitleText,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        updateUserData({ ...userData, SchoolName: newSchoolName });
        displayNotification(`School name changed to "${newSchoolName}"`);
        setShowModifySchoolName(false);
      })
      .catch((error) => {
        setFetchOutstanding(false);
        console.error(error);
        displayNotification("Failed to change school name");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Modify School Name</h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel">School Name</label>
          <input
            className="modalInput"
            type="text"
            value={newSchoolName}
            onChange={(e) => setNewSchoolName(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifySchoolName(false)}
            >
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={fetchModifySchoolName}
            >
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifySchoolNameModal;
