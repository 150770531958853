import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faDownload,
  faFileArrowDown,
  faFileArrowUp,
  faMagnifyingGlass,
  faSortDown,
  faSortUp,
  faUserPlus,
  faUsers,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import "./TableHeader.css";
import { useContext, useRef, useState } from "react";
import ButtonListPopup from "../../ButtonListPopup/ButtonListPopup";
import { OSCEAdminContext } from "../../..";
import { tableColumns } from "../../../Home/StudentTableData";

const GroupSelector = ({ setGroupSelected, userData, groupSelected }) => {
  return (
    <select
      onChange={(e) => setGroupSelected(e.target.value)}
      className="adminPortalTableHeaderSelect"
      value={groupSelected}
    >
      <option value="All Groups">View All Student Groups</option>
      {userData.Groups.map((group) => (
        <option
          key={`${group.groupId} - ${group.GroupName}`}
          value={group.groupId}
        >
          {group.groupId}
          {" - "}
          {group.GroupName}
        </option>
      ))}
    </select>
  );
};

const TableHeader = ({
  searchTerm,
  setSearchTerm,
  setShowUploadCSVModal,
  setShowAddStudentModal,
  groupSelected,
  setGroupSelected,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
}) => {
  const [showManageStudentsPopup, setShowManageStudentsPopup] = useState(false);
  const headerContainerRef = useRef(null);
  const { route, userData, mousePosition, handleExpiredUserSession } =
    useContext(OSCEAdminContext);
  const [isMobileBurgerToggled, setIsMobileBurgerToggled] = useState(false);

  const handleUploadCSVClick = () => {
    setShowUploadCSVModal(true);
    setShowManageStudentsPopup(false);
  };

  const handleAddStudentClick = () => {
    setShowAddStudentModal(true);
    setShowManageStudentsPopup(false);
  };

  const downloadUsersCSV = async () => {
    await fetch(`${route}/downloadcsv.webapi`, {
      method: "GET",

      headers: {
        Token: userData.Token,
      },
    })
      .then((response) => {
        if (response.ok) return response.blob();
        else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else throw new Error("Error downloading Users CSV");
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = "OSCE Users.csv";
        a.click();
        a.remove();
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="adminPortalTableHeaderContainer" ref={headerContainerRef}>
      <h1>Students</h1>
      <button
        className="adminPortalTableHeaderMobileBurgerButton"
        onClick={() => setIsMobileBurgerToggled(!isMobileBurgerToggled)}
      >
        {isMobileBurgerToggled ? (
          <FontAwesomeIcon icon={faX} />
        ) : (
          <FontAwesomeIcon icon={faBars} />
        )}
      </button>
      <div className="adminPortalTableHeaderControlWrapper">
        <div className="adminPortalTableHeaderSearchContainer">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input
            placeholder="Search The Student Table"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <GroupSelector
          key={"Desktop Group Selector"}
          setGroupSelected={setGroupSelected}
          userData={userData}
          groupSelected={groupSelected}
        />
        <span />
        <button
          onClick={() => setShowManageStudentsPopup(true)}
          className="adminPortalTableHeaderButton"
        >
          <FontAwesomeIcon icon={faUsers} />
          Manage Students
        </button>
      </div>
      {isMobileBurgerToggled && (
        <div className="adminPortalTableHeaderControlMobileWrapper">
          <div className="adminPortalTableHeaderMobileItemWrapper">
            <div className="adminPortalTableHeaderSearchContainer">
              <FontAwesomeIcon icon={faMagnifyingGlass} />
              <input
                placeholder="Search The Student Table"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="adminPortalTableHeaderMobileItemWrapper">
            <button
              onClick={() => setShowManageStudentsPopup(true)}
              className="adminPortalTableHeaderButton"
            >
              <FontAwesomeIcon icon={faUsers} />
              Manage Students
            </button>
          </div>
          <div className="adminPortalTableHeaderMobileItemWrapper">
            <GroupSelector
              key={"Mobile Group Selector"}
              setGroupSelected={setGroupSelected}
              userData={userData}
              groupSelected={groupSelected}
            />
          </div>
          <div className="adminPortalTableHeaderMobileItemWrapper adminPortalTableHeaderSortWrapper">
            <select
              className="adminPortalTableHeaderSelect"
              onChange={(e) => setSortBy(e.target.value)}
              value={sortBy}
            >
              <option value={""}>Default Sort</option>
              {tableColumns.map((column) => (
                <option key={column.label} value={column.label}>
                  Sort By {column.label}
                </option>
              ))}
            </select>
            {sortBy && (
              <button
                className="adminPortalTableHeaderSortOrderButton"
                onClick={() =>
                  setSortDirection(sortDirection === "asc" ? "desc" : "asc")
                }
              >
                <FontAwesomeIcon
                  icon={sortDirection === "asc" ? faSortDown : faSortUp}
                  style={{ top: sortDirection === "asc" ? -5 : 5 }}
                />
              </button>
            )}
          </div>
        </div>
      )}
      {showManageStudentsPopup && (
        <ButtonListPopup
          showButtonListPopup={showManageStudentsPopup}
          setShowButtonListPopup={setShowManageStudentsPopup}
          mousePos={{
            x: mousePosition.x - window.innerWidth * 0.05,
            y:
              mousePosition.y -
              headerContainerRef.current.getBoundingClientRect().top -
              window.scrollY,
          }}
        >
          <button onClick={handleUploadCSVClick}>
            <FontAwesomeIcon icon={faFileArrowUp} />
            Upload CSV
          </button>
          <a href={`${route}/sample_import.csv`}>
            <FontAwesomeIcon icon={faFileArrowDown} />
            Download CSV Template
          </a>
          <button onClick={downloadUsersCSV}>
            <FontAwesomeIcon icon={faDownload} />
            Download Users CSV
          </button>
          <button onClick={handleAddStudentClick}>
            <FontAwesomeIcon icon={faUserPlus} /> Add Account
          </button>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default TableHeader;
