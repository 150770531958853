import React, { useContext, useEffect, useRef, useState } from "react";
import formatFeedbackForCase from "../formatFeedbackForCase";
import ActionLog from "../../../../NewGrading/Components/ActionLog/ActionLog";
import CaseDataTabs from "./CaseDataTabs";
import { OSCEAdminContext } from "../..";
import formatTimeString from "../../../../Functions/FormatTimeString";
import formatDateString from "../../../../Functions/FormatDateString";

const CaseDataComponent = ({ casesArray, examGuid }) => {
  const [selectedActionLogItem, setSelectedActionLogItem] = useState(0);
  const [selectedCaseIndex, setSelectedCaseIndex] = useState(0);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const { userData } = useContext(OSCEAdminContext);

  const {
    CaseTitle,
    Diagnosis,
    CaseCategory,
    LastAttemptDate,
    CaseGrade,
    GroupGrade,
    SchoolGrade,
    AllSchoolsGrade,
    FeedbackForCase,
  } = casesArray[0];

  const { feedbackSections, SUMMARY, ActionLogList, ActionLogCompletionDate } =
    formatFeedbackForCase(FeedbackForCase);

  useEffect(() => {
    const container = containerRef.current;
    setContainerWidth(container.offsetWidth);

    window.addEventListener("resize", () => {
      setContainerWidth(container.offsetWidth);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setContainerWidth(container.offsetWidth);
      });
    };
  }, [containerRef]);

  const caseGuid = FeedbackForCase.CaseGuid;

  return (
    <div
      className="adminIndividualStudentGradesPageExamGradeBreakdownWrapper"
      ref={containerRef}
    >
      <CaseDataTabs
        casesArray={casesArray}
        parentContainerWidth={containerWidth}
        selectedCaseIndex={selectedCaseIndex}
        setSelectedCaseIndex={setSelectedCaseIndex}
        examGuid={examGuid}
      />
      <div
        className="adminIndividualStudentGradesPageExamGradeBreakdownContainer"
        style={{ zIndex: casesArray.length + 1 }}
      >
        <div>
          <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseTitleContainer">
            <h3>
              {CaseTitle} - {Diagnosis}
            </h3>
            <p>
              Case {selectedCaseIndex + 1} | {CaseCategory} | Completed:{" "}
              {formatDateString(LastAttemptDate, userData.TimeZone)}{" "}
              {formatTimeString(LastAttemptDate, userData.TimeZone)}
            </p>
          </div>
          <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseScoresWrapper">
            <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseScoreContainer">
              <h4>Student Score</h4>
              <p>{CaseGrade.toFixed(2)}%</p>
            </div>
            <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseScoreContainer">
              <h4>Group Average Score</h4>
              <p>{GroupGrade.toFixed(2)}%</p>
            </div>
            <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseScoreContainer">
              <h4>School Average Score</h4>
              <p>{SchoolGrade.toFixed(2)}%</p>
            </div>
            <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseScoreContainer">
              <h4>All Schools Average Score</h4>
              <p>{AllSchoolsGrade.toFixed(2)}%</p>
            </div>
          </div>
          {feedbackSections.map((section, sectionIndex) =>
            section.comments.length > 0 ? (
              <div
                className="adminIndividualStudentGradesPageExamGradeBreakdownCaseFeedbackSectionContainer"
                key={`Comment Section Container - ${caseGuid} - ${sectionIndex}`}
              >
                <h4>
                  {section.title}: {section.grade}%
                </h4>
                <p>Weight given for total score: {section.weight}%</p>
                <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseFeedbackSectionCommentWrapper">
                  {section.comments.map((comment, commentIndex) => (
                    <div
                      className={`adminIndividualStudentGradesPageExamGradeBreakdownCaseFeedbackSectionCommentContainer ${
                        comment.GradeColor === "Incorrect - Red"
                          ? "negativeFeedbackComment"
                          : comment.GradeColor === "Correct - Green"
                          ? "positiveFeedbackComment"
                          : "neutralFeedbackComment"
                      }`}
                      key={`Grade Comment - ${caseGuid} - ${sectionIndex} - ${commentIndex}`}
                    >
                      <h5>{comment.EventName}</h5>
                      {comment.OrderNames ? (
                        <ul>
                          {comment.OrderNames.split(",").map((order, index) => (
                            <li
                              key={`Grade Comment List Item - ${caseGuid} - ${sectionIndex} - ${commentIndex} - ${index}`}
                            >
                              {order}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                      {comment.GradeExplanation ? (
                        <p>
                          <strong style={{ fontWeight: 500 }}>Reason:</strong>{" "}
                          {comment.GradeExplanation}
                        </p>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            ) : null
          )}
          <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseFeedbackCaseSummaryContainer">
            <h4>Case Summary</h4>
            <p>{SUMMARY}</p>
          </div>
          {ActionLogList.length > 0 ? (
            <ActionLog
              actionLog={ActionLogList}
              selectedActionLogItem={selectedActionLogItem}
              key={`Action Log - ${caseGuid}`}
              updateSelectedActionLogItem={(newSelection) =>
                setSelectedActionLogItem(newSelection)
              }
              completionDate={ActionLogCompletionDate}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CaseDataComponent;
