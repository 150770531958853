import React from "react";
import "./NewUnauthenticatedPopup.css";
import { motion } from "framer-motion";

export default function NewUnauthenticatedPopup(props) {
  return (
    <div className="unauthenticatedPageContainer">
      <motion.div
        className="unauthenticatedBackdrop"
        initial={props.animationsDisabled ? false : { opacity: 0 }}
        animate={props.animationsDisabled ? false : { opacity: 1 }}
        exit={props.animationsDisabled ? false : { opacity: 0 }}
        transition={{ duration: 0.2 }}
        key="confirmationPopupBackdrop"
      />
      <motion.div
        className="unauthenticatedContainer"
        initial={
          props.animationsDisabled
            ? false
            : {
                y: 30,
                opacity: 0,
                translateX: "-50%",
                translateY: "-50%",
                scale: 0.9,
              }
        }
        animate={
          props.animationsDisabled
            ? false
            : {
                y: 0,
                opacity: 1,
                translateX: "-50%",
                translateY: "-50%",
                scale: 1,
              }
        }
        exit={
          props.animationsDisabled
            ? false
            : {
                y: 30,
                opacity: 0,
                translateX: "-50%",
                translateY: "-50%",
                scale: 0.9,
              }
        }
        transition={{ duration: 0.2 }}
        key="confirmationPopupContainer"
      >
        <h2 className="unauthenticatedHeader">You have been logged out</h2>
        <h4 className="unauthenticatedDescription">
          You are no longer logged into this session and will have to re-login.
        </h4>
        <h3 className="unauthenticatedListHeader">
          Common reasons you may see this message
        </h3>
        <ul className="unauthenticatedList">
          <li className="unauthenticatedListItem">
            You have signed in on another device
          </li>
          <li className="unauthenticatedListItem">
            You have been idle for too long
          </li>
          {!props.hideTOSBullet && (
            <li className="unauthenticatedListItem">
              You have shared your account details with someone else. This is
              not allowed. Please refer to our terms of service.
            </li>
          )}
          <li className="unauthenticatedListItem">
            In some cases, your account may have been compromised. If you
            believe this is the case, change your password immediately.
          </li>
        </ul>
        <button
          className="unauthenticatedButton tertiaryButton"
          onClick={() => window.location.reload()}
        >
          Return to Login
        </button>
      </motion.div>
    </div>
  );
}
