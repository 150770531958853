import getGroupCasesArray from "./getGroupCasesArray";
import getGroupCategoriesArray from "./getGroupCategoriesArray";

const getGroupsPageData = (analyticsData, userData) => {
  return analyticsData.GroupsSection.Groups.map((group) => ({
    groupId: group.GroupId,
    groupName: group.GroupName,
    studentsInGroup: group.StudentCount,
    groupAverageGrade: parseFloat(group.OverallAverage.toFixed(2)),
    schoolAverageGrade: parseFloat(
      analyticsData.StudentsSection.AverageScorePerStudent.toFixed(2)
    ),
    categories: getGroupCategoriesArray(group, analyticsData),
    cases: getGroupCasesArray(group, userData, analyticsData),
  }));
};

export default getGroupsPageData;
