import calculatePerformance from "../calculatePerformance";

const getGroupCategoriesArray = (group, analyticsData) => {
  return Object.entries(group.CategoryAverages).map(
    ([categoryName, categoryScore]) => {
      const schoolAverage =
        analyticsData.SchoolSection.CategoryAverages[categoryName] || 0;
      return {
        categoryName,
        groupAverageGrade: parseFloat(categoryScore.toFixed(2)),
        schoolAverageGrade: parseFloat(schoolAverage.toFixed(2)),
        performance: calculatePerformance(categoryScore, schoolAverage),
      };
    }
  );
};

export default getGroupCategoriesArray;
