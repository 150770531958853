import React, { useContext, useState } from "react";
import { ExamRulesContext } from "../ExamRulesPage";
import CasesHeader from "./ExamRulesDesktopModificationSection/CasesHeader/CasesHeader";
import CasesContainer from "./ExamRulesDesktopModificationSection/CasesContainer/CasesContainer";

const ExamRulesMobileCases = () => {
  const { setPageDisplayed } = useContext(ExamRulesContext);
  const [casesSearchTerm, setCasesSearchTerm] = useState("");
  const [selectedCaseCategory, setSelectedCaseCategory] = useState("All Cases");

  return (
    <div className="examRulesMobilePageWrapper">
      <button
        className="examRulesMobileBackButton"
        onClick={() => setPageDisplayed("overview")}
      >
        Back to Rules Overview
      </button>
      <CasesHeader
        selectedCaseCategory={selectedCaseCategory}
        setSelectedCaseCategory={setSelectedCaseCategory}
        searchTerm={casesSearchTerm}
        setSearchTerm={setCasesSearchTerm}
      />
      <CasesContainer
        searchTerm={casesSearchTerm}
        selectedCaseCategory={selectedCaseCategory}
      />
    </div>
  );
};

export default ExamRulesMobileCases;
