const studentValuesToIgnore = [
  "CustomerId",
  "CustType",
  "DateCustCreated",
  "DateCustModified",
  "OsceCredits",
  "PreceptorEmails",
  "SpecificCases",
  "CustomerFirstName",
  "CustomerMiddleName",
  "CustomerLastName",
];

const filterStudents = (students, groupSelected, searchTerm) => {
  return students.filter((student) => {
    if (
      (student.CustType !== "Student" && student.CustType !== "") ||
      student.UniqueSignInId === ""
    )
      return false;

    if (
      groupSelected !== "All Groups" &&
      `${student.GroupId}` !== groupSelected
    )
      return false;

    // Check if the full name appears in the search
    if (
      `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
      return true;

    // If not, check if any other property contains the search term
    return Object.entries(student).some(([key, studentProperty]) => {
      if (studentValuesToIgnore.includes(key)) return false;
      return studentProperty
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
    });
  });
};

export default filterStudents;
