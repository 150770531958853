import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./CasesContainer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmbulance,
  faBrain,
  faChild,
  faDice,
  faEllipsisVertical,
  faPlus,
  faStethoscope,
  faVenus,
} from "@fortawesome/free-solid-svg-icons";
import { faHeadSideBrain } from "@fortawesome/pro-solid-svg-icons";
import { OSCEAdminContext } from "../../../..";
import { ExamRulesContext } from "../../../ExamRulesPage";
import getCategoryIconFromString from "../../../../Util/getCategoryIconFromString";
import { v4 as uuidv4 } from "uuid";
import ButtonListPopup from "../../../../Components/ButtonListPopup/ButtonListPopup";

const CasesContainer = ({ searchTerm, selectedCaseCategory }) => {
  const [showCategoriesPopup, setShowCategoriesPopup] = useState(false);
  const containerRef = useRef();

  const getFilteredCaseList = () => {
    return userData.CaseLists.filter((caseData) => {
      return (
        caseData.Diagnosis.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedCaseCategory === "All Cases" ||
          caseData.CaseCategory === selectedCaseCategory)
      );
    });
  };

  const { userData, mousePosition } = useContext(OSCEAdminContext);
  const {
    setSelectedCaseList,
    selectedCaseList,
    selectedCaseMap,
    setSelectedCaseMap,
    selectedCategoryMap,
    setSelectedCategoryMap,
  } = useContext(ExamRulesContext);
  const [filteredCaseList, setFilteredCaseList] = useState(
    getFilteredCaseList()
  );

  useEffect(() => {
    setFilteredCaseList(getFilteredCaseList());
  }, [searchTerm, selectedCaseCategory]);

  const addToCaseOrCategoryMap = (title, type) => {
    const functionToExecute =
      type === "category" ? setSelectedCategoryMap : setSelectedCaseMap;
    const mapToEvaluate =
      type === "category" ? selectedCategoryMap : selectedCaseMap;
    if (mapToEvaluate[title]) {
      functionToExecute((prev) => ({
        ...prev,
        [title]: prev[title] + 1,
      }));
    } else {
      functionToExecute((prev) => ({
        ...prev,
        [title]: 1,
      }));
    }
  };

  const addCaseToCaseList = (caseData) => {
    const newCase = {
      ...caseData,
      uniqueId: uuidv4(),
      type: "case",
    };
    setSelectedCaseList((prev) => [...prev, newCase]);
    addToCaseOrCategoryMap(caseData.Diagnosis, "case");
  };

  const addCategoryToCaseList = (category) => {
    const newCategory = {
      category: category,
      uniqueId: uuidv4(),
      type: "category",
    };
    setSelectedCaseList((prev) => [...prev, newCategory]);
    addToCaseOrCategoryMap(category, "category");
  };

  const getCategoryCount = useMemo(() => {
    const categoryCount = Object.values(selectedCategoryMap).reduce(
      (acc, curr) => acc + curr,
      0
    );
    return categoryCount;
  }, [selectedCategoryMap]);

  const getCaseCount = useMemo(() => {
    const caseCount = Object.values(selectedCaseMap).reduce(
      (acc, curr) => acc + curr,
      0
    );
    return caseCount;
  }, [selectedCaseMap]);

  const handleMobileCategoryClick = (category) => {
    addCategoryToCaseList(category);
    setShowCategoriesPopup(false);
  };

  return (
    <div className="examRulesModificationCasesContainer" ref={containerRef}>
      {selectedCaseList.length >= 10 && (
        <div className="examRulesModificationCasesMaxQuantityOverlayContainer">
          <div className="examRulesModificationCasesMaxQuantityTextContainer">
            <p>
              You have selected the maximum number of cases. Please remove a
              case before adding more.
            </p>
          </div>
        </div>
      )}
      <h2 className="examRulesHeaderText">
        Categories ({getCategoryCount} Added)
      </h2>
      <button
        className="examRulesModificationCategoriesMobileWrapper"
        onClick={() => setShowCategoriesPopup(true)}
      >
        Add Category
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      <div className="examRulesModificationCategoriesWrapper">
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("Internal Medicine")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faStethoscope} />
          Internal Medicine ({selectedCategoryMap["Internal Medicine"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("Neurology")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faBrain} />
          Neurology ({selectedCategoryMap["Neurology"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("OB/GYN")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faVenus} />
          OB/GYN ({selectedCategoryMap["OB/GYN"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("Pediatrics")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faChild} />
          Pediatrics ({selectedCategoryMap["Pediatrics"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("Emergency Medicine")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faAmbulance} />
          Emergency Medicine ({selectedCategoryMap["Emergency Medicine"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryButton"
          onClick={() => addCategoryToCaseList("Psychiatry")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faHeadSideBrain} />
          Psychiatry ({selectedCategoryMap["Psychiatry"] || 0})
        </button>
        <button
          className="examRulesModificationCategoryRandomButton"
          onClick={() => addCategoryToCaseList("Random")}
          disabled={selectedCaseList.length >= 10}
        >
          <FontAwesomeIcon icon={faDice} />
          Random Category ({selectedCategoryMap["Random"] || 0})
        </button>
      </div>
      <h2 className="examRulesHeaderText">Cases ({getCaseCount} Added)</h2>
      <div className="examRulesSubContainer">
        {filteredCaseList.map((caseData, index) => (
          <div
            className="examRulesModificationCaseCard"
            key={`Case List Case - ${caseData.CaseId}`}
          >
            <button
              className="examRulesModificationAddCaseButton"
              onClick={() => addCaseToCaseList(caseData)}
              disabled={selectedCaseList.length >= 10}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <div className="examRulesModificationCaseCardTextWrapper">
              <p className="examRulesModificationCaseCardTitle">
                {caseData.Diagnosis} ({selectedCaseMap[caseData.Diagnosis] || 0}
                )
              </p>
              <FontAwesomeIcon
                icon={getCategoryIconFromString(caseData.CaseCategory)}
              />
              <p className="examRulesModificationCaseCardSubtitle">
                {caseData.CaseCategory} | Case ID: {caseData.CaseId}
              </p>
            </div>
          </div>
        ))}
      </div>
      {showCategoriesPopup && (
        <ButtonListPopup
          showButtonListPopup={showCategoriesPopup}
          setShowButtonListPopup={setShowCategoriesPopup}
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
        >
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Internal Medicine")}
          >
            <FontAwesomeIcon icon={faStethoscope} />
            Internal Medicine ({selectedCategoryMap["Internal Medicine"] || 0})
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Neurology")}
          >
            <FontAwesomeIcon icon={faBrain} />
            Neurology ({selectedCategoryMap["Neurology"] || 0})
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("OB/GYN")}
          >
            <FontAwesomeIcon icon={faVenus} />
            OB/GYN ({selectedCategoryMap["OB/GYN"] || 0})
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Pediatrics")}
          >
            <FontAwesomeIcon icon={faChild} />
            Pediatrics ({selectedCategoryMap["Pediatrics"] || 0})
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Emergency Medicine")}
          >
            <FontAwesomeIcon icon={faAmbulance} />
            Emergency Medicine ({selectedCategoryMap["Emergency Medicine"] || 0}
            )
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Psychiatry")}
          >
            <FontAwesomeIcon icon={faHeadSideBrain} />
            Psychiatry ({selectedCategoryMap["Psychiatry"] || 0})
          </button>
          <button
            className="examRulesModificationCategoriesPopupButton"
            onClick={() => handleMobileCategoryClick("Random")}
          >
            <FontAwesomeIcon icon={faDice} />
            Random ({selectedCategoryMap["Random"] || 0})
          </button>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default CasesContainer;
