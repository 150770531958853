import getCasesPageData from "./getCasesPageData";
import getCategoriesPageData from "./getCategoriesPageData/getCategoriesPageData";
import getGroupsPageData from "./getGroupsPageData/getGroupsPageData";
import getStudentsPageData from "./getStudentsPageData";

const transformGradesPageData = (analyticsData, userData) => {
  // Cases Page Data
  const casesPageData = getCasesPageData(analyticsData, userData);

  // Categories Page Data
  const categoriesPageData = getCategoriesPageData(analyticsData, userData);

  // Groups Page Data
  const groupsPageData = getGroupsPageData(analyticsData, userData);

  // Students Page Data
  const studentsPageData = getStudentsPageData(analyticsData, userData);

  return {
    casesPageData,
    categoriesPageData,
    groupsPageData,
    studentsPageData,
  };
};

export default transformGradesPageData;
