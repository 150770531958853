import React from 'react'
import './ActionLog.css'
import getDaysFromDate from '../../../Functions/GetDaysFromDate'

export default function ActionLog(props) {

    const updateSelectedActionLogItem = (index, action) => {
        let selectedTimeline = document.getElementById(`action-log-timeline-${index}`)
        if (selectedTimeline !== null && action !== 'timeline') {
            selectedTimeline.scrollIntoView({ behavior: 'smooth' })
        }
        let selectedAction = document.getElementById(`action-log-${index}`)
        if (selectedAction !== null && action !== 'action') {
            selectedAction.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
        props.updateSelectedActionLogItem(index)
    }

    const getRealTimeObject = (item) => {
        let realTime = new Date(item.CurrentActualTime);
        let realStart = new Date(props.actionLog[0].CurrentActualTime)

        let hoursAdjustment = 0
        if (realTime.getHours() > realStart.getHours()) {
            hoursAdjustment += realTime.getHours() - realStart.getHours()
        }
        let realMinutes = (realTime.getMinutes() + hoursAdjustment * 60) - realStart.getMinutes()
        let realSeconds = realTime.getSeconds() - realStart.getSeconds()

        if (realMinutes > 0 || hoursAdjustment > 0) {
            realSeconds = realTime.getSeconds()
        }

        if (realMinutes.toString().length === 1) {
            realMinutes = '0' + realMinutes
        }
        if (realSeconds.toString().length === 1) {
            realSeconds = '0' + realSeconds
        }

        return {
            minutes: realMinutes,
            seconds: realSeconds
        }
    }

    const getSimulationTimeObject = (item) => {
        let simulationTime = new Date(item.CurrentVirtualTime);
        let simulationStart = new Date(props.actionLog[0].CurrentVirtualTime)

        let simulationDays = getDaysFromDate(simulationTime) - getDaysFromDate(simulationStart)
        let simulationHours = simulationTime.getHours() - simulationStart.getHours()
        if (simulationHours.toString().length === 1) {
            simulationHours = '0' + simulationHours
        }
        let simulationMinutes = simulationTime.getMinutes() - simulationStart.getMinutes()
        if (simulationMinutes.toString().length === 1) {
            simulationMinutes = '0' + simulationMinutes
        }
        if (Number(simulationHours) < 0) {
            simulationDays -= 1
            simulationHours = 24 + simulationHours
        }

        return {
            days: simulationDays,
            hours: simulationHours,
            minutes: simulationMinutes
        }
    }

    const formatTableTime = (item) => {
        let realTimeObject = getRealTimeObject(item)
        let formattedRealTime = realTimeObject.minutes + ':' + realTimeObject.seconds + ' Real Time'

        let simulationTimeObject = getSimulationTimeObject(item)
        let formattedSimulationTime = `${simulationTimeObject.hours}:${simulationTimeObject.minutes}`

        return `${formattedSimulationTime} - ${formattedRealTime}`
    }

    const getActionLogDay = (item) => {
        let simulatedTimeObject = getSimulationTimeObject(item)
        return simulatedTimeObject.days
    }

    const getTimelineSimulatedTime = (item) => {
        let simulatedTimeObject = getSimulationTimeObject(item)
        return `${simulatedTimeObject.days} Days, ${simulatedTimeObject.hours} Hours, ${simulatedTimeObject.minutes} Minutes`
    }

    const getTimelineRealTime = (item) => {
        let realTimeObject = getRealTimeObject(item)
        return `${realTimeObject.minutes}:${realTimeObject.seconds}`
    }

    return (
        <div className='actionLogContainer'>



            <div className='actionLogTableContainer'>
                <h2 className='actionLogTitle'>Action Log</h2>
                <div className='actionLogTableWrapper'>
                    {props.actionLog.map((item, index) => (
                        <div
                            className={`actionLogTableItem ${index === props.selectedActionLogItem ? 'actionLogTableItemSelected' : ''}`}
                            onClick={() => updateSelectedActionLogItem(index, 'action')}
                            id={`action-log-${index}`}
                            key={`action-log-list-${index}`}
                        >
                        <div className='actionLogTableItemTimeWrapper'>
                            <h3 className='actionLogTableItemDay'>Day {getActionLogDay(item)}</h3>
                            {/* Format time as 00:00 AM - 01:06 Real Time */}
                            <h4 className='actionLogTableItemTime'>{formatTableTime(item)}</h4>
                        </div>
                        <p className='actionLogTableItemText'>{item.EventName}</p>
                        </div>
                    ))}
                </div>
            </div>



            <div className='actionLogTimelineWrapper'>
                <h2 className='actionLogTimelineHeader'>Action Log Timeline</h2>
                <div className='actionLogTimelineContainer'>
                    <div className={`actionLogTopRowWrapper ${props.actionLog.length % 2 === 0 ? 'actionLogEvenTopRowWrapper' : 'actionLogOddTopRowWrapper'}`}>
                        {props.actionLog.map((item, index) => (
                        index % 2 === 0 ? 
                            <div
                                style={{zIndex: index + 1}}
                                key={`topRow - ${index}`}
                                id={`action-log-timeline-${index}`}
                                className={`actionLogTopRowItem ${index === props.selectedActionLogItem ? 'actionLogRowItemSelected' : ''}`}
                                onClick={() => updateSelectedActionLogItem(index, 'timeline')}
                            >
                                <h4 className={`actionLogItemTitle ${index === props.selectedActionLogItem ? 'actionLogItemTitleSelected' : ''}`}>{item.EventName}</h4>
                                <p className='actionLogItemTime'>Simulated Time: {getTimelineSimulatedTime(item)}</p>
                                <p className='actionLogItemTime'>Real Time: {getTimelineRealTime(item)}</p>
                            </div>
                        :
                            null
                        ))}
                    </div>
                    {/* <div className='actionLogTimeLine' /> */}
                    <div className={`actionLogBottomRowWrapper ${props.actionLog.length % 2 === 0 ? 'actionLogEvenBottomRowWrapper' : 'actionLogOddBottomRowWrapper'}`}>
                        {props.actionLog.map((item, index) => (
                        index % 2 === 0 ?
                            null
                        :
                            <div
                                style={{zIndex: index + 1}}
                                key={`bottomRow - ${index}`}
                                id={`action-log-timeline-${index}`}
                                className={`actionLogBottomRowItem ${index === props.selectedActionLogItem ? 'actionLogRowItemSelected' : ''}`}
                                onClick={() => updateSelectedActionLogItem(index, 'timeline')}
                            >
                                <h4 className={`actionLogItemTitle ${index === props.selectedActionLogItem ? 'actionLogItemTitleSelected' : ''}`}>{item.EventName}</h4>
                                <p className='actionLogItemTime'>Simulated Time: {getTimelineSimulatedTime(item)}</p>
                                <p className='actionLogItemTime'>Real Time: {getTimelineRealTime(item)}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>



        </div>
    )
}
