import React, { useState } from "react";
import "./DesktopSelectorSwitch.css";

const DesktopSelectorSwitch = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="examRulesDesktopSelectorSwitchContainer">
      <button
        className={`examRulesDesktopSelectorSwitchButton ${
          selectedTab === "Students"
            ? "examRulesDesktopSelectorSwitchButtonSelected"
            : ""
        }`}
        onClick={() => setSelectedTab("Students")}
      >
        Students
      </button>
      <button
        className={`examRulesDesktopSelectorSwitchButton ${
          selectedTab === "Cases"
            ? "examRulesDesktopSelectorSwitchButtonSelected"
            : ""
        }`}
        onClick={() => setSelectedTab("Cases")}
      >
        Cases
      </button>
    </div>
  );
};

export default DesktopSelectorSwitch;
