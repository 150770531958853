import React, { useContext, useEffect, useState } from "react";
import "./ExamRulesDesktopModificationSection.css";
import DesktopSelectorSwitch from "./DesktopSelectorSwitch/DesktopSelectorSwitch";
import StudentsHeader from "./StudentsHeader/StudentsHeader";
import StudentsContainer from "./StudentsContainer/StudentsContainer";
import { OSCEAdminContext } from "../../..";
import filterStudents from "../../../Home/StudentsFilter";
import CasesHeader from "./CasesHeader/CasesHeader";
import CasesContainer from "./CasesContainer/CasesContainer";

const ExamRulesDesktopModificationSection = () => {
  const { userData } = useContext(OSCEAdminContext);
  const [studentsSearchTerm, setSearchTerm] = useState("");
  const [casesSearchTerm, setCasesSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("All Groups");
  const [filteredStudents, setFilteredStudents] = useState(userData.Students);
  const [selectedTab, setSelectedTab] = useState("Students");
  const [selectedCaseCategory, setSelectedCaseCategory] = useState("All Cases");

  useEffect(() => {
    setFilteredStudents(
      filterStudents(userData.Students, selectedGroup, studentsSearchTerm)
    );
  }, [studentsSearchTerm, selectedGroup]);

  return (
    <div className="examRulesDesktopModificationWrapper">
      <DesktopSelectorSwitch
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {selectedTab === "Students" ? (
        <>
          <StudentsHeader
            searchTerm={studentsSearchTerm}
            setSearchTerm={setSearchTerm}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
          />
          <StudentsContainer
            studentsList={filteredStudents}
            searchTerm={studentsSearchTerm}
            selectedGroup={selectedGroup}
          />
        </>
      ) : (
        <>
          <CasesHeader
            selectedCaseCategory={selectedCaseCategory}
            setSelectedCaseCategory={setSelectedCaseCategory}
            searchTerm={casesSearchTerm}
            setSearchTerm={setCasesSearchTerm}
          />
          <CasesContainer
            searchTerm={casesSearchTerm}
            selectedCaseCategory={selectedCaseCategory}
          />
        </>
      )}
    </div>
  );
};

export default ExamRulesDesktopModificationSection;
