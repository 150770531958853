import React, { useContext, useState } from "react";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ModifyNameModal = ({ student, setShowModifyName }) => {
  const {
    route,
    userData,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [firstName, setFirstName] = useState(student.CustomerFirstName);
  const [middleName, setMiddleName] = useState(student.CustomerMiddleName);
  const [lastName, setLastName] = useState(student.CustomerLastName);

  const fetchModifyName = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/modifystudent.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentId: student.CustomerId,
        StudentFirstName: firstName,
        StudentMiddleName: middleName,
        StudentLastName: lastName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        const students = structuredClone(userData.Students);
        const studentIndex = students.findIndex(
          (element) => element.CustomerId === student.CustomerId
        );
        students[studentIndex].CustomerFirstName = firstName;
        students[studentIndex].CustomerMiddleName = middleName;
        students[studentIndex].CustomerLastName = lastName;
        updateUserData({ ...userData, Students: students });
        displayNotification(
          `Name changed successfully to ${firstName} ${middleName} ${lastName}`
        );
        setShowModifyName(false);
      })
      .catch((error) => {
        setFetchOutstanding(false);
        console.error(error);
        displayNotification("Failed to change user's name");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Modify Name</h2>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorFirstName" className="modalInputLabel">
            First Name
          </label>
          <input
            type="text"
            id="preceptorFirstName"
            className="modalInput"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorMiddleName" className="modalInputLabel">
            Middle Name
          </label>
          <input
            type="text"
            id="preceptorMiddleName"
            className="modalInput"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorLastName" className="modalInputLabel">
            Last Name
          </label>
          <input
            type="text"
            id="preceptorLastName"
            className="modalInput"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#2c3e50" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifyName(false)}
            >
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={fetchModifyName}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyNameModal;
