import React, { useContext, useEffect, useState } from "react";
import "./StudentsPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faChevronRight,
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { OSCEAdminContext } from "../..";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";

const StudentsPage = () => {
  const { userData, mousePosition, handleChangePage } =
    useContext(OSCEAdminContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("All Groups");
  const [selectedSort, setSelectedSort] = useState("Default");
  const [filteredStudents, setFilteredStudents] = useState(
    userData.GradesData.studentsPageData.students
  );
  const [showMoreHeaderOptions, setShowMoreHeaderOptions] = useState(false);

  useEffect(() => {
    let filteredStudents = userData.GradesData.studentsPageData.students.filter(
      (studentData) =>
        studentData.studentName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (selectedGroup !== "All Groups") {
      filteredStudents = filteredStudents.filter(
        (studentData) => studentData.groupId === selectedGroup
      );
    }

    switch (selectedSort) {
      case "Default":
        filteredStudents = filteredStudents;
        break;
      case "Name":
        filteredStudents = filteredStudents.sort((a, b) =>
          a.studentName.localeCompare(b.studentName)
        );
        break;
      case "Exams Completed":
        filteredStudents = filteredStudents.sort(
          (a, b) => b.examsCompleted - a.examsCompleted
        );
        break;
      case "Cases Completed":
        filteredStudents = filteredStudents.sort(
          (a, b) => b.casesCompleted - a.casesCompleted
        );
        break;
      case "Average Score Per Case":
        filteredStudents = filteredStudents.sort(
          (a, b) => b.averageScorePerCase - a.averageScorePerCase
        );
        break;
      case "Performance":
        filteredStudents = filteredStudents.sort(
          (a, b) => b.performance - a.performance
        );
        break;
      default:
        break;
    }

    setFilteredStudents(filteredStudents);
  }, [searchTerm, selectedGroup, selectedSort]);

  const getNoStudentsText = () => {
    let noStudentsText = "No students found";
    if (searchTerm) {
      noStudentsText += ` for "${searchTerm}"`;
    }
    if (selectedGroup !== "All Groups") {
      const group = userData.Groups.find(
        (group) => group.groupId === Number(selectedGroup)
      );
      const groupName = group ? group.GroupName : null;

      noStudentsText += ` in group ${selectedGroup}${
        groupName ? ` - ${groupName}` : ""
      }`;
    }

    noStudentsText += ".";

    return noStudentsText;
  };

  const handleMobileGroupSelected = (e) => {
    setSelectedGroup(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  const handleMobileSelectSort = (e) => {
    setSelectedSort(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  const handleStudentClick = (studentId) => {
    // Navigate to IndividualStudentGrades page
    handleChangePage("Student Grades", {
      prevPage: "Grades",
      initialTab: "Students",
      studentId,
      backButtonText: "Back to Student Grades",
    });
  };

  return (
    <div className="adminStudentsPageWrapper">
      <div className="adminStudentsPageHeaderContainer">
        <h2>Students</h2>
        <div className="adminGradesTitleValuesListWrapper">
          <div className="adminGradesTitleValueWrapper">
            <h3>Number of Students</h3>
            <p>{userData.GradesData.studentsPageData.numberOfStudents}</p>
          </div>
          <div className="adminGradesTitleValueWrapper">
            <h3>Average Score Per Student</h3>
            <p>
              {userData.GradesData.studentsPageData.averageScorePerStudent}%
            </p>
          </div>
          <div className="adminGradesTitleValueWrapper">
            <h3>All Schools Average Score Per Student</h3>
            <p>
              {userData.GradesData.studentsPageData.allSchoolsAverageScore}%
            </p>
          </div>
        </div>
      </div>
      <div className="adminCasesPageCasesContainer">
        <h2 className="adminCasesHeader">Student Grades</h2>
        <div className="adminCasesPageHeaderOptionsWrapper">
          <div className="examRulesStudentHeaderSearchWrapper">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search Students..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <select
            className="examRulesStudentHeaderSelect"
            value={selectedGroup}
            onChange={(e) => setSelectedGroup(e.target.value)}
          >
            <option value="All Groups">View All Groups</option>
            {userData.GradesData.groupsPageData.map((group) => (
              <option
                value={group.groupId}
                key={`students-page-group-${group.groupId}-${group.groupName}`}
              >
                {`Group ${group.groupId}${
                  group.groupName ? ` - ${group.groupName}` : ""
                }`}
              </option>
            ))}
          </select>
          <div className="adminCasesPageHeaderOptionsDivider" />
          <select
            className="examRulesStudentHeaderSelect"
            value={selectedSort}
            onChange={(e) => setSelectedSort(e.target.value)}
          >
            <option value="Default">Sort By Default</option>
            <option value="Name">Sort By Name A-Z</option>
            <option value="Exams Completed">Sort By Exams Completed</option>
            <option value="Cases Completed">Sort By Cases Completed</option>
            <option value="Average Score Per Case">
              Sort By Average Score Per Case
            </option>
            <option value="Performance">Sort By Performance</option>
          </select>
          <button
            className="adminCasesPageHeaderMobileMoreOptionsButton"
            onClick={() => setShowMoreHeaderOptions(true)}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        </div>
        <div className="adminCaseListContainer">
          {filteredStudents.map((studentData, index) => (
            <div
              className="adminCaseListElementContainer"
              key={`students-page-student-list-${studentData.studentId}`}
              onClick={() => handleStudentClick(studentData.studentId)}
            >
              <div className="adminCaseListElementTitleWrapper">
                <h4>{studentData.studentName}</h4>
                {studentData.GroupId ? (
                  <p>
                    Group {studentData.groupId} - {studentData.groupName}
                  </p>
                ) : null}
              </div>
              <div className="adminCaseListValueListWrapper adminStudentsListValueListWrapper">
                <div className="adminCaseListValueWrapper">
                  <h5>Exams Completed</h5>
                  <p>{studentData.examsCompleted}</p>
                </div>
                <div className="adminCaseListValueWrapper">
                  <h5>Cases Completed</h5>
                  <p>{studentData.casesCompleted}</p>
                </div>
                <div className="adminCaseListValueWrapper">
                  <h5>Average Score Per Case</h5>
                  <p>{studentData.averageScorePerCase}%</p>
                </div>
                <div
                  className="adminCaseListPerformanceWrapper"
                  style={{
                    color: studentData.performance >= 0 ? "#13923e" : "#a90101",
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      studentData.performance >= 0 ? faArrowUp : faArrowDown
                    }
                  />
                  <p>
                    {studentData.performance >= 0
                      ? "Outperforming"
                      : "Underperforming"}{" "}
                    By {Math.abs(studentData.performance)}%
                  </p>
                </div>
              </div>
              <button className="adminStudentListElementButton">
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          ))}
          {filteredStudents.length === 0 && (
            <div className="adminCasesPageNoCasesFoundContainer">
              <p>{getNoStudentsText()}</p>
            </div>
          )}
        </div>
      </div>
      {showMoreHeaderOptions && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowMoreHeaderOptions}
          showButtonListPopup={showMoreHeaderOptions}
        >
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedGroup}
            onChange={handleMobileGroupSelected}
          >
            <option value="All Groups">View All Groups</option>
            {userData.GradesData.groupsPageData.map((group) => (
              <option
                value={group.groupId}
                key={`students-page-group-${group.groupId}-${group.groupName}`}
              >
                {`Group ${group.groupId}${
                  group.groupName ? ` - ${group.groupName}` : ""
                }`}
              </option>
            ))}
          </select>
          <div className="adminCasesPageHeaderOptionsDivider" />
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedSort}
            onChange={handleMobileSelectSort}
          >
            <option value="Default">Sort By Default</option>
            <option value="Name">Sort By Name A-Z</option>
            <option value="Exams Completed">Sort By Exams Completed</option>
            <option value="Cases Completed">Sort By Cases Completed</option>
            <option value="Average Score Per Case">
              Sort By Average Score Per Case
            </option>
            <option value="Performance">Sort By Performance</option>
          </select>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default StudentsPage;
