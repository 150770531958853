import React, { useContext, useState } from "react";
import "./AddStudentModal.css";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const AddStudentModal = ({ setShowAddStudentModal }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const {
    userData,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const fetchAddStudent = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/manualadd.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentFirstName: firstName,
        StudentMiddleName: middleName,
        StudentLastName: lastName,
        StudentEmail: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        const studentData = {
          CustType: "Student",
          CustomerEmail: email,
          CustomerFirstName: data[0].StudentFirstName,
          CustomerId: data[0].StudentId,
          CustomerLastName: data[0].StudentLastName,
          CustomerMiddleName: data[0].StudentMiddleName,
          CustomerUserName: "",
          DateCustCreated: "",
          DateCustModified: "",
          GroupId: data[0].GroupId,
          OsceCredits: 1,
          PreceptorEmailsOut: [],
          SpecficCasesOut: [],
          TotalBreakTime: 0,
          UniqueSignInId: data[0].UniqueSignInId,
        };

        updateUserData({
          ...userData,
          Students: [...userData.Students, studentData],
        });
        displayNotification(`${firstName} ${lastName} added successfully`);
        setShowAddStudentModal(false);
      })
      .catch((error) => {
        console.error(error);
        setFetchOutstanding(false);
        displayNotification("An error occurred", "error");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Add Student</h2>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorFirstName" className="modalInputLabel">
            First Name
          </label>
          <input
            type="text"
            id="preceptorFirstName"
            className="modalInput"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorMiddleName" className="modalInputLabel">
            Middle Name
          </label>
          <input
            type="text"
            id="preceptorMiddleName"
            className="modalInput"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorLastName" className="modalInputLabel">
            Last Name
          </label>
          <input
            type="text"
            id="preceptorLastName"
            className="modalInput"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="preceptorEmail" className="modalInputLabel">
            Email
          </label>
          <input
            type="email"
            id="preceptorEmail"
            className="modalInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#2c3e50" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowAddStudentModal(false)}
            >
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={fetchAddStudent}>
              Add Student
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddStudentModal;
