import React, { useContext, useRef, useState } from "react";
import "./StudentsHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { OSCEAdminContext } from "../../../..";
import ButtonListPopup from "../../../../Components/ButtonListPopup/ButtonListPopup";
import { ExamRulesContext } from "../../../ExamRulesPage";

const StudentsHeader = ({
  searchTerm,
  setSearchTerm,
  selectedGroup,
  setSelectedGroup,
}) => {
  const { userData, mousePosition } = useContext(OSCEAdminContext);
  const { setShowGroupListModal, selectedStudentIds } =
    useContext(ExamRulesContext);
  const containerRef = useRef();
  const [showButtonListPopup, setShowButtonListPopup] = useState(false);

  const handleMobileGroupFilterChange = (newGroup) => {
    setShowButtonListPopup(false);
    setSelectedGroup(newGroup);
  };

  const handleGroupListButtonClick = () => {
    setShowButtonListPopup(false);
    setShowGroupListModal(true);
  };

  return (
    <div className="examRulesStudentHeader" ref={containerRef}>
      <div className="examRulesStudentHeaderSearchWrapper">
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          placeholder="Search students..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <select
        onChange={(e) => setSelectedGroup(e.target.value)}
        className="examRulesStudentHeaderSelect"
        value={selectedGroup}
      >
        <option value="All Groups">View All Student Groups</option>
        {userData.Groups.map((group) => (
          <option
            key={`${group.groupId} - ${group.GroupName}`}
            value={group.groupId}
          >
            {group.groupId}
            {" - "}
            {group.GroupName}
          </option>
        ))}
      </select>
      <div className="examRulesStudentHeaderDivider" />
      <button
        className="examRulesStudentHeaderButton"
        onClick={() => setShowGroupListModal(true)}
        disabled={selectedStudentIds.length === 0}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Students To Group
      </button>
      <button
        className="examRulesStudentHeaderSelectMobile"
        onClick={() => setShowButtonListPopup(true)}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      {showButtonListPopup && (
        <ButtonListPopup
          showButtonListPopup={showButtonListPopup}
          setShowButtonListPopup={setShowButtonListPopup}
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
        >
          <button
            className="examRulesStudentHeaderButton"
            onClick={handleGroupListButtonClick}
            disabled={selectedStudentIds.length === 0}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Students To Group
          </button>
          <select
            onChange={(e) => handleMobileGroupFilterChange(e.target.value)}
            className="examRulesStudentHeaderSelectMobilePopup"
            value={selectedGroup}
          >
            <option value="All Groups">View All Student Groups</option>
            {userData.Groups.map((group) => (
              <option
                key={`${group.groupId} - ${group.GroupName}`}
                value={group.groupId}
              >
                {group.groupId}
                {" - "}
                {group.GroupName}
              </option>
            ))}
          </select>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default StudentsHeader;
