import React, { useContext, useRef, useState } from "react";
import "./CasesHeader.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import ButtonListPopup from "../../../../Components/ButtonListPopup/ButtonListPopup";
import { OSCEAdminContext } from "../../../..";

const CasesHeader = ({
  selectedCaseCategory,
  setSelectedCaseCategory,
  searchTerm,
  setSearchTerm,
}) => {
  const [showButtonListPopup, setShowButtonListPopup] = useState(false);
  const { mousePosition } = useContext(OSCEAdminContext);
  const containerRef = useRef();

  const handleMobileCategoryFilterChange = (newCategory) => {
    setShowButtonListPopup(false);
    setSelectedCaseCategory(newCategory);
  };

  return (
    <div className="examRulesCasesHeader" ref={containerRef}>
      <div className="examRulesStudentHeaderSearchWrapper">
        <FontAwesomeIcon icon={faSearch} />
        <input
          type="text"
          placeholder="Search cases..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <select
        onChange={(e) => setSelectedCaseCategory(e.target.value)}
        className="examRulesStudentHeaderSelect"
        value={selectedCaseCategory}
      >
        <option value="All Cases">View All Case Categories</option>
        <option value="Internal Medicine">Internal Medicine</option>
        <option value="Neurology">Neurology</option>
        <option value="OB/GYN">OB/GYN</option>
        <option value="Pediatrics">Pediatrics</option>
        <option value="Emergency Medicine">Emergency Medicine</option>
        <option value="Psychiatry">Psychiatry</option>
      </select>
      <button
        className="examRulesStudentHeaderSelectMobile"
        onClick={() => setShowButtonListPopup(true)}
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </button>
      {showButtonListPopup && (
        <ButtonListPopup
          showButtonListPopup={showButtonListPopup}
          setShowButtonListPopup={setShowButtonListPopup}
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
        >
          <select
            onChange={(e) => handleMobileCategoryFilterChange(e.target.value)}
            className="examRulesCasesHeaderSelectMobilePopup"
            value={selectedCaseCategory}
          >
            <option value="All Cases">View All Case Categories</option>
            <option value="Internal Medicine">Internal Medicine</option>
            <option value="Neurology">Neurology</option>
            <option value="OB/GYN">OB/GYN</option>
            <option value="Pediatrics">Pediatrics</option>
            <option value="Emergency Medicine">Emergency Medicine</option>
            <option value="Psychiatry">Psychiatry</option>
          </select>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default CasesHeader;
