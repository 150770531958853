const adminTableColumns = [
  {
    label: "Name",
    valueKey: ["AdminFirstName", "AdminMiddleName", "AdminLastName"],
  },
  {
    label: "Email",
    valueKey: ["AdminEmail"],
  },
  {
    label: "Role",
    valueKey: ["superAdmin"],
    valueModifier: (value) => (value ? "Super Admin" : "Admin"),
  },
];

const adminMobileTableData = {
  title: ["AdminFirstName", "AdminMiddleName", "AdminLastName"],
  titleModifier: null,
  subtitle: ["AdminEmail"],
  subtitleModifier: null,
  secondaryTitle: [],
  secondaryTitleModifier: null,
  values: [
    {
      label: "Role",
      valueKey: ["superAdmin"],
      valueModifier: (value) => (value ? "Super Admin" : "Admin"),
    },
  ],
};

export { adminTableColumns, adminMobileTableData };
