import React, { useContext, useState } from "react";
import "./PrimaryProfileDetails.css";
import InformationCard from "../Components/InformationCard/InformationCard";
import {
  faPenToSquare,
  faPlus,
  faShareFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import ModifyEmailModal from "./Modals/ModifyEmailModal";
import ModifyNameModal from "./Modals/ModifyNameModal";
import GroupListModal from "../../Components/Modals/GroupListModal";
import { OSCEAdminContext } from "../..";
import ConfirmationModal from "../../Components/Modals/ConfirmationModal/ConfirmationModal";

const PrimaryProfileDetails = ({ student }) => {
  const [showModifyEmail, setShowModifyEmail] = useState(false);
  const [showModifyName, setShowModifyName] = useState(false);
  const [showGroupList, setShowGroupList] = useState(false);
  const [showModifyCredits, setShowModifyCredits] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);

  const {
    userData,
    updateUserData,
    displayNotification,
    route,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const handleConfirmSelectedGroup = async (selectedGroup) => {
    return await fetch(`${route}/modifystudent.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentId: student.CustomerId,
        GroupId: selectedGroup.groupId,
        GroupName: selectedGroup.GroupName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          displayNotification("Group updated successfully.");
          const updatedStudent = {
            ...student,
            GroupId: selectedGroup.groupId,
            GroupName: selectedGroup.GroupName,
          };
          updateUserData({
            ...userData,
            Students: userData.Students.map((student) =>
              student.CustomerId === updatedStudent.CustomerId
                ? updatedStudent
                : student
            ),
          });
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        displayNotification("An error occurred. Please try again.");
        return false;
      });
  };

  const handleFetchSendEmail = async () => {
    return await fetch(`${route}/emailcredentials.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        Students: [student.CustomerId],
      }),
    })
      .then((response) => {
        if (response.ok) {
          displayNotification("Email sent successfully.");
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        displayNotification("Unable to send email. Please try again.");
        return false;
      });
  };

  return (
    <>
      <div className="studentProfilePagePrimaryDetailsWrapper">
        <InformationCard
          title="Name"
          text={`${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`}
          icon={faPenToSquare}
          onClick={() => setShowModifyName(true)}
        />
        <InformationCard
          title="Email"
          text={student.CustomerEmail}
          icon={faPenToSquare}
          onClick={() => setShowModifyEmail(true)}
        />
        <InformationCard
          title="Account Id"
          text={student.UniqueSignInId}
          icon={faShareFromSquare}
          onClick={() => setShowSendEmail(true)}
        />
        <InformationCard
          title="Credits Available"
          text={student.OsceCredits}
          icon={faPlus}
        />
        <InformationCard
          title="Group"
          text={
            student.GroupId === 0
              ? "No Group Assigned"
              : `${student.GroupId}${
                  student.GroupName ? ` - ${student.GroupName}` : ""
                }`
          }
          icon={faPenToSquare}
          onClick={() => setShowGroupList(true)}
        />
      </div>
      {showModifyEmail && (
        <ModifyEmailModal
          student={student}
          setShowModifyEmail={setShowModifyEmail}
        />
      )}
      {showModifyName && (
        <ModifyNameModal
          student={student}
          setShowModifyName={setShowModifyName}
        />
      )}
      {showGroupList && (
        <GroupListModal
          setShowModal={setShowGroupList}
          confirmSelection={handleConfirmSelectedGroup}
          showAddGroupButton={true}
          subHeader={`Student - ${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`}
        />
      )}
      {showSendEmail && (
        <ConfirmationModal
          header="Send Account Id Email"
          text={`Send an email to ${student.CustomerEmail} with their Account Id?`}
          closeModal={() => setShowSendEmail(false)}
          confirmFunction={handleFetchSendEmail}
        />
      )}
    </>
  );
};

export default PrimaryProfileDetails;
