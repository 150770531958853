import React, { useState } from "react";
import CCSCasesLoginForm from "./Components/CCSCasesLoginForm";
import ForgotPassword from "./Components/ForgotPassword";
import DemoSignup from "./Components/DemoSignup";
import TermsOfService from "./Components/LoginTermsOfService";

const CCSCasesLogin = ({ primaryColor, handleLogin, route }) => {
  const [page, setPage] = useState("login");
  const [successMessage, setSuccessMessage] = useState("");

  const showForgotPassword = () => {
    setPage("forgotPassword");
    setSuccessMessage("");
  };

  const showDemoSignup = () => {
    setPage("demoSignup");
    setSuccessMessage("");
  };

  const showTOS = () => {
    setPage("termsOfService");
    setSuccessMessage("");
  };

  const showLogin = () => {
    setPage("login");
  };

  const updateSuccessMessage = (newMessage) => {
    setSuccessMessage(newMessage);
  };

  return (
    <div className="loginContentWrapper">
      {page === "login" ? (
        <CCSCasesLoginForm
          showForgotPassword={showForgotPassword}
          successMessage={successMessage}
          primaryColor={primaryColor}
          route={route}
          handleLogin={handleLogin}
          showDemoSignup={showDemoSignup}
          showTOS={showTOS}
        />
      ) : page === "forgotPassword" ? (
        <ForgotPassword
          route={route}
          closeForgotPassword={showLogin}
          setSuccessMessage={updateSuccessMessage}
          primaryColor={primaryColor}
        />
      ) : page === "demoSignup" ? (
        <DemoSignup
          route={route}
          closeDemoSignup={showLogin}
          showTOS={showTOS}
          primaryColor={primaryColor}
          setSuccessMessage={updateSuccessMessage}
        />
      ) : (
        page === "termsOfService" && <TermsOfService closeTOS={showLogin} />
      )}
    </div>
  );
};

export default CCSCasesLogin;
