import React, { useContext, useState } from "react";
import "./ModifyBackgroundImageModal.css";
import MDSpinner from "react-md-spinner";
import { faImage, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OSCEAdminContext } from "../../..";

const ModifyBackgroundImageModal = ({ setShowModifyBreakBackgroundImage }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const {
    updateUserData,
    userData,
    route,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const handleConfirmClick = async () => {
    setFetchOutstanding(true);

    const formData = new FormData();
    formData.append("image", imageFile);

    await fetch(`${route}/getschoolbreakbackgroundimage.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        updateUserData({
          ...userData,
          SchoolBreakBackgroundImage: data.FileName,
        });
        displayNotification("Background image updated successfully.");
        setShowModifyBreakBackgroundImage(false);
      })
      .catch((error) => {
        displayNotification("Error updating background image.");
        setFetchOutstanding(false);
        console.error("Error:", error);
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Modify Break Background Image</h2>
        <div
          className="modalImageInputWrapper"
          style={{ rowGap: imageFile ? 10 : 5 }}
        >
          <label className="modalInputLabel">Background Image</label>
          {imageFile && (
            <button
              className="uploadCSVModalRemoveFileButton"
              onClick={() => setImageFile(null)}
              title={"Remove file"}
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon icon={faX} />
              {imageFile.name}
            </button>
          )}
          <div className="modalModifyImageImageContainer">
            {imageFile || userData.SchoolBreakBackgroundImage ? (
              <>
                <img
                  className="modalModifyImageImage"
                  src={
                    imageFile
                      ? URL.createObjectURL(imageFile)
                      : `https://osce.ccscases.com/logos/${userData.SchoolBreakBackgroundImage}`
                  }
                />
              </>
            ) : (
              <div className="modalModifyImagePlaceholderWrapper">
                <FontAwesomeIcon
                  icon={faImage}
                  className="modalModifyImagePlaceholderImage"
                />
                <p className="modalModifyImagePlaceholderText">
                  Click here to add an image
                </p>
              </div>
            )}
            <input
              type="file"
              className="modalModifyImageInput"
              accept="image/*"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </div>
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifyBreakBackgroundImage(false)}
            >
              Cancel
            </button>
            <button
              className={`modalConfirmButton ${
                !imageFile ? "buttonDisabled" : ""
              }`}
              disabled={!imageFile}
              onClick={handleConfirmClick}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyBackgroundImageModal;
