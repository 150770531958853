const calculateAverageChangePerMonth = (trends) => {
  if (trends.length < 2) return 0;
  let totalChange = 0;
  for (let i = 1; i < trends.length; i++) {
    totalChange += trends[i].AverageGrade - trends[i - 1].AverageGrade;
  }
  return parseFloat((totalChange / (trends.length - 1)).toFixed(2));
};

export default calculateAverageChangePerMonth;
