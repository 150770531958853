import React, { useEffect, useState } from "react";
import "./Navigation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChartLine,
  faListUl,
  faTags,
  faUserDoctor,
  faUserGroup,
  faX,
} from "@fortawesome/free-solid-svg-icons";

const Navigation = ({ currentTab, setCurrentTab, loadingGradesData }) => {
  const [isNavExpanded, setIsNavExpanded] = useState(window.innerWidth > 950);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 950) {
        setIsNavExpanded(true);
      } else {
        setIsNavExpanded(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsNavExpanded]);

  return (
    <div className="adminPortalGradesPageNavContainer">
      <FontAwesomeIcon icon={faChartLine} />
      <div className="adminPortalGradesPageNavTextWrapper">
        <h1>Grades</h1>
        <p>The University of New Mexico School of Medicine</p>
      </div>
      {isNavExpanded && (
        <nav className="adminPortalGradesPageNavLinksWrapper">
          <button
            className={`${
              currentTab === "Categories"
                ? "adminPortalGradesPageNavButtonSelected"
                : ""
            }`}
            onClick={() => setCurrentTab("Categories")}
            type="button"
            disabled={loadingGradesData}
          >
            <FontAwesomeIcon icon={faTags} />
            Categories
          </button>
          <button
            className={`${
              currentTab === "Cases"
                ? "adminPortalGradesPageNavButtonSelected"
                : ""
            }`}
            onClick={() => setCurrentTab("Cases")}
            type="button"
            disabled={loadingGradesData}
          >
            <FontAwesomeIcon icon={faListUl} />
            Cases
          </button>
          <button
            className={`${
              currentTab === "Groups"
                ? "adminPortalGradesPageNavButtonSelected"
                : ""
            }`}
            onClick={() => setCurrentTab("Groups")}
            type="button"
            disabled={loadingGradesData}
          >
            <FontAwesomeIcon icon={faUserGroup} />
            Groups
          </button>
          <button
            className={`${
              currentTab === "Students"
                ? "adminPortalGradesPageNavButtonSelected"
                : ""
            }`}
            onClick={() => setCurrentTab("Students")}
            type="button"
            disabled={loadingGradesData}
          >
            <FontAwesomeIcon icon={faUserDoctor} />
            Students
          </button>
        </nav>
      )}
      <button
        className="adminPortalGradesPageNavHamburger"
        onClick={() => setIsNavExpanded(!isNavExpanded)}
      >
        <FontAwesomeIcon icon={isNavExpanded ? faX : faBars} />
      </button>
    </div>
  );
};

export default Navigation;
