import React from 'react';
import './Grading.css';
import HighYieldInformationPopup from './HighYieldInformationPopup'
import FaqQuestionsArray from './FAQ_Json.json'
import logFetchError from './Functions/LogFetchError'
import fetchSubmitLogs from './Functions/FetchSubmitLogs'
import getDaysFromDate from './Functions/GetDaysFromDate';
import MDSpinner from 'react-md-spinner';

class Grading extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			gradingView: 'feedback',
			grading: this.props.grading,
			reviewLater: this.props.reviewLater,
			highYield: false,
			enableHighYieldSelect: true,
			displayHighYieldInformation: false,
			faqQuestions: [],
			reviewLaterOutstanding: false,
			retrieveCaseGradeOutstanding: false,
			peekCaseGradeOutstanding: false,
			viewCaseGradeOutstanding: false,
			highYieldOutstanding: false,
		}
	}

	componentDidMount() {
		if (this.props.gradingMode === 'peek') {
			this.fetchPeekCaseGrade()
		} else {
			this.fetchViewCaseGrade()
		}
		this.updateFaqQuestionsHandler()
	}

	updateFaqQuestionsHandler = () => {
		let questions = []
		let answers = []
		FaqQuestionsArray.forEach((value, index) => {
			let beginning = value.slice(0, 10)
			if (beginning.includes('question:')) {
				let question = value.slice(-value.length + 10)
				questions.push(question)
			} else if (beginning.includes('answer:')) {
				let answer = value.slice(-value.length + 8)
				answers.push(answer)
			}
		})
		let FAQ = []
		questions.forEach((value, index) => {
			FAQ.push({
				question: value,
				answer: answers[index]
			})
		})
		this.setState({faqQuestions: FAQ})
	}

	/*
	this function has been coppied from CaseSelect for ease of programming
	*/
	fetchReviewLater = (e) => {
		e.stopPropagation()
		let caseId;
		if (this.props.caseGrades) {
			caseId = this.props.caseGrades;
		}
		else {
			caseId = this.props.selectedCase;
		}
		this.setState({reviewLaterOutstanding: true})
		fetch(`${this.props.route}/reviewlater.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseId,
				marked: !this.state.reviewLater,
			})
		})
		.then(response => {
			this.setState({reviewLaterOutstanding: false})
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(response => {
			this.reviewLaterToggle();
		})
		.catch(error => {
			this.setState({reviewLaterOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchReviewLater')
		});
	}

	reviewLaterToggle = () => {
		this.props.updateReviewLater()
		this.setState({reviewLater: !this.state.reviewLater})
	}

	fetchRetrieveCaseGrade = (id) => {
		this.setState({retrieveCaseGradeOutstanding: true, gradingView: 'feedback'})
		fetch(`${this.props.route}/retreivecasegrade.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				CustomerGradeId: id,
			})
		})
		.then(response => {
			this.setState({retrieveCaseGradeOutstanding: false})
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(data => {
			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			data = JSON.parse(data)
			console.log(data)
			this.setState({
				selectedCase: id,
				grading: data.GradeFeedback,
				highYield: data.HighYieldFlag
			});
		})
		.catch(error => {
			this.setState({retrieveCaseGradeOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchRetrieveCaseGrade')
		});
	}

	fetchPeekCaseGrade = async () => {
		let caseId = this.props.selectedCase
		this.setState({peekCaseGradeOutstanding: true})
		await fetch(`${this.props.route}/peekatgrading.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseId,
			})
		})
		.then(response => {
			this.setState({peekCaseGradeOutstanding: false})
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(data => {
			console.log(data)
			if (data.includes('The customer has already done this case. The customer should use View Grades instead.')) {
				return
			}
			data = JSON.parse(data)
			console.log(data)
			this.setState({
				selectedCase: caseId,
				grading: data,
			});
		})
		.catch(error => {
			this.setState({peekCaseGradeOutstanding: false})
			console.log('error', error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchPeekCaseGrade')
		})
	}

	fetchViewCaseGrade = () => {
		let caseId;
		if (this.props.caseGrades) {
			caseId = this.props.caseGrades;
		}
		else {
			caseId = this.props.selectedCase;
		}
		this.setState({viewCaseGradeOutstanding: true})
		fetch(`${this.props.route}/viewcasegrade.webapi`, {
			method: 'POST',
			headers: {
				'Token': this.props.userData.Token,
				'Content-Type': 'text/plain',
			},
			body: JSON.stringify({
				customerId: this.props.userData.CustomerId,
				caseId: caseId,
			})
		})
		.then(response => {
			this.setState({viewCaseGradeOutstanding: false})
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
		})
		.then(response => {

			//Attempt sending logs
			fetchSubmitLogs(this.props.userProfile, this.props.userData)

			let data = response
			// reverse order of array
			let array = JSON.parse(data).vcgo;
			array.reverse();
			let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

			let caseArray = [];// only display the cases that are of the case selected
			// format dates
			for (let i = 0; i < array.length; i++) {
				let date = new Date(`${array[i].CaseCompletionDate}Z`);

				let minutes = date.getUTCMinutes();
				if (minutes.toString().length === 1) {
					minutes = '0' + minutes;
				}

				array[i].CaseCompletionDate = months[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear() + ', ' + date.getUTCHours() + ':' + minutes;

				if (array[i].CaseId === caseId && array[i].CaseCompletionDate !== 'January 1, 1, 0:00') {
					caseArray.push(array[i]);
				}
			}

			// if !this.props.grading fetch data for most recent case
			if (!this.props.grading) {
				this.fetchRetrieveCaseGrade(caseArray[0].CustomerGradeId);
			}
			let newCaseArray = []
			if (this.props.userProfile.selectedTimeZone !== "-7") {
				const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
				let hoursAdjustment = Number(this.props.userProfile.selectedTimeZone) + 7
				caseArray.forEach((value, index) => {
					let date = new Date(value.CaseCompletionDate)
					date.setTime(date.getTime() + (hoursAdjustment*60*60*1000))
					let newCaseArrayValue = value
					let dateString = `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`
					newCaseArrayValue.CaseCompletionDate = dateString
					newCaseArray.push(newCaseArrayValue)
				})
			} else {
				newCaseArray = [...caseArray]
			}
			this.setState({
				caseList: newCaseArray,
				selectedCase: caseArray[0].CustomerGradeId,
			});
		})
		.catch(error => {
			this.setState({viewCaseGradeOutstanding: false})
			// if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
			// 	this.props.setAuthenticationError()
			// }
			console.log(error)
			logFetchError(error, this.props.userProfile, this.props.userData, 'fetchViewCaseGrade')
		});
	}

	setGradingView = (view) => {
		this.setState({gradingView: view});
	}

	markHighYieldHandler = (e) => {
		e.stopPropagation()
		if (this.state.enableHighYieldSelect) {
			let caseId;
			if (this.props.caseGrades) {
				caseId = this.props.caseGrades;
			}
			else {
				caseId = this.props.selectedCase;
			}
			this.setState({highYield: !this.state.highYield, enableHighYieldSelect: false}, async () => {
				this.setState({highYieldOutstanding: true})
				await fetch(`${this.props.route}/highyield.webapi`, {
					method: 'POST',
					headers: {
						'Token': this.props.userData.Token,
						'Content-Type': 'text/plain',
					},
					body: JSON.stringify({
						customerid: this.props.userData.CustomerId,
						caseid: caseId,
						markhighyield: this.state.highYield,
						unmarkhighyield: !this.state.highYield
					})
				})
				.then(response => {
					this.setState({highYieldOutstanding: false})
					//Attempt sending logs
					fetchSubmitLogs(this.props.userProfile, this.props.userData)

					if (response.status === 401) {
						this.props.setAuthenticationError()
						throw new Error('Authentication Error')
					} else {
						return response.text()
					}
				})
				.then(response => {
					this.setState({enableHighYieldSelect: true})
				})
				.catch((error) => {
					this.setState({highYield: !this.state.highYield, enableHighYieldSelect: true, highYieldOutstanding: false})
					logFetchError(error, this.props.userProfile, this.props.userData, 'markHighYieldHandler')
				})
			})
		}
	}

	render() {

		let gradingView;
		if (this.state.grading) {
			if (this.state.gradingView === 'feedback') {

				// split GradeOut into arrays for each GradeCategory
				let diagnosisOrders = [];
				let treatmentOrders = [];
				let preventativeOrders = [];
				let timing = [];
				let appropriateOrders = [];
				let appropriateSequence = [];
				for (let i = 0; i < this.state.grading.GradeOut.length; i++) {

					if (!this.props.EnableFeedback) {
						this.state.grading.GradeOut[i].Status = 'optional';
					}
					else if (this.state.grading.GradeOut[i].GradeColor.includes('Incorrect')) {
						this.state.grading.GradeOut[i].Status = 'incorrect';
					}
					else if (this.state.grading.GradeOut[i].GradeColor.includes('Optional')) {
						this.state.grading.GradeOut[i].Status = 'optional';
					}
					else if (this.state.grading.GradeOut[i].GradeColor.includes('Correct')) {
						this.state.grading.GradeOut[i].Status = 'correct';
					}

					if (this.state.grading.GradeOut[i].GradeCategory === 'Diagnosis') {

						if (this.state.grading.GradeOut[i].OrderNamesList.length > 0) {
							this.state.grading.GradeOut[i].OrderNames = '';
							for (let j = 0; j < this.state.grading.GradeOut[i].OrderNamesList.length; j++) {
								if (this.state.grading.GradeOut[i].OrderNames !== '') {
									this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + ' / ';
								}
								this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + this.state.grading.GradeOut[i].OrderNamesList[j];
							}
						}

						diagnosisOrders.push(Object.assign({}, this.state.grading.GradeOut[i]));

						if (!this.props.EnableFeedback) {
							diagnosisOrders[diagnosisOrders.length-1].EventName = 'Suggested: order';
						}
					}
					else if (this.state.grading.GradeOut[i].GradeCategory === 'Treatment') {

						if (this.state.grading.GradeOut[i].OrderNamesList.length > 0) {
							this.state.grading.GradeOut[i].OrderNames = '';
							for (let j = 0; j < this.state.grading.GradeOut[i].OrderNamesList.length; j++) {
								if (this.state.grading.GradeOut[i].OrderNames !== '') {
									this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + ' / ';
								}
								this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + this.state.grading.GradeOut[i].OrderNamesList[j];
							}
						}

						treatmentOrders.push(Object.assign({}, this.state.grading.GradeOut[i]));

						if (!this.props.EnableFeedback) {
							treatmentOrders[treatmentOrders.length-1].EventName = 'Suggested: order';
						}
					}
					else if (this.state.grading.GradeOut[i].GradeCategory === 'Timing') {
						timing.push(this.state.grading.GradeOut[i]);
					}
					else if (this.state.grading.GradeOut[i].GradeCategory === 'Appropriate Location' || this.state.grading.GradeOut[i].GradeCategory === 'Appropriate Sequence') {
						appropriateSequence.push(this.state.grading.GradeOut[i]);
					}
					else if (this.state.grading.GradeOut[i].GradeCategory === 'Appropriate Orders') {
						appropriateOrders.push(this.state.grading.GradeOut[i]);
					}
					else if (this.state.grading.GradeOut[i].GradeCategory === 'Preventive care') {

						if (this.state.grading.GradeOut[i].OrderNamesList.length > 0) {
							this.state.grading.GradeOut[i].OrderNames = '';
							for (let j = 0; j < this.state.grading.GradeOut[i].OrderNamesList.length; j++) {
								if (this.state.grading.GradeOut[i].OrderNames !== '') {
									this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + ' / ';
								}
								this.state.grading.GradeOut[i].OrderNames = this.state.grading.GradeOut[i].OrderNames + this.state.grading.GradeOut[i].OrderNamesList[j];
							}
						}

						preventativeOrders.push(Object.assign({}, this.state.grading.GradeOut[i]));

						if (!this.props.EnableFeedback) {
							preventativeOrders[preventativeOrders.length-1].EventName = 'Suggested: order';
						}
					}
				}

				// formatting for enable feedback grading checkbox
				let overallScore;
				let diagnosisGrade;
				let treatmentGrade;
				let preventativeGrade;
				let timingGrade;
				let appropriateGrade;
				let sequenceGrade;
				if (this.props.EnableFeedback) {
					overallScore = <div>{'Overall Score: ' + this.state.grading.TotalGrade + '%'}</div>;
					diagnosisGrade = (this.state.grading.FinalDiagnoisGrade ? this.state.grading.FinalDiagnoisGrade : 0) + '% (weight given for total score: ' + (this.state.grading.WEIGHTDIAGNOSIS ? this.state.grading.WEIGHTDIAGNOSIS : 0) + '%)';
					treatmentGrade = (this.state.grading.FinalTreatmentGrade ? this.state.grading.FinalTreatmentGrade : 0) + '% (weight given for total score: ' + (this.state.grading.WEIGHTTREATMENT ? this.state.grading.WEIGHTTREATMENT : 0) + '%)';
					preventativeGrade = (this.state.grading.FinalPreventiveCareGrade ? this.state.grading.FinalPreventiveCareGrade : 0) + '% (weight given for total score: ' + (this.state.grading.WEIGHTPREVENTATIVE ? this.state.grading.WEIGHTPREVENTATIVE : 0) + '%)';
					timingGrade = (this.state.grading.FinalTimingGrade ? this.state.grading.FinalTimingGrade : 0) + '% (weight given for total score: ' + (this.state.grading.WEIGHTTIMING ? this.state.grading.WEIGHTTIMING : 0) + '%)';
					appropriateGrade = (this.state.grading.FinalAppropriateGrade ? this.state.grading.FinalAppropriateGrade : 0) + '% (weight given for total score: ' + (this.state.grading.WEIGHTAPPROPRIATE ? this.state.grading.WEIGHTAPPROPRIATE : 0) + '%)';
					sequenceGrade = (this.state.grading.FinalSequenceGrade ? this.state.grading.FinalSequenceGrade : 0) + '% (weight give for total score: ' + (this.state.grading.WEIGHTSEQUENCING ? this.state.grading.WEIGHTSEQUENCING : 0) + '%)';
				}

				// map each array to an element
				diagnosisOrders = diagnosisOrders.map((data, index) =>
					<div className={'grading-feedback-event ' + data.Status} key={`grading-feedback-diagnosis-event-${index}`}>
						<div className='grading-feedback-event-header'>{data.EventName}</div>
						<ul>
							<li>{data.OrderNames}</li>
						</ul>
						<div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{data.GradeExplanation}</div>
					</div>
				);

				treatmentOrders = treatmentOrders.map((data, index) =>
					<div className={'grading-feedback-event ' + data.Status} key={`grading-feedback-treatment-event-${index}`}>
						<div className='grading-feedback-event-header'>{data.EventName}</div>
						<ul>
							<li>{data.OrderNames}</li>
						</ul>
						<div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{data.GradeExplanation}</div>
					</div>
				);

				if (preventativeOrders.length === 0) {
					preventativeOrders = (
						<div className='grading-feedback-event optional'>
							<div className='grading-feedback-event-header'>{'None Required'}</div>
						</div>
					);
				}
				else {
					preventativeOrders = preventativeOrders.map((data, index) =>
						<div className={'grading-feedback-event ' + data.Status}  key={`grading-feedback-preventative-event-${index}`}>
							<div className='grading-feedback-event-header'>{data.EventName}</div>
							<ul>
								<li>{data.OrderNames}</li>
							</ul>
							<div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{data.GradeExplanation}</div>
						</div>
					);
				}

				timing = timing.map((data, index) =>
					<div className={'grading-feedback-event ' + data.Status}  key={`grading-timing-event-${index}`}>
						<div className='grading-feedback-event-header'>{data.EventName}</div>
						<div><span className='grading-feedback-event-reason'>{'Reason: '}</span>{data.GradeExplanation}</div>
					</div>
				);

				appropriateOrders = appropriateOrders.map((data, index) =>
					<div className={'grading-feedback-event ' + data.Status}  key={`grading-appropriate-order-${index}`}>
						<div className='grading-feedback-event-header'>{data.EventName}</div>
					</div>
				);

				appropriateSequence = appropriateSequence.map((data, index) =>
					<div className={'grading-feedback-event ' + data.Status} key={`grading-appropriate-sequence-${index}`}>
						<div className='grading-feedback-event-header'>{data.EventName}</div>
						<div><span className='grading-feedback-event-reason'></span>{data.GradeExplanation}</div>
					</div>
				);

				gradingView = (
					<div className='grading-feedback'>
						<div>
						<div className='grading-feedback-header'>
							{overallScore}
							<div>{'Final Diagnosis for this case: ' + this.state.grading.FinalDiagnosis}</div>
						</div>
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Diagnosis Orders '}
								{diagnosisGrade}
							</div>
							{diagnosisOrders}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Treatment Orders '}
								{treatmentGrade}
							</div>
							{treatmentOrders}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Preventative Orders '}
								{preventativeGrade}
							</div>
							{preventativeOrders}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Timing '}
								{timingGrade}
							</div>
							{timing}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Appropriate Orders '}
								{appropriateGrade}
							</div>
							{appropriateOrders}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Appropriate Sequence / Appropriate Location '}
								{sequenceGrade}
							</div>
							{appropriateSequence}
						</div>
						<div className='grading-feedback-category'>
							<div className='grading-feedback-category-header'>
								{'Summary Notes'}
							</div>
							<div className='grading-feedback-summary'>{this.state.grading.SUMMARY}</div>
						</div>
					</div>
				)
			}
			else if (this.state.gradingView === 'log') {
				let ActionLogList = [...this.state.grading.ActionLogList]
				let realStart = new Date(ActionLogList[0].CurrentActualTime);
				let simulationStart = new Date(ActionLogList[0].CurrentVirtualTime);
				// format times
				for (let i = 0; i < ActionLogList.length; i++) {

					let realTime = new Date(ActionLogList[i].CurrentActualTime);
					let simulationTime = new Date(ActionLogList[i].CurrentVirtualTime);

					let hoursAdjustment = 0
					if (realTime.getHours() > realStart.getHours()) {
						hoursAdjustment += realTime.getHours() - realStart.getHours()
					}
					let realMins = (realTime.getMinutes() + hoursAdjustment * 60) - realStart.getMinutes();
					let realSecs = realTime.getSeconds() - realStart.getSeconds();

					if (realMins > 0 || hoursAdjustment > 0) {
						realSecs = realTime.getSeconds();
					}

					if (realMins.toString().length === 1) {
						realMins = '0' + realMins;
					}
					if (realSecs.toString().length === 1) {
						realSecs = '0' + realSecs;
					}
					ActionLogList[i].RealTime = realMins + ' min : ' + realSecs + ' sec';

					let simulationDays = getDaysFromDate(simulationTime) - getDaysFromDate(simulationStart);
					let simulationHrs = simulationTime.getHours() - simulationStart.getHours();
					if (simulationHrs.toString().length === 1) {
						simulationHrs = '0' + simulationHrs;
					}
					let simulationMins = simulationTime.getMinutes() - simulationStart.getMinutes();
					if (simulationMins.toString().length === 1) {
						simulationMins = '0' + simulationMins;
					}
					if (Number(simulationHrs) < 0) {
						simulationDays -= 1
						simulationHrs = 24 + simulationHrs
					}
					ActionLogList[i].SimulationTime = simulationDays + ' days - ' + simulationHrs + ' hr : ' + simulationMins + ' min';

					if (ActionLogList[i].EventName.includes('State Changed to:')) {
						ActionLogList.splice(i, 1);
						i--;
					}

				}

				// dynamically create log list
				let logTable = ActionLogList.map((data) =>
					<div className='grading-log-row'>
						<div className='grading-log-column'>{data.RealTime}</div>
						<div className='grading-log-column'>{data.SimulationTime}</div>
						<div className='grading-log-column-event'>{data.EventName}</div>
					</div>
				);

				gradingView = (
					<div className='grading-log'>
						<div className='grading-log-headers'>
							<div className='grading-log-header'>{'Real Time'}</div>
							<div className='grading-log-header'>{'Elapsed Simulation Time'}</div>
							<div className='grading-log-header event'>{'Event'}</div>
						</div>
						<div className='grading-log-content'>
							{logTable}
						</div>
					</div>
				);
			} else if (this.state.gradingView === 'Faq') {
				gradingView = (
					<div style={{height: 'calc(100% - 69px)', overflowY: 'auto'}}>
						<div className='grading-feedback-header'>
							<div>Frequently Asked Questions</div>
						</div>
						{this.state.faqQuestions.map((value, index) => (
							<div className='grading-feedback-event optional'>
								<div className='grading-feedback-event-header' style={{fontWeight: 'bold'}}>{value.question}</div>
								<div>{value.answer}</div>
							</div>
						))}
					</div>
				)
			}
		}

		// fill the list of completed cases
		let caseList;
		if (this.state.caseList) {
			caseList = this.state.caseList.map((data, index) =>
				<div key={`grading case completed #${index + 1}`} className={'grading-case-list-date ' + (this.state.selectedCase === data.CustomerGradeId)} onClick={() => this.fetchRetrieveCaseGrade(data.CustomerGradeId)}>{data.CaseCompletionDate}</div>
			);
		}

		return (
			<div className='grading'>
				{this.state.displayHighYieldInformation && <HighYieldInformationPopup closePopup={() => this.setState({displayHighYieldInformation: false})}/>}
				<div className='grading-case-list'>
					<div className='grading-case-list-header'>{'Case Completion Date'}</div>
					<div className='grading-case-list-content'>
						{caseList}
					</div>
				</div>
				<div className='grading-view'>
					<div className='grading-view-tabs'>
						<div className='grading-view-tabs-wrapper'>
							<div className={'grading-view-tab ' + (this.state.gradingView === 'feedback')} onClick={() => this.setGradingView('feedback')}>{'Feedback'}</div>
							{this.state.grading?.ActionLogList?.length > 0 && <div className={'grading-view-tab ' + (this.state.gradingView === 'log')} onClick={() => this.setGradingView('log')}>{'Log'}</div>}
							<div className={'grading-view-tab ' + (this.state.gradingView === 'Faq')} onClick={() => this.setGradingView('Faq')}>{'FAQ'}</div>
						</div>
						{this.state.grading.NUMFIRSTATTEMPT >= 100 && <div className={'grading-view-text'}>Average first attempt score: {this.state.grading.AverageFirstAttempt.toFixed(2)}%</div>}
					</div>
					{(this.state.retrieveCaseGradeOutstanding || this.state.viewCaseGradeOutstanding || this.state.peekCaseGradeOutstanding) ? 
						<div className='newGradingLoaderContainer'>
							<MDSpinner
								size={60}
								singleColor={this.props.primaryColor}
							/>
							<p className='newGradingLoaderText'>Loading Grades</p>
						</div>
					:
						gradingView
					}
					<div className='grading-view-footer'>
						<div onClick={() => this.setState({displayHighYieldInformation: true})}>
							{this.state.highYieldOutstanding ?
								<MDSpinner
									size={20}
									singleColor={this.props.primaryColor}
								/>
							:
								<input type='checkbox' checked={this.state.highYield} onClick={this.markHighYieldHandler}/>
							}
							<label><span onClick={this.markHighYieldHandler}>High</span> Yield<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" style={{width: '14px', position: 'relative', top: '-2px', left: '2px'}} className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></label>
						</div>
						<div>
						{this.state.reviewLaterOutstanding ?
								<MDSpinner
									size={20}
									singleColor={this.props.primaryColor}
								/>
							:
								<input type='checkbox' checked={this.state.reviewLater} onClick={this.fetchReviewLater}/>
							}
							<label onClick={this.fetchReviewLater}>{'Review Later'}</label>
						</div>
						<input type='button' value='Close Grading' onClick={this.props.closeGrading}/>
					</div>
				</div>
			</div>
		)
	}
}

export default Grading;
