import React, { useState } from "react";
import logFetchError from "../../Functions/LogFetchError";
import fetchSubmitLogs from "../../Functions/FetchSubmitLogs";
import MDSpinner from "react-md-spinner";

const DemoSignup = ({
  route,
  closeDemoSignup,
  showTOS,
  primaryColor,
  setSuccessMessage,
}) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [referrer, setReferrer] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const joinDemoHandler = (e) => {
    e.preventDefault();
    setFetchOutstanding(true);
    if (email !== confirmEmail) {
      setErrorMessage(
        "Emails do not match. Please check that you've spelled everything correctly."
      );
      setFetchOutstanding(false);
      return;
    }
    fetch(`${route}/demosignup.webapi`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerFirstName: firstName,
        customerLastName: lastName,
        customerEmail: email,
        whereDidYouHear: referrer,
      }),
    })
      .then((response) => response.text())
      .then((result) => {
        setFetchOutstanding(false);

        //Attempt sending logs
        fetchSubmitLogs(null, null);
        if (result.includes("signupError:")) {
          setErrorMessage(result.slice(13, result.length));
        } else {
          setSuccessMessage(
            "Demo account created. Please check your email for your password."
          );
          closeDemoSignup();
        }
      })
      .catch((error) => {
        setFetchOutstanding(false);
        logFetchError(error, null, null, "fetchDemoSignup");
      });
  };

  return (
    <form
      className="loginContainer"
      onSubmit={joinDemoHandler}
      key="demoSignupContainer"
    >
      <h1 className="loginHeader">Demo Signup</h1>
      <label className="loginLabel">First Name</label>
      <input
        type="text"
        autoComplete="given-name"
        required
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        className="loginInput"
      />
      <label className="loginLabel">Last Name</label>
      <input
        type="text"
        autoComplete="family-name"
        required
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        className="loginInput"
      />
      <label className="loginLabel">Email</label>
      <input
        type="email"
        autoComplete="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="loginInput"
      />
      <label className="loginLabel">Confirm Email</label>
      <input
        type="email"
        autoComplete="email"
        required
        value={confirmEmail}
        onChange={(e) => setConfirmEmail(e.target.value)}
        className="loginInput"
      />
      <label className="loginLabel">Where did you hear about us?</label>
      <select
        type="text"
        required
        value={referrer}
        onChange={(e) => setReferrer(e.target.value)}
        className="loginSelect"
      >
        <option>Google Search</option>
        <option>Bing Search</option>
        <option>Facebook</option>
        <option>Friend</option>
        <option>Other</option>
      </select>
      {!fetchOutstanding ? (
        <div className="loginButtonWrapper">
          <button
            className="loginButton"
            type="button"
            onClick={closeDemoSignup}
          >
            Cancel
          </button>
          <button className="loginButton loginPrimaryButton" type="submit">
            Join Demo
          </button>
        </div>
      ) : (
        <div className="loginButtonLoaderWrapper">
          <MDSpinner size={35} singleColor={primaryColor} />
        </div>
      )}
      <a className="loginTermsOfServiceText" type="button" onClick={showTOS}>
        Terms of Service
      </a>
      {errorMessage !== "" && <p className="loginErrorText">{errorMessage}</p>}
    </form>
  );
};

export default DemoSignup;
