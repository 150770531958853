const tableColumns = [
  {
    label: "Name",
    valueKey: ["CustomerFirstName", "CustomerMiddleName", "CustomerLastName"],
  },
  {
    label: "Student Email",
    valueKey: ["CustomerEmail"],
  },
  {
    label: "Group ID",
    valueKey: ["GroupId"],
  },
  {
    label: "Group Name",
    valueKey: ["GroupName"],
  },
  {
    label: "Sign In ID",
    valueKey: ["UniqueSignInId"],
  },
];

const mobileTableData = {
  title: ["CustomerFirstName", "CustomerMiddleName", "CustomerLastName"],
  titleLabel: "Name",
  titleModifier: null,
  subtitle: ["CustomerEmail"],
  subtitleLabel: "Student Email",
  subtitleModifier: null,
  secondaryTitle: ["GroupId"],
  secondaryTitleLabel: "Group ID",
  secondaryTitleModifier: (value) => `Group ID: ${value}`,
  values: [
    {
      label: "Group Name",
      valueKey: ["GroupName"],
      valueModifier: (value) => `Group Name: ${value}`,
    },
    {
      label: "Sign In ID",
      valueKey: ["UniqueSignInId"],
      valueModifier: (value) => `Sign In ID: ${value}`,
    },
  ],
};

export { tableColumns, mobileTableData };
