import React from "react";
import MDSpinner from "react-md-spinner";
import SimulationTime from "./SimulationTime";

class ReviewOrders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTable: "orderSheet",
      caseEndLoading: false,
    };

    this.changeTable = this.changeTable.bind(this);
  }

  changeTable(table) {
    this.setState({ selectedTable: table });
  }

  fetchGrading = async () => {
    this.setState({ caseEndLoading: true });
    await this.props.fetchGrading(
      this.props.caseData.ReviewLaterFlag,
      this.props.caseData.ID
    );
    this.setState({ caseEndLoading: false });
  };

  render() {
    let table;
    if (this.state.selectedTable === "orderSheet") {
      let orders = this.props.orderSheet.map((order, index) => (
        <div
          className="order-review-row"
          key={index}
          onClick={this.props.reviewPopup.bind(
            this,
            "cancelOrder",
            order,
            index
          )}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">{order.ROUTE}</div>
          <div className="log-review-column">{order.FREQUENCY}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <p className="order-review-table-header">Order Sheet</p>
          <div className="order-review-headers-actual">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">Route</div>
            <div className="order-review-column review-header">Frequency</div>
            <div className="order-review-column review-header">Order Time</div>
            <div className="order-review-column review-header">Report Time</div>
          </div>
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "progressNotes") {
      // create order list
      let orders = this.props.progressNotes.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      // list patient updates
      let patientUpdates = this.props.patientUpdates.map((update) => (
        <div
          className="log-review-row"
          onClick={this.props.reviewPopup.bind(this, "patientUpdate", update)}
        >
          <div className="order-review-column">Patient Update</div>
          <div className="log-review-column"></div>
          <div className="log-review-column">
            <SimulationTime simulationTime={update.CurrentVirtualTime} />
          </div>
        </div>
      ));

      // list all completed physicals
      let physicals = this.props.completedPhysicals.map((physical, index) => (
        <div
          className="log-review-row"
          onClick={this.props.reviewPopup.bind(this, "physicalResults", index)}
        >
          <div className="order-review-column">History and Physical</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={physical.timeOrdered} />
          </div>
          <div className="log-review-column">
            <SimulationTime simulationTime={physical.timeReported} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          <div
            className="log-review-row"
            onClick={this.props.reviewPopup.bind(this, "caseIntroduction")}
          >
            <div className="order-review-column">Case Introduction</div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
          </div>
          <div
            className="log-review-row"
            onClick={this.props.reviewPopup.bind(this, "initialHistory")}
          >
            <div className="order-review-column">Initial History</div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
          </div>
          {physicals}
          {patientUpdates}
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "vitalSigns") {
      // create order list
      let orders = this.props.vitalSigns.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            {order.CANCELLED && (
              <p style={{ margin: "0px", marginRight: "5px" }}>CANCELLED</p>
            )}
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          <div
            className="log-review-row"
            onClick={this.props.reviewPopup.bind(this, "initialVitals")}
          >
            <div className="order-review-column">Initial Vital Signs</div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
            <div className="log-review-column">
              <SimulationTime simulationTime={this.props.startTime} />
            </div>
          </div>
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "labReports") {
      let orders = this.props.labReports.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            {order.CANCELLED && (
              <p style={{ margin: "0px", marginRight: "5px" }}>CANCELLED</p>
            )}
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "imaging") {
      let orders = this.props.imaging.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            {order.CANCELLED && (
              <p style={{ margin: "0px", marginRight: "5px" }}>CANCELLED</p>
            )}
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "otherTests") {
      let orders = this.props.otherTests.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            {order.CANCELLED && (
              <p style={{ margin: "0px", marginRight: "5px" }}>CANCELLED</p>
            )}
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          {orders}
        </div>
      );
    } else if (this.state.selectedTable === "treatmentRecord") {
      let orders = this.props.treatmentRecord.map((order) => (
        <div
          className="log-review-row"
          onClick={() => this.props.reviewOrderPopup(order)}
        >
          <div className="order-review-column">{order.NAME}</div>
          <div className="log-review-column">
            <SimulationTime simulationTime={order.CurrentVirtualTime} />
          </div>
          <div className="log-review-column">
            {order.CANCELLED && (
              <p style={{ margin: "0px", marginRight: "5px" }}>CANCELLED</p>
            )}
            <SimulationTime simulationTime={order.ReportTime} />
          </div>
        </div>
      ));

      table = (
        <div className="order-review-table">
          <div className="order-review-headers">
            <div className="order-review-column review-header">Order</div>
            <div className="order-review-column review-header">
              Time Ordered
            </div>
            <div className="order-review-column review-header">
              Time Reported
            </div>
          </div>
          {orders}
        </div>
      );
    }

    let orderButtonStyle;
    if (this.state.selectedTable !== "orderSheet") {
      orderButtonStyle = "fade";
    }

    let buttons = (
      <div className="review-orders-footer">
        <input
          className={"simulation-button " + orderButtonStyle}
          type="button"
          value="Order"
          onClick={this.props.setPopup.bind(this, "orders")}
        />
      </div>
    );

    if (this.props.endCase && this.state.caseEndLoading) {
      buttons = (
        <div className="options-footer-loading-container">
          <MDSpinner size={25} singleColor={this.props.primaryColor} />
        </div>
      );
    } else if (this.props.endCase) {
      buttons = (
        <div className="review-orders-footer">
          <input
            className="simulation-button button-gap"
            type="button"
            value="Write New Orders"
            onClick={this.props.setPopup.bind(this, "orders")}
          />
          <input
            className="simulation-button"
            type="button"
            value="End Case"
            onClick={this.fetchGrading}
          />
        </div>
      );
    }

    return (
      <div className="review-orders">
        <div className="review-orders-content">
          <div className="review-orders-chart-tabs">
            <div className="review-orders-chart-tabs-group">
              <div
                className={
                  "review-orders-chart-tab " +
                  ("orderSheet" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "orderSheet")}
              >
                Order Sheet
              </div>
              <div
                className={
                  "review-orders-chart-tab " +
                  ("progressNotes" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "progressNotes")}
              >
                Progress Notes
              </div>
              <div
                className={
                  "review-orders-chart-tab " +
                  ("vitalSigns" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "vitalSigns")}
              >
                Vital Signs
              </div>
              <div
                className={
                  "review-orders-chart-tab " +
                  ("labReports" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "labReports")}
              >
                Lab Reports
              </div>
            </div>
            <div className="review-orders-chart-tabs-group">
              <div
                className={
                  "review-orders-chart-tab " +
                  ("imaging" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "imaging")}
              >
                Imaging
              </div>
              <div
                className={
                  "review-orders-chart-tab " +
                  ("otherTests" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "otherTests")}
              >
                Other Tests
              </div>
              <div
                className={
                  "review-orders-chart-tab " +
                  ("treatmentRecord" === this.state.selectedTable)
                }
                onClick={this.changeTable.bind(this, "treatmentRecord")}
              >
                Treatment Record
              </div>
            </div>
          </div>
          <div className="review-orders-table-wrapper">
            {table}
            {buttons}
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewOrders;
