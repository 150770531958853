import React, { useState } from "react";
import MDSpinner from "react-md-spinner";
import fetchSubmitLogs from "../../Functions/FetchSubmitLogs";
import logFetchError from "../../Functions/LogFetchError";

const ForgotPassword = ({
  route,
  closeForgotPassword,
  setSuccessMessage,
  primaryColor,
}) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");

  const fetchForgotPassword = (e) => {
    let errorResponse = false;
    e.preventDefault();
    setFetchOutstanding(true);
    fetch(`${route}/forgotpassword.webapi`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          errorResponse = true;
          return response.text();
        }
      })
      .then((result) => {
        setFetchOutstanding(false);
        //Attempt sending logs
        fetchSubmitLogs(null, null);

        if (errorResponse) {
          result = result.replace("{", "");
          result = result.replace("}", "");
          setErrorText(result);
        } else {
          setSuccessMessage("Password reset email sent");
          closeForgotPassword();
        }
      })
      .catch((error) => {
        setFetchOutstanding(false);
        setErrorText(error);
        logFetchError(error, null, null, "forgotPasswordHandler");
      });
  };

  return (
    <form
      className="loginContainer"
      onSubmit={fetchForgotPassword}
      key="forgotPasswordContainer"
    >
      <h1 className="loginHeader">Forgot Password</h1>
      <label className="loginLabel">Email</label>
      <input
        type="text"
        autoComplete="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="loginInput"
      />
      {!fetchOutstanding ? (
        <div className="loginButtonWrapper">
          <button
            className="loginButton"
            type="button"
            onClick={closeForgotPassword}
          >
            Cancel
          </button>
          <button className="loginButton loginPrimaryButton" type="submit">
            Reset Password
          </button>
        </div>
      ) : (
        <div className="loginButtonLoaderWrapper">
          <MDSpinner size={35} singleColor={primaryColor} />
        </div>
      )}
      {errorText !== "" && <p className="loginErrorText">{errorText}</p>}
    </form>
  );
};

export default ForgotPassword;
