// import logFetchError from './LogFetchError'

const fetchSubmitLogs = (userProfile, userData) => {
    if (process.env.REACT_APP_ERROR_REPORT === "true") {
        let logArray = []
        logArray = getLogs()
        if (logArray.length !== 0) {
            fetch(`${getRoute()}/frontendlog.webapi`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'text/plain',
                },
                body: JSON.stringify(logArray)
            }).then((res) => {
                if (res.ok) {
                    console.log('successfully sent logs')
                    removeLogs()
                } else {
                    throw new Error(res.text())
                }
            })
            .catch((error) => {
                // logFetchError(error, userProfile, userData, 'fetchSubmitLogs')
                console.log('error submitting logs')
            })
        }
    }
}

const getRoute = () => {
    let route = ''
    if (process.env.REACT_APP_PRODUCTION !== 'false') {
        route = 'https://app.ccscases.com'
    } else {
        route = `http://localhost:${process.env.REACT_APP_NETWORK_PORT ? process.env.REACT_APP_NETWORK_PORT : 80}`
    }
    return route
}

const getLogs = () => {
    let logArray = []
    if (localStorage.length > 0) {
        for(let i = 0; i < localStorage.length; ++i) {
            let tempStr = localStorage.key(i)
            if (tempStr.includes('ccsLog')) {
                logArray.push(JSON.parse(localStorage.getItem(localStorage.key(i))))
            }
        }
    }
    return logArray
}

const removeLogs = () => {
    for(let i = 0; i < localStorage.length; ++i) {
        let tempStr = localStorage.key(i)
        if (tempStr.includes('ccsLog')) {
            localStorage.removeItem(tempStr)
            --i
        }
    }
}

export default fetchSubmitLogs