import calculatePerformance from "./calculatePerformance";

const getCasesPageData = (analyticsData, userData) => {
  const analyticsDataCaseList = analyticsData.CasesSection.Cases;

  return analyticsDataCaseList
    .map((caseData) => {
      const userCase = userData.CaseLists.find(
        (c) => c.CaseId === caseData.CaseId
      );
      return {
        caseId: caseData.CaseId,
        caseTitle: userCase ? userCase.CaseTitle : "Unknown Case",
        caseDiagnosis: caseData.CaseDiagnosis,
        category: userCase ? userCase.CaseCategory : "Unknown Category",
        completionCount: caseData.CompletionCount,
        schoolAverageGrade: parseFloat(caseData.SchoolAverage.toFixed(2)),
        allSchoolsAverageScore: parseFloat(
          caseData.AllSchoolsAverage.toFixed(2)
        ),
        performance: calculatePerformance(
          caseData.SchoolAverage,
          caseData.AllSchoolsAverage
        ),
      };
    })
    .sort((a, b) => a.caseId - b.caseId);
};

export default getCasesPageData;
