import React, { useMemo } from "react";
import "./MobileTableBody.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";

/*

Example of the mobileTableData data structure

{
  title: ["CustomerFirstName", "CustomerMiddleName", "CustomerLastName"],
  titleModifier: null,
  subtitle: ["CustomerEmail"],
  subtitleModifier: null,
  secondaryTitle: ["GroupId"],
  secondaryTitleModifier: (value) => `Group ID: ${value}`,
  values: [
    {
      label: "Group Name",
      valueKey: ["GroupName"],
      valueModifier: (value) => `Group Name: ${value}`,
    },
    {
      label: "Sign In ID",
      valueKey: ["UniqueSignInId"],
      valueModifier: (value) => `Sign In ID: ${value}`,
    },
  ],
}

*/

const MobileTableBody = ({
  valuesList,
  mobileTableData,
  componentKey,
  tableName,
  onBodyClick,
  onButtonsClick,
  parentSortBy,
  parentSortDirection,
  enableSort = false,
}) => {
  const sortedValuesList = useMemo(() => {
    if (!enableSort) return valuesList;

    const columns = [
      {
        label: mobileTableData.titleLabel,
        valueKey: mobileTableData.title,
      },
      {
        label: mobileTableData.subtitleLabel,
        valueKey: mobileTableData.subtitle,
      },
      {
        label: mobileTableData.secondaryTitleLabel,
        valueKey: mobileTableData.secondaryTitle,
      },
      ...mobileTableData.values,
    ];

    if (parentSortBy) {
      // get the table data item from the column label
      const columnObject = columns.find(
        (column) => column.label === parentSortBy
      );
      return [...valuesList].sort((a, b) => {
        const valueA = columnObject.valueKey.map((key) => a[key]).join(" ");
        const valueB = columnObject.valueKey.map((key) => b[key]).join(" ");

        if (valueA < valueB) {
          return parentSortDirection === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return parentSortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return valuesList;
  }, [valuesList, parentSortBy, parentSortDirection]);

  return (
    <div className="adminPortalMobileTableListWrapper">
      {sortedValuesList.map((tableValue, tableIndex) => (
        <div
          className={`adminPortalMobileTableCardContainer ${
            onBodyClick ? "adminPortalMobileTableCardContainerArrow" : ""
          }`}
          key={`${tableName} - ${tableValue[componentKey]}`}
          onClick={(event) =>
            onBodyClick ? onBodyClick(tableIndex, event, tableValue) : null
          }
        >
          <div>
            <h2>
              {mobileTableData.titleModifier
                ? mobileTableData.titleModifier(
                    tableValue[mobileTableData.title]
                  )
                : mobileTableData.title.map((key) => tableValue[key]).join(" ")}
            </h2>
            {onButtonsClick ? (
              <button className="adminPortalMobileTableEllipsesButton">
                <FontAwesomeIcon
                  icon={faEllipsisVertical}
                  onClick={(event) =>
                    onButtonsClick
                      ? onButtonsClick(tableIndex, event, tableValue)
                      : null
                  }
                />
              </button>
            ) : (
              <h3>
                {mobileTableData.secondaryTitleModifier
                  ? mobileTableData.secondaryTitleModifier(
                      tableValue[mobileTableData.secondaryTitle]
                    )
                  : tableValue[mobileTableData.secondaryTitle]}
              </h3>
            )}
            <p className="adminPortalMobileTableBodyEmail">
              {mobileTableData.subtitleModifier
                ? mobileTableData.subtitleModifier(
                    tableValue[mobileTableData.subtitle]
                  )
                : tableValue[mobileTableData.subtitle]}
            </p>
          </div>
          {mobileTableData.values.length > 0 && (
            <div>
              {mobileTableData.values
                .filter((value) => tableValue[value.valueKey] !== null)
                .map((value) => (
                  <p
                    className="adminPortalMobileTableBodyText"
                    key={`${tableName} - ${value.label}`}
                  >
                    {value.valueModifier
                      ? value.valueModifier(tableValue[value.valueKey])
                      : value.valueKey.map((key) => tableValue[key]).join(" ")}
                  </p>
                ))}
            </div>
          )}
          {onBodyClick && (
            <FontAwesomeIcon
              icon={faChevronRight}
              className="adminPortalMobileTableBodyArrow"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default MobileTableBody;
