import React, { useContext, useState } from "react";
import "./ModifyUniversityLogoModal.css";
import MDSpinner from "react-md-spinner";
import { faImage, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OSCEAdminContext } from "../../..";

const ModifyUniversityLogoModal = ({ setShowModifySchoolLogo }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const {
    userData,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [logoBackgroundColor, setLogoBackgroundColor] = useState(
    userData.SchoolLogoBackgroundColor
      ? userData.SchoolLogoBackgroundColor
      : "#0B335D"
  );

  const handleConfirmClick = async () => {
    const formData = new FormData();

    if (imageFile) {
      formData.append("image", imageFile);
    }
    if (logoBackgroundColor !== userData.SchoolLogoBackgroundColor) {
      formData.append(
        "data",
        JSON.stringify({
          LogoBackgroundColor: logoBackgroundColor,
        })
      );
    }
    if (
      !imageFile &&
      logoBackgroundColor === userData.SchoolLogoBackgroundColor
    ) {
      displayNotification(
        "Please select a new image or change the background color"
      );
      return;
    }

    setFetchOutstanding(true);
    await fetch(`${route}/getschoollogo.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        displayNotification("Logo updated successfully");
        updateUserData({
          ...userData,
          SchoolLogo:
            data.FileName === "" ? userData.SchoolLogo : data.FileName,
          SchoolLogoBackgroundColor: logoBackgroundColor,
        });
        setShowModifySchoolLogo(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        displayNotification(`Failed to update logo`);
        setFetchOutstanding(false);
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Modify University Logo</h2>
        <div
          className="modalImageInputWrapper"
          style={{ rowGap: imageFile ? 10 : 5 }}
        >
          <label className="modalInputLabel">Logo Image</label>
          {imageFile && (
            <button
              className="uploadCSVModalRemoveFileButton"
              onClick={() => setImageFile(null)}
              title={"Remove file"}
              style={{ padding: 0 }}
            >
              <FontAwesomeIcon icon={faX} />
              {imageFile.name}
            </button>
          )}
          <div className="modalModifyImageImageContainer">
            {imageFile || userData.SchoolLogo ? (
              <img
                className="modalModifyImageImage"
                src={
                  imageFile
                    ? URL.createObjectURL(imageFile)
                    : `https://osce.ccscases.com/logos/${userData.SchoolLogo}`
                }
              />
            ) : (
              <div className="modalModifyImagePlaceholderWrapper">
                <FontAwesomeIcon
                  icon={faImage}
                  className="modalModifyImagePlaceholderImage"
                />
                <p className="modalModifyImagePlaceholderText">
                  Click here to add an image
                </p>
              </div>
            )}
            <input
              type="file"
              className="modalModifyImageInput"
              accept="image/*"
              onChange={(e) => setImageFile(e.target.files[0])}
            />
          </div>
        </div>
        <div className="modalInputWrapper">
          <label className="modalInputLabel">Logo Background Color</label>
          <div className="modifyUniversityLogoColorInputWrapper">
            <input
              className="modifyUniversityLogoColorInput"
              type="color"
              value={logoBackgroundColor}
              onChange={(e) => setLogoBackgroundColor(e.target.value)}
            />
            <div className="modifyUniversityLogoColorTextWrapper">
              <p className="modifyUniversityLogoColorText">
                {logoBackgroundColor}
              </p>
            </div>
          </div>
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifySchoolLogo(false)}
            >
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={handleConfirmClick}>
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyUniversityLogoModal;
