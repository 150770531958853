import React, { useState } from "react";
import OSCELoginForm from "./OSCELoginForm";
import TermsOfService from "../../NewLogin/Components/LoginTermsOfService";
import ForgotPassword from "../../NewLogin/Components/ForgotPassword";

const OSCELogin = ({ route, handleLogin }) => {
  const [page, setPage] = useState("Login");
  const [successMessage, setSuccessMessage] = useState("");
  const [accountType, setAccountType] = useState("Student/Preceptor");

  let loginPageDisplay = null;

  switch (page) {
    case "termsOfService":
      loginPageDisplay = <TermsOfService closeTOS={() => setPage("Login")} />;
      break;
    case "forgotPassword":
      loginPageDisplay = (
        <ForgotPassword
          route={route}
          closeForgotPassword={() => setPage("Login")}
          setSuccessMessage={(newMessage) => setSuccessMessage(newMessage)}
          primaryColor={null}
        />
      );
      break;
    default:
      loginPageDisplay = (
        <OSCELoginForm
          route={route}
          handleLogin={handleLogin}
          showTos={() => setPage("termsOfService")}
          showForgotPassword={() => setPage("forgotPassword")}
          successMessage={successMessage}
          accountType={accountType}
          setAccountType={setAccountType}
        />
      );
      break;
  }
  return <div className="loginContentWrapper">{loginPageDisplay}</div>;
};

export default OSCELogin;
