import React, { useContext } from "react";
import "./OverviewCaseCard.css";
import getCategoryIconFromString from "../../../../Util/getCategoryIconFromString";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ExamRulesContext } from "../../../ExamRulesPage";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const OverviewCaseCard = ({ title, category, caseId, index, type, id }) => {
  const { setSelectedCaseList, setSelectedCaseMap, setSelectedCategoryMap } =
    useContext(ExamRulesContext);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const removeCaseFromCaseList = () => {
    setSelectedCaseList((prev) => prev.filter((_, i) => i !== index));
    const functionToExecute =
      type === "category" ? setSelectedCategoryMap : setSelectedCaseMap;
    functionToExecute((prev) => {
      const newMap = { ...prev };
      let mapKey = type === "category" ? category : title;
      newMap[mapKey] -= 1;
      if (newMap[mapKey] === 0) {
        delete newMap[mapKey];
      }
      return newMap;
    });
  };

  return (
    <div className="examRulesOverviewCaseContainer" style={style}>
      <button
        className="examRulesOverviewCaseGripButton"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
      >
        <FontAwesomeIcon icon={faGripVertical} />
      </button>
      <div className="examRulesOverviewCaseDetailsWrapper">
        <p className="examRulesOverviewCaseTitle">
          {index + 1} - {title}
        </p>
        <FontAwesomeIcon icon={getCategoryIconFromString(category)} />
        <p className="examRulesOverviewCaseSubtitle">
          {category}
          {caseId ? ` | Case ID: ${caseId}` : ""}
        </p>
      </div>
      <button
        className="examRulesOverviewCaseRemoveButton"
        onClick={removeCaseFromCaseList}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};

export default OverviewCaseCard;
