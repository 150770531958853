import React, { useContext, useState } from "react";
import "./ConfirmCSVModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ConfirmCSVModal = ({ csvData, setShowConfirmCSVModal, csvFile }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const { route, userData, displayNotification, handleExpiredUserSession } =
    useContext(OSCEAdminContext);

  const handleConfirmCSVUpload = async () => {
    setFetchOutstanding(true);
    const formData = new FormData();
    formData.append("CSV", csvFile);
    await fetch(`${route}/getcsv.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        setShowConfirmCSVModal(false);
        displayNotification(
          `Added ${csvData.NumOfStudentsImporting} students successfully`
        );
      })
      .catch((error) => {
        setFetchOutstanding(false);
        console.error(error);
        displayNotification("Failed to add students");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer" style={{ overflowY: "auto" }}>
        <h2 className="modalHeader">Confirm CSV Upload</h2>
        <p className="modalParagraph">
          You are adding {csvData.NumOfStudentsImporting} new students. Please
          confirm that the students you are uploading are correct.
        </p>
        <div className="confirmCSVStudentsContainer">
          {csvData.CsvCase.map((student, index) => (
            <div
              className="confirmCSVStudentWrapper"
              key={`${student.StudentFirstName} ${student.StudentMiddleName} ${student.StudentLastName}`}
            >
              <div className="confirmCSVStudentHeaderWrapper">
                <span className="confirmCSVStudentNumber">{index + 1}</span>
                <h3 className="confirmCSVStudentHeader">
                  {student.StudentFirstName} {student.StudentMiddleName}{" "}
                  {student.StudentLastName}
                </h3>
                <p className="confirmCSVStudentEmail">{student.StudentEmail}</p>
              </div>
              {student.Warning && student.Warning.length > 0 && (
                <div className="confirmCSVStudentWarningContainer">
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    className="confirmCSVStudentWarningIcon"
                  />
                  <p className="confirmCSVStudentWarningText">
                    {student.Warning}
                  </p>
                </div>
              )}
              <div className="confirmCSVStudentTextItemWrapper">
                <p className="confirmCSVStudentTextItemKey">Total Break Time</p>
                <p className="confirmCSVStudentTextItemValue">
                  {student.TotalBreakTime} Minutes
                </p>
              </div>
              <div className="confirmCSVStudentTextItemWrapper">
                <p className="confirmCSVStudentTextItemKey">Group</p>
                <p className="confirmCSVStudentTextItemValue">
                  {student.GroupId} - {student.GroupName}
                </p>
              </div>
              {/* Commenting this out as we are no longer supporting Preceptor emails in this upload as of 9-24-2024 */}
              {/* <div className="confirmCSVStudentTextItemWrapper">
                <p className="confirmCSVStudentTextItemKey">Preceptors</p>
                <div className="confirmCSVStudentTextItemMultipleValueWrapper">
                  {student.PreceptorEmails.map((email) => (
                    <p
                      className="confirmCSVStudentTextItemValue"
                      key={`${student.StudentFirstName} ${student.StudentMiddleName} ${student.StudentLastName} ${email}`}
                    >
                      {email}
                    </p>
                  ))}
                </div>
              </div> */}
              <h4 className="confirmCSVStudentCasesText">
                Cases ({student.AllCases.length})
              </h4>
              {student.AllCases.map((caseItem) => (
                <div
                  className="confirmCSVStudentTextItemWrapper"
                  key={`${student.StudentFirstName} ${student.StudentMiddleName} ${student.StudentLastName} ${caseItem.CaseId}`}
                >
                  <p className="confirmCSVStudentTextItemKey">
                    Case {caseItem.CaseId}
                  </p>
                  <p className="confirmCSVStudentTextItemValue">
                    {caseItem.Diagnosis}
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="confirmCSVCreditsTextWrapper">
          <p className="confirmCSVCreditsTextKey">Total Credits</p>
          <p className="confirmCSVCreditsTextValue">{csvData.NumOfCredits}</p>
        </div>
        <div className="confirmCSVCreditsTextWrapper">
          <p className="confirmCSVCreditsTextKey">
            Credits Required For Upload
          </p>
          <p className="confirmCSVCreditsTextValue">
            {csvData.NumOfStudentsImporting}
          </p>
        </div>
        <div className="confirmCSVCreditsLineBreak" />
        <div className="confirmCSVCreditsTextWrapper">
          <p className="confirmCSVCreditsTextKey">
            Remaining Credits After Upload
          </p>
          <p className="confirmCSVCreditsTextValue">
            {csvData.NumOfCredits - csvData.NumOfStudentsImporting}
          </p>
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowConfirmCSVModal(false)}
            >
              Cancel
            </button>

            <button
              className="modalConfirmButton"
              onClick={handleConfirmCSVUpload}
            >
              Confirm Upload
            </button>
            {/* <button className='confirmCSVAddCreditsButton'>Add Credits</button> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmCSVModal;
