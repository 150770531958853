import {
  faStethoscope,
  faBrain,
  faVenus,
  faChild,
  faAmbulance,
  faDice,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faHeadSideBrain } from "@fortawesome/pro-solid-svg-icons";

const getCategoryIconFromString = (category) => {
  switch (category) {
    case "Internal Medicine":
      return faStethoscope;
    case "Neurology":
      return faBrain;
    case "OB/GYN":
      return faVenus;
    case "Pediatrics":
      return faChild;
    case "Emergency Medicine":
      return faAmbulance;
    case "Psychiatry":
      return faHeadSideBrain;
    case "Random":
      return faDice;
    default:
      return faQuestionCircle;
  }
};

export default getCategoryIconFromString;
