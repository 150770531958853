import React, { useContext, useEffect, useState } from "react";
import "./GradesPage.css";
import Navigation from "./Navigation/Navigation";
import CategoriesPage from "./CategoriesPage/CategoriesPage";
import CasesPage from "./CasesPage/CasesPage";
import GroupsPage from "./GroupsPage/GroupsPage";
import StudentsPage from "./StudentsPage/StudentsPage";
import { OSCEAdminContext } from "..";
import transformGradesPageData from "../Util/TransformGradesPageData/transformGradesPageData";
import MDSpinner from "react-md-spinner";

const GradesPage = () => {
  const {
    userData,
    updateUserData,
    route,
    displayNotification,
    handleExpiredUserSession,
    prevPageProps,
  } = useContext(OSCEAdminContext);
  const [currentTab, setCurrentTab] = useState(
    prevPageProps?.initialTab ? prevPageProps.initialTab : "Categories"
  );
  const [fetchOutstanding, setFetchOutstanding] = useState(
    !userData.GradesData
  );

  useEffect(() => {
    if (!userData.GradesData) {
      fetch(`${route}/oscegrading.webapi`, {
        method: "POST",
        headers: {
          Token: userData.Token,
        },
        body: JSON.stringify({
          CustomerId: userData.CustomerId,
        }),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            handleExpiredUserSession();
            return false;
          } else {
            throw new Error("Failed to fetch grading data.");
          }
        })
        .then((data) => {
          if (!data) return;
          const gradesData = transformGradesPageData(
            data.OsceAnalytics,
            userData
          );
          updateUserData({ ...userData, GradesData: gradesData });
          setFetchOutstanding(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          displayNotification(
            "Failed to fetch grading data. Please try reloading the page."
          );
          setFetchOutstanding(false);
        });
    }
  }, []);

  const contentPages = {
    Categories: <CategoriesPage />,
    Cases: <CasesPage />,
    Groups: <GroupsPage />,
    Students: <StudentsPage />,
  };

  return (
    <div className="adminPortalGradesPageWrapper">
      <Navigation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        loadingGradesData={fetchOutstanding}
      />
      {!fetchOutstanding ? (
        contentPages[currentTab]
      ) : (
        <div className="adminPortalGradesPageLoadingContainer">
          <MDSpinner size={40} singleColor="#0B335D" />
          <p>Loading Grades Data</p>
        </div>
      )}
    </div>
  );
};

export default GradesPage;
