import React, { useContext, useState } from "react";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ReportBugModal = ({ closeModal }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [bugDescription, setBugDescription] = useState("");
  const { displayNotification, route, userData, handleExpiredUserSession } =
    useContext(OSCEAdminContext);

  const handleConfirmClick = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/feedback.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerId: userData.CustomerId,
        feedback: bugDescription,
      }),
    }).then(async (response) => {
      if (response.ok) {
        displayNotification("Feedback sent successfully");
        closeModal();
      } else if (response.status === 401) {
        handleExpiredUserSession();
      } else {
        displayNotification("Failed to send feedback");
        setFetchOutstanding(false);
      }
    });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Report Bug/Feedback</h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel" htmlFor="bugDescription">
            Description
          </label>
          <textarea
            className="modalTextArea"
            type="text"
            id="bugDescription"
            value={bugDescription}
            onChange={(e) => setBugDescription(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button className="modalCancelButton" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={handleConfirmClick}
              disabled={bugDescription.length <= 0}
            >
              Report Bug/Feedback
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportBugModal;
