import React, { useContext, useEffect, useState } from "react";
import "./CasesPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowUp,
  faEllipsisVertical,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import getCategoryIconFromString from "../../Util/getCategoryIconFromString";
import { OSCEAdminContext } from "../..";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";

const CasesPage = () => {
  const { userData, mousePosition } = useContext(OSCEAdminContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All Cases");
  const [selectedSort, setSelectedSort] = useState("Case Id");
  const [filteredCases, setFilteredCases] = useState(
    userData.GradesData.casesPageData
  );
  const [showMoreHeaderOptions, setShowMoreHeaderOptions] = useState(false);

  useEffect(() => {
    let filteredCases = userData.GradesData.casesPageData.filter((caseData) => {
      if (
        caseData.caseTitle.toLowerCase().includes(searchTerm.toLowerCase()) ||
        caseData.caseDiagnosis
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        caseData.caseId
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
      return false;
    });

    if (selectedCategory !== "All Cases") {
      filteredCases = filteredCases.filter(
        (caseData) => caseData.category === selectedCategory
      );
    }

    switch (selectedSort) {
      case "Title":
        filteredCases = filteredCases.sort((a, b) =>
          a.caseTitle.localeCompare(b.caseTitle)
        );
        break;
      case "Diagnosis":
        filteredCases = filteredCases.sort((a, b) =>
          a.caseDiagnosis.localeCompare(b.caseDiagnosis)
        );
        break;
      case "School Score":
        filteredCases = filteredCases.sort(
          (a, b) => b.schoolAverageGrade - a.schoolAverageGrade
        );
        break;
      case "All Schools Score":
        filteredCases = filteredCases.sort(
          (a, b) => b.allSchoolsAverageScore - a.allSchoolsAverageScore
        );
        break;
      case "Performance":
        filteredCases = filteredCases.sort(
          (a, b) => b.performance - a.performance
        );
        break;
      case "Completion Count":
        filteredCases = filteredCases.sort(
          (a, b) => b.completionCount - a.completionCount
        );
        break;
      default:
        break;
    }

    setFilteredCases(filteredCases);
  }, [searchTerm, selectedCategory, selectedSort, setFilteredCases, userData]);

  const getNoCasesText = () => {
    let noCasesText = "No cases found";
    if (searchTerm) {
      noCasesText += ` for "${searchTerm}"`;
    }
    if (selectedCategory !== "All Cases") {
      noCasesText += ` in category "${selectedCategory}"`;
    }

    noCasesText += ".";

    return noCasesText;
  };

  const handleMobileSelectCategory = (e) => {
    setSelectedCategory(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  const handleMobileSelectSort = (e) => {
    setSelectedSort(e.target.value);
    setShowMoreHeaderOptions(false);
  };

  return (
    <div className="adminCasesPageCasesContainer">
      <h2 className="adminCasesHeader">Case Grades</h2>
      <div className="adminCasesPageHeaderOptionsWrapper">
        <div className="examRulesStudentHeaderSearchWrapper">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            placeholder="Search cases..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <select
          className="examRulesStudentHeaderSelect"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="All Cases">View All Categories</option>
          <option value="Internal Medicine">Internal Medicine</option>
          <option value="Neurology">Neurology</option>
          <option value="OB/GYN">OB/GYN</option>
          <option value="Pediatrics">Pediatrics</option>
          <option value="Emergency Medicine">Emergency Medicine</option>
          <option value="Psychiatry">Psychiatry</option>
        </select>
        <div className="adminCasesPageHeaderOptionsDivider" />
        <select
          className="examRulesStudentHeaderSelect"
          value={selectedSort}
          onChange={(e) => setSelectedSort(e.target.value)}
        >
          <option value="Case Id">Sort By CaseId</option>
          <option value="Title">Sort By Title A-Z</option>
          <option value="Diagnosis">Sort By Diagnosis A-Z</option>
          <option value="School Score">Sort By Highest School Score</option>
          <option value="All Schools Score">
            Sort By Highest All Schools Score
          </option>
          <option value="Performance">Sort By Performance</option>
          <option value="Completion Count">Sort By Completion Count</option>
        </select>
        <button
          className="adminCasesPageHeaderMobileMoreOptionsButton"
          onClick={() => setShowMoreHeaderOptions(true)}
        >
          <FontAwesomeIcon icon={faEllipsisVertical} />
        </button>
      </div>
      <div className="adminCaseListContainer">
        {filteredCases.map((caseData, index) => (
          <div
            className="adminCaseListElementContainer"
            key={`cases-page-case-list-${caseData.caseId}`}
          >
            <h3>{caseData.caseId}</h3>
            <div className="adminCaseListElementTitleWrapper">
              <h4>
                {caseData.caseTitle} - {caseData.caseDiagnosis}
              </h4>
              <FontAwesomeIcon
                icon={getCategoryIconFromString(caseData.category)}
              />
              <p>
                {caseData.category} | {caseData.completionCount} Completions
              </p>
            </div>
            <div className="adminCaseListValueListWrapper adminCaseListValueWrapperColumns">
              <div className="adminCaseListValueWrapper">
                <h5>School Average Score</h5>
                <p>{caseData.schoolAverageGrade}%</p>
              </div>
              <div className="adminCaseListValueWrapper">
                <h5>All Schools Average Score</h5>
                <p>{caseData.allSchoolsAverageScore}%</p>
              </div>
              <div
                className="adminCaseListPerformanceWrapper"
                style={{
                  color: caseData.performance >= 0 ? "#13923e" : "#a90101",
                }}
              >
                <FontAwesomeIcon
                  icon={caseData.performance >= 0 ? faArrowUp : faArrowDown}
                />
                <p>
                  {caseData.performance >= 0
                    ? "Outperforming"
                    : "Underperforming"}{" "}
                  By {Math.abs(caseData.performance)}%
                </p>
              </div>
            </div>
          </div>
        ))}
        {filteredCases.length === 0 && (
          <div className="adminCasesPageNoCasesFoundContainer">
            <p>{getNoCasesText()}</p>
          </div>
        )}
      </div>
      {showMoreHeaderOptions && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowMoreHeaderOptions}
          showButtonListPopup={showMoreHeaderOptions}
        >
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedCategory}
            onChange={handleMobileSelectCategory}
          >
            <option value="All Cases">View All Categories</option>
            <option value="Internal Medicine">Internal Medicine</option>
            <option value="Neurology">Neurology</option>
            <option value="OB/GYN">OB/GYN</option>
            <option value="Pediatrics">Pediatrics</option>
            <option value="Emergency Medicine">Emergency Medicine</option>
            <option value="Psychiatry">Psychiatry</option>
          </select>
          <select
            className="examRulesStudentHeaderSelect examRulesButtonListHeaderSelect"
            value={selectedSort}
            onChange={handleMobileSelectSort}
          >
            <option value="Case Id">Sort By CaseId</option>
            <option value="Title">Sort By Title A-Z</option>
            <option value="Diagnosis">Sort By Diagnosis A-Z</option>
            <option value="School Score">Sort By Highest School Score</option>
            <option value="All Schools Score">
              Sort By Highest All Schools Score
            </option>
            <option value="Performance">Sort By Performance</option>
            <option value="Completion Count">Sort By Completion Count</option>
          </select>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default CasesPage;
