import React, { Component } from 'react'
import './ReportCard.css'
import fetchSubmitLogs from '../Functions/FetchSubmitLogs'
import logFetchError from '../Functions/LogFetchError'
import BarGraphModule from './BarGraphModule/BarGraphModule'
import TopTenCases from './TopTenCasesModule/TopTenCasesModule'
import TestPrepCalculator from './TestPrepCalculator/TestPrepCalculator'
import PerformanceOverTimeModule from './PerformanceOverTimeModule/PerformanceOverTimeModule'
import AverageAttemptByCategory from './AverageAttemptByCategory/AverageAttemptByCategory'
import MDSpinner from 'react-md-spinner'

export default class ReportCard extends Component {
    constructor() {
        super()
        this.state = {
            reportCardData: null,
            jsonreport: null,
            firstAttemptData: null,
            bestAttemptData: null,
            worstPerformingCases: [],
            bestPerformingCases: [],
            reportCardError: null,
            loadingReportCard: false,
            loadingChangeExamDate: false,
        }
    }

    componentDidMount() {
        this.fetchReportCard()
    }

    fetchReportCard = () => {
        this.setState({loadingReportCard: true})

        fetch(`${this.props.route}/reportcard.webapi`, {
            method: 'POST',
            headers: {
                'Token': this.props.userData.Token,
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify({
                customerId: this.props.userData.CustomerId
            })
        })
        .then((response) => {
            this.setState({loadingReportCard: false})
			if (response.status === 401) {
				this.props.setAuthenticationError()
				throw new Error('Authentication Error')
			} else {
				return response.text()
			}
        })
        .then(result => {
            //Attempt sending logs
            fetchSubmitLogs(this.props.userProfile, this.props.userData)

            if (result.includes('Error:')) {
                return this.setState({reportCardError: result.substring(7)})
            }

            const reportCardData = JSON.parse(result)

            reportCardData.da = reportCardData.da.reverse()

            // Create the json report for bar graph modules
            let jsonreport = this.formatJsonReport(reportCardData.jsonreport)

            this.setState({reportCardData, jsonreport})

        })
        .catch(error => {
            this.setState({loadingReportCard: false})
            console.log('error', error);
            this.setState({reportCardError: "Failed to get report card"})
            logFetchError(error, this.props.userProfile, this.props.userData, 'fetchLoginToken')
        });
    }

    formatJsonReport = (jsonreport) => {
        // Initialize format for final report. New categories can be added here and they
        // will populate in the respective bar graph modules.
        let finalReport = [
            {
                title: 'Pediatrics',
                visibilityToggle: 'showPediatrics',
                scores: []
            },
            {
                title: 'Internal Medicine',
                visibilityToggle: 'showInternalMedicine',
                scores: []
            },
            {
                title: 'OB/GYN',
                visibilityToggle: 'showOBGYN',
                scores: []
            },
            {
                title: 'Neurology',
                visibilityToggle: 'showNeurology',
                scores: []
            },
            {
                title: 'Emergency Medicine',
                visibilityToggle: 'showEmergencyMedicine',
                scores: []
            },
            {
                title: 'Psychiatry',
                visibilityToggle: 'showPsychiatry',
                scores: []
            },
        ]

        jsonreport.forEach((value, index) => {
            finalReport[value.CaseCategory - 1].scores.push(value)
        })

        return(finalReport)
    }

    fetchChangeExamDate = async (newDate) => {

        this.setState({loadingChangeExamDate: true})

        // updatedSuccessfully is keeping track of whether or not the input field should
        // clear itself after fetchChangeExamDate is finished executing
        let updatedSuccessfully = false
        await fetch(`${this.props.route}/updateexamdate.webapi`, {
            method: 'POST',
            headers: {
                'Token': this.props.userData.Token,
                'Content-Type': 'text/plain',
            },
            body: JSON.stringify({
                customerId: this.props.userData.CustomerId,
                ExamDate: newDate
            })
        })
        .then(response => response.text())
        .then(result => {
            this.setState({loadingChangeExamDate: false})
            //Attempt sending logs
            fetchSubmitLogs(this.props.userProfile, this.props.userData)
            this.fetchReportCard()
            updatedSuccessfully = false
        })
        .catch(error => {
            this.setState({loadingChangeExamDate: false})
            console.log('error', error);
            logFetchError(error, this.props.userProfile, this.props.userData, 'fetchChangeExamDate')
        });

        return updatedSuccessfully
    }

    render() {
        return (
            <div className='reportCardPageContainer'>
                <div className='reportCardHeaderContainer'>
                    <h1 className='reportCardHeaderText'>Step 3 CCS Cases - Report Card</h1>
                    <button className='reportCardHeaderButton' onClick={this.props.returnToCaseList}>Back to Case List</button>
                </div>
                {this.state.loadingReportCard &&
                    <div className='reportCardLoadingContainer'>
                        <MDSpinner
                            size={50}
                            singleColor='#0B335D'
                            className='reportCardSpinner'
                        />
                        <p className='loadingText'>Loading Report Card...</p>
                    </div>
                }
                {this.state.reportCardData &&
                    <div className='reportCardOverviewTitleValueWrapper'>
                        <div className='reportCardTitleValueContainer'>
                            <p className='reportCardTitleValueText'>Total Cases Attempted</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.TotalNumberOfCasesAttempted}</p>
                        </div>
                        <div className='reportCardTitleValueContainer'>
                            <p className='reportCardTitleValueText'>Total Cases Completed</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.TotalCasesCompleted}</p>
                        </div>
                        <div className='reportCardTitleValueContainer'>
                            <p className='reportCardTitleValueText'>Total Cases Remaining</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.TotalNumberOfCasesLeftToDo}</p>
                        </div>
                        <div className='reportCardTitleValueContainer'>
                            <p className='reportCardTitleValueText'>Average Attempts Per Case</p>
                            <p className='reportCardTitleValueText'>{Number(this.state.reportCardData.AverageAttemptsPerCase).toFixed(2)}</p>
                        </div>
                    </div>
                }
                {this.state.jsonreport &&
                    <BarGraphModule
                        jsonreport={this.state.jsonreport}
                        showAverages={true}
                        title={'First Case Attempts'}
                        primaryValueKey={'FirstCaseAttemptGrade'}
                        secondaryValueKey={'AverageGeneralUserFirstCaseAttempt'}
                        legendPrimary={'Your Score'}
                        legendSecondary={'All Users\' Average Score'}
                        customContainerStyle={{margin: 'none'}}
                    />
                }
                {this.state.reportCardData &&
                    <div className='dualModuleWrapper'>
                        <TopTenCases
                            title="Top 10 Worst Performing Cases"
                            userData={this.state.reportCardData.TopTenWorseCases}
                        />
                        <TopTenCases
                            title="Top 10 Best Performing Cases"
                            userData={this.state.reportCardData.TopTenBestCases}
                        />
                    </div>
                }
                {this.state.reportCardData &&
                    <div className='reportCardThreeTitleValueWrapper'>
                        <div className='reportCardTitleValueContainer' style={{gridTemplateColumns: '1fr 1fr'}}>
                            <p className='reportCardTitleValueText'>Worst Category By Average Score</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.LowestCategoryForAverages.CategoryName} ({this.state.reportCardData.LowestCategoryForAverages.categoryAverage}%)</p>
                        </div>
                        <div className='reportCardTitleValueContainer' style={{gridTemplateColumns: '1fr 1fr'}}>
                            <p className='reportCardTitleValueText'>Worst Category By First Score</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.LowestCategoryForFirstAttempts.CategoryName} ({this.state.reportCardData.LowestCategoryForFirstAttempts.categoryAverage}%)</p>
                        </div>
                        <div className='reportCardTitleValueContainer' style={{gridTemplateColumns: '1fr 1fr'}}>
                            <p className='reportCardTitleValueText'>Worst Category By Best Score</p>
                            <p className='reportCardTitleValueText'>{this.state.reportCardData.LowestCategoryForBestAttempts.CategoryName} ({this.state.reportCardData.LowestCategoryForBestAttempts.categoryAverage}%)</p>
                        </div>
                    </div>
                }
                {this.state.jsonreport &&
                    <BarGraphModule
                        jsonreport={this.state.jsonreport}
                        showAverages={false}
                        title={'First Attempt vs Best Attempt By Case'}
                        primaryValueKey={'FirstCaseAttemptGrade'}
                        secondaryValueKey={'BestCaseAttemptGrade'}
                        legendPrimary={'Your First Attempt'}
                        legendSecondary={'Your Best Attempt'}
                    />
                }
                {this.state.reportCardData &&
                    <AverageAttemptByCategory
                        data={this.state.reportCardData}
                    />
                }
                {this.state.reportCardData &&
                    <div className='dualModuleWrapper testPrepCalculatorWrapper'>
                        <TestPrepCalculator
                            RecMinDailyCases={this.state.reportCardData.RecMinDailyCases}
                            RecMinDailyCasesRepeats={this.state.reportCardData.RecMinDailyCasesRepeats}
                            DaysUntilExam={this.state.reportCardData.DaysUntilExam}
                            fetchChangeExamDate={this.fetchChangeExamDate}
                            loadingChangeExamDate={this.state.loadingChangeExamDate}
                        />
                    </div>
                }
                {this.state.reportCardData &&
                    <PerformanceOverTimeModule
                        reportCardData={this.state.reportCardData}
                    />
                }
                {this.state.reportCardError &&
                    <div className='reportCardErrorWrapper'>
                        <p className='reportCardErrorMessage'>
                            {this.state.reportCardError}
                        </p>
                    </div>
                }
            </div>
        )
    }
}
