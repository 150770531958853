import "./index.css";
import { useEffect, useState, createContext } from "react";
import AdminNavigation from "./Components/Navigation/Navigation";
import AdminHome from "./Home/AdminHome";
import MySchool from "./MySchool/MySchool";
import "./Components/Modals/GlobalModalStyles.css";
import StudentProfilePage from "./StudentProfilePage/StudentProfilePage";
import useMousePosition from "./Util/useMousePosition";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExamRulesPage from "./ExamRulesPage/ExamRulesPage";
import GradesPage from "./GradesPage/GradesPage";
import UnauthenticatedModal from "../../Util/NewUnauthenticatedPopup/NewUnauthenticatedPopup";
import IndividualStudentGrades from "./IndividualStudentGrades/IndividualStudentGrades";
import AccountSettings from "./AccountSettings/AccountSettings";

export const OSCEAdminContext = createContext(null);

const OSCEAdmin = ({ userData, route, updateUserData }) => {
  const [currentPage, setCurrentPage] = useState("Home");
  const [prevPageProps, setPrevPageProps] = useState(null);
  const mousePosition = useMousePosition();
  const [showUnauthenticatedModal, setShowUnauthenticatedModal] =
    useState(false);

  let contentToRender = null;

  const handleSelectStudent = (index) => {
    handleChangePage("Student", {
      studentId: userData.Students[index].CustomerId,
    });
  };

  const displayNotification = (message) => {
    toast(message);
  };

  const handleModifyUsersExamRules = (studentId) => {
    handleChangePage("Exam Rules", { studentId });
  };

  const handleChangePage = (newPage, props) => {
    setCurrentPage(newPage);
    if (props) {
      setPrevPageProps(props);
    } else {
      setPrevPageProps(null);
    }
  };

  switch (currentPage) {
    case "Home":
      contentToRender = <AdminHome handleSelectStudent={handleSelectStudent} />;
      break;
    case "My School":
      contentToRender = <MySchool />;
      break;
    case "Student":
      contentToRender = <StudentProfilePage />;
      break;
    case "Exam Rules":
      contentToRender = <ExamRulesPage />;
      break;
    case "Grades":
      contentToRender = <GradesPage handleChangePage={handleChangePage} />;
      break;
    case "Settings":
      contentToRender = <AccountSettings />;
      break;
    case "Student Grades":
      contentToRender = <IndividualStudentGrades />;
      break;
    default:
      contentToRender = <AdminHome handleSelectStudent={handleSelectStudent} />;
      break;
  }

  const handleExpiredUserSession = () => {
    setShowUnauthenticatedModal(true);
  };

  return (
    <div className="adminPortalContainer">
      <AdminNavigation
        currentPage={currentPage}
        setCurrentPage={handleChangePage}
      />
      <OSCEAdminContext.Provider
        value={{
          userData,
          route,
          updateUserData,
          mousePosition,
          displayNotification,
          handleModifyUsersExamRules,
          handleExpiredUserSession,
          prevPageProps,
          handleChangePage,
        }}
      >
        {contentToRender}
      </OSCEAdminContext.Provider>
      <ToastContainer theme="light" />
      {showUnauthenticatedModal && (
        <UnauthenticatedModal hideTOSBullet={true} />
      )}
    </div>
  );
};

export default OSCEAdmin;
