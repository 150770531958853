export const updateLocation = (simulationData, locationId) => {
  let tempSimulationData = simulationData;
  tempSimulationData.CaseLocationId = locationId;
  switch (locationId) {
    case 1:
      tempSimulationData.CaseLocationDesc = "Emergency Department";
      break;
    case 2:
      tempSimulationData.CaseLocationDesc = "Office";
      break;
    case 3:
      tempSimulationData.CaseLocationDesc = "Intensive Care Unit";
      break;
    case 4:
      tempSimulationData.CaseLocationDesc = "Home";
      break;
    case 5:
      tempSimulationData.CaseLocationDesc = "Inpatient Unit";
      break;
    default:
      tempSimulationData.CaseLocationDesc = "";
  }
  return tempSimulationData;
};
