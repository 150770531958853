import calculateAverageChangePerMonth from "./calculateAverageChangePerMonth";
import getGraphData from "./getGraphData";

const getCategoriesPageData = (analyticsData, userData) => {
  const categoriesPageDataArray =
    analyticsData.CategoryTimeSection.CategoryTrends.map((categoryTrend) => {
      const category = analyticsData.CategoriesSection.Categories.find(
        (cat) => cat.CategoryName === categoryTrend.CategoryName
      );

      const getCategoryAverageGrade = () => {
        return parseFloat(
          analyticsData.SchoolSection.CategoryAverages[
            categoryTrend.CategoryName
          ].toFixed(2)
        );
      };

      return {
        categoryName: categoryTrend.CategoryName,
        categoryAverageGrade: getCategoryAverageGrade(),
        allSchoolsAverageGrade: parseFloat(
          category.AllSchoolsAverage.toFixed(2)
        ),
        casesCompletedInThisCategory: category.CompletedCasesCount,
        averageChangePerMonth: calculateAverageChangePerMonth(
          categoryTrend.MonthlyTrends
        ),
        graphData: getGraphData(categoryTrend),
      };
    });

  return categoriesPageDataArray;
};

export default getCategoriesPageData;
