import React from 'react';
import MDSpinner from 'react-md-spinner';

class Reevaluate extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			minimumTime: {    // this stores the minimum time allowed to be selected base on mode
				days: this.props.simulationTime.days,
				hours: this.props.simulationTime.hours,
				minutes: this.props.simulationTime.minutes,
			},
			selectedTime: {    // this should store the amount time has been changed from the minimum
				days: this.props.simulationTime.days,
				hours: this.props.simulationTime.hours,
				minutes: this.props.simulationTime.minutes,
			},
			reevaluateMode: 'on',    // what checkbox is selected, on, in, with, call
			selectedCalendarDay: this.props.simulationTime.days,
			reevaluateError: false,
			fetchingAdvance: false
		}
		this.reevaluateModeClick = this.reevaluateModeClick.bind(this);
		this.calendarDayClick = this.calendarDayClick.bind(this);
	}

	componentDidMount() {
		var Days = document.getElementById('Days');
		var Hours = document.getElementById('Hours');
		var Minutes = document.getElementById('Minutes');

		Days.onkeydown = function(e) {
			if (e.key === '-' || e.key === '+') {
				return false
			}
		}
		Hours.onkeydown = function(e) {
			if (e.key === '-' || e.key === '+') {
				return false
			}
		}
		Minutes.onkeydown = function(e) {
			if (e.key === '-' || e.key === '+') {
				return false
			}
		}
	}

	/*
	this is used to update the minimum hours and minutes
	*/
	componentDidUpdate() {

		// when days is greater than minimum, hours minimum should be 0 same with minutes
		if (this.state.reevaluateMode === 'on') {

			let days = this.props.simulationTime.days;
			let hours = this.props.simulationTime.hours;
			let minutes = this.props.simulationTime.minutes;
			if (this.state.selectedTime.days > 0) {
				hours = 0;
			}
			if (this.state.selectedTime.hours > 0) {
				minutes = 0;
			}

			if (this.state.minimumTime.hours !== hours || this.state.minimumTime.minutes !== minutes) {
				this.setState({
					minimumTime: {
						days: days,
						hours: hours,
						minutes: minutes,
					}
				});
			}
		}
	}

	/*
	sets the selectedTime to the amount changed from minimum
	*/
	timeSelectChange = (type, event) => {

		let maxDays = 999

		// Need to check that we're not going beyond day 999 for 'in' mode
		if (this.state.reevaluateMode === 'in')
			maxDays = 999 - this.props.simulationTime.days

		if (event.target.value.includes('.'))
			event.target.value = event.target.value.replace('.', '');

		let selectedTime = this.state.selectedTime
		if (type === 'days') {
			// Check if the user has entered a value greater than 999
			if (event.target.value > maxDays)
				event.target.value = maxDays
			selectedTime.days = event.target.value
			this.setState({
				selectedCalendarDay: this.props.simulationTime.days + (event.target.value - this.state.minimumTime.days),
			});
		}
		else if (type === 'hours') {
			if (event.target.value > 23)
				event.target.value = 23;
			selectedTime.hours = event.target.value
		}
		else if (type === 'minutes') {
			if (event.target.value > 59) {
				event.target.value = 59;
			}
			selectedTime.minutes = event.target.value
		}
		this.setState({selectedTime})
	}

	/*
	base on mode and selected time calculates the number of minutes to advance the time
	*/
	reevaluateCase = async () => {
		if (this.state.reevaluateMode === 'in') {
			let selectedTime = this.state.selectedTime
			selectedTime = {
				days: Number(selectedTime.days),
				hours: Number(selectedTime.hours),
				minutes: Number(selectedTime.minutes)
			}
			if (selectedTime.days < 0 || selectedTime.hours < 0 || selectedTime.minutes < 0) {
				return this.setState({reevaluateError: true})
			} else {
				this.setState({reevaluateError: false})
			}
			let time = this.state.selectedTime.days * 1440 + this.state.selectedTime.hours * 60 + this.state.selectedTime.minutes;
			if (this.props.endCase) {
				let selectedTime = {
					days: Number(this.state.selectedTime.days) + Number(this.props.simulationTime.days),
					hours: Number(this.state.selectedTime.hours) + Number(this.props.simulationTime.hours),
					minutes: Number(this.state.selectedTime.minutes) + Number(this.props.simulationTime.minutes)
				}
				let minimumTime = {
					days: this.props.simulationTime.days,
					hours: this.props.simulationTime.hours,
					minutes: this.props.simulationTime.minutes,
				}
				this.setState({fetchingAdvance: true})
				await this.props.fetchAdvance(selectedTime, minimumTime);
				this.setState({fetchingAdvance: false})
			}
			else {
				this.setState({fetchingAdvance: true})
				await this.props.fetchAdvance(time);
				this.setState({fetchingAdvance: false})
				// This code is believed to be causing the first result popup to close prematurely
				// if (!this.props.scheduleAppointment) {
					// this.props.closePopup();
				// }
			}
		}
		else if (this.state.reevaluateMode === 'on') {
			let selectedTime = this.state.selectedTime
			selectedTime = {
				days: Number(selectedTime.days),
				hours: Number(selectedTime.hours),
				minutes: Number(selectedTime.minutes)
			}
			let minimumTime = this.props.simulationTime

			//This just checks to make sure times are at least their minimum if they are on the day where it applies
			if (selectedTime.days < minimumTime.days ||
				(selectedTime.hours < minimumTime.hours && selectedTime.days <= minimumTime.days) ||
				(selectedTime.hours <= minimumTime.hours && selectedTime.days <= minimumTime.days && selectedTime.minutes < minimumTime.minutes)
				) {
					return this.setState({reevaluateError: true})
			} else {
				this.setState({reevaluateError: false})
			}

			let time = 0;
			time += (selectedTime.days - minimumTime.days) * 1440;
			time += (selectedTime.hours - minimumTime.hours) * 60;
			time += selectedTime.minutes - minimumTime.minutes

			if (this.props.endCase) {
				// pass the time displayed
				this.setState({fetchingAdvance: true})
				await this.props.fetchAdvance(selectedTime, this.state.minimumTime);
				this.setState({fetchingAdvance: false})
			}
			else {
				this.setState({fetchingAdvance: true})
				await this.props.fetchAdvance(time);
				this.setState({fetchingAdvance: false})
				// if (!this.props.scheduleAppointment) {
				// 	this.props.closePopup();
				// }
			}
		}
		else if (this.state.reevaluateMode === 'with') {
			// advance only to the next patient or order update
			if (this.props.advanceUpdate) {
				this.props.advanceUpdate();
			}
			else {
				//this.props.closePopup();
				this.setState({fetchingAdvance: true})
				await this.props.fetchAdvance(999 * 1440);
				this.setState({fetchingAdvance: false})
			}
		}
		else if (this.state.reevaluateMode === 'call') {
			// advance indefinitely
			//this.props.closePopup();
			this.setState({fetchingAdvance: true})
			await this.props.fetchAdvance(999 * 1440);
			this.setState({fetchingAdvance: false})
		}
	}

	/*
	changes the selected checkbox so that only one can be selected at a time
	*/
	reevaluateModeClick(mode) {
		if (mode === 'on') {
			this.setState({
				minimumTime: {
					days: this.props.simulationTime.days,
					hours: this.props.simulationTime.hours,
					minutes:this.props.simulationTime.minutes,
				}
			});
		}
		else if (mode === 'in') {
			this.setState({
				minimumTime: {
					days: 0,
					hours: 0,
					minutes: 0
				},
				selectedTime: {
					days: 0,
					hours: 0,
					minutes: 0
				}
			});
		}
		this.setState({
			reevaluateMode: mode,
			selectedCalendarDay: this.props.simulationTime.days,
		});
		if (mode !== "in") {
			this.setState({
				selectedTime: {
					days: this.props.simulationTime.days,
					hours: this.props.simulationTime.hours,
					minutes: this.props.simulationTime.minutes,
				}
			})
		}
	}

	/*
	called when a day on the calendar is clicked
	changes the state which will rerender the calendar and highlight the newly selected day
	*/
	calendarDayClick(day) {
		if (day >= this.props.simulationTime.days) {
			this.setState({
				selectedCalendarDay: day,
				selectedTime: {
					days: this.state.reevaluateMode === 'on' ? day : day - this.props.simulationTime.days,
					hours: this.state.selectedTime.hours,
					minutes: this.state.selectedTime.minutes,
				}
			});
		}
	}

	render() {
		let calendarRowsArray = [];// array that will contain an array for each row of the calendar
		for (let i = 0; i < 999; i+=7) {
			let tempDaysArray = [];// array that represents a row in the calendar each index is a day
			for (let j = 1; j < 8; j++) {
				let day = i+j-1;// move all the days forward by 1 spot so that calendar starts on monday not sunday
				if (day <= 999) {
					if (day === 0) {
						day = '';
					}
					tempDaysArray.push(day);
				}
			}
			calendarRowsArray.push(tempDaysArray);
		}

		// dynamically create calendar jsx from 2d array
		let calendarRows = [];// stores element for each week of calendar
		for (let i = 0; i < calendarRowsArray.length; i++) {
			let tempRow = calendarRowsArray[i].map((day) =>
				<div 
					className={'calendar-day ' + (day === this.state.selectedCalendarDay)}// background color when selected
					onClick={this.calendarDayClick.bind(this, day)}
				>{day}</div>
			);
			calendarRows.push(tempRow);
		}
		let calendar = calendarRows.map((week) =>
			<div className='calendar-days-row'>
				{week}
			</div>
		);

		// set the displayed selected time to selectedTime + minimumTime
		let days = this.state.selectedTime.days;
		let hours = this.state.selectedTime.hours;
		let minutes = this.state.selectedTime.minutes;

		// dynamically display the day of the week
		let dayNum = this.state.selectedCalendarDay % 7;
		let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
		let dayWeek = daysOfWeek[dayNum];

		return (
			<div className='popup-container'>
				<div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup reevaluate-popup'>
						<div className='popup-header'>Reevaluate</div>
						<div className='popup-reevaluate-content'>
							<div className='reevaluate-box-calendar'>
								<div className='popup-header'>Calendar</div>
								<div className='calendar'>
									<div className='calendar-headers'>
										<p className='calendar-header'>Sun</p>
										<p className='calendar-header'>Mon</p>
										<p className='calendar-header'>Tue</p>
										<p className='calendar-header'>Wed</p>
										<p className='calendar-header'>Thu</p>
										<p className='calendar-header'>Fri</p>
										<p className='calendar-header'>Sat</p>
									</div>
									<div className='calendar-days'>
										{calendar}
									</div>
								</div>
							</div>
							<div className='reevaluate-box-case'>
								<div className='popup-header'>Reevaluate Case</div>
								<div className='reevaluate-case'>
									<div className='reevaluate-mode-checkbox' onClick={this.reevaluateModeClick.bind(this, 'on')}>
										<input type='checkbox' checked={this.state.reevaluateMode === 'on'}/>
										<label className='reevaluate-mode-label'>On</label>
									</div>
									<div className='reevaluate-mode-checkbox' onClick={this.reevaluateModeClick.bind(this, 'in')} style={{gridRow: 2}}>
										<input type='checkbox' checked={this.state.reevaluateMode === 'in'}/>
										<label className='reevaluate-mode-label'>In</label>
									</div>
									{!(this.props.scheduleAppointment || this.props.endCase) && <div style={{gridColumnStart: 1, gridColumnEnd: 3}} className='reevaluate-mode-checkbox' onClick={this.reevaluateModeClick.bind(this, 'with')}>
										<input type='checkbox' checked={this.state.reevaluateMode === 'with'}/>
										<label className='reevaluate-mode-label'>With next available result</label>
									</div>}
									{!(this.props.scheduleAppointment || this.props.endCase) && <div style={{gridColumnStart: 1, gridColumnEnd: 3}} className='reevaluate-mode-checkbox' onClick={this.reevaluateModeClick.bind(this, 'call')}>
										<input type='checkbox' checked={this.state.reevaluateMode === 'call'}/>
										<label className='reevaluate-mode-label'>Call / see me as needed</label>
									</div>}
									{ (this.state.reevaluateMode === "on" || this.state.reevaluateMode === "in") &&
										<div className='reevaluate-case-time'>
											<div className='reevaluate-case-amounts'>
												<div className='reevaluate-case-amount'>
													<p className='reevaluate-case-text'>Day(s):</p>
													{/* <input className='reevaluate-case-input' id='Days' type='number' min={this.state.minimumTime.days} min='0' max='999' value={days === 0 ? '' : days} onChange={(event) => this.timeSelectChange('days', event)}/> */}
													<input className='reevaluate-case-input' id='Days' type='number' min='0' max='999' value={days === 0 ? '' : days} onChange={(event) => this.timeSelectChange('days', event)}/>
												</div>
												<div className='reevaluate-case-amount'>
													<p className='reevaluate-case-text'>Hour(s):</p>
													{/* <input className='reevaluate-case-input' id='Hours' type='number' min={this.state.minimumTime.hours} min='0' max='23' value={hours === 0 ? '' : hours} onChange={(event) => this.timeSelectChange('hours', event)}/> */}
													<input className='reevaluate-case-input' id='Hours' type='number' min='0' max='23' value={hours === 0 ? '' : hours} onChange={(event) => this.timeSelectChange('hours', event)}/>
												</div>
												<div className='reevaluate-case-amount'>
													<p className='reevaluate-case-text'>Minute(s):</p>
													{/* <input className='reevaluate-case-input' id='Minutes' type='number' min={this.state.minimumTime.minutes} min='0' max='59' value={minutes === 0 ? '' : minutes} onChange={(event) => this.timeSelectChange('minutes', event)}/> */}
													<input className='reevaluate-case-input' id='Minutes' type='number' min='0' max='59' value={minutes === 0 ? '' : minutes} onChange={(event) => this.timeSelectChange('minutes', event)}/>
												</div>
											</div>
											<p className='reevaluate-case-text'>{dayWeek}</p>
										</div>
									}
								</div>
							</div>
						</div>
						<p className='reevaluate-error-text'>{this.state.reevaluateError ? 'Error: invalid time entry' : ''}</p>
						{(this.state.fetchingAdvance || this.props.fetchingSchedule) ?
							<div className='options-footer-loading-container'>
								<MDSpinner
									size={25}
									singleColor={this.props.primaryColor}
								/>
							</div>
						:
							<div className='options-footer'>
								<input className='simulation-button button-gap' type='button' value='OK' onClick={this.reevaluateCase}/>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.props.closePopup}/>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Reevaluate;
