import React, { useEffect } from "react";
import ExamTimerDonutChart from "./ExamTimerDonutChart";

const ExamTimer = ({
  initialTimeInSeconds,
  handleTimerFinish,
  breakTimePassed,
  setBreakTimePassed,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (breakTimePassed <= initialTimeInSeconds - 1)
        setBreakTimePassed(breakTimePassed + 1);
      if (breakTimePassed === initialTimeInSeconds - 1) handleTimerFinish();
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [breakTimePassed]);

  return (
    <div className="examTransitionTimerWrapper">
      <div className="examTransitionDonutChartContainer">
        <ExamTimerDonutChart
          initialTime={initialTimeInSeconds}
          timePassed={breakTimePassed}
        />
      </div>
      <div className="examTransitionTimerTextContainer">
        <p className="examTransitionTimerText">
          The next case will begin in <br />{" "}
          {initialTimeInSeconds - breakTimePassed > 60 && (
            <>
              <span className="examTransitionTimerNumberText">
                {Math.floor((initialTimeInSeconds - breakTimePassed) / 60)}
              </span>
              Minute
              {initialTimeInSeconds - breakTimePassed > 120 ? "s" : ""}{" "}
            </>
          )}
          <span className="examTransitionTimerNumberText">
            {(initialTimeInSeconds - breakTimePassed) % 60}
          </span>
          Second{(initialTimeInSeconds - breakTimePassed) % 60 === 1 ? "" : "s"}
        </p>
      </div>
    </div>
  );
};

export default ExamTimer;
