import React, { useState } from "react";
import "./ConfirmationModal.css";
import MDSpinner from "react-md-spinner";

const ConfirmationModal = ({ header, text, closeModal, confirmFunction }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);

  const handleConfirm = async () => {
    setFetchOutstanding(true);
    if (await confirmFunction()) closeModal();
    else setFetchOutstanding(false);
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">{header}</h2>
        <p className="modalParagraph">{text}</p>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button className="modalCancelButton" onClick={closeModal}>
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={handleConfirm}>
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal;
