import React from "react";

const TermsOfService = ({ closeTOS }) => {
  return (
    <div
      className="loginContainer termsOfServiceContainer"
      key="termsOfServiceContainer"
    >
      <h1 className="loginHeader">Terms of Service</h1>
      <div className="loginTermsOfServiceWrapper">
        <p>These updates were effective October 1, 2020</p>

        <p>
          These Software as a Service Terms and Conditions (together with any
          applicable Order Form issued hereunder, the “Agreement”), effective as
          of the date set forth on an applicable Order Form (“Effective Date”),
          is between Axiom Innovations LLC, a Colorado corporation, and the
          Customer named in such Order Form (“Customer”). Axiom Innovations LLC
          and Customer agree as follows:
        </p>
        <p>
          These User Terms are Legally Binding. These User Terms are a legally
          binding contract between you and us. As part of these User Terms, you
          agree to comply with the terms set below. If you access or use the
          Services, or continue accessing or using the Services are being
          notified of a change to the User Terms, you confirm that you have
          read, understand, and agree to be bound by the User Terms.
        </p>
        <p>
          <strong>Overview:</strong>
        </p>
        <p>By agreeing to these Terms, you promise to follow these rules:</p>
        <p>
          1. You will not share your login/password with anyone. Each
          subscription is for one person only – that’s the person who purchased
          the product (unless it is gifted and then only for the gifted
          individual).
          <br />
          <br />
          2. If you do share your login/password, you are breaking these terms
          of service and we have the right to cancel your subscription without a
          refund.
          <br />
          <br />
          3. You will not copy or reproduce any content.
          <br />
          <br />
          4. Once purchased, there are no refunds for the subscription.
        </p>

        <p>
          <strong>1. SERVICES</strong>
        </p>
        <p>
          1.1 License. Subject to the terms and conditions of this Agreement,
          Axiom Innovations LLC (a) will use commercially reasonable efforts to
          host, operate and maintain the services as set forth on the Order
          Form, which may include the Axiom Innovations LLC platform (the
          “Platform”), and/or other services offered by Axiom Innovations LLC
          (collectively, the “Services”), (b) grants Customer a non-exclusive,
          non-transferable, non-sublicensable right and license to access and
          use the Services and (c) grants Customer a non-exclusive,
          non-sublicensable and non-transferable license to use (i.e., to
          download and display locally) Content solely for purposes of using the
          Services. For clarity, unless otherwise specified by Axiom Innovations
          LLC in writing, any and all rights or licenses granted by Axiom
          Innovations LLC to Customer shall only apply to Customer and not
          Customer’s affiliates, including, without limitation, any parent,
          subsidiary, or other entity controlled by or under common control with
          Customer.
          <br />
          <br />
          1.2 Access and Account Setup. Upon execution of an Order Form, Axiom
          Innovations LLC will provide Customer with a unique login and password
          to access the Services web pages which are hosted and maintained by
          Axiom Innovations LLC. Customer shall be responsible for the acts or
          omissions of any person who accesses the Services using logins
          provided to or created by Customer. Axiom Innovations LLC reserves the
          right to modify or discontinue any part of the Services at any time.
          The Customer can cancel the Service at any time, but no refund will be
          offered. From time to time, Axiom Innovations LLC personnel may log in
          to the Service under Customer’s account in order to maintain or
          improve the Service, including providing Customer assistance with
          technical or billing issues. Customer hereby acknowledges and consents
          to such access.
          <br />
          <br />
          1.3 Service Availability. Axiom Innovations LLC will use commercially
          reasonable efforts to maintain the Service availability to send and
          receive data, subject to downtimes resulting from maintenance, repairs
          and upgrades. Axiom Innovations LLC will attempt to notify Customer
          electronically via the Service in advance of any planned downtime.
          Notwithstanding the foregoing, Axiom Innovations LLC will not be
          liable for any failures in the Service or any other problems which are
          related to (a) the Customer Content (b) outages to any
          telecommunications or public Internet backbones, networks or servers,
          or other equipment or service outside of Axiom Innovations LLC’s
          facilities or control.
          <br />
          <br />
          1.4 Service Support. Axiom Innovations LLC will provide Customer with
          e-mail support for Customer’s use of the Service during Axiom
          Innovations LLC’s regular business hours. Customer agrees that Axiom
          Innovations LLC is not responsible to provide support for any issues
          resulting from problems, errors or inquiries related to Customer’s
          systems or hardware.
          <br />
          <br />
          1.5 Customer Content. As used herein, the term “Content” includes,
          without limitation, information, data, text, photographs, software,
          scripts, graphics, and interactive features generated, provided, or
          otherwise made accessible on or through the Services, including
          without limitation all Customer Content (as defined below). All
          Content created through or submitted to the Services by Customer
          (collectively “Customer Content”) is the sole responsibility of
          Customer. Customer acknowledges and agrees that Axiom Innovations LLC
          will not assume any, and hereby disclaims all, responsibility and
          liability for Customer Content and any modifications thereto. Customer
          hereby grants Axiom Innovations LLC a worldwide, non-exclusive,
          royalty-free, fully paid-up license to use, reproduce, perform,
          display, modify, and distribute the Customer Content in connection
          with providing the Services to Customer.
          <br />
          <br />
          1.6 Use Restrictions. Except as expressly permitted in this Agreement,
          Customer shall not directly or indirectly (a) use any of Axiom
          Innovations LLC’s Confidential Information (defined below) to create
          any service, software, documentation or data that is similar or
          competitive to any aspect of the Services, (b) disassemble, decompile,
          reverse engineer or use any other means to attempt to discover any
          source code of the Services, or the underlying ideas, algorithms or
          trade secrets therein, (c) encumber, sublicense, transfer, rent,
          lease, time-share or use the Services in any service bureau
          arrangement or otherwise for the benefit of any third party, (d) copy,
          harvest, scrape, distribute, manufacture, adapt, create derivative
          works of, translate, localize, port or otherwise modify any aspect of
          the Services, (e) use or allow the transmission, transfer, export,
          re-export or other transfer of any product, technology or information
          it obtains or learns pursuant to this Agreement (or any direct product
          thereof) in violation of any export control or other laws and
          regulations of the United States or any other relevant jurisdiction,
          (f) interfere or attempt to interfere with the proper working of the
          Services or any activities conducted on the Services, (g) remove any
          copyright patent, trademark, or other intellectual property notices,
          information, and restrictions contained in any Content accessed
          through the Services, or (h) permit any third party to engage in any
          of the foregoing proscribed acts. Prohibited and Unauthorized Use. You
          will not (i) use or launch any automated system including, “robots”,
          “spiders,” or “offline readers” that sends more request messages to
          our servers in a given period of time than a human can reasonably
          produce in the same period by using a conventional browser. (ii) use
          the Subscription Service in any manner that damages, disables,
          overburdens, or impairs our website or interferes with any other
          party’s use of the Subscription Service; (iii) attempt to gain
          unauthorized access to the Subscription Service; (iv) access the
          Subscription Service other than through our interface; or (v) use the
          Subscription Service for any purpose or in any manner that is unlawful
          or prohibited by the Agreement.
          <br />
          <br />
          You many not use the Subscription Service if you are legally
          prohibited from receiving or using the Subscription Service under the
          laws of the country in which you are a resident or from which you
          access or use the Subscription Service. The Subscription Service is
          not designed to comply with industry-specific regulations such as the
          Health Insurance Portability and Accountability Act (HIPPA), the
          Gramm-Leach-Bliley Act (GLBA), or the Federal Information Security
          Management Act (FISMA), so you many not use the Subscription Service
          where your communications would be subject to such laws.
          <br />
          <br />
          1.7 Limitations. Axiom Innovations LLC will not be responsible or
          liable for any failure in the Services resulting from or attributable
          to (a) Customer use of the Services not in accordance with this
          Agreement or any relevant documentation provided by Axiom Innovations
          LLC, (b) failures in any telecommunications, network or other service
          or equipment outside of Axiom Innovations LLC’s or its service
          providers’ facilities, (c) Customer's or any third party’s products,
          services, negligence, acts or omissions, (d) any force majeure or
          other cause beyond Axiom Innovations LLC’s reasonable control, or (e)
          unauthorized access, breach of firewalls or other hacking by third
          parties.
        </p>

        <p>
          <strong>2. PROPRIETARY RIGHTS</strong>
        </p>
        <p>
          Subject to the rights and licenses expressly granted hereunder,
          Customer shall retain all rights, title and interest (including all
          intellectual property and proprietary rights) in and to the Customer
          Content. Subject to the limited rights and licenses expressly granted
          hereunder, Axiom Innovations LLC (and its licensors) shall retain all
          rights, title and interest (including all intellectual property and
          proprietary rights) in and to the Services, the Platform, all Content,
          all Axiom Innovations LLC trademarks, names, logos, all copies,
          modifications and derivative works thereof, and all rights to patent,
          copyright, trade secret and other proprietary or intellectual property
          rights therein. Additionally, all Customer (a) suggestions for
          correction, change or modification to the Services, (b) evaluations,
          and (c) other feedback, information and reports provided to Axiom
          Innovations LLC hereunder (collectively, “Feedback”), will be the
          property of Axiom Innovations LLC, and Customer shall and hereby does
          assign any rights in such Feedback to Axiom Innovations LLC. Customer
          agrees to assist Axiom Innovations LLC, at Axiom Innovations LLC’s
          expense, in obtaining intellectual property protection for such
          Feedback, as Axiom Innovations LLC may reasonably request.
        </p>

        <p>
          <strong>3. CONFIDENTIALITY</strong>
        </p>

        <p>
          3.1 Definition. Each party agrees that the business, technical and
          financial information, including without limitation, the Services, the
          Platform, and all software, source code, inventions, algorithms,
          know-how and ideas and the terms and conditions of this Agreement,
          designated in writing as confidential or disclosed in a manner that a
          reasonable person would understand the confidentiality of the
          information disclosed, shall be the confidential property of the
          disclosing party and its licensors (“Confidential Information”). For
          the avoidance of doubt, any and all data provided to Customer through
          the Services (other than Customer Content) shall be considered Axiom
          Innovations LLC’s Confidential Information. Confidential Information
          does not include information that (a) is previously rightfully known
          to the receiving party without restriction on disclosure, (b) is or
          becomes known to the general public, through no act or omission on the
          part of the receiving party, (c) is disclosed to the receiving party
          by a third party without breach of any separate nondisclosure
          obligation, or (d) is independently developed by the receiving party.
          <br />
          <br />
          3.2 Confidentiality. Except for the specific rights granted by this
          Agreement, the receiving party shall not access, use or disclose any
          of the disclosing party’s Confidential Information without its written
          consent, and shall use at least the standard of care used to protect
          its own Confidential Information, but not less than reasonable care to
          protect the disclosing party’s Confidential Information, including
          ensuring that its employees and contractors with access to such
          Confidential Information (a) have a need to know for the purposes of
          this Agreement and (b) have been apprised of and agree to restrictions
          at least as protective of the disclosing party’s Confidential
          Information as this Agreement. Each party shall be responsible for any
          breach of confidentiality by its employees and contractors. Each party
          may disclose only the general nature, but not the specific terms, of
          this Agreement without the prior consent of the other party; provided
          that either party may provide a copy of this Agreement or otherwise
          disclose its terms in connection with any legal or regulatory
          requirement, financing transaction or due diligence inquiry.
          <br />
          <br />
          3.3 Required Disclosure. Nothing herein shall prevent a receiving
          party from disclosing any Confidential Information as necessary
          pursuant to any applicable court order, law, rule or regulation;
          provided that prior to any such disclosure, the receiving party shall
          use reasonable efforts to (a) promptly notify the disclosing party (to
          the extent legally permitted) in writing of such requirement to
          disclose and (b) cooperate with the disclosing party in protecting
          against or minimizing any such disclosure or obtaining a protective
          order.
        </p>

        <p>
          <strong>4. PAYMENTS; TAXES</strong>
        </p>

        <p>
          Customer shall pay to Axiom Innovations LLC fees as set forth in an
          applicable Order Form in accordance with the terms therein. All
          subscription purchases are done in advance and are non-refundable. All
          payments are exclusive of federal, state, local and foreign taxes,
          duties, tariffs, levies, withholdings and similar assessments, and
          Customer agrees to bear and be responsible for the payment of all such
          charges, excluding taxes based upon Axiom Innovations LLC’s net
          income.
        </p>
        <p>
          <strong>5. LIMITED WARRANTY AND DISCLAIMERS</strong>
        </p>
        <p>
          5.1 General. Each party represents and warrants that: (a) it has full
          power and authority, and has obtained all approvals, permissions and
          consents necessary, to enter into this Agreement and to perform its
          obligations hereunder; (b) this Agreement is legally binding upon it
          and enforceable in accordance with its terms; (c) the execution,
          delivery and performance of this Agreement does not and will not
          conflict with any agreement, instrument, judgment or understanding to
          which it is a party or by which it may be bound; and (d) it will
          perform its obligations hereunder in accordance with all applicable
          laws.
          <br />
          <br />
          5.2 Customer. Customer represents and warrants that (a) Customer has
          all rights to grant the licenses to Axiom Innovations LLC set forth
          herein, including without limitation to Customer Content, without
          infringement or violation of any applicable laws or third party
          rights, including without limitation, any privacy rights, publicity
          rights, copyrights, trademarks, contract rights, or any other
          intellectual property or proprietary rights, and (b) Customer shall
          not make available through the Services any Content that is
          disparaging, obscene, offensive, or otherwise inappropriate or that
          contains any viruses or any other harmful code.
        </p>

        <p>
          <strong>6. DISCLAIMERS</strong>
        </p>

        <p>
          EXCEPT AS PROVIDED IN SECTION 5.1 HEREIN, TO THE FULLEST EXTENT
          PERMITTED BY LAW, AXIOM INNOVATIONS LLC HEREBY DISCLAIMS (FOR ITSELF
          AND ITS SUPPLIERS) ALL OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          ORAL OR WRITTEN, WITH RESPECT TO THE SERVICES, INCLUDING, WITHOUT
          LIMITATION, ALL IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, QUIET
          ENJOYMENT, INTEGRATION, MERCHANTABILITY, FITNESS FOR ANY PARTICULAR
          PURPOSE, RELIABILITY, OR THAT THEIR OPERATION WILL BE UNINTERRUPTED OR
          ERROR-FREE, AS WELL AS ALL WARRANTIES ARISING FROM COURSE OF DEALING,
          COURSE OF PERFORMANCE OR USAGE OF TRADE.
        </p>

        <p>
          <strong>7. INDEMNIFICATION</strong>
        </p>

        <p>
          7.1 Customer. Customer agrees to defend against and hold Axiom
          Innovations LLC harmless from any claim by a third party that arises
          from or is related to (a) any Customer Content, (b) Customer’s use of
          the Services in violation of this Agreement, and to indemnify Axiom
          Innovations LLC for settlement amounts or damages, liabilities, costs
          and expenses (including reasonable attorneys’ fees) awarded and
          arising out of such claims.
          <br />
          <br />
          7.2 Axiom Innovations LLC. Axiom Innovations LLC agrees to (a) defend
          against and hold Customer harmless from any claim by a third party
          that Services infringe a valid U.S. patent (issued as of the Effective
          Date), or any copyright or trade secret, of such third party and (b)
          indemnify Customer for settlement amounts or third party damages,
          liabilities, costs and expenses (including reasonable attorneys’ fees)
          awarded and arising out of such claim. If any part of the Services
          become or, in Axiom Innovations LLC’s opinion, is likely to become the
          subject of any injunction preventing its use as contemplated herein,
          Axiom Innovations LLC may, at its option (1) obtain for Customer the
          right to continue using the Services or (2) replace or modify the
          Services so that such services become non-infringing. If (1) and (2)
          are not reasonably available to Axiom Innovations LLC, Axiom
          Innovations LLC may terminate this Agreement upon written notice to
          Customer and refund to Customer a pro-rated amount of any pre-paid
          fees. Axiom Innovations LLC shall have no liability or obligation
          hereunder with respect to any claim to the extent based upon (i) any
          use of the Services not strictly in accordance with this Agreement or
          in an application or environment or on a platform or with devices for
          which it was not designed or contemplated, (ii) modifications,
          alterations, combinations or enhancements of the Services not created
          by or for Axiom Innovations LLC, (iii) any Customer Content, or (iv)
          Customer’s continuing allegedly infringing activity after being
          notified thereof. The foregoing states the entire liability of Axiom
          Innovations LLC, and Customer’s exclusive remedy, with respect to any
          actual or alleged violation of intellectual property rights by the
          Services, any part thereof or its use or operation.
          <br />
          <br />
          7.3 Procedures. Any claim for indemnification hereunder requires that
          (a) the indemnified party provides prompt written notice of the claim
          and reasonable cooperation, information, and assistance in connection
          therewith, and (b) the indemnifying party shall have sole control and
          authority to defend, settle or compromise such claim. The indemnifying
          party shall not make any settlement that requires a materially adverse
          act or admission by the indemnified party without the indemnified
          party's written consent (such consent not to be unreasonably delayed,
          conditioned or withheld). The indemnifying party shall not be liable
          for any settlement made without its prior written consent.
        </p>

        <p>
          <strong>8. LIMITATION OF LIABILITY</strong>
        </p>

        <p>
          EXCEPT for any breach of SECTION 3 (CONFIDENTIALITY) OR LIABILITIES TO
          THIRD PARTIES PURSUANT TO SECTION 7 (INDEMNIFICATION), In no event
          shall EITHER PARTY BE LIABLE CONCERNING THE SUBJECT MATTER OF this
          agreement, regardless of the form of any claim or action (whether in
          CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHERwise), for any (A)
          interruption OF USE, LOSS OR INACCURACY of data, loss oF, OR COST OF
          PROCURING SUBSTITUTE TECHNOLOGY, GOODS or SERVICES, (B) INDIRECT,
          PUNITIVE, INCIDENTAL, RELIANCE, SPECIAL, EXEMPLARY OR CONSEQUENTIAL
          DAMAGES INCLUDING, BUT NOT LIMITED TO, LOSS OF BUSINESS, REVENUES,
          PROFITS AND GOODWILL OR (C) damages, IN THE AGGREGATE, in excess of
          the amounts PAID TO IT (IN THE CASE OF AXIOM INNOVATIONS LLC) OR PAID
          AND PAYABLE BY IT (IN THE CASE OF CUSTOMER) HEREUNDER DURING THE SIX
          (6) MONTHS PRECEDING SUCH CLAIM, EVEN IF IT HAS BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS ARE INDEPENDENT FROM
          ALL OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITHSTANDING
          THE FAILURE OF ANY REMEDY PROVIDED HEREIN.
        </p>

        <p>
          <strong>9. TERM AND TERMINATION</strong>
        </p>

        <p>
          9.1 Term. Unless otherwise specified in an applicable Order Form or
          terminated as provided herein, this Agreement shall commence on the
          Effective Date and shall continue for one (1) year from the Effective
          Date. The term shall automatically renew for successive one (1) year
          terms, unless either party notifies the other party of its intent not
          to renew at least thirty (30) days prior to the end of the then
          current term. 9.2 Termination. This Agreement may be earlier
          terminated by either party (a) if the other party materially breaches
          a provision of this Agreement and fails to cure such breach within
          thirty (30) days after receiving written notice of such breach from
          the non-breaching party (ten (10) days in the case on non-payment).9.3
          Effects of Termination. Upon any expiration or termination of this
          Agreement, all corresponding rights, obligations and licenses of the
          parties shall cease, except that all obligations that accrued prior to
          the effective date of termination (including without limitation, all
          payment obligations) shall survive. The provisions of Sections 2
          (Proprietary Rights), 3 (Confidentiality), 7 (Indemnification),
          6(Disclaimers), 8 (Limitation of Liability), 10 (General Provisions)
          and this Section 9.3 shall survive.
        </p>

        <p>
          <strong>10. GENERAL PROVISIONS</strong>
        </p>

        <p>
          10.1 Entire Agreement. This Agreement constitutes the entire
          agreement, and supersedes all prior negotiations or agreements (oral
          or written), between the parties regarding the subject matter hereof.
          Any inconsistent or additional terms on any related purchase order,
          confirmation or similar form, even if signed by the parties hereafter,
          shall have no effect under this Agreement.
          <br />
          <br />
          10.2 Publicity. Customer hereby consents to inclusion of its name and
          logo in client lists and marketing materials that may be published as
          part of Axiom Innovations LLC’s marketing and promotional efforts.
          From time to time upon Axiom Innovations LLC’s request, Customer
          agrees it will provide reasonable cooperation and assistance in
          connection with such efforts (such as, for example, by acting as a
          reference, issuing press releases and writing testimonials and case
          studies with statements attributed to a named employee of Customer).
          <br />
          <br />
          10.3 Modification and Waiver. No change, consent or waiver under this
          Agreement will be binding on either party unless made in writing and
          physically signed by an authorized representative of both parties. The
          failure of either party to enforce its rights under this Agreement at
          any time for any period will not be construed as a waiver of such
          rights, and the exercise of one right or remedy will not be deemed a
          waiver of any other right or remedy. 10.4 Severability. If any
          provision of this Agreement is determined to be illegal or
          unenforceable, that provision will be limited or eliminated to the
          minimum extent necessary so that this Agreement will otherwise remain
          in full force and effect and enforceable.
          <br />
          <br />
          10.4 Governing Law. This Agreement shall be governed by and construed
          under the laws of the State of California and the United States
          without regard to conflicts of laws provisions thereof. Exclusive
          jurisdiction and venue for actions related to this Agreement will be
          the state and federal courts located in San Francisco County,
          California, and both parties consent to the jurisdiction of such
          courts with respect to any such actions.
          <br />
          <br />
          10.5 Remedies. Except as specifically provided otherwise herein, each
          right and remedy in this Agreement is in addition to any other right
          or remedy, at law or in equity. Each party agrees that, in the event
          of any breach or threatened breach of Section 3, the non-breaching
          party will suffer irreparable damage for which it will have no
          adequate remedy at law. Accordingly, the non-breaching party shall be
          entitled to seek injunctive and other equitable remedies to prevent or
          restrain such breach or threatened breach, without the necessity of
          posting any bond.
          <br />
          <br />
          10.6 Notices. All notices under this Agreement will be in writing and
          delivered to the parties at their respective addresses stated herein
          or at such other address designated by written notice. Notices will be
          deemed to have been duly given when received, if personally delivered;
          when receipt is electronically confirmed, if transmitted by email or
          facsimile; the day after being sent, if sent for next day delivery by
          recognized overnight delivery service; or upon receipt, if sent by
          certified or registered mail, return receipt requested.
          <br />
          <br />
          10.7 Force Majeure. In the event that either party is prevented from
          performing, or is unable to perform, any of its obligations under this
          Agreement due to any cause beyond its reasonable control, the affected
          party shall give written notice thereof to the other party and its
          performance shall be extended for the period of delay or inability to
          perform due to such occurrence.
          <br />
          <br />
          10.8 Assignment. This Agreement and the rights and obligations
          hereunder may not be assigned, in whole or in part, by Customer
          without Axiom Innovations LLC's written consent. This Agreement shall
          be binding upon, and inure to the benefit of, the successors,
          representatives and permitted assigns of the parties hereto.
          <br />
          <br />
          10.9 Independent Contractors. The parties shall be independent
          contractors under this Agreement, and nothing herein will constitute
          either party as the employer, employee, agent or representative of the
          other party, or both parties as joint venturers or partners for any
          purpose.
          <br />
          <br />
          10.10 In order to use the Service, you must: 1 – be at least eighteen
          (18) years old and able to enter into contracts. 2 – complete the
          account registration process. 3 – agree to these Terms. 4 – not be
          based in Cuba, Iran, North Korea, Syria, or any other country that is
          subject to a U.S. government embargo, or that has been designated by
          the U.S. government as a “terrorist-supporting” country; and 5 – not
          be listed on any U.S. government list of prohibited or restricted
          persons.
        </p>

        <p>
          THIS SOFTWARE AND ACCOMPANYING WRITTEN MATERIALS ARE PROVIDED AS IS.
          Axiom Innovations LLC AND ANYONE WHO HAS BEEN INVOLVED IN THE
          CREATION, PRODUCTION OR DELIVERY OF THIS PRODUCT DISCLAIM ALL
          WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE
          WARRANTIES OF MERCHANTABILITY AND OF FITNESS FOR ANY PURPOSE. Axiom
          Innovations LLC ASSUMES NO LIABILITY FOR DAMAGES, DIRECT OR
          CONSEQUENTIAL, WHICH MAY RESULT FROM THE USE OF THIS PROGRAM. YOU
          ACKNOWLEDGE THAT YOU HAVE READ THIS LICENSE AGREEMENT AND LIMITED
          WARRANTY, UNDERSTAND THEM, AND AGREE TO BE BOUND BY THEIR TERMS AND
          CONDITIONS. YOU ALSO AGREE THAT THE LICENSE AND LIMITED WARRANTY ARE
          THE COMPLETE AND EXCLUSIVE STATEMENT OF AGREEMENT BETWEEN THE PARTIES
          AND SUPERSEDE ALL PROPOSALS OF PRIOR AGREEMENTS, ORAL OR WRITTEN, AND
          ANY OTHER COMMUNICATIONS BETWEEN THE PARTIES RELATING TO THE SUBJECT
          MATTER OF THE LICENSE AGREEMENT OR THE LIMITED WARRANTY. THIS
          AGREEMENT SHALL BE GOVERNED AND CONSTRUED IN ACCORDANCE WITH THE LAWS
          OF THE STATE OF COLORADO.
        </p>

        <p>EMAIL info@ccscases.com</p>
      </div>
      <button
        className="loginButton termsOfServiceBackButton loginPrimaryButton"
        onClick={closeTOS}
      >
        Back
      </button>
    </div>
  );
};

export default TermsOfService;
