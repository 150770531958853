import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faSchool,
  faFilePen,
  faChartLine,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import CCSLogo from "../../../../images/logoCropped.png";
import React from "react";
import "./Navigation.css";

const NavigationLink = ({ title, icon, currentPage, setCurrentPage }) => {
  return (
    <button
      className="adminPortalNavigationLink"
      style={{
        opacity: currentPage === title ? 1 : 0.6,
        cursor: currentPage === title ? "initial" : "Pointer",
      }}
      disabled={currentPage === title}
      onClick={() => setCurrentPage(title)}
      key={`Navigation - ${title}`}
    >
      <FontAwesomeIcon icon={icon} className="adminPortalNavigationIcon" />
      {title}
    </button>
  );
};

const AdminNavigation = ({ currentPage, setCurrentPage }) => {
  const navigationLinks = [
    {
      title: "Home",
      icon: faHouse,
    },
    {
      title: "My School",
      icon: faSchool,
    },
    {
      title: "Exam Rules",
      icon: faFilePen,
    },
    {
      title: "Grades",
      icon: faChartLine,
    },
    {
      title: "Settings",
      icon: faGear,
    },
  ];

  return (
    <div
      className="adminPortalNavigationContainer"
      style={{
        paddingBottom:
          currentPage === "Home" || currentPage === "Settings" ? 30 : 0,
      }}
    >
      <img
        src={CCSLogo}
        className="adminPortalNavigationLogo"
        alt={"CCS Cases Logo"}
      />
      <nav className="adminPortalDesktopNavigationWrapper">
        {navigationLinks.map(({ title, icon }) => (
          <NavigationLink
            title={title}
            icon={icon}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            key={`Navigation Link - ${title}`}
          />
        ))}
      </nav>
      <nav className="adminPortalMobileNavigationWrapper">
        <div>
          {navigationLinks.slice(0, 2).map(({ title, icon }) => (
            <NavigationLink
              title={title}
              icon={icon}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              key={`Navigation Link - ${title}`}
            />
          ))}
        </div>
        <div>
          {navigationLinks.slice(2, 5).map(({ title, icon }) => (
            <NavigationLink
              title={title}
              icon={icon}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              key={`Navigation Link - ${title}`}
            />
          ))}
        </div>
      </nav>
    </div>
  );
};

export default AdminNavigation;
