import React, { createContext, useContext, useEffect, useState } from "react";
import "./ExamRulesPage.css";
import ExamRulesOverview from "./Components/ExamRulesOverview/ExamRulesOverview";
import ExamRulesDesktopModificationSection from "./Components/ExamRulesDesktopModificationSection/ExamRulesDesktopModificationSection";
import ExamRulesMobileStudents from "./Components/ExamRulesMobileStudents";
import ExamRulesMobileCases from "./Components/ExamRulesMobileCases";
import GroupListModal from "../Components/Modals/GroupListModal";
import { OSCEAdminContext } from "..";
import { v4 as uuidv4 } from "uuid";
import mapCategoryIdToCategory from "../Util/MapCategoryIdToCategory";

export const ExamRulesContext = createContext(null);

const ExamRulesPage = () => {
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [selectedCaseList, setSelectedCaseList] = useState([]);
  const [selectedCaseMap, setSelectedCaseMap] = useState({});
  const [selectedCategoryMap, setSelectedCategoryMap] = useState({});
  const [pageDisplayed, setPageDisplayed] = useState("overview");
  const [showGroupListModal, setShowGroupListModal] = useState(false);
  const {
    userData,
    route,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
    prevPageProps,
  } = useContext(OSCEAdminContext);
  const [breakTimeInMinutes, setBreakTimeInMinutes] = useState(0);

  useEffect(() => {
    if (prevPageProps) {
      setSelectedStudentIds([prevPageProps.studentId]);
      const selectedStudent = userData.Students.find(
        (student) => student.CustomerId === prevPageProps.studentId
      );
      let studentCases = [];
      let studentCaseMap = {};
      selectedStudent.SpecficCasesOut.forEach((value) => {
        studentCases.push({
          CaseCategory: mapCategoryIdToCategory[value.CaseCategoryId],
          CaseId: value.CaseId,
          CaseTitle: null,
          CategoryId: 5,
          Diagnosis: value.Diagnosis,
          type: "case",
          uniqueId: uuidv4(),
        });
        if (studentCaseMap[value.Diagnosis]) {
          studentCaseMap[value.Diagnosis] += 1;
        } else {
          studentCaseMap[value.Diagnosis] = 1;
        }
      });
      setSelectedCaseList(studentCases);
      setSelectedCaseMap(studentCaseMap);
      setBreakTimeInMinutes(selectedStudent.TotalBreakTime);
    }
  }, []);

  let componentToShow = null;

  if (pageDisplayed === "student") {
    componentToShow = <ExamRulesMobileStudents />;
  } else if (pageDisplayed === "case") {
    componentToShow = <ExamRulesMobileCases />;
  } else {
    componentToShow = (
      <ExamRulesOverview
        breakTimeInMinutes={breakTimeInMinutes}
        setBreakTimeInMinutes={setBreakTimeInMinutes}
      />
    );
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 768) setPageDisplayed("overview");
    });

    return () => {
      window.removeEventListener("resize", () => {
        if (window.innerWidth > 768) setPageDisplayed("overview");
      });
    };
  }, []);

  const handleAddStudentsToGroup = async (selectedGroup) => {
    return await fetch(`${route}/addstudentstogroup.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentIds: selectedStudentIds,
        GroupId: selectedGroup.groupId,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else if (res.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error("Failed to add students to group");
        }
      })
      .then((data) => {
        if (!data) return false;
        const updatedStudents = userData.Students.map((student) => {
          if (selectedStudentIds.includes(student.CustomerId)) {
            return {
              ...student,
              GroupId: selectedGroup.groupId,
              GroupName: selectedGroup.GroupName,
            };
          } else {
            return student;
          }
        });
        updateUserData({ ...userData, Students: updatedStudents });
        displayNotification(
          `${selectedStudentIds.length} students added to group ${selectedGroup.groupId} - ${selectedGroup.GroupName}`
        );
        return true;
      })
      .catch((error) => {
        console.log("Error adding students to group: ", error);
        displayNotification("Failed to add students to group.");
        return false;
      });
  };

  return (
    <ExamRulesContext.Provider
      value={{
        selectedStudentIds: selectedStudentIds,
        setSelectedStudentIds: setSelectedStudentIds,
        selectedCaseList: selectedCaseList,
        setSelectedCaseList: setSelectedCaseList,
        selectedCaseMap: selectedCaseMap,
        setSelectedCaseMap: setSelectedCaseMap,
        selectedCategoryMap: selectedCategoryMap,
        setSelectedCategoryMap: setSelectedCategoryMap,
        pageDisplayed: pageDisplayed,
        setPageDisplayed: setPageDisplayed,
        setShowGroupListModal: setShowGroupListModal,
      }}
    >
      <div className="examRulesPageContainer">
        {componentToShow}
        <ExamRulesDesktopModificationSection />
      </div>
      {showGroupListModal && (
        <GroupListModal
          setShowModal={setShowGroupListModal}
          confirmSelection={handleAddStudentsToGroup}
          showAddGroupButton={true}
          subHeader={`Adding ${selectedStudentIds.length} students to a group`}
        />
      )}
    </ExamRulesContext.Provider>
  );
};

export default ExamRulesPage;
