import React, { useState } from "react";
import "./ListItemsSelectorModal.css";
import MDSpinner from "react-md-spinner";

const ListItemsSelectorModal = ({
  closeModal,
  confirmSelection,
  initialSelectedElements,
  dataLabel,
  subtitle = "",
  dataArray,
  dataValueUniqueId,
  dataValuesToDisplay,
  singleSelect = false,
  customTitle,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [selectedElements, setSelectedElements] = useState(
    initialSelectedElements
  );

  const handleSelectElement = (elementId) => {
    if (singleSelect) {
      setSelectedElements([elementId]);
      return;
    }

    if (selectedElements.includes(elementId)) {
      setSelectedElements(selectedElements.filter((id) => id !== elementId));
    } else {
      setSelectedElements([...selectedElements, elementId]);
    }
  };

  const filteredElements = dataArray.filter((student) =>
    dataValuesToDisplay
      .map((value) => student[value])
      .join(" ")
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const handleConfirmSelection = async () => {
    setFetchOutstanding(true);
    if (await confirmSelection(selectedElements)) {
      closeModal();
    } else {
      setFetchOutstanding(false);
    }
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer">
        <h2 className="modalHeader">
          {customTitle ? customTitle : `Add ${dataLabel}`}
        </h2>
        {subtitle.length !== "" && <p className="modalSubHeader">{subtitle}</p>}
        <div className="modalInputWrapper">
          <label htmlFor="studentSearch" className="modalInputLabel">
            Search {dataLabel}
          </label>
          <input
            type="text"
            id="studentSearch"
            className="modalInput"
            placeholder={`Search for ${dataLabel.toLowerCase()}...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label className="modalInputLabel">
            {dataLabel} ({dataArray.length}) - {selectedElements.length}{" "}
          </label>
          <div className="addAccountsModalAccountListWrapper">
            {filteredElements.map((element) => (
              <button
                className={`addAccountsModalAccountListItem ${
                  selectedElements.includes(element[dataValueUniqueId])
                    ? "addAccountsModalAccountListItemSelected"
                    : ""
                }`}
                key={element[dataValueUniqueId]}
                onClick={() => handleSelectElement(element[dataValueUniqueId])}
              >
                {dataValuesToDisplay.map((value) => element[value]).join(" ")}
              </button>
            ))}
            {filteredElements.length === 0 && (
              <p className="addAccountsModalNoAccountsMessage">
                No {dataLabel.toLowerCase()} match search "{searchTerm}"
              </p>
            )}
          </div>
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button className="modalCancelButton" onClick={closeModal}>
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={handleConfirmSelection}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListItemsSelectorModal;
