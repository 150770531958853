import React, { Component } from "react";
import "./ViewCompletionsPopup.css";
import { motion } from "framer-motion";
import MDSpinner from "react-md-spinner";
import formatDateString from "../../../Functions/FormatDateString";
import formatTimeString from "../../../Functions/FormatTimeString";

export default class ViewCompletionsPopup extends Component {
  constructor() {
    super();
    this.state = {
      fetchOutstanding: false,
    };
  }

  confirmNewCompletion = async () => {
    let id =
      this.props.previousCompletions[this.props.previousCompletionSelected]
        .CustomerGradeId;
    this.setState({ fetchOutstanding: true });
    await this.props.confirm(id);
    this.props.closePopup();
  };

  render() {
    return (
      <div className="viewCompletionsPopupPageWrapper">
        <motion.div
          className="viewCompletionsPopupBackdrop"
          onClick={this.props.closePopup}
          key="viewCompletionsPopupBackdrop"
          initial={this.props.animationsDisabled ? false : { opacity: 0 }}
          animate={this.props.animationsDisabled ? false : { opacity: 1 }}
          exit={this.props.animationsDisabled ? false : { opacity: 0 }}
          transition={{ duration: 0.2 }}
        />
        <motion.div
          className="viewCompletionsPopupContainer"
          initial={
            this.props.animationsDisabled
              ? false
              : {
                  y: 30,
                  opacity: 0,
                  translateX: "-50%",
                  translateY: "-50%",
                  scale: 0.9,
                }
          }
          animate={
            this.props.animationsDisabled
              ? false
              : {
                  y: 0,
                  opacity: 1,
                  translateX: "-50%",
                  translateY: "-50%",
                  scale: 1,
                }
          }
          exit={
            this.props.animationsDisabled
              ? false
              : {
                  y: 30,
                  opacity: 0,
                  translateX: "-50%",
                  translateY: "-50%",
                  scale: 0.9,
                }
          }
          transition={{ duration: 0.2 }}
          key="viewCompletionsPopupContainer"
        >
          <button
            className="popupCloseButton"
            onClick={this.props.closePopup}
          />
          <h2 className="viewCompletionsPopupTitle">Previous Completions</h2>
          <h4 className="viewCompletionsPopupSubtitle">
            Score on First Attempt:{" "}
            {this.props.previousCompletions[0].FirstAttemptGrade}%
          </h4>
          <div className="viewCompletionsPopupOptionsContainer">
            {this.props.previousCompletions.map((completion, index) => (
              <div
                className={`viewCompletionsPopupOption ${
                  this.props.previousCompletionSelected === index
                    ? "viewCompletionsPopupOptionSelected"
                    : ""
                }`}
                onClick={() =>
                  this.props.updatePreviousCompletionSelected(index)
                }
              >
                <div className="viewCompletionsPopupOptionGradeContainer">
                  <p className="viewCompletionsPopupOptionGrade">
                    {completion.GradeForCase}%
                  </p>
                </div>
                {/* <p className='viewCompletionsPopupOptionDate'>August 1st, 10:37 PM</p> */}
                <p className="viewCompletionsPopupOptionDate">
                  {formatDateString(
                    completion.CaseCompletionDate,
                    this.props.timeZone
                  )}
                  ,{" "}
                  {formatTimeString(
                    completion.CaseCompletionDate,
                    this.props.timeZone
                  )}
                </p>
              </div>
            ))}
          </div>
          <div className="viewCompletionsPopupButtonContainer">
            <button
              className="viewCompletionsPopupButton secondaryButton"
              onClick={this.props.closePopup}
            >
              Close
            </button>
            {this.state.fetchOutstanding ? (
              <div className="incompleteCasePopupLoadingWrapper">
                <MDSpinner size={30} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <button
                className="viewCompletionsPopupButton tertiaryButton"
                onClick={this.confirmNewCompletion}
              >
                Confirm
              </button>
            )}
          </div>
        </motion.div>
      </div>
    );
  }
}
