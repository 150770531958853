const mapCategoryIdToCategory = {
  1: "Pediatrics",
  2: "Internal Medicine",
  3: "OB/GYN",
  4: "Neurology",
  5: "Emergency Medicine",
  6: "Psychiatry",
};

export default mapCategoryIdToCategory;
