const formatFeedbackForCase = (feedback) => {
  const {
    FinalDiagnoisGrade,
    FinalTreatmentGrade,
    FinalTimingGrade,
    FinalAppropriateGrade,
    FinalSequenceGrade,
    FinalPreventiveCareGrade,
  } = feedback;

  const {
    WEIGHTDIAGNOSIS,
    WEIGHTTREATMENT,
    WEIGHTTIMING,
    WEIGHTAPPROPRIATE,
    WEIGHTSEQUENCING,
    WEIGHTPREVENTATIVE,
  } = feedback;

  const { SUMMARY, ActionLogList, GradeOut, CompletionDate } = feedback;

  const feedbackSections = {
    Diagnosis: {
      grade: FinalDiagnoisGrade,
      weight: WEIGHTDIAGNOSIS,
      title: "Diagnosis Orders",
      comments: [],
    },
    Treatment: {
      grade: FinalTreatmentGrade,
      weight: WEIGHTTREATMENT,
      title: "Treatment Orders",
      comments: [],
    },
    Timing: {
      grade: FinalTimingGrade,
      weight: WEIGHTTIMING,
      title: "Timing",
      comments: [],
    },
    Appropriate: {
      grade: FinalAppropriateGrade,
      weight: WEIGHTAPPROPRIATE,
      title: "Appropriate Orders",
      comments: [],
    },
    Sequencing: {
      grade: FinalSequenceGrade,
      weight: WEIGHTSEQUENCING,
      title: "Appropriate Location / Appropriate Sequence",
      comments: [],
    },
    Preventative: {
      grade: FinalPreventiveCareGrade,
      weight: WEIGHTPREVENTATIVE,
      title: "Preventative Care",
      comments: [],
    },
  };

  GradeOut.forEach((gradeComment) => {
    switch (gradeComment.GradeCategory) {
      case "Diagnosis":
        feedbackSections.Diagnosis.comments.push(gradeComment);
        break;
      case "Treatment":
        feedbackSections.Treatment.comments.push(gradeComment);
        break;
      case "Timing":
        feedbackSections.Timing.comments.push(gradeComment);
        break;
      case "Appropriate Sequence":
        feedbackSections.Appropriate.comments.push(gradeComment);
        break;
      case "Appropriate Orders":
        feedbackSections.Sequencing.comments.push(gradeComment);
        break;
      case "Preventive care":
        feedbackSections.Preventative.comments.push(gradeComment);
        break;
      default:
        break;
    }
  });

  return {
    feedbackSections: Object.values(feedbackSections),
    SUMMARY,
    ActionLogList,
    ActionLogCompletionDate: CompletionDate,
  };
};

export default formatFeedbackForCase;
