import React from "react";
import SimulationTime from "./SimulationTime";
import Reevaluate from "./Reevaluate";

import ambulance from "../images/ambulance.png";
import care from "../images/care.png";
import unit from "../images/unit.png";
import office from "../images/office.png";
import home from "../images/home.png";
import logFetchError from "../Functions/LogFetchError";
import fetchSubmitLogs from "../Functions/FetchSubmitLogs";
import getDaysFromDate from "../Functions/GetDaysFromDate";
import MDSpinner from "react-md-spinner";

class LocationPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationPopup: "locationChange",
      appointment: false,
      locationPopupLoading: false,
      confirmLocationLoading: false,
      fetchingSchedule: false,
    };

    this.confirmSchedule = this.confirmSchedule.bind(this);
    this.closeSchedule = this.closeSchedule.bind(this);
    this.fetchSchedule = this.fetchSchedule.bind(this);
    this.scheduleAppointmentClick = this.scheduleAppointmentClick.bind(this);
    this.locationClick = this.locationClick.bind(this);
    this.fetchConfirmLocation = this.fetchConfirmLocation.bind(this);
    this.fetchLocation = this.fetchLocation.bind(this);
  }

  confirmSchedule(confirmed) {
    if (!confirmed) {
      this.setState({ appointment: false });
    }
    this.setState({ locationPopup: "orderReview" });
  }

  closeSchedule() {
    this.setState({ locationPopup: "orderReview" });
  }

  fetchSchedule(mins) {
    this.setState({ fetchingSchedule: true });
    fetch(`${this.props.route}/schedule.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
        caseId: this.props.caseData.ID,
        TimeInMin: mins,
      }),
    })
      .then((response) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        let result = response;
        this.props.simulateNetworkLag();
        this.setState({
          appointment: JSON.parse(result),
          locationPopup: "confirmSchedule",
          fetchingSchedule: false,
        });
      })
      .catch((error) => {
        this.setState({ fetchingSchedule: false });
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchSchedule"
        );
      });
  }

  /*
	display the reevaluate popup when Schedule Appointment is clicked
	when the scheduler is closed reopen orderReview popup
	*/
  scheduleAppointmentClick() {
    this.setState({ locationPopup: "scheduleAppointment" });
  }

  locationClick(value) {
    this.setState({ selectedLocation: value });
  }

  fetchConfirmLocation() {
    if (
      this.state.appointment === false &&
      (this.state.selectedLocation === 2 || this.state.selectedLocation === 4)
    ) {
      return this.setState({ locationPopup: "scheduleError" });
    }

    if (
      ((this.state.selectedLocation === 2 ||
        this.state.selectedLocation === 4) &&
        this.state.appointment) ||
      this.state.selectedLocation === 1 ||
      this.state.selectedLocation === 3 ||
      this.state.selectedLocation === 5
    ) {
      this.setState({ confirmLocationLoading: true });
      fetch(`${this.props.route}/confirmlocation.webapi`, {
        method: "POST",
        headers: {
          Token: this.props.userData.Token,
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          customerId: this.props.userData.CustomerId,
          caseguid: this.props.simulationData.caseguid,
          caseId: this.props.caseData.ID,
          locationToChange: this.state.selectedLocation,
          confirm: true,
        }),
      })
        .then((response) => {
          //Attempt sending logs
          fetchSubmitLogs(this.props.userProfile, this.props.userData);

          if (response.status === 401) {
            this.props.setAuthenticationError();
            throw new Error("Authentication Error");
          } else {
            return response.text();
          }
        })
        .then(async (response) => {
          this.props.simulateNetworkLag();
          this.props.closePopup();
          // console.log(this.state)
          if (this.state.appointment) {
            if (
              this.state.selectedLocation === 4 ||
              this.state.selectedLocation === 2
            ) {
              this.props.updateLocation(4);
            }
            this.props.appendLocationOrders(this.state.locationData.dboList);
            this.props.trackAppointmentData(
              this.state.locationData,
              this.state.selectedLocation
            );
            // console.log('calling fetch waiting for appointment from fetchConfirmLocation')
            await this.props.fetchWaitingForAppointment(
              this.state.appointment.TimeInMin
            );
          } else {
            // add new orders to orderSheet, update location data
            this.props.updateLocation(this.state.selectedLocation);

            // display vital signs
            if (!this.state.locationData.EROfficeVitals) {
              for (let i = 0; i < this.state.locationData.dboList.length; i++) {
                if (this.state.locationData.dboList[i].ID === 1636) {
                  let order = { ...this.state.locationData.dboList[i] };
                  order.ReportTime = this.formatDateHandler(order.ReportTime);
                  if (order.CANCELLED !== "will be DELETED") {
                    this.props.reviewPopup("orderUpdate", order);
                  }
                }
              }
            } else {
              // location is er or office need to display vitals in a different way
              // need to add this vitals to vital signs list
              let order = {
                NAME: "Vital Signs",
                RESULTNORMAL: this.state.locationData.EROfficeVitals,
              };
              order.ReportTime = Object.assign({}, this.props.simulationTime);
              order.CurrentVirtualTime = order.ReportTime;
              this.props.reviewPopup("orderUpdate", order);
              this.props.appendVitals(order);
            }

            // remove orders from order sheet
            this.props.appendLocationOrders(this.state.locationData.dboList);
          }
          this.setState({ confirmLocationLoading: false });
        })
        .catch((error) => {
          this.setState({ confirmLocationLoading: false });
          // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
          // 	this.props.setAuthenticationError()
          // }
          console.log("error", error);
          logFetchError(
            error,
            this.props.userProfile,
            this.props.userData,
            "fetchConfirmLocation"
          );
        });
    }
  }

  // findOrderInOrderSheet = (order) => {
  // 	console.log(order, this.state.locationData)
  // 	let foundFlag = false
  // 	if (this.props.orderSheet) {
  // 		this.props.orderSheet.forEach((orderValue) => {
  // 			console.log(order.CANCELLED)
  // 			if (orderValue.ID === order.ID && order.CANCELLED === null) {
  // 				foundFlag = true
  // 			}
  // 		})
  // 	}
  // 	return foundFlag
  // }

  fetchLocation() {
    if (this.state.selectedLocation) {
      this.setState({ locationPopupLoading: true });
      fetch(`${this.props.route}/location.webapi`, {
        method: "POST",
        headers: {
          Token: this.props.userData.Token,
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          customerId: this.props.userData.CustomerId,
          caseguid: this.props.simulationData.caseguid,
          caseId: this.props.caseData.ID,
          locationToChange: this.state.selectedLocation,
          appointmentMin: 0,
        }),
      })
        .then((response) => {
          //Attempt sending logs
          fetchSubmitLogs(this.props.userProfile, this.props.userData);

          if (response.status === 401) {
            this.props.setAuthenticationError();
            throw new Error("Authentication Error");
          } else {
            return response.text();
          }
        })
        .then((response) => {
          let result = response;
          this.props.simulateNetworkLag();
          if (
            result.includes(
              "You cannot transfer the patient to the ED from this location"
            )
          ) {
            return this.setState({
              errorMessage:
                " You cannot transfer the patient to that location from this location.",
              locationPopupLoading: false,
            });
          }
          let data = JSON.parse(result);

          // if first order is empty remove it
          if (data?.dboList[0]?.ID === 0) {
            data.dboList.shift();
          }
          // loop through dboList and format everything correctly
          for (let i = 0; i < data.dboList.length; i++) {
            data.dboList[i].LocationOrder = true;
            // // if a new order format it correctly

            // if (!this.findOrderInOrderSheet(data.dboList[i])) {
            data.dboList[i].TimeOrdered = {
              days: this.props.simulationTime.days,
              hours: this.props.simulationTime.hours,
              minutes: this.props.simulationTime.minutes,
            };
            data.dboList[i].CurrentVirtualTime = data.dboList[i].TimeOrdered;

            if (data.dboList[i].URGENCY === null) {
              data.dboList[i].URGENCY = "routine";
            }
            if (data.dboList[i].ROUTE === null) {
              data.dboList[i].ROUTE = "";
            }

            let hours = Number(data.dboList[i].FREQUENCY) / 60;
            if (data.dboList[i].FREQUENCY && !isNaN(hours)) {
              data.dboList[i].FREQUENCY = "q" + hours + "h";
            }
            if (isNaN(hours)) {
              hours = 0;
            }
            if (data.dboList[i].ReportTime !== "0001-01-01T00:00:00") {
              let reportTime = new Date(`${data.dboList[i].ReportTime}Z`);
              data.dboList[i].ReportTime = {
                days: getDaysFromDate(reportTime),
                hours: reportTime.getUTCHours(),
                minutes: reportTime.getUTCMinutes(),
              };
            }
          }
          this.setState({
            locationData: data,
            locationPopup: "orderReview",
            locationPopupLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ locationPopupLoading: false });
          // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
          // 	this.props.setAuthenticationError()
          // }
          console.log("error", error);
          // error popup
          this.setState({
            errorMessage:
              " You cannot transfer the patient to that location from this location.",
          });
          logFetchError(
            error,
            this.props.userProfile,
            this.props.userData,
            "fetchLocation"
          );
        });
    }
  }

  formatDateHandler = (date) => {
    if (typeof date === "string") {
      let newDate = new Date(`${date}Z`);
      return {
        days: getDaysFromDate(newDate),
        hours: newDate.getUTCHours(),
        minutes: newDate.getUTCMinutes(),
      };
    } else {
      return date;
    }
  };

  render() {
    let locationPopup;
    if (this.state.locationPopup === "locationChange") {
      let error;
      if (this.state.errorMessage) {
        error = <div className="order-error">{this.state.errorMessage}</div>;
      }

      let locationStyles = [
        null,
        "location-button",
        "location-button",
        "location-button",
        "location-button",
        "location-button",
      ];
      locationStyles[this.props.simulationData.CaseLocationId] +=
        " current-location";
      if (
        this.state.selectedLocation !== this.props.simulationData.CaseLocationId
      ) {
        locationStyles[this.state.selectedLocation] += " selected";
      }

      locationPopup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="location-popup">
            <div className="popup-header">Location Change {error}</div>
            <div
              className="popup-reevaluate-content"
              style={{ marginBottom: "1em" }}
            >
              <div className="location-column left">
                <div className="popup-header">Inpatient Locations</div>
                <div
                  className={locationStyles[1]}
                  onClick={this.locationClick.bind(this, 1)}
                >
                  <img
                    src={ambulance}
                    className="location-button-image"
                    alt="ambulance"
                  />
                  <div className="location-button-text">
                    Emergency Department
                  </div>
                </div>
                <div
                  className={locationStyles[3]}
                  onClick={this.locationClick.bind(this, 3)}
                >
                  <img
                    src={care}
                    className="location-button-image"
                    alt="care"
                  />
                  <div className="location-button-text">
                    Intensive Care Unit
                  </div>
                </div>
                <div
                  className={locationStyles[5]}
                  onClick={this.locationClick.bind(this, 5)}
                >
                  <img
                    src={unit}
                    className="location-button-image"
                    alt="unit"
                  />
                  <div className="location-button-text">Inpatient Unit</div>
                </div>
              </div>
              <div className="location-column right">
                <div className="popup-header">Outpatient Locations</div>
                <div
                  className={locationStyles[2]}
                  onClick={this.locationClick.bind(this, 2)}
                >
                  <img
                    src={office}
                    className="location-button-image"
                    alt="office"
                  />
                  <div className="location-button-text">Office</div>
                </div>
                <div
                  className={locationStyles[4]}
                  onClick={this.locationClick.bind(this, 4)}
                >
                  <img
                    src={home}
                    className="location-button-image"
                    alt="home"
                  />
                  <div className="location-button-text">Home</div>
                </div>
              </div>
            </div>
            {this.state.locationPopupLoading ? (
              <div className="options-footer-loading-container">
                <MDSpinner size={25} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <div className="options-footer">
                <input
                  className="simulation-button button-gap"
                  type="button"
                  value="Change Location"
                  onClick={this.fetchLocation}
                />
                <input
                  className="simulation-button"
                  type="button"
                  value="Close"
                  onClick={this.props.closePopup}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.locationPopup === "orderReview") {
      // schedule appointment, dont allow confirm move until schedule
      let schedule;
      if (
        this.state.selectedLocation === 2 ||
        this.state.selectedLocation === 4
      ) {
        // display the scheduled appointment or Not Scheduled
        let appointment = "Not Scheduled";
        if (this.state.appointment) {
          // parse out the time of appointment from the confirmation string
          let time = new Date(`${this.state.appointment.AppointmentDate}Z`);
          time = {
            days: getDaysFromDate(time),
            hours: time.getUTCHours(),
            minutes: time.getUTCMinutes(),
          };

          appointment = <SimulationTime simulationTime={time} />;
        }

        schedule = (
          <div className="location-schedule-box">
            <div className="popup-header" style={{ paddingLeft: "0.25em" }}>
              Schedule An Appointment
            </div>
            <div className="popup-element centered white-space">
              {"Current Time: "}
              <SimulationTime simulationTime={this.props.simulationTime} />
            </div>
            <div className="popup-element centered white-space">
              {"Appointment: "}
              {appointment}
            </div>
            <div className="popup-element centered">
              <input
                className="simulation-button"
                type="button"
                value="Schedule Appointment"
                onClick={this.scheduleAppointmentClick}
              />
            </div>
          </div>
        );
      }
      let tableContent = this.state.locationData.dboList.map((data) => (
        <div className="order-review-row no-pointer">
          <div className="order-review-column">{data.NAME}</div>
          <div className="order-review-column">{data.ROUTE}</div>
          <div className="order-review-column">{data.FREQUENCY}</div>
          <div className="order-review-column">
            <SimulationTime
              simulationTime={this.formatDateHandler(data.CurrentVirtualTime)}
            />
          </div>
          <div className="order-review-column">
            {data.ReportTime !== "0001-01-01T00:00:00" && (
              <SimulationTime
                simulationTime={this.formatDateHandler(data.ReportTime)}
                cancelled={data.CANCELLED}
              />
            )}
          </div>
        </div>
      ));

      locationPopup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <div className="popup-header">Order Review</div>
            <div className="order-review-table">
              <div className="order-review-headers">
                <div className="order-review-column">Order Name</div>
                <div className="order-review-column">Route</div>
                <div className="order-review-column">Frequency</div>
                <div className="order-review-column">Order Time</div>
                <div className="order-review-column">Report Time</div>
              </div>
              {tableContent}
            </div>
            {schedule}
            {this.state.confirmLocationLoading ? (
              <div className="options-footer-loading-container">
                <MDSpinner size={25} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <div className="options-footer">
                <input
                  className="simulation-button button-gap"
                  type="button"
                  value="Confirm Move"
                  onClick={this.fetchConfirmLocation}
                />
                <input
                  className="simulation-button"
                  type="button"
                  value="Cancel Move"
                  onClick={this.props.closePopup}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.locationPopup === "scheduleAppointment") {
      locationPopup = (
        <Reevaluate
          simulationTime={this.props.simulationTime}
          fetchAdvance={this.fetchSchedule}
          closePopup={this.closeSchedule}
          advanceUpdate={false}
          scheduleAppointment={true}
          fetchingSchedule={this.state.fetchingSchedule}
        />
      );
    } else if (this.state.locationPopup === "confirmSchedule") {
      let time;
      if (this.state.appointment.AppointmentDate) {
        time = new Date(`${this.state.appointment.AppointmentDate}Z`);
        time = {
          days: getDaysFromDate(time),
          hours: time.getUTCHours(),
          minutes: time.getUTCMinutes(),
        };
      }

      let days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      locationPopup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <div className="popup-header">Confirmation</div>
            <div className="popup-content">
              <div className="popup-element white-space">
                Schedule appointment for {days[time.days % 7]}, Day {time.days}{" "}
                at {time.hours}:
                {time.minutes < 10 ? `0${time.minutes}` : time.minutes}?
              </div>
            </div>
            <div className="options-footer">
              <input
                className="simulation-button button-gap"
                type="button"
                value="OK"
                onClick={this.confirmSchedule.bind(this, true)}
              />
              <input
                className="simulation-button"
                type="button"
                value="Cancel"
                onClick={this.confirmSchedule.bind(this, false)}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.locationPopup === "scheduleError") {
      locationPopup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <div className="popup-header">Scheduling Error</div>
            <div className="popup-content">
              <div className="popup-element white-space">
                You must select a valid appointment time.
              </div>
            </div>
            <div className="options-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={() => this.setState({ locationPopup: "orderReview" })}
              />
            </div>
          </div>
        </div>
      );
    }

    return <div className="popup-container">{locationPopup}</div>;
  }
}

export default LocationPopup;
