import React, { useContext } from "react";
import { OSCEAdminContext } from "../..";
import formatTimeString from "../../../../Functions/FormatTimeString";
import formatDateString from "../../../../Functions/FormatDateString";

const ExamDataOverviewComponent = ({
  selectedExam,
  setShowChangeExamPopup,
}) => {
  const { userData } = useContext(OSCEAdminContext);

  const {
    GroupId,
    GroupName,
    OverallGrade,
    OverallGradeForGroup,
    OverallGradeForSchool,
    OverallGradeForAllSchools,
    TimeOsceEnded,
    OsceIndGrade: casesArray,
  } = selectedExam;

  return (
    <div className="adminIndividualStudentGradesPageExamDataContainer">
      <div className="adminIndividualStudentGradesPageExamDataTitleWrapper">
        <h2>
          Exam 1 - {formatDateString(TimeOsceEnded, userData.TimeZone)}{" "}
          {formatTimeString(TimeOsceEnded, userData.TimeZone)}
        </h2>
        <button onClick={() => setShowChangeExamPopup(true)}>
          Change Selected Exam
        </button>
      </div>
      <div className="flexBreaker" />
      <div className="adminIndividualStudentGradesPageExamDataValueWrapper">
        <h3>Number of Cases in Exam</h3>
        <p>{casesArray.length}</p>
      </div>
      <div className="adminIndividualStudentGradesPageExamDataValueWrapper">
        <h3>Group</h3>
        <p>
          {GroupId}
          {GroupName ? ` - ${GroupName}` : ""}
        </p>
      </div>
      <div className="adminIndividualStudentGradesPageExamDataScoreWrapper">
        <div className="adminIndividualStudentGradesPageExamDataScoreContainer">
          <h3>Student Score</h3>
          <p>{OverallGrade.toFixed(2)}%</p>
        </div>
        <div className="adminIndividualStudentGradesPageExamDataScoreContainer">
          <h3>Group Average Score</h3>
          <p>{OverallGradeForGroup.toFixed(2)}%</p>
        </div>
        <div className="adminIndividualStudentGradesPageExamDataScoreContainer">
          <h3>School Average Score</h3>
          <p>{OverallGradeForSchool.toFixed(2)}%</p>
        </div>
        <div className="adminIndividualStudentGradesPageExamDataScoreContainer">
          <h3>All Schools Average Score</h3>
          <p>{OverallGradeForAllSchools.toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
};

export default ExamDataOverviewComponent;
