import React from "react";
import SimulationTime from "./SimulationTime";

class PhysicalResults extends React.Component {
  render() {
    let results = {};
    let time = this.props.simulationTime;
    if (this.props.reviewPhysical || this.props.reviewPhysical === 0) {
      time =
        this.props.completedPhysicals[this.props.reviewPhysical].timeReported;
    }

    if (this.props.physicalResults.CurrentPatientUpdate) {
      results.update = (
        <div className="popup-element">
          <div className="physical-result-header">Patient Update</div>
          <div>{this.props.physicalResults.CurrentPatientUpdate}</div>
        </div>
      );
    }
    if (this.props.physicalResults.GENERALAPPREARANCE) {
      results.appearance = (
        <div className="popup-element">
          <div className="physical-result-header">General appearance</div>
          <div>{this.props.physicalResults.GENERALAPPREARANCE}</div>
        </div>
      );
    }
    if (this.props.physicalResults.SKIN) {
      results.skin = (
        <div className="popup-element">
          <div className="physical-result-header">Skin examination</div>
          <div>{this.props.physicalResults.SKIN}</div>
        </div>
      );
    }
    if (this.props.physicalResults.BREAST) {
      results.breast = (
        <div className="popup-element">
          <div className="physical-result-header">Breast examination</div>
          <div>{this.props.physicalResults.BREAST}</div>
        </div>
      );
    }
    if (this.props.physicalResults.LYMPHNODE) {
      results.lymph = (
        <div className="popup-element">
          <div className="physical-result-header">Lymph node examination</div>
          <div>{this.props.physicalResults.LYMPHNODE}</div>
        </div>
      );
    }
    if (this.props.physicalResults.HEENTNECK) {
      results.neck = (
        <div className="popup-element">
          <div className="physical-result-header">HEENT/Neck examination</div>
          <div>{this.props.physicalResults.HEENTNECK}</div>
        </div>
      );
    }
    if (this.props.physicalResults.CHESTLUNG) {
      results.chest = (
        <div className="popup-element">
          <div className="physical-result-header">Chest/Lung examination</div>
          <div>{this.props.physicalResults.CHESTLUNG}</div>
        </div>
      );
    }
    if (this.props.physicalResults.CARDIOVASCULAR) {
      results.heart = (
        <div className="popup-element">
          <div className="physical-result-header">
            Heart/Cardiovascular examination
          </div>
          <div>{this.props.physicalResults.CARDIOVASCULAR}</div>
        </div>
      );
    }
    if (this.props.physicalResults.ABDOMINAL) {
      results.abdominal = (
        <div className="popup-element">
          <div className="physical-result-header">Abdominal examination</div>
          <div>{this.props.physicalResults.ABDOMINAL}</div>
        </div>
      );
    }
    if (this.props.physicalResults.GENITAL) {
      results.genital = (
        <div className="popup-element">
          <div className="physical-result-header">Genital examination</div>
          <div>{this.props.physicalResults.GENITAL}</div>
        </div>
      );
    }
    if (this.props.physicalResults.RECTAL) {
      results.rectal = (
        <div className="popup-element">
          <div className="physical-result-header">Rectal examination</div>
          <div>{this.props.physicalResults.RECTAL}</div>
        </div>
      );
    }
    if (this.props.physicalResults.EXTREMITIESSPINE) {
      results.spine = (
        <div className="popup-element">
          <div className="physical-result-header">
            Extremities/Spine examination
          </div>
          <div>{this.props.physicalResults.EXTREMITIESSPINE}</div>
        </div>
      );
    }
    if (this.props.physicalResults.NEUROLOGICPSYCHOLOGIC) {
      results.neuro = (
        <div className="popup-element">
          <div className="physical-result-header">
            Neurologic/Psychologic examination
          </div>
          <div>{this.props.physicalResults.NEUROLOGICPSYCHOLOGIC}</div>
        </div>
      );
    }

    return (
      <div className="popup-blocker">
        <div className="popup-backdrop" />
        <div className="information-popup">
          <div className="popup-header">{"History and Physical"}</div>
          <div className="popup-content">
            <div className="popup-element centered">
              <SimulationTime simulationTime={time} />
            </div>
            {results.update}
            {results.appearance}
            {results.skin}
            {results.breast}
            {results.lymph}
            {results.neck}
            {results.chest}
            {results.heart}
            {results.abdominal}
            {results.genital}
            {results.rectal}
            {results.spine}
            {results.neuro}
          </div>
          <div className="popup-footer">
            <input
              className="simulation-button"
              type="button"
              value="OK"
              onClick={this.props.closePopup}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PhysicalResults;
