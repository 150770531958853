import calculatePerformance from "./calculatePerformance";

const getStudentsPageData = (analyticsData, userData) => {
  const studentData = analyticsData.StudentsSection;

  const getGroupName = (student) => {
    return (
      analyticsData.GroupsSection.Groups.find(
        (group) => group.GroupId === student.GroupId
      )?.GroupName || "Unknown Group"
    );
  };

  const getCasesCompleted = (student) => {
    return (
      studentData.Students.find((stud) => stud.StudentId === student.CustomerId)
        ?.CasesCompleted || 0
    );
  };

  const getAverageScorePerCase = (student) => {
    return parseFloat(
      (
        studentData.Students.find(
          (stud) => stud.StudentId === student.CustomerId
        )?.AverageScore || 0
      ).toFixed(2)
    );
  };

  const getStudentPerformance = (student) => {
    const studentAverageScore =
      studentData.Students.find((stud) => stud.StudentId === student.CustomerId)
        ?.AverageScore || 0;
    const allSchoolsAverageScore = studentData.AverageScorePerStudentAllSchools;

    return calculatePerformance(studentAverageScore, allSchoolsAverageScore);
  };

  return {
    numberOfStudents: userData.Students.length,
    averageScorePerStudent: parseFloat(
      studentData.AverageScorePerStudent.toFixed(2)
    ),
    allSchoolsAverageScore: parseFloat(
      studentData.AverageScorePerStudentAllSchools.toFixed(2)
    ),
    students: userData.Students.map((student) => ({
      studentId: student.CustomerId,
      studentName: `${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`,
      groupId: student.GroupId,
      groupName: getGroupName(student),
      examsCompleted: student.SpecficCasesOut.length,
      casesCompleted: getCasesCompleted(student),
      averageScorePerCase: getAverageScorePerCase(student),
      performance: getStudentPerformance(student),
    })),
  };
};

export default getStudentsPageData;
