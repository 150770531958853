import React, { useState } from "react";
import LoginPage from "../NewLogin/LoginPage";
import OSCEStudent from "./StudentPortal";
import OSCEAdmin from "./AdminPortal";

const WEB_SERVER_ROUTE = "https://osce.ccscases.com";
const OSCE = () => {
  // const [application, setApplication] = useState("Student");
  // const [userData, setUserData] = useState(null);
  const [application, setApplication] = useState("Admin");
  const [userData, setUserData] = useState(null);

  const handleLogin = (userAccountDetails, application) => {
    // My User ID: 5544779
    console.log(userAccountDetails);

    if (application === "Admin") {
      // Add the GroupName to each student
      userAccountDetails.Students = userAccountDetails.Students.map(
        (student) => {
          const group = userAccountDetails.Groups.find(
            (group) => group.groupId === student.GroupId
          );
          if (group) student.GroupName = group.GroupName;
          return student;
        }
      );
    }

    setApplication(application);
    setUserData(userAccountDetails);
  };

  const updateUserData = (newUserData) => {
    setUserData(newUserData);
  };

  if (!userData) {
    return (
      <div style={{ height: "100vh" }}>
        <LoginPage
          application="OSCE"
          handleLogin={handleLogin}
          route={WEB_SERVER_ROUTE}
          primaryColor={null}
          selectedTheme={null}
        />
      </div>
    );
  }

  let contentToRender = null;

  switch (application) {
    case "Student":
      contentToRender = <OSCEStudent userData={userData} />;
      break;
    case "Admin":
      contentToRender = (
        <OSCEAdmin
          userData={userData}
          route={WEB_SERVER_ROUTE}
          updateUserData={updateUserData}
        />
      );
      break;
    default:
      contentToRender = <OSCEStudent userData={userData} />;
      break;
  }

  return contentToRender;
};

export default OSCE;
