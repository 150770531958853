import TableHeader from "../Components/StudentTable/TableHeader/TableHeader";
import { useContext, useState } from "react";
import UploadCSVModal from "../Components/Modals/UploadCSVModal/UploadCSVModal";
import ConfirmCSVModal from "../Components/Modals/ConfirmCSVModal/ConfirmCSVModal";
import DesktopTableBody from "../Components/StudentTable/TableBody/DesktopTableBody/DesktopTableBody";
import MobileTableBody from "../Components/StudentTable/TableBody/MobileTableBody/MobileTableBody";
import { tableColumns, mobileTableData } from "./StudentTableData";
import filterStudents from "./StudentsFilter";
import { OSCEAdminContext } from "..";
import AddStudentModal from "../Components/Modals/AddStudentModal/AddStudentModal";

const AdminHome = ({ handleSelectStudent }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showUploadCSVModal, setShowUploadCSVModal] = useState(false);
  const [showConfirmCSVModal, setShowConfirmCSVModal] = useState(false);
  const [showAddStudentModal, setShowAddStudentModal] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [csvFile, setCsvFile] = useState(null);
  const [groupSelected, setGroupSelected] = useState("All Groups");
  const { userData } = useContext(OSCEAdminContext);
  const [sortBy, setSortBy] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const handleConfirmCSVUpload = (newCsvData, newCsvFile) => {
    setCsvData(newCsvData);
    setCsvFile(newCsvFile);
    setShowUploadCSVModal(false);
    setShowConfirmCSVModal(true);
  };

  const filteredStudentsList = filterStudents(
    userData.Students,
    groupSelected,
    searchTerm
  );

  const studentTableClickHandler = (index, event) => {
    const unfilteredStudentIndex = userData.Students.findIndex(
      (student) =>
        student.UniqueSignInId === filteredStudentsList[index].UniqueSignInId
    );
    handleSelectStudent(unfilteredStudentIndex);
  };

  return (
    <div className="adminPortalHomeContainer">
      {showUploadCSVModal && (
        <UploadCSVModal
          setShowUploadCSVModal={setShowUploadCSVModal}
          handleConfirmCSVUpload={handleConfirmCSVUpload}
        />
      )}
      {showConfirmCSVModal && (
        <ConfirmCSVModal
          csvData={csvData}
          setShowConfirmCSVModal={setShowConfirmCSVModal}
          csvFile={csvFile}
        />
      )}
      {showAddStudentModal && (
        <AddStudentModal setShowAddStudentModal={setShowAddStudentModal} />
      )}
      <div className="adminPortalHomeTableWrapper">
        <TableHeader
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setShowUploadCSVModal={setShowUploadCSVModal}
          setShowAddStudentModal={setShowAddStudentModal}
          groupSelected={groupSelected}
          setGroupSelected={setGroupSelected}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
        />
        {filteredStudentsList.length === 0 ? (
          <div className="adminPortalTableBodyNoStudentsContainer">
            <p className="adminPortalTableBodyNoStudentsText">
              No Students were found for "{searchTerm}"
            </p>
          </div>
        ) : (
          <>
            <DesktopTableBody
              valuesList={filteredStudentsList}
              tableColumns={tableColumns}
              componentKey={"UniqueSignInId"}
              tableName={"Desktop Student Accounts"}
              onValueClick={studentTableClickHandler}
              parentSortBy={sortBy}
              parentSetSortBy={setSortBy}
              parentSortDirection={sortDirection}
              parentSetSortDirection={setSortDirection}
            />
            <MobileTableBody
              valuesList={filteredStudentsList}
              mobileTableData={mobileTableData}
              componentKey={"UniqueSignInId"}
              tableName={"Mobile Student Accounts"}
              onBodyClick={studentTableClickHandler}
              parentSortBy={sortBy}
              parentSortDirection={sortDirection}
              enableSort
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AdminHome;
