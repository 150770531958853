import React, { Component } from 'react'
import MDSpinner from 'react-md-spinner'

export default class OrderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cursorY: 0,
      scrollOffset: 0
    }

    this.textArea = React.createRef();
  }

  /*
  To-Do for autocomplete:
    [x] Add click support to insert the suggested term
    [x] Perform the search alphabetically
    [x] Only autocomplete based on the matching first letters of the sequence
    [x] Prioritize non-comma deliniated phrases
    [x] Onclick behavior is still a little off, need to update to make this more natural
    [x] getCaretCoordinates is off by one for each line after the first. Going to need to come up with a better
        solution than substr.
    [] Scrolling the container leaves the autocomplete box floating. Need to figure out how to make the parent container
       scroll instead of the child input container
  */

  componentDidMount() {
		this.textArea.current.focus();
    this.textArea.current.addEventListener('keyup', this.handleAutoSuggest)
    this.textArea.current.addEventListener('click', this.handleAutoSuggest)
    this.textArea.current.addEventListener('scroll', this.handleScroll);
	}

  componentWillUnmount() {
    this.textArea.current.removeEventListener('keyup', this.handleAutoSuggest)
    this.textArea.current.removeEventListener('click', this.handleAutoSuggest)
    this.textArea.current.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({scrollOffset: this.textArea.current.scrollTop})
    if (this.props.autoSuggestTerm)
      this.props.updateAutoSuggest("");
  }

  placeAutosuggestContainer = (position) => {
    const topOffset = this.getCurrentlySelectedLine() * 19
    
    this.setState({ cursorY: topOffset });
  }

  getSubstringAtIndex = (str, index) => {
    const segments = str.split('\n');
    let accumulatedLength = 0;

    for (let i = 0; i < segments.length; i++) {
      accumulatedLength += segments[i].length;
      if (index <= accumulatedLength + i) { 
        return segments[i];
      }
    }

    return '';
  }

  handleAutoSuggest = () => {
    const carotIndex = this.textArea.current.selectionStart
    // console.log(e.target.selectionStart, carotIndex);
    this.placeAutosuggestContainer(carotIndex);

    const substring = this.getSubstringAtIndex(this.props.orderInput, carotIndex);
    this.props.updateAutoSuggest(substring);
  }

  handleChange = (e) => {
    this.props.handleOrderInput(e.target.value);
  }

  getCurrentlySelectedLine = () => {
    const orderTermsArray = this.props.orderInput.split('\n');
    const carotIndex = this.textArea.current.selectionStart
    let totalLetters = 0;

    let i = 0;
    while(totalLetters < carotIndex) {
      if ((carotIndex - totalLetters) <= orderTermsArray[i].length)
        // Term selected is in the current orderTermsArray Index
        break;
      totalLetters += orderTermsArray[i++].length + 1;
    }
    return i;
  }

  handleSuggestionClick = () => {
    const orderTermsArray = this.props.orderInput.split('\n');
    let i = this.getCurrentlySelectedLine();
    
    orderTermsArray[i] = this.props.autoSuggestTerm;
    this.props.handleOrderInput(orderTermsArray.join("\n"));
		this.textArea.current.focus();
    this.props.updateAutoSuggest("")
    this.textArea.current.scrollTop = this.textArea.current.scrollHeight
    setTimeout(() => {
      this.handleAutoSuggest();
    }, 10)
  }

  render() {
    return (
      <div className='popup-blocker'>
					<div className='popup-backdrop' />
					<div className='information-popup'>
						<div className='popup-header'>Order Input {this.props.error}</div>
						<div className='order-input-content'>
							<textarea
								className='order-input'
								value={this.props.orderInput}
								onChange={this.handleChange}
								ref={this.textArea}
							/>
              {this.props.autoSuggestTerm &&
                <div className='order-input-autosuggest' style={{top: this.state.cursorY - 22 - this.state.scrollOffset}} onClick={this.handleSuggestionClick}>
                  <p>{this.props.autoSuggestTerm}</p>
                </div>
              }
						</div>
						{this.props.ordersPopupLoading ?
							<div className='options-footer-loading-container'>
								<MDSpinner
									size={25}
									singleColor={this.props.primaryColor}
								/>
							</div>
						:
							<div className='options-footer'>
								<input className='simulation-button button-gap' type='button' value='Confirm Orders' onClick={this.props.fetchOrders}/>
								<input className='simulation-button' type='button' value='Cancel' onClick={this.props.closePopup}/>
							</div>
						}
					</div>
				</div>
    )
  }
}
