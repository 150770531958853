import React from 'react';

class SimulationTime extends React.Component {

	render() {

		let time;
		// display completely different date format
		if (this.props.startTime) {
			//calculate elapsed time from simulationTime and startTime
			let days;
			let hours;
			if (this.props.simulationTime.hours >= this.props.startTime.hours) {
				hours = this.props.simulationTime.hours - this.props.startTime.hours;
				days = this.props.simulationTime.days - this.props.startTime.days;
			}
			else {
				hours = this.props.simulationTime.hours + (24 - this.props.startTime.hours);
				days = this.props.simulationTime.days - this.props.startTime.days - 1;
			}
			let minutes = this.props.simulationTime.minutes;
			
			if (hours.toString().length === 1) {
				hours = '0' + hours;
			}
			if (minutes.toString().length === 1) {
				minutes = '0' + minutes;
			}
			
			time = (
				<p className='simulation-time'>
					{'Elapsed SIMULATED Case Time = ' + days + ' Days ' + hours + ' Hrs ' + minutes + ' Mins'}
				</p>
			)
		}
		else if (this.props.cancelled) {
			time = (
				<div className='red'>
					{this.props.cancelled}
				</div>
			)
		}
		else if (this.props.simulationTime) {
			let dayWeek;
			if (this.props.dayWeek) {
				// calculate the day of the week based on the day this will be a mod 7 then a line up of int and day
				let dayNum = this.props.simulationTime.days % 7;
				let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
				dayWeek = <div className='white-space'>{' (' + days[dayNum] + ')'}</div>
			}

			let hours = this.props.simulationTime.hours;
			if (hours?.toString().length === 1) {
				hours = '0' + hours;
			}

			let minutes = this.props.simulationTime.minutes;
			if (minutes?.toString().length === 1) {
				minutes = '0' + minutes;
			}

			time = (
				<p className='simulation-time'>
					{'Day ' + this.props.simulationTime.days + ' @ ' + Math.floor(hours) + ':' + minutes}
					{dayWeek}
				</p>
			)
		}

		return (
			<p className='simulation-option-main'>
				{time}
			</p>
		)
	}
}

export default SimulationTime;
