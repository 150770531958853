import React, { useContext, useEffect, useState } from "react";
import "./PreceptorsTable.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import DesktopTableBody from "../../Components/StudentTable/TableBody/DesktopTableBody/DesktopTableBody";
import MobileTableBody from "../../Components/StudentTable/TableBody/MobileTableBody/MobileTableBody";
import { OSCEAdminContext } from "../..";
import AddAccountsModal from "../../Components/Modals/ListItemsSelectorModal/ListItemsSelectorModal";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";

const PreceptorsTable = ({ student }) => {
  const {
    userData,
    route,
    updateUserData,
    displayNotification,
    mousePosition,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [preceptorsList, setPreceptorsList] = useState(
    userData.Preceptors.filter((preceptor) =>
      preceptor.StudentIds.includes(student.CustomerId)
    )
  );
  const [showAddPreceptorModal, setShowAddPreceptorModal] = useState(false);
  const [showPreceptorTableOptionsPopup, setShowPreceptorTableOptionsPopup] =
    useState(false);
  const [selectedPreceptorIndex, setSelectedPreceptorIndex] = useState(null);

  const preceptorTableColumns = [
    {
      label: "Name",
      valueKey: [
        "PreceptorFirstName",
        "PreceptorMiddleName",
        "PreceptorLastName",
      ],
    },
    {
      label: "Email",
      valueKey: ["PreceptorEmail"],
    },
  ];

  const preceptorMobileTableData = {
    title: ["PreceptorFirstName", "PreceptorMiddleName", "PreceptorLastName"],
    titleModifier: null,
    subtitle: ["PreceptorEmail"],
    subtitleModifier: null,
    secondaryTitle: [],
    secondaryTitleModifier: null,
    values: [],
  };

  useEffect(() => {
    setPreceptorsList(
      userData.Preceptors.filter((preceptor) =>
        preceptor.StudentIds.includes(student.CustomerId)
      )
    );
  }, [userData]);

  const fetchUpdatePreceptors = async (selectedPreceptors) => {
    let body = {
      StudentId: student.CustomerId,
      PreceptorIdsToAdd: selectedPreceptors.filter(
        (preceptorId) =>
          !preceptorsList
            .map((preceptor) => preceptor.PreceptorId)
            .includes(preceptorId)
      ),
      PreceptorIdsToRemove: preceptorsList
        .map((preceptor) => preceptor.PreceptorId)
        .filter((preceptorId) => !selectedPreceptors.includes(preceptorId)),
    };

    return await fetch(`${route}/addpreceptortostudent.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify(body),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return false;
        let preceptors = structuredClone(userData.Preceptors);

        for (let i = 0; i < preceptors.length; ++i) {
          if (selectedPreceptors.includes(preceptors[i].PreceptorId)) {
            // The preceptor is selected and thus the student should be in the Preceptors StudentId array
            if (!preceptors[i].StudentIds.includes(student.CustomerId))
              preceptors[i].StudentIds.push(student.CustomerId);
          } else {
            // The preceptor is not selected and thus the student should not be in the Preceptors StudentId array
            if (preceptors[i].StudentIds.includes(student.CustomerId))
              preceptors[i].StudentIds = preceptors[i].StudentIds.filter(
                (studentId) => studentId !== student.CustomerId
              );
          }
        }

        updateUserData({
          ...userData,
          Preceptors: preceptors,
        });

        console.log(data);
        displayNotification("Preceptors Updated Successfully");
        return true;
      })
      .catch((error) => {
        displayNotification(`Error: ${error}`);
        return false;
      });
  };

  const handleDeletePreceptorClick = () => {
    const selectedPreceptorId =
      preceptorsList[selectedPreceptorIndex].PreceptorId;
    const newPreceptorsList = preceptorsList.filter(
      (preceptor) => preceptor.PreceptorId !== selectedPreceptorId
    );
    const preceptorIdList = newPreceptorsList.map(
      (preceptor) => preceptor.PreceptorId
    );
    fetchUpdatePreceptors(preceptorIdList);
    setShowPreceptorTableOptionsPopup(false);
  };

  const handleTableBodyClick = (preceptorIndex) => {
    setShowPreceptorTableOptionsPopup(true);
    setSelectedPreceptorIndex(preceptorIndex);
  };

  return (
    <div className="studentProfilePagePreceptorsSectionWrapper">
      <div className="studentProfilePageSectionHeaderWrapper">
        <h2 className="studentProfilePageSectionHeader">Preceptors</h2>
        <button
          className="studentProfilePageSectionHeaderButton"
          onClick={() => setShowAddPreceptorModal(true)}
        >
          <FontAwesomeIcon icon={faUserPlus} />
          Add Preceptor
        </button>
      </div>
      <DesktopTableBody
        valuesList={preceptorsList}
        tableColumns={preceptorTableColumns}
        componentKey={"PreceptorId"}
        tableName={"Student Preceptors"}
        onValueClick={handleTableBodyClick}
      />
      <MobileTableBody
        valuesList={preceptorsList}
        mobileTableData={preceptorMobileTableData}
        componentKey={"PreceptorId"}
        tableName={"Student Preceptors"}
        onButtonsClick={handleTableBodyClick}
      />
      {showAddPreceptorModal && (
        <AddAccountsModal
          closeModal={() => setShowAddPreceptorModal(false)}
          confirmSelection={fetchUpdatePreceptors}
          initialSelectedElements={preceptorsList.map(
            (preceptor) => preceptor.PreceptorId
          )}
          dataLabel={"Preceptors"}
          subtitle={`Select Preceptors for ${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`}
          dataArray={userData.Preceptors}
          dataValueUniqueId={"PreceptorId"}
          dataValuesToDisplay={[
            "PreceptorFirstName",
            "PreceptorMiddleName",
            "PreceptorLastName",
          ]}
        />
      )}
      {showPreceptorTableOptionsPopup && (
        <ButtonListPopup
          mousePos={{
            x: mousePosition.x,
            y: mousePosition.y,
          }}
          setShowButtonListPopup={setShowPreceptorTableOptionsPopup}
          showButtonListPopup={showPreceptorTableOptionsPopup}
        >
          <button onClick={handleDeletePreceptorClick}>
            <FontAwesomeIcon icon={faTrashCan} />
            Remove Preceptor From Student
          </button>
        </ButtonListPopup>
      )}
    </div>
  );
};

export default PreceptorsTable;
