import React, { useContext, useMemo } from "react";
import "./StudentsContainer.css";
import { ExamRulesContext } from "../../../ExamRulesPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import formatStudentSubtext from "./FormatStudentSubtext";
import { OSCEAdminContext } from "../../../..";

const StudentsContainer = ({ studentsList, searchTerm, selectedGroup }) => {
  const { selectedStudentIds, setSelectedStudentIds } =
    useContext(ExamRulesContext);
  const { userData } = useContext(OSCEAdminContext);

  const handleSelectStudent = (studentIndex) => {
    const studentId = studentsList[studentIndex].CustomerId;
    if (selectedStudentIds.includes(studentId)) {
      setSelectedStudentIds(selectedStudentIds.filter((s) => s !== studentId));
    } else {
      setSelectedStudentIds([...selectedStudentIds, studentId]);
    }
  };

  const getNoStudentsText = () => {
    let text = "No students found";

    if (searchTerm !== "") {
      text += ` for search "${searchTerm}"`;
    }

    if (selectedGroup !== "All Groups") {
      const groupName = userData.Groups.find(
        (group) => `${group.groupId}` === selectedGroup
      ).GroupName;
      text += ` in group "${selectedGroup} - ${groupName}"`;
    }

    return text;
  };

  const handleSelectAllStudentsClick = () => {
    if (selectedStudentIds.length === studentsList.length) {
      setSelectedStudentIds([]);
    } else {
      setSelectedStudentIds(studentsList.map((student) => student.CustomerId));
    }
  };

  const isSelectAllChecked = useMemo(() => {
    if (studentsList.length === 0) {
      return false;
    }

    if (studentsList.length === selectedStudentIds.length) {
      let studentsMap = {};
      studentsList.forEach((student) => {
        studentsMap[student.CustomerId] = true;
      });

      return selectedStudentIds.every((studentId) => studentsMap[studentId]);
    }

    return false;
  }, [studentsList, selectedStudentIds]);

  return (
    <div className="examRulesModificationStudentsContainer">
      <h2 className="examRulesHeaderText">
        Students ({selectedStudentIds.length} Selected)
      </h2>
      <div className="examRulesModificationStudentsCheckAllWrapper">
        <button
          className="examRulesModificationSelectAllStudentsCheckbox"
          onClick={handleSelectAllStudentsClick}
        >
          {isSelectAllChecked && <FontAwesomeIcon icon={faCheck} />}
        </button>
        <p>Select All ({studentsList.length} Students)</p>
      </div>
      <div className="examRulesSubContainer">
        {studentsList.map((student, studentIndex) => (
          <div
            className={`examRulesModificationStudentCard ${
              selectedStudentIds.includes(student.CustomerId)
                ? "examRulesModificationStudentCardSelected"
                : ""
            }`}
            key={student.CustomerId}
            onClick={() => handleSelectStudent(studentIndex)}
          >
            <button className="examRulesModificationStudentCheckbox">
              {selectedStudentIds.includes(student.CustomerId) && (
                <FontAwesomeIcon icon={faCheck} />
              )}
            </button>
            <h3 className="examRulesModificationStudentName">
              {student.CustomerFirstName} {student.CustomerMiddleName}{" "}
              {student.CustomerLastName}
            </h3>
            <p className="examRulesModificationStudentSubText">
              {formatStudentSubtext(student)}
            </p>
          </div>
        ))}
        {studentsList.length === 0 && (
          <p className="examRulesModificationNoStudentsSelected">
            {getNoStudentsText()}
          </p>
        )}
      </div>
    </div>
  );
};

export default StudentsContainer;
