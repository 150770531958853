import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { OSCEAdminContext } from "../..";
import ButtonListPopup from "../../Components/ButtonListPopup/ButtonListPopup";
import { createPortal } from "react-dom";

const CaseDataTabs = ({
  casesArray,
  parentContainerWidth,
  selectedCaseIndex,
  setSelectedCaseIndex,
  examGuid,
}) => {
  const { mousePosition } = useContext(OSCEAdminContext);
  const [visibleTabs, setVisibleTabs] = useState(casesArray.length);
  const tabRefs = useRef([]);
  const [showCasesButtonList, setShowCasesButtonList] = useState(false);

  const updateTabsDisplayed = useCallback(() => {
    let tabsWidth = 60;
    let totalTabCount = 0;
    for (let tab of tabRefs.current) {
      if (tab) {
        tabsWidth += tab.offsetWidth;
        if (tabsWidth > parentContainerWidth) break;
        totalTabCount++;
      }
    }
    setVisibleTabs(totalTabCount);
  }, [tabRefs, parentContainerWidth]);

  const handleButtonListCaseClick = (index) => {
    setSelectedCaseIndex(index + visibleTabs);
    setShowCasesButtonList(false);
  };

  useLayoutEffect(() => {
    if (parentContainerWidth) updateTabsDisplayed();
  }, [casesArray, parentContainerWidth, updateTabsDisplayed]);

  useEffect(() => {
    window.addEventListener("resize", updateTabsDisplayed);
    return () => {
      window.removeEventListener("resize", updateTabsDisplayed);
    };
  }, [casesArray, parentContainerWidth, updateTabsDisplayed]);

  return (
    <>
      <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonsUnderlayWrapper">
        {casesArray.map((caseData, index) => (
          <button
            className={
              selectedCaseIndex === index
                ? "adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonSelected"
                : ""
            }
            style={{
              flexShrink: 0,
              visibility: index < visibleTabs ? "visible" : "hidden",
              position: index < visibleTabs ? "static" : "absolute",
              zIndex:
                selectedCaseIndex === index
                  ? casesArray.length + 1
                  : casesArray.length - index,
            }}
            onClick={() => setSelectedCaseIndex(index)}
            key={`Cases Tab Overlay Element - ${examGuid} - ${index}`}
          >
            <h2>Case {index + 1}</h2>
            <p>Tension Pneumothorax</p>
          </button>
        ))}
        {visibleTabs < casesArray.length && (
          <button
            style={{
              zIndex:
                selectedCaseIndex > visibleTabs - 1 ? casesArray.length + 1 : 0,
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </button>
        )}
      </div>
      <div className="adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonsWrapper">
        {casesArray.map((caseData, index) => (
          <div
            className={
              selectedCaseIndex === index
                ? "adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonSelected"
                : "adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonHidden"
            }
            style={{
              flexShrink: 0,
              visibility:
                index < visibleTabs && selectedCaseIndex === index
                  ? "visible"
                  : "hidden",
              position: index < visibleTabs ? "static" : "absolute",
              zIndex: casesArray.length * 2 - index + 1,
            }}
            onClick={() => setSelectedCaseIndex(index)}
            key={`Cases Tab - ${examGuid} - ${index}`}
            ref={(el) => (tabRefs.current[index] = el)}
          >
            <h2>Case {index + 1}</h2>
            <p>Tension Pneumothorax</p>
          </div>
        ))}
        {visibleTabs < casesArray.length && (
          <div
            style={{
              zIndex:
                selectedCaseIndex > visibleTabs - 1
                  ? casesArray.length * 2 + 1
                  : 1,
            }}
            onClick={setShowCasesButtonList}
            className={
              selectedCaseIndex > visibleTabs - 1
                ? "adminIndividualStudentGradesPageExamGradeBreakdownCaseEllipsisButtonSelected"
                : ""
            }
          >
            <FontAwesomeIcon icon={faEllipsisVertical} />
          </div>
        )}
      </div>
      {showCasesButtonList &&
        createPortal(
          <ButtonListPopup
            showButtonListPopup={showCasesButtonList}
            setShowButtonListPopup={setShowCasesButtonList}
            mousePos={{
              x: mousePosition.x,
              y: mousePosition.y,
            }}
          >
            {casesArray.slice(visibleTabs).map((caseData, index) => (
              <button
                onClick={() => handleButtonListCaseClick(index)}
                className={`${
                  selectedCaseIndex === visibleTabs + index
                    ? "adminIndividualStudentGradesPageExamGradeBreakdownCaseButtonPopupListItemSelected"
                    : ""
                }`}
              >
                Case {visibleTabs + index + 1} - Tension Pneumothorax
              </button>
            ))}
          </ButtonListPopup>,
          document.body
        )}
    </>
  );
};

export default CaseDataTabs;
