import React, { useContext, useState } from "react";
import { OSCEAdminContext } from "../../..";
import MDSpinner from "react-md-spinner";

const ModifyEmailModal = ({ setShowModifyEmail, student }) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [newStudentEmail, setNewStudentEmail] = useState(student.CustomerEmail);
  const {
    route,
    userData,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);

  const fetchModifyEmail = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/modifystudent.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        StudentId: student.CustomerId,
        StudentEmail: newStudentEmail,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        const students = structuredClone(userData.Students);
        const studentIndex = students.findIndex(
          (element) => element.CustomerId === student.CustomerId
        );
        students[studentIndex].CustomerEmail = newStudentEmail;
        updateUserData({ ...userData, Students: students });
        displayNotification(
          `Email changed successfully to "${newStudentEmail}"`
        );
        setShowModifyEmail(false);
      })
      .catch((error) => {
        setFetchOutstanding(false);
        console.error(error);
        displayNotification("Failed to change user email");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">Modify Email</h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel">Student Email</label>
          <input
            className="modalInput"
            type="text"
            value={newStudentEmail}
            onChange={(e) => setNewStudentEmail(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifyEmail(false)}
            >
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={fetchModifyEmail}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyEmailModal;
