import React, { useContext, useState } from "react";
import "./ModifyAdminModal.css";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../..";

const ModifyAdminModal = ({ setShowModifyAdminModal, selectedAdmin }) => {
  const {
    route,
    userData,
    updateUserData,
    displayNotification,
    handleExpiredUserSession,
  } = useContext(OSCEAdminContext);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [firstName, setFirstName] = useState(
    selectedAdmin ? selectedAdmin.AdminFirstName : ""
  );
  const [middleName, setMiddleName] = useState(
    selectedAdmin ? selectedAdmin.AdminMiddleName : ""
  );
  const [lastName, setLastName] = useState(
    selectedAdmin ? selectedAdmin.AdminLastName : ""
  );
  const [email, setEmail] = useState(
    selectedAdmin ? selectedAdmin.AdminEmail : ""
  );

  const fetchAddAdmin = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/makenewadmin.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        FirstName: firstName,
        MiddleName: middleName,
        LastName: lastName,
        Email: email,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          if (response.status === 400) {
            throw new Error(await response.text());
          } else {
            throw new Error(
              `Error: ${response.status} - ${response.statusText}`
            );
          }
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        setShowModifyAdminModal(false);
        displayNotification(
          `Admin ${firstName} ${lastName} added successfully`
        );
        updateUserData({
          ...userData,
          Admins: [
            ...userData.Admins,
            {
              AdminEmail: email,
              AdminFirstName: firstName,
              AdminId: data.AdminId,
              AdminLastName: lastName,
              AdminMiddleName: middleName,
              superAdmin: false,
            },
          ],
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchOutstanding(false);
        displayNotification(`${error}`);
      });
  };

  const fetchModifyAdmin = async () => {
    setFetchOutstanding(true);
    await fetch(`${route}/editadminorpreceptor.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerId: selectedAdmin.AdminId,
        CustomerFirstName: firstName,
        CustomerMiddleName: middleName,
        CustomerLastName: lastName,
        CustomerEmail: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .then((data) => {
        if (!data) return;
        console.log(data);
        setShowModifyAdminModal(false);
        displayNotification(
          `Admin ${firstName} ${lastName} modified successfully`
        );
        updateUserData({
          ...userData,
          Admins: userData.Admins.map((admin) => {
            if (admin.AdminId === selectedAdmin.AdminId) {
              return {
                AdminEmail: email,
                AdminFirstName: firstName,
                AdminLastName: lastName,
                AdminMiddleName: middleName,
                superAdmin: admin.superAdmin,
              };
            }
            return admin;
          }),
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        setFetchOutstanding(false);
        displayNotification("Error modifying admin");
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">
          {selectedAdmin ? "Modify" : "Add"} Administrator
        </h2>
        <div className="modalInputWrapper">
          <label htmlFor="adminFirstName" className="modalInputLabel">
            First Name
          </label>
          <input
            type="text"
            id="adminFirstName"
            className="modalInput"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="adminMiddleName" className="modalInputLabel">
            Middle Name
          </label>
          <input
            type="text"
            id="adminMiddleName"
            className="modalInput"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="adminLastName" className="modalInputLabel">
            Last Name
          </label>
          <input
            type="text"
            id="adminLastName"
            className="modalInput"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="modalInputWrapper">
          <label htmlFor="adminEmail" className="modalInputLabel">
            Email
          </label>
          <input
            type="email"
            id="adminEmail"
            className="modalInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowModifyAdminModal(false)}
            >
              Cancel
            </button>
            <button
              className="modalConfirmButton"
              onClick={selectedAdmin ? fetchModifyAdmin : fetchAddAdmin}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyAdminModal;
