const preceptorTableColumns = [
  {
    label: "Name",
    valueKey: [
      "PreceptorFirstName",
      "PreceptorMiddleName",
      "PreceptorLastName",
    ],
  },
  {
    label: "Email",
    valueKey: ["PreceptorEmail"],
  },
  {
    label: "Account ID",
    valueKey: ["UniqueLoginId"],
  },
  {
    label: "Number of Students",
    valueKey: ["StudentIds"],
    valueModifier: (value) => value.length,
  },
];

const preceptorMobileTableData = {
  title: ["PreceptorFirstName", "PreceptorMiddleName", "PreceptorLastName"],
  titleModifier: null,
  subtitle: ["PreceptorEmail"],
  subtitleModifier: null,
  secondaryTitle: [],
  secondaryTitleModifier: null,
  values: [
    {
      label: "Account ID",
      valueKey: ["UniqueLoginId"],
      valueModifier: (value) => `Account ID: ${value}`,
    },
    {
      label: "Number of Students",
      valueKey: ["StudentIds"],
      valueModifier: (value) => `Number of Students: ${value.length}`,
    },
  ],
};

export { preceptorTableColumns, preceptorMobileTableData };
