import React, { Component } from "react";
import "./NewGrading.css";
import Logo from "../images/logoCropped.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faUser,
  faUsers,
  faCheck,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSpinnerThird,
  faUserDoctorMessage,
} from "@fortawesome/pro-solid-svg-icons";
import ActionLog from "./Components/ActionLog/ActionLog";
import logFetchError from "../Functions/LogFetchError";
import fetchSubmitLogs from "../Functions/FetchSubmitLogs";
import ViewCompletionsPopup from "./Components/ViewCompletionsPopup/ViewCompletionsPopup";
import { AnimatePresence, motion } from "framer-motion";
import MDSpinner from "react-md-spinner";
import formatDateString from "../Functions/FormatDateString";
import formatTimeString from "../Functions/FormatTimeString";

export default class NewGrading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedActionLogItem: 0,
      caseCompletionDate: null,
      reviewLater: this.props.reviewLater,
      highYield: false,
      selectedCaseData: {
        AvgGradeForCase: 0,
      },
      grading: this.props.grading
        ? this.props.grading
        : {
            GradeFeedback: {
              GradeOut: [],
            },
          },
      gradingSections: [
        {
          title: "Diagnosis Orders",
          gradeValue: "FinalDiagnoisGrade",
          weightValue: "WEIGHTDIAGNOSIS",
        },
        {
          title: "Treatment Orders",
          gradeValue: "FinalTreatmentGrade",
          weightValue: "WEIGHTTREATMENT",
        },
        {
          title: "Preventive care",
          gradeValue: "FinalPreventiveCareGrade",
          weightValue: "WEIGHTPREVENTATIVE",
        },
        {
          title: "Timing",
          gradeValue: "FinalTimingGrade",
          weightValue: "WEIGHTTIMING",
        },
        {
          title: "Appropriate Orders",
          gradeValue: "FinalAppropriateGrade",
          weightValue: "WEIGHTAPPROPRIATE",
        },
        {
          title: "Appropriate Location / Appropriate Sequence",
          gradeValue: "FinalSequenceGrade",
          weightValue: "WEIGHTSEQUENCING",
        },
      ],
      enableFeedbackGrading:
        props.gradingMode === "peek" ? false : !this.props.DisableFeedback,
      viewPreviousCompletions: false,
      previousCompletionSelected: 0,
      previousCompletions: [],
      highYieldLoading: false,
      reviewLaterLoading: false,
    };
  }

  componentDidMount() {
    if (this.props.gradingMode === "peek") {
      this.fetchPeekCaseGrade();
    } else {
      this.fetchViewCaseGrade();
    }

    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute(
      "content",
      this.props.selectedTheme.tertiaryBackgroundColor
    );
  }

  fetchReviewLater = () => {
    let caseId;
    if (this.props.caseGrades) {
      caseId = this.props.caseGrades;
    } else {
      caseId = this.props.selectedCase;
    }
    this.setState({ reviewLaterLoading: true });
    fetch(`${this.props.route}/reviewlater.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseId: caseId,
        marked: !this.state.reviewLater,
      }),
    })
      .then((response) => {
        this.setState({ reviewLaterLoading: false });
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        this.reviewLaterToggle();
      })
      .catch((error) => {
        this.setState({ reviewLaterLoading: false });
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log(error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchReviewLater"
        );
      });
  };

  reviewLaterToggle = () => {
    this.setState({ reviewLater: !this.state.reviewLater }, () => {
      this.props.updateReviewLater();
    });
  };

  getDateSuffix = (day) => {
    if (day === 1 || day === 21 || day === 31) {
      return "st";
    } else if (day === 2 || day === 22) {
      return "nd";
    } else if (day === 3 || day === 23) {
      return "rd";
    } else {
      return "th";
    }
  };

  formatCompletionDate = (unformattedDate) => {
    let dateObject = new Date(unformattedDate);

    // Format the date as Aug 1st, 2021, 10:37 PM
    const MONTHS = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = MONTHS[dateObject.getMonth()];
    let day = dateObject.getDate();
    let daySuffix = this.getDateSuffix(day);
    let year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    let minutes = dateObject.getMinutes();
    let ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let formattedDate =
      month +
      " " +
      day +
      daySuffix +
      ", " +
      year +
      ", " +
      hours +
      ":" +
      minutes +
      " " +
      ampm;
    return formattedDate;
  };

  fetchRetrieveCaseGrade = async (id) => {
    await fetch(`${this.props.route}/retreivecasegrade.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        CustomerGradeId: id,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((data) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        data = JSON.parse(data);

        // Get caseData from the unfiltered case list
        let caseData = this.props.unfilteredCaseList.filter(
          (tempCase) => tempCase.ID === this.props.selectedCase
        )[0];

        // sort by GradeCategory
        data.GradeFeedback.GradeOut.sort((a, b) => {
          if (a.GradeCategory < b.GradeCategory) {
            return -1;
          } else if (a.GradeCategory > b.GradeCategory) {
            return 1;
          } else {
            return 0;
          }
        });
        data.GradeFeedback.GradeOut = data.GradeFeedback.GradeOut.filter(
          (grade) => grade.GradeCategory !== ""
        );

        for (let i = 0; i < data.GradeFeedback.GradeOut.length; i++) {
          if (
            data.GradeFeedback.GradeOut[i].GradeColor.includes(
              "Correct - Green"
            )
          ) {
            data.GradeFeedback.GradeOut[i].Correct = true;
          } else {
            data.GradeFeedback.GradeOut[i].Correct = false;
          }
        }

        for (let i = 0; i < data.GradeFeedback.ActionLogList.length; ++i) {
          if (
            data.GradeFeedback.ActionLogList[i].EventName.includes(
              "State Changed to:"
            )
          ) {
            data.GradeFeedback.ActionLogList.splice(i, 1);
            i--;
          }
        }

        this.setState({
          selectedCase: id,
          selectedCaseData: caseData,
          caseCompletionDate: data.CaseCompletionDate,
          caseGradeData: data,
          grading: data,
          highYield: data.HighYieldFlag,
        });
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchRetrieveCaseGrade"
        );
      });
  };

  fetchPeekCaseGrade = () => {
    let caseId = this.props.selectedCase;
    fetch(`${this.props.route}/peekatgrading.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseId: caseId,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((data) => {
        if (
          data.includes(
            "The customer has already done this case. The customer should use View Grades instead."
          )
        ) {
          return;
        }
        data = JSON.parse(data);
        let grading = {
          GradeFeedback: data,
        };

        let caseData = this.props.unfilteredCaseList.filter(
          (tempCase) => tempCase.ID === this.props.selectedCase
        )[0];

        this.setState({
          caseCompletionDate: data.CompletionDate,
          selectedCase: caseId,
          grading,
          selectedCaseData: caseData,
        });
      })
      .catch((error) => {
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchPeekCaseGrade"
        );
      });
  };

  fetchViewCaseGrade = () => {
    let caseId;
    if (this.props.caseGrades) {
      caseId = this.props.caseGrades;
    } else {
      caseId = this.props.selectedCase;
    }
    fetch(`${this.props.route}/viewcasegrade.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseId: caseId,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        let data = response;
        // reverse order of array
        let array = JSON.parse(data).vcgo;
        array.reverse();

        let caseArray = []; // only display the cases that are of the case selected
        for (let i = 0; i < array.length; i++) {
          if (
            array[i].CaseId === caseId &&
            array[i].CaseCompletionDate !== "January 1, 1, 0:00"
          ) {
            caseArray.push(array[i]);
          }
        }

        let newCaseArray = [...caseArray];

        // sort newCaseArray by CaseCompletionDate
        newCaseArray.sort((a, b) => {
          return (
            new Date(b.CaseCompletionDate) - new Date(a.CaseCompletionDate)
          );
        });

        // if !this.props.grading fetch data for most recent case
        if (!this.props.grading) {
          this.fetchRetrieveCaseGrade(newCaseArray[0].CustomerGradeId);
        }

        this.setState({
          previousCompletions: newCaseArray,
          selectedCase: newCaseArray[0].CustomerGradeId,
        });
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log(error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchViewCaseGrade"
        );
      });
  };

  // enableFeedbackGrading = () => {
  // 	this.setState({enableFeedbackGrading: !this.state.enableFeedbackGrading});
  // }

  markHighYieldHandler = () => {
    let caseId;
    if (this.props.caseGrades) {
      caseId = this.props.caseGrades;
    } else {
      caseId = this.props.selectedCase;
    }
    this.setState(
      { highYield: !this.state.highYield, highYieldLoading: true },
      () => {
        fetch(`${this.props.route}/highyield.webapi`, {
          method: "POST",
          headers: {
            Token: this.props.userData.Token,
            "Content-Type": "text/plain",
          },
          body: JSON.stringify({
            customerid: this.props.userData.CustomerId,
            caseid: caseId,
            markhighyield: this.state.highYield,
            unmarkhighyield: !this.state.highYield,
          }),
        })
          .then((response) => {
            this.setState({ highYieldLoading: false });
            //Attempt sending logs
            fetchSubmitLogs(this.props.userProfile, this.props.userData);

            if (response.status === 401) {
              this.props.setAuthenticationError();
              throw new Error("Authentication Error");
            } else {
              return response.text();
            }
          })
          .then((response) => {})
          .catch((error) => {
            this.setState({
              highYield: !this.state.highYield,
              highYieldLoading: false,
            });
            logFetchError(
              error,
              this.props.userProfile,
              this.props.userData,
              "markHighYieldHandler"
            );
          });
      }
    );
  };

  highYieldHelpHandler = (e) => {
    e.stopPropagation();
    let confirmationPopupOptions = {
      title: `High Yield`,
      description: `If you think this case has a good chance to show up on the USMLE Step 3 exam, you can mark it as High Yield. This helps other users know which cases to study.`,
      confirmFunction: async () => {
        this.props.closeConfirmationPopup();
      },
      children: null,
      closePopup: () => {
        this.props.closeConfirmationPopup();
      },
      continueOnly: true,
    };
    this.props.displayConfirmationPopup(confirmationPopupOptions);
  };

  render() {
    return (
      <div className="newGradingPageWrapper">
        <nav className="newGradingNavbar">
          <img className="newGradingLogo" alt="CCS Cases Logo" src={Logo} />
        </nav>
        <AnimatePresence exitBeforeEnter>
          {this.state.grading.GradeFeedback.GradeOut.length === 0 && (
            <motion.div
              className="newGradingLoaderContainer"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={
                this.props.animationsDisabled ? false : { y: 0, opacity: 1 }
              }
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingLoaderContainer"
            >
              <MDSpinner size={60} singleColor={this.props.primaryColor} />
              <p className="newGradingLoaderText">Loading Grades</p>
            </motion.div>
          )}
          {this.state.grading.GradeFeedback.GradeOut.length > 0 && (
            <motion.div
              className="newGradingBackWrapper"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={this.props.animationsDisabled ? false : { opacity: 1 }}
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingBackWrapper"
            >
              <button
                className="tertiaryButton newGradingBackButton"
                onClick={() => this.props.closeGrading()}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="newGradingBackIcon"
                />
                Back To Case List
              </button>
              {this.props.gradingMode !== "peek" &&
                this.state.grading.GradeFeedback.ActionLogList.length > 0 && (
                  <div
                    className="newGradingCheckboxContainer"
                    onClick={this.markHighYieldHandler}
                    style={{
                      gridTemplateColumns: "30px auto auto",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="newGradingCheckbox">
                      {this.state.highYield && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="newGradingCheckboxIcon"
                        />
                      )}
                    </div>
                    <p className="newGradingCheckboxText">High Yield</p>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="highYieldMoreInfoButton"
                      onClick={this.highYieldHelpHandler}
                    />
                    {this.state.highYieldLoading && (
                      <div className="highYieldLoadingContainer">
                        <MDSpinner size={35} singleColor={"#fff"} />
                      </div>
                    )}
                  </div>
                )}
              <div
                className="newGradingCheckboxContainer"
                onClick={this.fetchReviewLater}
                style={{ gridTemplateColumns: "30px auto" }}
              >
                <div className="newGradingCheckbox">
                  {this.state.reviewLater && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="newGradingCheckboxIcon"
                    />
                  )}
                </div>
                <p className="newGradingCheckboxText">Review Later</p>
                {this.state.reviewLaterLoading && (
                  <div className="highYieldLoadingContainer">
                    <MDSpinner size={30} singleColor={"#fff"} />
                  </div>
                )}
              </div>
            </motion.div>
          )}
          {this.state.grading.GradeFeedback.GradeOut.length > 0 && (
            <motion.div
              className="newGradingCaseTitleContainer"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={this.props.animationsDisabled ? false : { opacity: 1 }}
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingCaseTitleContainer"
            >
              <div className="newGradingCaseNumberContainer">
                <h1 className="newGradingCaseNumber">
                  {this.state.selectedCaseData.ID}
                </h1>
              </div>
              <div className="newGradingCaseTitleWrapper">
                <h2 className="newGradingCaseTitle">
                  {this.state.selectedCaseData.TITLE} -{" "}
                  {this.state.selectedCaseData.CASECATEGORYDESC}
                </h2>
                <h3 className="newGradingCaseCompletionDate">
                  Completed:{" "}
                  {formatDateString(
                    this.state.caseCompletionDate,
                    this.props.userData.TimeZone
                  )}
                  ,{" "}
                  {formatTimeString(
                    this.state.caseCompletionDate,
                    this.props.userData.TimeZone
                  )}
                </h3>
              </div>
              {this.props.gradingMode !== "peek" && (
                <button
                  className="newGradingViewPreviousButton tertiaryButton"
                  onClick={() =>
                    this.setState({ viewPreviousCompletions: true })
                  }
                >
                  View Previous Completions
                </button>
              )}
            </motion.div>
          )}
          {this.state.grading.GradeFeedback.GradeOut.length > 0 && (
            <motion.div
              className="newGradingCaseDetailsWrapper"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={this.props.animationsDisabled ? false : { opacity: 1 }}
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingCaseDetailsWrapper"
            >
              <div className="newGradingCaseDetailContainer">
                <div className="newGradingCaseDetailIconContainer">
                  <FontAwesomeIcon
                    icon={faUser}
                    className="newGradingCaseDetailIcon"
                  />
                </div>
                <h3 className="newGradingCaseDetailTitle">Your Score</h3>
                <h2 className="newGradingCaseDetailValue">
                  {this.props.gradingMode === "peek"
                    ? this.state.grading.GradeFeedback.TotalGrade
                    : this.state.grading.GradeForCase}
                  %
                </h2>
              </div>

              <div className="newGradingCaseDetailContainer">
                <div className="newGradingCaseDetailIconContainer">
                  <FontAwesomeIcon
                    icon={faUsers}
                    className="newGradingCaseDetailIcon"
                  />
                </div>
                <h3 className="newGradingCaseDetailTitle">
                  Average First Attempt Score
                </h3>
                <h2 className="newGradingCaseDetailValue">
                  {this.props.gradingMode === "peek"
                    ? this.state.grading.GradeFeedback.AverageFirstAttempt.toFixed(
                        1
                      )
                    : this.state.grading.AverageFirstAttempt.toFixed(1)}
                  %
                </h2>
              </div>

              <div className="newGradingCaseDetailContainer">
                <div className="newGradingCaseDetailIconContainer">
                  <FontAwesomeIcon
                    icon={faUserDoctorMessage}
                    className="newGradingCaseDetailIcon"
                  />
                </div>
                <h3 className="newGradingCaseDetailTitle">Case Diagnosis</h3>
                <h2 className="newGradingCaseDetailValue">
                  {this.state.grading.GradeFeedback.FinalDiagnosis}
                </h2>
              </div>
            </motion.div>
          )}
          {/* {this.state.grading.GradeFeedback.GradeOut.length > 0 &&
                        <motion.div
                            className='newGradingFeedbackTitleContainer'
                            initial={this.props.animationsDisabled ? false : {opacity: 0}}
                            animate={this.props.animationsDisabled ? false : {opacity: 1}}
                            exit={this.props.animationsDisabled ? false : {opacity: 0}}
                            transition={{duration: 0.2}}
                            key="newGradingFeedbackTitleContainer"
                        >
                            <h2 className='newGradingFeedbackTitle'>Grading Feedback</h2>
                            {this.props.gradingMode !== 'peek' &&
                                <div className='newGradingFeedbackCheckboxContainer' onClick={() => this.setState({enableFeedbackGrading: !this.state.enableFeedbackGrading})}>
                                    <div className='newGradingCheckbox'>
                                        {!this.state.enableFeedbackGrading && <FontAwesomeIcon icon={faCheck} className='newGradingCheckboxIcon'/>}
                                    </div>
                                    <p className='newGradingCheckboxText'>
                                        Disable Feedback
                                    </p>
                                </div>
                            }
                        </motion.div>
                    } */}
          {this.state.grading.GradeFeedback.GradeOut.length > 0 &&
            this.state.gradingSections.map(
              (section, sectionIndex) =>
                this.state.grading.GradeFeedback.GradeOut.some((feedback) =>
                  section.title.includes(feedback.GradeCategory)
                ) && (
                  <motion.div
                    className="newGradingFeedbackItemContainer"
                    initial={
                      this.props.animationsDisabled ? false : { opacity: 0 }
                    }
                    animate={
                      this.props.animationsDisabled ? false : { opacity: 1 }
                    }
                    exit={
                      this.props.animationsDisabled ? false : { opacity: 0 }
                    }
                    transition={{ duration: 0.2 }}
                    key={`newGradingFeedbackItemContainer - ${sectionIndex}`}
                  >
                    <h2 className="newGradingFeedbackItemTitle">
                      {section.title}:{" "}
                      {this.state.grading.GradeFeedback[section.gradeValue]
                        ? this.state.grading.GradeFeedback[section.gradeValue]
                        : 0}
                      %
                    </h2>
                    <h3 className="newGradingFeedbackItemValue">
                      Weight given for total score:{" "}
                      {this.state.grading.GradeFeedback[section.weightValue]}%
                    </h3>
                    <div className="newGradingFeedbackItemContentWrapper">
                      {this.state.grading.GradeFeedback.GradeOut.map(
                        (feedback, index) =>
                          section.title.includes(feedback.GradeCategory) && (
                            <div
                              key={`GradingFeedback - ${sectionIndex} - ${index}`}
                              className={`newGradingFeedbackAnswerWrapper ${
                                !this.state.enableFeedbackGrading
                                  ? "newGradingFeedbackAnswerNeutral"
                                  : feedback.Correct
                                  ? "newGradingFeedbackAnswerCorrect"
                                  : feedback.EventName.includes(
                                      "Optional Order"
                                    )
                                  ? "newGradingFeedbackAnswerNeutral"
                                  : "newGradingFeedbackAnswerIncorrect"
                              }`}
                            >
                              <h4
                                className="newGradingFeedbackAnswerTitle"
                                style={{
                                  marginBottom:
                                    !feedback.OrderNames &&
                                    !feedback.GradeExplanation
                                      ? "0px"
                                      : "15px",
                                }}
                              >
                                {(feedback.EventName.includes(
                                  "Correctly Ordered"
                                ) ||
                                  feedback.EventName.includes(
                                    "Should have Ordered"
                                  )) &&
                                !this.state.enableFeedbackGrading
                                  ? "Suggested Order"
                                  : feedback.EventName}
                              </h4>
                              {feedback.OrderNames &&
                                feedback.OrderNamesList.length === 0 && (
                                  <ul className="newGradingFeedbackAnswerItemList">
                                    <li className="newGradingFeedbackAnswerItem">
                                      {feedback.OrderNames}
                                    </li>
                                  </ul>
                                )}
                              {feedback.OrderNamesList.length !== 0 && (
                                <ul className="newGradingFeedbackAnswerItemList">
                                  <li className="newGradingFeedbackAnswerItem">
                                    {feedback.OrderNamesList.map(
                                      (orderName, orderNameIndex) =>
                                        orderName +
                                        (orderNameIndex !==
                                        feedback.OrderNamesList.length - 1
                                          ? " / "
                                          : "")
                                    )}
                                  </li>
                                </ul>
                              )}
                              {feedback.GradeExplanation && (
                                <p className="newGradingFeedbackReason">
                                  <strong>Reason: </strong>
                                  {feedback.GradeExplanation}
                                </p>
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </motion.div>
                )
            )}
          {this.state.grading.GradeFeedback.SUMMARY && (
            <motion.div
              className="newGradingCaseSummaryContainer"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={this.props.animationsDisabled ? false : { opacity: 1 }}
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingCaseSummaryContainer"
            >
              <h2 className="newGradingCaseSummaryTitle">Case Summary</h2>
              <p className="newGradingCaseSummaryText">
                {this.state.grading.GradeFeedback.SUMMARY}
              </p>
            </motion.div>
          )}
          {this.state.grading.GradeFeedback.ActionLogList &&
            this.props.gradingMode !== "peek" &&
            this.state.grading.GradeFeedback.ActionLogList.length > 0 && (
              <ActionLog
                actionLog={this.state.grading.GradeFeedback.ActionLogList}
                selectedActionLogItem={this.state.selectedActionLogItem}
                key="actionLogComponent"
                updateSelectedActionLogItem={(newSelection) =>
                  this.setState({ selectedActionLogItem: newSelection })
                }
                completionDate={this.state.grading.GradeFeedback.CompletionDate}
              />
            )}
          {this.state.grading.GradeFeedback.GradeOut.length > 0 && (
            <motion.div
              className="newGradingBackWrapper"
              initial={this.props.animationsDisabled ? false : { opacity: 0 }}
              animate={this.props.animationsDisabled ? false : { opacity: 1 }}
              exit={this.props.animationsDisabled ? false : { opacity: 0 }}
              transition={{ duration: 0.2 }}
              key="newGradingBackFooterWrapper"
            >
              <button
                className="tertiaryButton newGradingBackButton"
                onClick={() => this.props.closeGrading()}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="newGradingBackIcon"
                />
                Back To Case List
              </button>
              {this.props.gradingMode !== "peek" &&
                this.state.grading.GradeFeedback.ActionLogList.length > 0 && (
                  <div
                    className="newGradingCheckboxContainer"
                    onClick={this.markHighYieldHandler}
                    style={{
                      gridTemplateColumns: "30px auto auto",
                      paddingRight: "10px",
                    }}
                  >
                    <div className="newGradingCheckbox">
                      {this.state.highYield && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="newGradingCheckboxIcon"
                        />
                      )}
                    </div>
                    <p className="newGradingCheckboxText">High Yield</p>
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      className="highYieldMoreInfoButton"
                      onClick={this.highYieldHelpHandler}
                    />
                    {this.state.highYieldLoading && (
                      <div className="highYieldLoadingContainer">
                        <MDSpinner size={35} singleColor={"#fff"} />
                      </div>
                    )}
                  </div>
                )}
              <div
                className="newGradingCheckboxContainer"
                onClick={this.fetchReviewLater}
                style={{ gridTemplateColumns: "30px auto" }}
              >
                <div className="newGradingCheckbox">
                  {this.state.reviewLater && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="newGradingCheckboxIcon"
                    />
                  )}
                </div>
                <p className="newGradingCheckboxText">Review Later</p>
                {this.state.reviewLaterLoading && (
                  <div className="highYieldLoadingContainer">
                    <MDSpinner size={30} singleColor={"#fff"} />
                  </div>
                )}
              </div>
            </motion.div>
          )}
          <AnimatePresence>
            {this.state.viewPreviousCompletions && (
              <ViewCompletionsPopup
                closePopup={() =>
                  this.setState({ viewPreviousCompletions: false })
                }
                previousCompletionSelected={
                  this.state.previousCompletionSelected
                }
                previousCompletions={this.state.previousCompletions}
                updatePreviousCompletionSelected={(newSelection) =>
                  this.setState({ previousCompletionSelected: newSelection })
                }
                confirm={(caseId) => this.fetchRetrieveCaseGrade(caseId)}
                timeZone={this.props.userData.TimeZone}
                primaryColor={this.props.primaryColor}
                key="viewCompletionsPopupComponent"
              />
            )}
          </AnimatePresence>
        </AnimatePresence>
      </div>
    );
  }
}
