const formatDate = (date) => {
  return new Date(date).toLocaleString("en-US", {
    month: "short",
    year: "numeric",
  });
};

const getGraphData = (categoryTrend) => {
  const graphDataArray = categoryTrend.MonthlyTrends.map((trend) => ({
    date: formatDate(trend.Month),
    averageGrade: parseFloat(trend.AverageGrade.toFixed(2)),
    numberOfStudents: trend.StudentCount,
    numberOfCompletions: trend.CompletionCount,
  }));

  return graphDataArray;
};

export default getGraphData;
