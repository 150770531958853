import React, { useState, useMemo, useEffect } from "react";
import "./DesktopTableBody.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

/*
  TableColumnItem: {
    label: string,
    valueKey: Array<string>,
  }

  TableColumns: Array<TableColumnItem>
*/

const DesktopTableBody = ({
  valuesList,
  tableColumns,
  componentKey,
  tableName,
  onValueClick,
  parentSortBy,
  parentSetSortBy,
  parentSortDirection,
  parentSetSortDirection,
}) => {
  const [sortedColumn, setSortedColumn] = useState(
    parentSortBy ? parentSortBy : ""
  );
  const [sortDirection, setSortDirection] = useState(
    parentSortDirection ? parentSortDirection : "asc"
  );

  const handleSort = (column) => {
    // column is a TableColumnItem
    if (sortedColumn === column.label) {
      if (sortDirection === "asc") {
        setSortDirection("desc");
      } else {
        setSortDirection("asc");
        setSortedColumn("");
      }
    } else {
      setSortedColumn(column.label);
      setSortDirection("asc");
    }
  };

  useEffect(() => {
    if (parentSetSortBy) parentSetSortBy(sortedColumn);
    if (parentSetSortDirection) parentSetSortDirection(sortDirection);
  }, [sortedColumn, sortDirection]);

  useEffect(() => {
    setSortedColumn(parentSortBy);
    setSortDirection(parentSortDirection);
  }, [parentSortBy, parentSortDirection]);

  const sortedValuesList = useMemo(() => {
    if (sortedColumn) {
      // get the table data item from the column label
      const columnObject = tableColumns.find(
        (column) => column.label === sortedColumn
      );
      return [...valuesList].sort((a, b) => {
        const valueA = columnObject.valueKey.map((key) => a[key]).join(" ");
        const valueB = columnObject.valueKey.map((key) => b[key]).join(" ");

        if (valueA < valueB) {
          return sortDirection === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return valuesList;
  }, [valuesList, sortedColumn, sortDirection]);

  const handleValueClick = (value, event) => {
    if (!onValueClick) return;

    const unfilteredValueIndex = valuesList.findIndex(
      (val) => val[componentKey] === value[componentKey]
    );

    onValueClick(unfilteredValueIndex, event, value);
  };

  return (
    <table className="adminPortalDesktopTableContainer">
      <thead className="adminPortalDesktopTableHeader">
        <tr
          className="adminPortalDesktopTableHeaderRow"
          style={{ gridTemplateColumns: `repeat(${tableColumns.length}, 1fr)` }}
        >
          {tableColumns.map((column) => (
            <th
              key={`${tableName} - Header - ${column.label}`}
              onClick={() => handleSort(column)}
              className="adminPortalDesktopTableHeaderElement"
            >
              {column.label}
              {sortedColumn !== column.label ? null : sortDirection ===
                "asc" ? (
                <FontAwesomeIcon icon={faCaretDown} />
              ) : (
                <FontAwesomeIcon icon={faCaretUp} />
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="adminPortalDesktopTableBody">
        {sortedValuesList.map((value, index) => (
          <tr
            className="adminPortalDesktopTableBodyRow"
            style={{
              gridTemplateColumns: `repeat(${tableColumns.length}, 1fr)`,
            }}
            key={`${tableName} - Body - ${value[componentKey]}`}
            onClick={(event) => handleValueClick(value, event)}
          >
            {tableColumns.map((column, index) => (
              <td
                key={`${tableName} - Body - ${index} - ${value[componentKey]}`}
                className="adminPortalDesktopTableBodyElement"
              >
                {column.valueModifier
                  ? column.valueModifier(value[column.valueKey])
                  : column.valueKey.map((key) => value[key]).join(" ")}
              </td>
            ))}
          </tr>
        ))}
        {sortedValuesList.length === 0 && (
          <tr
            className="adminPortalDesktopTableBodyRow"
            style={{ pointerEvents: "none" }}
          >
            <td
              className="adminPortalDesktopTableBodyElement"
              colSpan={tableColumns.length}
              style={{ justifySelf: "center" }}
            >
              No {tableName} Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default DesktopTableBody;
