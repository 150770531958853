import React, { useState } from "react";
import MDSpinner from "react-md-spinner";

const ModifyGroupModal = ({
  setShowEditGroupModal,
  selectedGroup,
  fetchModifyGroups,
}) => {
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [groupName, setGroupName] = useState(
    selectedGroup ? selectedGroup.GroupName : ""
  );

  const handleConfirmClick = async () => {
    setFetchOutstanding(true);
    let successfulFetch = await fetchModifyGroups(
      selectedGroup ? "update" : "add",
      groupName,
      selectedGroup ? selectedGroup.groupId : ""
    );

    if (successfulFetch) setShowEditGroupModal(false);
    else setFetchOutstanding(false);
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainerSmall">
        <h2 className="modalHeader">
          {selectedGroup ? "Modify" : "Add"} Group{" "}
          {selectedGroup ? selectedGroup.groupId : ""}
        </h2>
        <div className="modalInputWrapper">
          <label className="modalInputLabel">Group Name</label>
          <input
            className="modalInput"
            type="text"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        {fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={35} singleColor="#0B335D" />
          </div>
        ) : (
          <div className="modalButtonsWrapper">
            <button
              className="modalCancelButton"
              onClick={() => setShowEditGroupModal(false)}
            >
              Cancel
            </button>
            <button className="modalConfirmButton" onClick={handleConfirmClick}>
              Save Changes
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ModifyGroupModal;
