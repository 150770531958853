import React from "react";
import "./Simulation.css";
import PhysicalOptions from "./PhysicalOptions";
import PhysicalResults from "./PhysicalResults";
import ReviewOrders from "./ReviewOrders";
import SimulationTime from "./SimulationTime";
import Reevaluate from "./Reevaluate";
import PopupsComponent from "./PopupsComponent";
import ConfirmPhysicalPopup from "./ConfirmPhysicalPopup";
import LocationPopup from "./LocationPopup";

import hx from "../images/hx.png";
import order from "../images/order.png";
import watch from "../images/watch.png";
import ambulance from "../images/ambulance.png";
import office from "../images/office.png";
import unit from "../images/unit.png";
import care from "../images/care.png";
import home from "../images/home.png";
import lag from "../images/loading.gif";
import logFetchError from "../Functions/LogFetchError";
import fetchSubmitLogs from "../Functions/FetchSubmitLogs";
import getDaysFromDate from "../Functions/GetDaysFromDate";
import MDSpinner from "react-md-spinner";

class Simulation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupQueue: this.props.startCaseData.popupQueue, // keeps track of popup component
      simulationTime: {
        // tracks the current simulation time
        days: this.props.startCaseData.elapsedSimulatedCaseTime.days,
        hours: this.props.startCaseData.elapsedSimulatedCaseTime.hours,
        minutes: this.props.startCaseData.elapsedSimulatedCaseTime.minutes,
      },
      elapsedRealTime: this.props.startCaseData.currentActualTime,
      centerContent: this.props.startCaseData.centerContent, // determines what component is displayed in the center of screen not as a popup
      physicalCheckboxes: {
        interval: false,
        appearance: false,
        skin: false,
        breasts: false,
        lymph: false,
        neck: false,
        chest: false,
        heart: false,
        abdomen: false,
        genitalia: false,
        rectal: false,
        spine: false,
        neuro: false,
      },
      endCase: false,
      orderSheet: [...this.props.startCaseData.currentOrders], // array of validated orders
      progressNotes: [],
      vitalSigns: [...this.props.startCaseData.vitalsResults],
      labReports: [],
      imaging: [],
      otherTests: [],
      treatmentRecord: [],
      patientUpdates: [...this.props.startCaseData.patientUpdates],
      completedPhysicals: [...this.props.startCaseData.physicalResults], // completed physicals go to progress notes
      physicalResults: this.props.startCaseData.physicalResults[0],
      advanceTimeResults: {},
      orderPopup: [], // for multiple order updates at same time
      simulateNetworkLag: false,
      simulationOptionSelected:
        this.props.startCaseData.simulationOptionSelected,
      fontSize: 1,
      darkMode: false,
      validateOrderError: "",
      orderIndexToDelete: 0,
      prereqOptions: [],
      timeRemainingInSeconds: 0,
      timeLimitInMinutes: 0,
      fetchAdvanceLoading: false,
    };

    this.simulateNetworkLag = this.simulateNetworkLag.bind(this);
    this.decrementTimerBySecond = this.decrementTimerBySecond.bind(this);
    this.arrivedToAppointment = this.arrivedToAppointment.bind(this);
    this.trackAppointmentData = this.trackAppointmentData.bind(this);
    this.fetchWaitingForAppointment =
      this.fetchWaitingForAppointment.bind(this);
    this.updateOrders = this.updateOrders.bind(this);
    this.appendVitals = this.appendVitals.bind(this);
    this.appendLocationOrders = this.appendLocationOrders.bind(this);
    this.advanceUpdate = this.advanceUpdate.bind(this);
    this.fetchCancel = this.fetchCancel.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.fetchVitals = this.fetchVitals.bind(this);
    this.reviewPopup = this.reviewPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.addRealTimeMinute = this.addRealTimeMinute.bind(this);
    this.physicalCheckboxToggle = this.physicalCheckboxToggle.bind(this);
    this.physicalCheckboxesClear = this.physicalCheckboxesClear.bind(this);
    this.fetchValidate = this.fetchValidate.bind(this);
    this.setPopup = this.setPopup.bind(this);
  }

  secondsTimer = null;
  minutesTimer = null;

  simulateNetworkLag() {
    if (this.props.networkLag !== "none") {
      this.setState({ simulateNetworkLag: true });

      // calculate milliseconds
      let time;
      if (this.props.networkLag === "random") {
        time = (Math.floor(Math.random() * 6) + 1) * 1000;
      } else {
        time = this.props.networkLag * 1000;
      }

      setTimeout(() => {
        this.setState({ simulateNetworkLag: false });
      }, time);
    }

    // clearTimeout(this.loginTimeout);
    // this.loginTimeout = setTimeout(
    // 	() => window.location.reload(),
    // 	3600000
    // );

    // clearTimeout(this.timeoutWarning);
    // this.timeoutWarning = setTimeout(
    // 	() => this.setPopup('timeoutWarning'),
    // 	3300000
    // );
  }

  /*
	use appointmentData to set orders display vitals change location remove orders
	*/
  arrivedToAppointment() {
    // add new orders to orderSheet, update location data
    this.appendLocationOrders(this.state.appointmentData.locationData.dboList);
    this.props.updateLocation(2);

    // display vital signs
    if (!this.state.appointmentData.locationData.EROfficeVitals) {
      for (
        let i = 0;
        i < this.state.appointmentData.locationData.dboList.length;
        i++
      ) {
        if (this.state.appointmentData.locationData.dboList[i].ID === 1636) {
          let order = Object.assign(
            {},
            this.state.appointmentData.locationData.dboList[i]
          );
          if (order.CANCELLED === null) {
            this.reviewPopup("orderUpdate", order);
          }
        }
      }
    } else {
      // location is er or office need to display vitals in a different way
      // need to add this vitals to vital signs list
      let order = {
        NAME: "Vital Signs",
        RESULTNORMAL: this.state.appointmentData.locationData.EROfficeVitals,
      };
      order.ReportTime = Object.assign({}, this.state.simulationTime);
      order.CurrentVirtualTime = order.ReportTime;
      this.reviewPopup("orderUpdate", order);
      this.appendVitals(order);
    }
  }

  /*
	used to save the data from change location so that when appointment is made updates after arrival
	*/
  trackAppointmentData(locationData, selectedLocation) {
    this.setState({
      appointmentData: {
        locationData: locationData,
        selectedLocation: selectedLocation,
      },
    });
  }

  /*
	had to move from LocationPopup because this works like advance and may need to be called multiple times to finish
	*/
  async fetchWaitingForAppointment(mins) {
    await fetch(`${this.props.route}/waitingforappt.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
        caseId: this.props.caseData.ID,
        ApptTimeInMin: mins,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);
        // console.log('fetched waiting for appointment')

        let data = response;
        this.simulateNetworkLag();
        data = JSON.parse(data);
        let result = JSON.parse(data.results);
        result.CurrentVirtualTime = new Date(`${result.CurrentVirtualTime}Z`);
        result.CurrentVirtualTime = {
          days: getDaysFromDate(result.CurrentVirtualTime),
          hours: result.CurrentVirtualTime.getUTCHours(),
          minutes: result.CurrentVirtualTime.getUTCMinutes(),
        };

        // update the current simulation time
        let simulationTime = {
          days: result.CurrentVirtualTime.days,
          hours: result.CurrentVirtualTime.hours,
          minutes: result.CurrentVirtualTime.minutes,
        };
        let dontClosePatientUpdate = true;

        if (
          result.PatientUpdate === null &&
          this.state.advanceTimeResults.PatientUpdate !== null
        ) {
          result.PatientUpdate = this.state.advanceTimeResults.PatientUpdate;
          dontClosePatientUpdate = false;
        }
        if (
          this.state.popupQueue?.every(
            (value) => value !== "endCaseInstructions"
          )
        ) {
          this.setState({
            simulationTime,
            advanceTimeResults: result,
          });
        }

        if (result.ReturnResult.length !== 0) {
          this.updateOrders(result);
        }

        if (result.PatientUpdate) {
          // add result to an array of patient updates that will be listed under Progress Notes
          let patientUpdates = this.state.patientUpdates;
          patientUpdates.push(result);
          this.setState({
            patientUpdates: patientUpdates,
          });
          this.setPopup("patientUpdate", false, dontClosePatientUpdate);
        }

        if (data.ArrivedToAppointment) {
          data.ArrivedToAppointment = data.ArrivedToAppointment.replace(
            "{",
            ""
          );
          data.ArrivedToAppointment = data.ArrivedToAppointment.replace(
            "}",
            ""
          );
          this.arrivedToAppointment();

          this.setState({
            waitingForAppointment: false,
            appointmentUpdate: data,
          });
          this.setPopup("appointmentUpdate", false, true);
        } else {
          this.setState({ waitingForAppointment: data });
        }

        if (result.CaseEnds) {
          this.setPopup("endCaseInstructions", false, true);
          this.setState({ waitingForAppointment: false });
        }
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchWaitingForAppointment"
        );
      });
  }

  /*
	moved from inside fetchAdvance becuase this needs to be used for waiting for appointment aswell
	*/
  updateOrders(result) {
    for (let i = 0; i < result.ReturnResult.length; i++) {
      if (result.ReturnResult[i].ID === 0 || !result.ReturnResult[i].ID)
        continue;

      let orderSheet = this.state.orderSheet;
      let appointmentData = this.state.appointmentData;
      let order;
      let reportTime;

      // remove the order from orderSheet
      // dont remove if TABPLACEMENT === null or if frequency
      for (let j = 0; j < this.state.orderSheet.length; j++) {
        if (result.ReturnResult[i].ID === this.state.orderSheet[j].orderId) {
          if (
            this.state.orderSheet[j].TABPLACEMENT !== null &&
            (this.state.orderSheet[j].FREQUENCY === "One Time/Bolus" ||
              !this.state.orderSheet[j].FREQUENCY ||
              this.state.orderSheet[j].FREQUENCY === "")
          ) {
            reportTime = Object.assign({}, orderSheet[j].ReportTime);
            if (appointmentData?.locationData?.dboList) {
              appointmentData.locationData.dboList.splice(j, 1);
            }
            order = orderSheet.splice(j, 1)[0];
          } else {
            order = orderSheet[j];
            reportTime = Object.assign({}, orderSheet[j].ReportTime);
            // update ReportTime
            if (result.ReturnResult[i].TIMESTATINMINS >= 1440) {
              orderSheet[j].ReportTime.days +=
                result.ReturnResult[i].TIMESTATINMINS / 1440;
            } else if (result.ReturnResult[i].TIMESTATINMINS < 1440) {
              orderSheet[j].ReportTime.hours +=
                result.ReturnResult[i].TIMESTATINMINS / 60;
              if (orderSheet[j].ReportTime.hours > 23) {
                orderSheet[j].ReportTime.days++;
                orderSheet[j].ReportTime.hours -= 24;
              }
            }
          }
        }
      }

      // set correct dates
      if (order) {
        result.ReturnResult[i].CurrentVirtualTime = order.CurrentVirtualTime; // this is really time ordered
        result.ReturnResult[i].ReportTime = reportTime;
      }

      let orderPopup = this.state.orderPopup ? [...this.state.orderPopup] : [];
      if (orderPopup.length > 0) {
        if (orderPopup[0].CANCELLED) {
          orderPopup.shift();
        }
      }
      orderPopup.push(result.ReturnResult[i]);
      this.setState({ orderPopup });

      // multiple updates - check to see if there are multiple updates if so call setPopup with multiple parameters
      if (i !== result.ReturnResult.length - 1) {
        this.setPopup("orderUpdate", true, true);
      } else {
        if (result.PatientUpdate) {
          this.setPopup("orderUpdate", true, false);
        } else {
          if (result.ReturnResult[i].CANCELLED !== true) {
            this.setPopup(
              "orderUpdate",
              !result.TimeLeftFromInput || result.TimeLeftFromInput === 0
                ? true
                : false,
              this.state.popupQueue[0] === "reevaluate" ||
                this.state.popupQueue[0] === "primum"
                ? false
                : true
            );
          }
        }
      }

      // move order from orderSheet to TABPLACEMENT array
      if (result.ReturnResult[i].TABPLACEMENT === "Progress") {
        let progressNotes = this.state.progressNotes;
        progressNotes.push(result.ReturnResult[i]);
        this.setState({ progressNotes: progressNotes });
      } else if (result.ReturnResult[i].TABPLACEMENT === "Vitals") {
        let vitalSigns = [...this.state.vitalSigns];
        vitalSigns.push(result.ReturnResult[i]);
        this.setState({ vitalSigns: vitalSigns });
      } else if (result.ReturnResult[i].TABPLACEMENT === "Lab") {
        let labReports = this.state.labReports;
        labReports.push(result.ReturnResult[i]);
        this.setState({ labReports: labReports });
      } else if (result.ReturnResult[i].TABPLACEMENT === "Imaging") {
        let imaging = this.state.imaging;
        imaging.push(result.ReturnResult[i]);
        this.setState({ imaging: imaging });
      } else if (result.ReturnResult[i].TABPLACEMENT === "Other") {
        let otherTests = this.state.otherTests;
        otherTests.push(result.ReturnResult[i]);
        this.setState({ otherTests: otherTests });
      } else if (result.ReturnResult[i].TABPLACEMENT === "") {
        let treatmentRecord = this.state.treatmentRecord;
        treatmentRecord.push(result.ReturnResult[i]);
        this.setState({ treatmentRecord: treatmentRecord });
      } else if (result.ReturnResult[i].TABPLACEMENT === null) {
        // the order is a location order
        // this is assuming that only location orders will have a TABPLACEMENT of null
        let vitalSigns = [...this.state.vitalSigns];
        vitalSigns.push(result.ReturnResult[i]);
        this.setState({ vitalSigns: vitalSigns });
      }
      this.setState({ orderSheet: orderSheet, appointmentData });
    }
    if (
      result.TimeLeftFromInput === 0 &&
      this.state.popupQueue[0] === "primum"
    ) {
      this.setState({ advanceUpdate: false }, () => this.closePopup());
    }
  }

  appendVitals(order) {
    let vitalSigns = [...this.state.vitalSigns];
    vitalSigns.push(order);
    this.setState({ vitalSigns: vitalSigns });
  }

  appendLocationOrders(orders) {
    let temp = [];
    let vitalSigns = [...this.state.vitalSigns];
    for (let i = 0; i < orders.length; i++) {
      let newOrder = orders[i].CANCELLED === null ? true : false;

      if (newOrder) {
        let order = Object.assign({}, orders[i]);
        order.orderId = order.ID;
        if (typeof order.ReportTime === "string") {
          let reportTime = new Date(`${order.ReportTime}Z`);
          if (order.ReportTime === "0001-01-01T00:00:00") {
            order.ReportTime = null;
          } else {
            order.ReportTime = {
              days: getDaysFromDate(reportTime),
              hours: reportTime.getUTCHours(),
              minutes: reportTime.getUTCMinutes(),
            };
          }
        }

        if (typeof order.TimeOrdered === "string") {
          let timeOrdered = new Date(`${order.TimeOrdered}Z`);
          order.TimeOrdered = {
            days: getDaysFromDate(timeOrdered),
            hours: timeOrdered.getUTCHours(),
            minutes: timeOrdered.getUTCMinutes(),
          };
        }

        if (typeof order.CurrentVirtualTime === "string") {
          let currentVirtualTime = new Date(`${order.CurrentVirtualTime}Z`);
          let timeOrdered = new Date(`${order.TimeOrdered}Z`);
          if (currentVirtualTime.getTime() === -62135579038000) {
            order.CurrentVirtualTime = {
              days: getDaysFromDate(timeOrdered),
              hours: timeOrdered.getUTCHours(),
              minutes: timeOrdered.getUTCMinutes(),
            };
          } else {
            order.CurrentVirtualTime = {
              days: getDaysFromDate(timeOrdered),
              hours: currentVirtualTime.getUTCHours(),
              minutes: currentVirtualTime.getUTCMinutes(),
            };
          }
        }
        if (orders[i].CANCELLED === null) {
          temp.push(order);
        }
        // add the on location change vitals to review chart
        if (order.ID === 1636) {
          let vitalOrder = JSON.parse(JSON.stringify(order));
          vitalOrder.ReportTime = vitalOrder.TimeOrdered;
          vitalSigns.push(vitalOrder);
        }
      }
    }

    this.setState({ orderSheet: temp, vitalSigns });
  }

  /*
	advance to only the next update whether that be a patient update or an order update
	*/
  advanceUpdate() {
    this.setState({ advanceUpdate: true }); // state mark to not continue advancing after update
    this.fetchAdvance(999 * 1440);
  }

  async fetchCancel(id) {
    this.setState({ cancelOrderOutstanding: true });
    await fetch(`${this.props.route}/cancel.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
        caseId: this.props.caseData.ID,
        orderToCancel: id,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        this.simulateNetworkLag();

        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);
        this.setState({ cancelOrderOutstanding: false });
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        this.setState({ cancelOrderOutstanding: false });
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchCancel"
        );
      });
  }

  /*
	first open a confirmation popup
	remove order from incompleteOrders
	call cancel.webapi
	*/
  async handleCancelOrder() {
    let orders = this.state.orderSheet;
    orders[this.state.orderIndexToDelete].CANCELLED = true;
    if (orders[this.state.orderIndexToDelete].orderId) {
      orders[this.state.orderIndexToDelete].ID =
        orders[this.state.orderIndexToDelete].orderId;
    } else if (orders[this.state.orderIndexToDelete].ID) {
      orders[this.state.orderIndexToDelete].orderId =
        orders[this.state.orderIndexToDelete].ID;
    }
    orders[this.state.orderIndexToDelete].FREQUENCY = null;
    orders[this.state.orderIndexToDelete].ReportTime =
      this.state.simulationTime;
    orders[this.state.orderIndexToDelete].RESULTNORMAL = "Order Canceled.";
    let result = {
      ReturnResult: [orders[this.state.orderIndexToDelete]],
    };
    await this.fetchCancel(orders[this.state.orderIndexToDelete].orderId);
    this.updateOrders(result);
    // call cancel.webapi

    this.setState({
      cancelOrder: false,
      orderSheet: orders,
    });
    this.closePopup();
  }

  /*
	set the state that advance is for physical
	call advance
	*/
  handleAdvancePhysical = async (time) => {
    // track physical time ordered
    let array = this.state.completedPhysicals;
    array.push({
      timeOrdered: {
        days: this.state.simulationTime.days,
        hours: this.state.simulationTime.hours,
        minutes: this.state.simulationTime.minutes,
      },
    });

    this.setState({
      completedPhysicals: array,
      advancePhysical: true, // used to track when to fetch Physical and when to auto advance vs prompt advance
    });

    await this.fetchAdvance(time);
    this.closePopup(); // closes the confirm physical popup this is just so that the time doesnt update before closing
  };

  async fetchVitals() {
    fetch(`${this.props.route}/vitals.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        let result = response;
        this.simulateNetworkLag();

        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        this.setState({ initialVitals: JSON.parse(result) });
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchVitals"
        );
      });
  }

  /*
	called when progress notes or vital sign is clicked
	reopen the popup that is specified
	*/
  reviewPopup(popup, index, orderIndex) {
    if (popup === "caseIntroduction") {
      this.setPopup("introduction");
    } else if (popup === "initialHistory") {
      this.setPopup("history");
    } else if (popup === "physicalResults") {
      this.setPopup("physicalResults");
      this.setState({
        reviewPhysical: index,
        physicalResults: this.state.completedPhysicals[index],
      });
    } else if (popup === "initialVitals") {
      this.setPopup("vitals");
    } else if (popup === "cancelOrder") {
      this.setPopup("cancelOrder");
      this.setState({ cancelOrder: index, orderIndexToDelete: orderIndex }); // store the order id that may be canceled, set to false on prompt close
    } else if (popup === "orderUpdate") {
      //order in review chart is clicked, popup resultnormal
      // orderPopup is now an array to allow for multiple updates at the same time
      let orderPopup = [...this.state.orderPopup];
      orderPopup.push(index);
      this.setState({ orderPopup: orderPopup }, () => {
        this.setPopup("orderUpdate", true);
      });
    } else if (popup === "patientUpdate") {
      this.setPopup("patientUpdate", true);
      this.setState({ advanceTimeResults: index });
    }
  }

  reviewOrderPopup = (order) => {
    let orderPopup = [...this.state.orderPopup];
    orderPopup.shift();
    orderPopup.push(order);
    let popupQueue = [...this.state.popupQueue];
    popupQueue.push("orderUpdate");
    this.setState({ orderPopup: orderPopup, popupQueue });
  };

  /*
	add popup to the popupQueue
	*/
  setPopup(popup, update, dontClose) {
    let popupQueue = this.state.popupQueue;
    let closed;
    if (!dontClose) {
      // this is so that appointmentUpdate doesnt close popups prematurely
      closed = popupQueue.shift();
    }
    popupQueue.push(popup);

    if (update) {
      // this is simply to avoid primum with Patient update click from Progress Notes
    } else if (
      /*else if (this.state.waitingForAppointment) {
			this.fetchWaitingForAppointment(this.state.waitingForAppointment.ApptTimeLeftInMin);
		}*/
      (popup === "patientUpdate" || popup === "orderUpdate") &&
      this.state.advanceUpdate
    ) {
      // dont continue advancing this is when with next available result is checked when advancing
      this.setState({ advanceUpdate: false });
    } else if (
      (popup === "patientUpdate" || popup === "orderUpdate") &&
      this.state.advanceTimeResults.TimeLeftFromInput &&
      !this.state.advancePhysical &&
      popupQueue.every((value) => value !== "primum")
    ) {
      popupQueue.push("primum");
    } else if (
      (closed === "patientUpdate" || closed === "orderUpdate") &&
      this.state.advancePhysical
    ) {
      if (this.state.popupQueue.includes("endCaseInstructions")) {
        this.fetchPhysical();
      } else {
        this.fetchAdvance(this.state.advanceTimeResults.TimeLeftFromInput);
      }
    }
    this.setState({ popupQueue: popupQueue });
  }

  /*
	closes any popup that might be on screen
	*/
  async closePopup() {
    let queue = this.state.popupQueue;
    let popup = queue.shift();

    if (queue[0] === "endCaseInstructions") {
      this.setCenterContent("reviewOrders");
      if (this.props.timedExam) {
        this.setState(
          {
            endCase: true,
            timeRemainingInSeconds: 120,
          },
          () => {
            clearInterval(this.secondsTimer);
            this.secondsTimer = null;
            this.secondsTimer = setInterval(
              () => this.decrementTimerBySecond(),
              1000
            );
          }
        );
      } else {
        this.setState({
          endCase: true,
        });
      }
    }
    // multiple updates
    if (popup === "orderUpdate") {
      // remove from orderPopup
      let orderPopup = this.state.orderPopup;
      orderPopup.shift();
      this.setState({ orderPopup });
    }

    if (
      (popup === "patientUpdate" || popup === "orderUpdate") &&
      this.state.advancePhysical
    ) {
      if (this.state.popupQueue.includes("endCaseInstructions")) {
        this.fetchPhysical();
      } else {
        this.fetchAdvance(this.state.advanceTimeResults.TimeLeftFromInput);
      }
    } else if (
      this.state.waitingForAppointment &&
      this.state.advanceTimeResults.TimeLeftFromInput === 0 &&
      !this.state.advanceTimeResults.CaseEnds
    ) {
      // console.log('Calling fetch waiting for appointment from close popup')
      await this.fetchWaitingForAppointment(0);
    }

    this.setState({
      popupQueue: queue,
      reviewPhysical: false,
      cancelOrder: false,
    });
  }

  /*
	run this function automatically when the component is created
	starts the real-time timer to tick every 60 seconds
	*/
  async componentDidMount() {
    // Check for completed orders. If this is not an incomplete case start, there will be no completed orders
    if (this.props.startCaseData.completedOrders.length !== 0) {
      let completedOrders = {
        ReturnResult: [...this.props.startCaseData.completedOrders],
        CustomerId: this.props.userData.CustomerId,
        PatientUpdate: null,
        TimeLeftFromInput: 0,
        TimeToNextUpdate: 0,
        caseguid: this.props.simulationData.caseguid,
        CurrentVirtualTime: this.state.simulationTime,
      };
      setTimeout(() => this.updateOrders(completedOrders), 100);
    }

    // Add a confirmation popup that will display to the user if they try to close the page or press the back button
    window.onbeforeunload = () => {
      return false;
    };

    // Check if the case is supposed to end. This will occur in an incomplete case start where the user
    // has reached the end of case screen but exited before reaching the grading page
    if (this.props.startCaseData.caseEnds) {
      if (this.props.timedExam) {
        this.setState(
          { endCase: true, timeRemainingInSeconds: 120 },
          async () => {
            this.setPopup("endCaseInstructions", false, true);
            await this.fetchPhysical();
            clearInterval(this.secondsTimer);
            this.secondsTimer = null;
            this.secondsTimer = setInterval(
              () => this.decrementTimerBySecond(),
              1000
            );
          }
        );
      } else {
        this.setState({ endCase: true }, () => {
          this.setPopup("endCaseInstructions", false, true);
        });
      }
    }

    let timeLimitInMinutes;
    if (this.props.timedExam) {
      if (this.props.customTimeLimit === "none") {
        timeLimitInMinutes = parseInt(this.props.caseData.TIMEMODEDESC) - 2;
      } else {
        timeLimitInMinutes = this.props.customTimeLimit - 2;
      }
    } else {
      timeLimitInMinutes = -1;
    }

    await this.fetchVitals();

    this.setState({
      timeLimitInMinutes: timeLimitInMinutes,
    });

    this.minutesTimer = setInterval(() => this.addRealTimeMinute(), 60000);
  }

  componentWillUnmount() {
    // Clear any running timers
    clearInterval(this.secondsTimer);
    this.secondsTimer = null;
    clearInterval(this.minutesTimer);
    this.minutesTimer = null;

    // Remove page leave warning popup
    window.onbeforeunload = () => {};
  }

  /*
	tick the real-time timer up one minute
	once the time limit is reached
	only time out if timed exam was checked
	*/
  addRealTimeMinute() {
    if (
      this.state.elapsedRealTime + 1 === this.state.timeLimitInMinutes &&
      this.props.timedExam &&
      !this.state.endCase
    ) {
      if (this.state.popupQueue.length === 0) {
        this.setCenterContent("reviewOrders");
      }

      if (this.props.timedExam) {
        this.setState(
          {
            endCase: true,
            timeRemainingInSeconds: 120,
          },
          () => {
            clearInterval(this.secondsTimer);
            this.secondsTimer = null;
            this.secondsTimer = setInterval(
              () => this.decrementTimerBySecond(),
              1000
            );
          }
        );
      } else {
        this.setState({ endCase: true });
      }

      if (this.state.popupQueue.length > 0) {
        let popupQueue = JSON.parse(JSON.stringify(this.state.popupQueue));
        this.setState({ popupQueue: popupQueue.slice(-1) }, () => {
          this.setPopup("endCaseInstructions", false, true);
          this.closePopup();
        });
      } else {
        this.setPopup("endCaseInstructions", false, true);
      }
      this.setState({ waitingForAppointment: false });
    }
    this.setState({ elapsedRealTime: this.state.elapsedRealTime + 1 });
  }

  /*
	called every minute once at case end screen to end the case after 2 minutes
	*/
  decrementTimerBySecond() {
    if (this.state.timeRemainingInSeconds - 1 === 0) {
      //TODO display popup on close fetch grading
      this.props.fetchGrading(this.props.caseData.ReviewLaterFlag);
    }
    this.setState({
      timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1,
    });
  }

  /*
	change the contend displayed in the center of the screen, not a popup
	possible values of content: physicalOptions, reviewOrders
	*/
  setCenterContent(content) {
    if (!this.state.popup) {
      this.setState({ centerContent: content });
    }
  }

  /*
	instead of seperate state variable for each checkbox create dictionary
	checkbox is just a string to specify location in dictionary
	*/
  physicalCheckboxToggle(checkbox) {
    let temp = this.state.physicalCheckboxes;
    temp[checkbox] = !temp[checkbox];
    this.setState({ physicalCheckboxes: temp });
  }

  /*
	sets all of the physical checkboxes to false
	*/
  physicalCheckboxesClear() {
    this.setState({
      physicalCheckboxes: {
        interval: false,
        appearance: false,
        skin: false,
        breasts: false,
        lymph: false,
        neck: false,
        chest: false,
        heart: false,
        abdomen: false,
        genitalia: false,
        rectal: false,
        spine: false,
        neuro: false,
      },
    });
  }

  /*
	advance the time based on the reevaluate case selection
	this is called on physicals, aswell as manual advancement
	*/
  fetchAdvance = async (minutes) => {
    this.setState({ fetchAdvanceLoading: true });
    await fetch(`${this.props.route}/advance.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseId: this.props.caseData.ID,
        caseguid: this.props.simulationData.caseguid,
        advanceTimeMin: minutes,
      }),
    })
      .then((response) => {
        this.setState({ fetchAdvanceLoading: false });
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then(async (response) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        let result = response;
        this.simulateNetworkLag();
        result = JSON.parse(result);
        result.CurrentVirtualTime = new Date(`${result.CurrentVirtualTime}Z`);

        result.CurrentVirtualTime = {
          days: getDaysFromDate(result.CurrentVirtualTime),
          hours: result.CurrentVirtualTime.getUTCHours(),
          minutes: result.CurrentVirtualTime.getUTCMinutes(),
        };

        if (result.CaseEnds) {
          result.TimeLeftFromInput = 0;
        }
        if (!result.TimeLeftFromInput) {
          result.TimeLeftFromInput = 0;
        }
        // console.log(result)
        // update the current simulation time
        this.setState({
          simulationTime: {
            days: result.CurrentVirtualTime.days,
            hours: result.CurrentVirtualTime.hours,
            minutes: result.CurrentVirtualTime.minutes,
          },
          advanceTimeResults: result,
        });

        let orderUpdate = false; // used to not fetch physical when physical and order take same amount of time
        this.updateOrders(result);

        // display order update
        if (result.ReturnResult.length !== 0) {
          orderUpdate = true;
        }

        // check to see if there are any orders in ordersheet that need to be move automatically on advance
        for (let i = 0; i < this.state.orderSheet.length; i++) {
          if (
            this.state.orderSheet[i].ReportTime === null &&
            this.state.orderSheet[i].FREQUENCY === "One Time/Bolus"
          ) {
            // the order is completed immediately when the time is advanced
            let orderSheet = this.state.orderSheet;
            let treatmentRecord = this.state.treatmentRecord;
            treatmentRecord.push(orderSheet.splice(i, 1)[0]);
            this.setState({
              treatmentRecord: treatmentRecord,
              orderSheet: orderSheet,
            });
          }
        }

        if (result.PatientUpdate) {
          // add result to an array of patient updates that will be listed under Progress Notes
          let patientUpdates = this.state.patientUpdates;
          patientUpdates.push(result);
          this.setState({ patientUpdates: patientUpdates });
          if (result.ReturnResult.length === 0) {
            this.setPopup(
              "patientUpdate",
              this.state.advanceUpdate ? true : false,
              false
            );
          } else {
            this.setPopup("patientUpdate", false, true);
          }
          orderUpdate = true;
        }

        // end case also need to end case once real time limit is reached
        if (result.CaseEnds) {
          this.setState(
            {
              popupQueue: [
                this.state.popupQueue[this.state.popupQueue.length - 1],
              ],
            },
            () => {
              this.setPopup("endCaseInstructions", false, true);
            }
          );
        }

        if (
          this.state.advancePhysical &&
          !result.TimeLeftFromInput &&
          !orderUpdate
        ) {
          await this.fetchPhysical();
        } else if (
          result.ReturnResult.length === 0 &&
          !result.PatientUpdate &&
          !result.CaseEnds &&
          !this.state.waitingForAppointment
        ) {
          // console.log('calling new closePopup change')
          this.closePopup();
        }
      })
      .catch((error) => {
        this.setState({ fetchAdvanceLoading: false });
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchAdvance"
        );
        console.log("error", error);
      });
  };

  /*
	fetch physical and set the popup to physicalResults
	*/
  fetchPhysical = async () => {
    await fetch(`${this.props.route}/physical.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
        caseId: this.props.caseData.ID,
        generalapprearance: +this.state.physicalCheckboxes.appearance,
        skin: +this.state.physicalCheckboxes.skin,
        breast: +this.state.physicalCheckboxes.breasts,
        lymphnode: +this.state.physicalCheckboxes.lymph,
        heentneck: +this.state.physicalCheckboxes.neck,
        chestlung: +this.state.physicalCheckboxes.chest,
        cardiovascular: +this.state.physicalCheckboxes.heart,
        abdominal: +this.state.physicalCheckboxes.abdomen,
        genital: +this.state.physicalCheckboxes.genitalia,
        rectal: +this.state.physicalCheckboxes.rectal,
        extremitiesspine: +this.state.physicalCheckboxes.spine,
        neurologicpsychologic: +this.state.physicalCheckboxes.neuro,
        patientUpdate: +this.state.physicalCheckboxes.interval,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          this.props.setAuthenticationError();
          throw new Error("Authentication Error");
        } else {
          return response.text();
        }
      })
      .then((response) => {
        //Attempt sending logs
        fetchSubmitLogs(this.props.userProfile, this.props.userData);

        let result = response;
        this.simulateNetworkLag();
        result = JSON.parse(result);
        // track time reported and create a physical array
        let array = this.state.completedPhysicals;
        if (array[array.length - 1].timeReported) {
          array[array.length - 1].timeReported = {
            days: this.state.simulationTime.days,
            hours: this.state.simulationTime.hours,
            minutes: this.state.simulationTime.minutes,
          };
        }

        let reportTime = new Date(`${result.ReportTime}Z`);
        result.timeReported = {
          days: getDaysFromDate(reportTime),
          hours: reportTime.getUTCHours(),
          minutes: reportTime.getUTCMinutes(),
        };

        array[array.length - 1].index = array.length - 1;

        // add the results to the array object that already contains the times
        Object.assign(array[array.length - 1], result);

        let physicalCheckboxes = {
          interval: false,
          appearance: false,
          skin: false,
          breasts: false,
          lymph: false,
          neck: false,
          chest: false,
          heart: false,
          abdomen: false,
          genitalia: false,
          rectal: false,
          spine: false,
          neuro: false,
        };
        this.setState({
          advancePhysical: false,
          completedPhysicals: array,
          physicalAdvance: false,
          physicalResults: result,
          physicalCheckboxes,
        });
        this.setPopup("physicalResults", false, true);
      })
      .catch((error) => {
        // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
        // 	this.props.setAuthenticationError()
        // }
        console.log("error", error);
        logFetchError(
          error,
          this.props.userProfile,
          this.props.userData,
          "fetchPhysical"
        );
      });
  };

  /*
	orderTime is just for the end case screen where the user can select a later time for order
	*/
  async fetchValidate(
    orderId,
    frequency,
    route,
    orderTime,
    orderPopupCallback,
    stopSpinnerCallback
  ) {
    let result;
    if (!this.props.fetchOutstanding) {
      //frequency can be either a string or minutes int
      if (!route) {
        route = null;
      }
      await fetch(`${this.props.route}/validate.webapi`, {
        method: "POST",
        headers: {
          Token: this.props.userData.Token,
          "Content-Type": "text/plain",
        },
        body: JSON.stringify({
          customerId: this.props.userData.CustomerId,
          caseguid: this.props.simulationData.caseguid,
          caseId: this.props.caseData.ID,
          orderId: orderId,
          route: route,
          frequency: frequency,
        }),
      })
        .then((response) => {
          if (response.status === 401) {
            this.props.setAuthenticationError();
            throw new Error("Authentication Error");
          } else {
            return response.text();
          }
        })
        .then((response) => {
          result = response;
          this.simulateNetworkLag();
          if (result.includes("EXCEPTION_ERROR:")) {
            result = result.replace("{EXCEPTION_ERROR: ", "");
            result = result.replace("}", "");
            return orderPopupCallback("orderError", result);
          }

          //Attempt sending logs
          fetchSubmitLogs(this.props.userProfile, this.props.userData);

          let order = JSON.parse(result);

          if (order.PrerequisiteOut) {
            order.ID = orderId;
            return this.prereqHandler(order, orderPopupCallback);
          }
          let orderDate = new Date(`${order.CurrentVirtualTime}Z`);
          order.CurrentVirtualTime = {
            days: getDaysFromDate(orderDate),
            hours: orderDate.getUTCHours(),
            minutes: orderDate.getUTCMinutes(),
          };

          let date = new Date(`${order.ReportTime}Z`);
          if (orderTime) {
            if (order.ReportTime) {
              // set the report time to orderTime + difference between current and report time
              order.ReportTime = {
                days:
                  orderTime.days +
                  (getDaysFromDate(date) - getDaysFromDate(orderDate)),
                hours:
                  orderTime.hours +
                  (date.getUTCHours() - orderDate.getUTCHours()),
                minutes:
                  orderTime.minutes +
                  (date.getUTCMinutes() - orderDate.getUTCMinutes()),
              };
              if (order.ReportTime.minutes > 59) {
                order.ReportTime.minutes -= 60;
                order.ReportTime.hours += 1;
              }
              if (order.ReportTime.hours > 23) {
                order.ReportTime.hours -= 24;
                order.ReportTime.days += 1;
              }
            }
          } else {
            if (order.ReportTime) {
              order.ReportTime = {
                days: getDaysFromDate(date),
                hours: date.getUTCHours(),
                minutes: date.getUTCMinutes(),
              };
              if (
                order.CurrentVirtualTime.days === order.ReportTime.days &&
                order.CurrentVirtualTime.hours === order.ReportTime.hours &&
                order.CurrentVirtualTime.minutes === order.ReportTime.minutes
              ) {
                order.ReportTime = null;
              }
            }
          }

          if (order.FREQUENCY && !isNaN(Number(order.FREQUENCY))) {
            order.FREQUENCY = "q" + Number(order.FREQUENCY) / 60 + "h";
          }

          // Consulation Popup
          if (order.CONSULTDIALOG) {
            // let popupQueue = [...this.state.popupQueue];
            // popupQueue.push('consultationReason')
            // this.setState({popupQueue: popupQueue});
            orderPopupCallback("consultation");
          } else {
            orderPopupCallback("");
          }
          let temp = this.state.orderSheet;
          temp.push(order);
          this.setState({ orderSheet: temp });
          //this.closePopup();
        })
        .catch((error) => {
          stopSpinnerCallback();
          // if (error.toString().includes('SyntaxError: Unexpected token U in JSON at position 1') || error.toString().includes('SyntaxError: JSON Parse error: Expected \'}\'')) {
          // 	this.props.setAuthenticationError()
          // }
          console.log("error", error);
          logFetchError(
            error + ", Initial Response: " + result,
            this.props.userProfile,
            this.props.userData,
            "fetchValidate"
          );
        });
    }
  }

  fetchPrerequisiteOrder = async (
    prereqOrderId,
    initialOrderId,
    callbackFunction
  ) => {
    await fetch(`${this.props.route}/prerequisite.webapi`, {
      method: "POST",
      headers: {
        Token: this.props.userData.Token,
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        customerId: this.props.userData.CustomerId,
        caseguid: this.props.simulationData.caseguid,
        caseId: this.props.caseData.ID,
        orders: `${initialOrderId}, ${prereqOrderId}`,
      }),
    }).then((res) => {
      if (res.status === 401) {
        this.props.setAuthenticationError();
        throw new Error("Authentication Error");
      } else {
        const decoder = new TextDecoder("utf-8");
        let result = "";
        let reader = res.body.getReader();
        reader
          .read()
          .then(function processText({ done, value }) {
            if (done) {
              return JSON.parse(result);
            }

            result += decoder.decode(value);

            // Read some more, and call this function again
            return reader.read().then(processText);
          })
          .then((res) => {
            //Attempt sending logs
            fetchSubmitLogs(this.props.userProfile, this.props.userData);

            let temp = JSON.parse(JSON.stringify(res));
            let orderSheet = this.state.orderSheet
              ? [...this.state.orderSheet]
              : [];
            res.forEach((order, orderIndex) => {
              let orderTime = new Date(`${order.CurrentVirtualTime}Z`);
              let reportTime = new Date(`${order.ReportTime}Z`);

              temp[orderIndex].CurrentVirtualTime = {
                days: getDaysFromDate(orderTime),
                hours: orderTime.getUTCHours(),
                minutes: orderTime.getUTCMinutes(),
              };

              temp[orderIndex].ReportTime = {
                days: getDaysFromDate(reportTime),
                hours: reportTime.getUTCHours(),
                minutes: reportTime.getUTCMinutes(),
              };
              orderSheet.push(temp[orderIndex]);
            });
            this.setState({ orderSheet }, () => callbackFunction());
          })
          .catch((error) => {
            console.log(error);
            logFetchError(
              error,
              this.props.userProfile,
              this.props.userData,
              "fetchPrerequisiteOrder"
            );
          });
      }
    });
  };

  prereqHandler = (order, orderPopupCallback) => {
    this.setState({ prereqOptions: order.PrerequisiteOut });
    orderPopupCallback("prereqsRequired", order);
  };

  changeFontHandler = () => {
    document.documentElement.style.setProperty(
      "--primary-font-size",
      `${16 + this.state.fontSize * 2}px`
    );
    document.documentElement.style.setProperty(
      "--secondary-font-size",
      `${14 + this.state.fontSize * 2}px`
    );
    if (this.state.fontSize === 2) {
      this.setState({ fontSize: 0 });
    } else {
      this.setState({ fontSize: this.state.fontSize + 1 });
    }
  };

  reverseColorHandler = () => {
    if (this.state.darkMode) {
      document.documentElement.style.setProperty("--primary-color", "#FCFCFC");
      document.documentElement.style.setProperty("--accent-color", "#e6e6e6");
      document.documentElement.style.setProperty(
        "--button-background-color",
        "white"
      );
      document.documentElement.style.setProperty("--off-color", "#c8c8c8");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#CAD6DF"
      );
      document.documentElement.style.setProperty("--hover-color", "#9ebac0");
      document.documentElement.style.setProperty(
        "--selected-button-color",
        "#B7C3CC"
      );
      document.documentElement.style.setProperty(
        "--header-font-color",
        "#0B335D"
      );
      document.documentElement.style.setProperty(
        "--primary-font-color",
        "black"
      );
      document.documentElement.style.setProperty("--border-color", "black");
      document.documentElement.style.setProperty(
        "--gradient",
        "linear-gradient(to bottom, #eee 0%, #ccc 100%)"
      );
      document.documentElement.style.setProperty(
        "--reverse-gradient",
        "linear-gradient(to top, #eee 0%, #ccc 100%)"
      );
    } else {
      document.documentElement.style.setProperty("--primary-color", "#000000");
      document.documentElement.style.setProperty("--accent-color", "#141414");
      document.documentElement.style.setProperty(
        "--button-background-color",
        "#575757"
      );
      document.documentElement.style.setProperty("--off-color", "#505050");
      document.documentElement.style.setProperty(
        "--secondary-color",
        "#292B2D"
      );
      document.documentElement.style.setProperty("--hover-color", "#266070");
      document.documentElement.style.setProperty(
        "--selected-button-color",
        "#266070"
      );
      document.documentElement.style.setProperty(
        "--header-font-color",
        "white"
      );
      document.documentElement.style.setProperty(
        "--primary-font-color",
        "white"
      );
      document.documentElement.style.setProperty("--border-color", "white");
      document.documentElement.style.setProperty(
        "--gradient",
        "linear-gradient(to bottom, rgb(46, 46, 46) 0%, rgb(68, 68, 68) 100%)"
      );
      document.documentElement.style.setProperty(
        "--reverse-gradient",
        "linear-gradient(to top, rgb(46, 46, 46) 0%, rgb(68, 68, 68) 100%)"
      );
    }
    this.setState({ darkMode: !this.state.darkMode });
  };

  render() {
    let locationImage;
    if (this.props.simulationData.CaseLocationDesc === "Emergency Department") {
      locationImage = ambulance;
    } else if (this.props.simulationData.CaseLocationDesc === "Office") {
      locationImage = office;
    } else if (
      this.props.simulationData.CaseLocationDesc === "Intensive Care Unit"
    ) {
      locationImage = care;
    } else if (
      this.props.simulationData.CaseLocationDesc === "Inpatient Unit"
    ) {
      locationImage = unit;
    } else if (this.props.simulationData.CaseLocationDesc === "Home") {
      locationImage = home;
    }

    let popup;
    if (this.state.popupQueue[0] === "introduction") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Case Introduction</p>
            <div className="popup-content">
              <div className="popup-element centered">
                <SimulationTime
                  simulationTime={this.props.simulationData.InitialStartTime}
                />
              </div>
              <div className="popup-element centered">
                {this.props.simulationData.CaseLocationDesc}
              </div>
              <div className="popup-element">
                {this.props.simulationData.INTRODUCTION}
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "vitals") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Initial Vital Signs</p>
            <div className="popup-content">
              <div className="popup-element centered">
                <SimulationTime
                  simulationTime={this.props.simulationData.InitialStartTime}
                />
              </div>
              {this.state.initialVitals && (
                <div className="popup-element">
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Temperature: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.TEMPERATURE}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Pulse: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.PULSE}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Respiratory rate: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.RESPIRATORYRATE}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">
                      {"Blood pressure, systolic: "}
                    </p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.BPSYSTOLIC}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">
                      {"Blood pressure, diastolic: "}
                    </p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.BPDIASTOLIC}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Height: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.HEIGHT}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Weight: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.WEIGHT}
                    </p>
                  </div>
                  <div className="popup-vital-line">
                    <p className="popup-vital">{"Body Mass Index: "}</p>
                    <p className="popup-vital-text">
                      {this.state.initialVitals.BMINDEX}
                    </p>
                  </div>
                </div>
              )}
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "history") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Initial History</p>
            <div className="popup-content">
              <div className="popup-element">
                <div>
                  {"Reason(s) for visit: " +
                    this.props.simulationData.InitialReason}
                </div>
              </div>
              <div className="popup-element">
                <div>History of present Illness:</div>
                <div className="popup-history">
                  {this.props.simulationData.HISTORY}
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "confirmPhysical") {
      popup = (
        <ConfirmPhysicalPopup
          physicalCheckboxes={this.state.physicalCheckboxes}
          simulationTime={this.state.simulationTime}
          closePopup={this.closePopup}
          advancePhysical={this.handleAdvancePhysical}
          primaryColor={this.props.primaryColor}
        />
      );
    } else if (this.state.popupQueue[0] === "physicalResults") {
      popup = (
        <PhysicalResults
          simulationTime={this.state.simulationTime}
          physicalResults={this.state.physicalResults}
          closePopup={this.closePopup}
          completedPhysicals={this.state.completedPhysicals}
          reviewPhysical={this.state.reviewPhysical}
        />
      );
    } else if (this.state.popupQueue[0] === "patientUpdate") {
      const GetPatientUpdate = () => {
        let update = this.state.advanceTimeResults.PatientUpdate;
        let filteredUpdate = "";
        if (update) {
          for (let i = 0; i < update.length; ++i) {
            if (update[i] !== "{" && update[i] !== "}") {
              filteredUpdate += update[i];
            }
          }
        }

        return <p>{filteredUpdate}</p>;
      };

      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Patient Update</p>
            <div className="popup-content">
              <div className="popup-element centered">
                <SimulationTime
                  simulationTime={
                    this.state.advanceTimeResults.CurrentVirtualTime
                  }
                />
              </div>
              <div className="popup-element">
                <div className="physical-result-header">PATIENT UPDATE</div>
                <GetPatientUpdate />
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "primum") {
      // popup prompt to continue advanceing or stop
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Primum CCS</p>
            <div className="popup-content">
              <div className="popup-element">
                <div className="simulation-time white-space">
                  {"Current time is: "}
                  <SimulationTime simulationTime={this.state.simulationTime} />
                </div>
                <div>{"Continue clock advance?"}</div>
              </div>
            </div>
            {/* {true ? */}
            {this.state.fetchAdvanceLoading ? (
              <div className="options-footer-loading-container">
                <MDSpinner size={25} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <div className="options-footer">
                <input
                  className="simulation-button button-gap"
                  type="button"
                  value="Stop Now"
                  onClick={() => {
                    this.setState({ advanceUpdate: false }, () =>
                      this.closePopup()
                    );
                  }}
                />
                <input
                  className="simulation-button"
                  type="button"
                  value="Continue"
                  onClick={() =>
                    this.fetchAdvance(
                      this.state.advanceTimeResults.TimeLeftFromInput
                    )
                  }
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "orders") {
      popup = (
        <PopupsComponent
          closePopup={this.closePopup}
          userData={this.props.userData}
          userProfile={this.props.userProfile}
          simulationData={this.props.simulationData}
          fetchValidate={this.fetchValidate}
          endCase={this.state.endCase}
          simulationTime={this.state.simulationTime}
          simulateNetworkLag={this.simulateNetworkLag}
          orderSheet={this.state.orderSheet}
          setAuthenticationError={this.props.setAuthenticationError}
          prereqOptions={this.state.prereqOptions}
          fetchPrerequisiteOrder={this.fetchPrerequisiteOrder}
          route={this.props.route}
          fetchOutstanding={this.props.fetchOutstanding}
          primaryColor={this.props.primaryColor}
        />
      );
    } else if (this.state.popupQueue[0] === "reevaluate") {
      popup = (
        <Reevaluate
          simulationTime={this.state.simulationTime}
          fetchAdvance={this.fetchAdvance}
          closePopup={this.closePopup}
          advanceUpdate={this.advanceUpdate}
          primaryColor={this.props.primaryColor}
        />
      );
    } else if (this.state.popupQueue[0] === "location") {
      popup = (
        <LocationPopup
          closePopup={this.closePopup}
          userData={this.props.userData}
          userProfile={this.props.userProfile}
          simulationData={this.props.simulationData}
          caseData={this.props.caseData}
          simulationTime={this.state.simulationTime}
          orderSheet={this.state.orderSheet}
          appendLocationOrders={this.appendLocationOrders}
          updateLocation={this.props.updateLocation}
          reviewPopup={this.reviewPopup}
          cancelLocationOrders={this.cancelLocationOrders}
          appendVitals={this.appendVitals}
          fetchWaitingForAppointment={this.fetchWaitingForAppointment}
          trackAppointmentData={this.trackAppointmentData}
          simulateNetworkLag={this.simulateNetworkLag}
          setAuthenticationError={this.props.setAuthenticationError}
          route={this.props.route}
          primaryColor={this.props.primaryColor}
        />
      );
    } else if (this.state.popupQueue[0] === "orderUpdate") {
      const formatTime = (time) => {
        let date = new Date(time);
        if (typeof time === "string") {
          return {
            days: date.getDate(),
            hours: date.getHours(),
            minutes: date.getMinutes(),
          };
        } else {
          return time;
        }
      };

      const getTime = () => {
        if (this.state.orderPopup[0].LocationOrder) {
          return formatTime(this.state.orderPopup[0].CurrentVirtualTime);
        } else {
          return formatTime(this.state.orderPopup[0].ReportTime);
        }
      };

      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">{this.state.orderPopup[0].NAME}</p>
            <div className="popup-content">
              <div className="popup-element centered">
                {/* <SimulationTime simulationTime={formatTime(this.state.orderPopup[0].CurrentVirtualTime)}/> */}
                <SimulationTime simulationTime={getTime()} />
              </div>
              <div className="popup-element popup-history">
                {this.state.orderPopup[0].RESULTNORMAL}
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "cancelOrder") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Warning</p>
            <div className="popup-content">
              <div className="popup-element">
                {"Cancel " + this.state.cancelOrder.NAME + "?"}
              </div>
            </div>
            {this.state.cancelOrderOutstanding ? (
              <div className="options-footer-loading-container">
                <MDSpinner size={25} singleColor={this.props.primaryColor} />
              </div>
            ) : (
              <div className="options-footer">
                <input
                  className="simulation-button button-gap"
                  type="button"
                  value="Yes"
                  onClick={this.handleCancelOrder}
                />
                <input
                  className="simulation-button"
                  type="button"
                  value="No"
                  onClick={this.closePopup}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "appointmentUpdate") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Patient Update</p>
            <div className="popup-content">
              <div className="popup-element">
                {this.state.appointmentUpdate.ArrivedToAppointment}
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "endCaseInstructions") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Case-end Instructions</p>
            <div className="popup-content">
              <div className="popup-element centered bold">
                {this.props.timedExam
                  ? 'This case will end in 2 minutes of "real time".'
                  : "The case is ending."}
              </div>
              <div className="popup-element">
                {
                  'On the next screens, finalize care of the patient\'s "current" condition by:'
                }
              </div>
              <div className="popup-element">
                <ul className="bold">
                  <li>{"deleting orders you want canceled now."}</li>
                  <li>{"adding orders to be done now."}</li>
                  <li>
                    {
                      'adding orders relevant to the patient\'s "current" condition to be done in the future.'
                    }
                  </li>
                </ul>
              </div>
              <div className="popup-element centered">
                {"Then confirm orders"}
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "confirmExitCase") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Confirm Exit</p>
            <div className="popup-content">
              <div className="popup-element">
                <div>
                  {
                    'Are you sure you would like to exit this case? Please note that using "Exit case" will prematurely close the case, and the case will not be graded. Your progress will be saved so that the case can be completed at a later time. If you would like to complete the case, you will need to advance the clock until the case ends.'
                  }
                </div>
              </div>
            </div>
            <div className="options-footer">
              <input
                className="simulation-button button-gap"
                type="button"
                value="Yes"
                onClick={this.props.closeGrading}
              />
              <input
                className="simulation-button"
                type="button"
                value="No"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    } else if (this.state.popupQueue[0] === "timeoutWarning") {
      popup = (
        <div className="popup-blocker">
          <div className="popup-backdrop" />
          <div className="information-popup">
            <p className="popup-header">Warning</p>
            <div className="popup-content">
              <div className="popup-element">
                <div>
                  {
                    "You will be logged out in 5 minutes if you remain inactive."
                  }
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <input
                className="simulation-button"
                type="button"
                value="OK"
                onClick={this.closePopup}
              />
            </div>
          </div>
        </div>
      );
    }

    let centerContent;
    if (this.state.centerContent === "physicalOptions") {
      centerContent = (
        <PhysicalOptions
          physicalCheckboxes={this.state.physicalCheckboxes}
          physicalCheckboxToggle={this.physicalCheckboxToggle}
          physicalCheckboxesClear={this.physicalCheckboxesClear}
          setPopup={this.setPopup}
        />
      );
    } else if (this.state.centerContent === "reviewOrders") {
      centerContent = (
        <ReviewOrders
          setPopup={this.setPopup}
          startTime={this.props.simulationData.InitialStartTime}
          reviewPopup={this.reviewPopup}
          reviewOrderPopup={this.reviewOrderPopup}
          completedPhysicals={this.state.completedPhysicals}
          orderSheet={this.state.orderSheet}
          progressNotes={this.state.progressNotes}
          vitalSigns={this.state.vitalSigns}
          labReports={this.state.labReports}
          imaging={this.state.imaging}
          otherTests={this.state.otherTests}
          treatmentRecord={this.state.treatmentRecord}
          patientUpdates={this.state.patientUpdates}
          endCase={this.state.endCase}
          fetchGrading={this.props.fetchGrading}
          caseData={this.props.caseData}
          orderPopup={this.state.orderPopup}
          primaryColor={this.props.primaryColor}
        />
      );
    }

    let simulationOptions;
    if (!this.state.endCase) {
      simulationOptions = (
        <div className="simulation-options">
          <div className="simulation-options-group">
            <div
              className={`simulation-option ${
                this.state.simulationOptionSelected === 0 &&
                "simulation-option-selected"
              }`}
              onClick={() => {
                this.setState({ simulationOptionSelected: 0 });
                this.setCenterContent("physicalOptions");
              }}
            >
              <img src={hx} alt="hx" />
              <p className="simulation-option-main">Interval Hx or PE</p>
            </div>
            <div
              className={`simulation-option ${
                this.state.simulationOptionSelected === 1 &&
                "simulation-option-selected"
              }`}
              onClick={() => {
                this.setState({ simulationOptionSelected: 1 });
                this.setCenterContent("reviewOrders");
              }}
            >
              <img src={order} alt="order" />
              <p className="simulation-option-main">
                Write Orders or Review Chart
              </p>
            </div>
          </div>
          <div className="simulation-options-group">
            <div
              className="simulation-option"
              onClick={() => this.setPopup("reevaluate")}
            >
              <img src={watch} alt="watch" />
              <div className="simulation-option-main">
                <p>Obtain Results or See Patient Later</p>
                <SimulationTime
                  simulationTime={this.state.simulationTime}
                  dayWeek={true}
                />
              </div>
            </div>
            <div
              className="simulation-option"
              onClick={() => this.setPopup("location")}
            >
              <img src={locationImage} alt="location" />
              <div className="simulation-option-main">
                <p>Change Location</p>
                <div>{this.props.simulationData.CaseLocationDesc}</div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let timeLimitDisplay;
    if (this.props.timedExam) {
      timeLimitDisplay = this.state.timeLimitInMinutes + " minutes";
    } else {
      timeLimitDisplay = "No Limit";
    }

    let countDownUIElement = (
      <p className="simulation-option-main">
        {"Elapsed REAL time = " + this.state.elapsedRealTime + " Mins"}
      </p>
    );
    if (this.state.endCase) {
      let mins = (this.state.timeRemainingInSeconds / 60) | 0;
      let secs = this.state.timeRemainingInSeconds - mins * 60;
      if (this.props.timedExam) {
        countDownUIElement = (
          <p className="simulation-option-main">
            {"Remaining REAL time = " + mins + " Mins " + secs + " Secs"}
          </p>
        );
      } else {
        countDownUIElement = <p className="simulation-option-main"></p>;
      }
    }

    let networkLag;
    if (this.state.simulateNetworkLag) {
      networkLag = (
        <div className="network-lag">
          <div className="network-lag-popup">
            <img src={lag} alt="loading" />
          </div>
        </div>
      );
    }

    return (
      <div className="simulation">
        {networkLag}
        <div className="simulation-top-bar">
          <div className="time-title">
            {this.props.timedExam
              ? "Maximum allotted real time: " +
                timeLimitDisplay +
                " + 2 minutes for case-end orders"
              : "Maximum allotted real time: Untimed Exam"}
          </div>
          <div className="simulation-end-button">
            <input
              style={{ marginRight: "0.5em" }}
              className="simulation-button"
              type="button"
              value="Change Font Size"
              onClick={this.changeFontHandler}
            />
            <input
              style={{ marginRight: "0.5em" }}
              className="simulation-button"
              type="button"
              value="Reverse Color"
              onClick={this.reverseColorHandler}
            />
            <input
              className="simulation-button"
              type="button"
              value="Exit Case"
              onClick={this.setPopup.bind(this, "confirmExitCase")}
            />
          </div>
        </div>
        {simulationOptions}
        <div className="simulation-center-content">{centerContent}</div>
        <div
          className={`simulation-popup ${
            this.state.popupQueue[0] === undefined
              ? "simulation-popup-hidden"
              : ""
          }`}
        >
          {popup}
        </div>
        <div className="simulation-time-bar">
          <div>
            <SimulationTime
              simulationTime={this.state.simulationTime}
              startTime={this.props.simulationData.InitialStartTime}
            />
          </div>
          {countDownUIElement}
        </div>
      </div>
    );
  }
}

export default Simulation;
