import React from 'react';

class OrderSelect extends React.Component {

	render() {

		let style = 'table-row';
		if (this.props.selectedOrder) {
			style += ' selected';
		}

		return (
			<div className={`order-select-text ${style}`} onClick={this.props.orderSelectClick.bind(this, this.props.data)}>
				<div>{this.props.data.original}</div>
			</div>
		)
	}
}

export default OrderSelect;
