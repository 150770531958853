import React, { useState } from "react";
import fetchSubmitLogs from "../../Functions/FetchSubmitLogs";
import logFetchError from "../../Functions/LogFetchError";
import MDSpinner from "react-md-spinner";

const CCSCasesLoginForm = ({
  route,
  handleLogin,
  showForgotPassword,
  successMessage,
  primaryColor,
  showDemoSignup,
  showTOS,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [errorText, setErrorText] = useState("");

  const fetchLogin = (e) => {
    e.preventDefault();
    setFetchOutstanding(true);
    fetch(`${route}/login.webapi`, {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        userName: email,
        password: password,
      }),
    })
      .then((response) => response.text())
      .then((result) => {
        setFetchOutstanding(false);

        //Attempt sending logs
        fetchSubmitLogs(null, null);

        if (result.includes("Token")) {
          let parsedResult = JSON.parse(result);
          let stateData = {
            userData: parsedResult,
            userProfile: parsedResult,
            hideCasePopup: !parsedResult.DontShowHideCasePopup,
            selectedTheme: Number(parsedResult.Theme),
          };
          handleLogin(stateData);
        } else {
          result = result.replace("{", "");
          result = result.replace("}", "");
          setErrorText(result);
          setPassword("");
        }
      })
      .catch((error) => {
        setFetchOutstanding(false);
        setErrorText(error);
        logFetchError(error, null, null, "fetchLoginToken");
      });
  };

  const handleForgotPasswordClick = () => {
    showForgotPassword();
  };

  return (
    <form className="loginContainer" onSubmit={fetchLogin} key="loginContainer">
      <h1 className="loginHeader">Login</h1>
      <label className="loginLabel">Email</label>
      <input
        type="text"
        autoComplete="email"
        className="loginInput"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <label className="loginLabel">Password</label>
      <input
        type="password"
        autoComplete="password"
        className="loginInput"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <a
        className="forgotPasswordText"
        type="button"
        onClick={handleForgotPasswordClick}
      >
        Forgot Password?
      </a>
      {!fetchOutstanding ? (
        <div className="loginButtonWrapper">
          <button
            className="loginButton"
            type="button"
            onClick={showDemoSignup}
          >
            Demo Signup
          </button>
          <button className="loginButton loginPrimaryButton" type="submit">
            Login
          </button>
        </div>
      ) : (
        <div className="loginButtonLoaderWrapper">
          <MDSpinner size={35} singleColor={primaryColor} />
        </div>
      )}
      <a className="loginTermsOfServiceText" type="button" onClick={showTOS}>
        Terms of Service
      </a>
      {errorText !== "" && <p className="loginErrorText">{errorText}</p>}
      {successMessage !== "" && (
        <p className="loginSuccessText">{successMessage}</p>
      )}
    </form>
  );
};

export default CCSCasesLoginForm;
