import React, { useContext, useMemo } from "react";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import OverviewCaseCard from "./OverviewCaseCard/OverviewCaseCard";
import { ExamRulesContext } from "../../ExamRulesPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const SelectedCasesSubContainer = () => {
  const { selectedCaseList, setSelectedCaseList, setPageDisplayed } =
    useContext(ExamRulesContext);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const indexToMove = selectedCaseList.findIndex(
      (item) => item.uniqueId === active.id
    );
    let targetIndex = selectedCaseList.findIndex(
      (item) => item.uniqueId === over.id
    );

    setSelectedCaseList((prev) => arrayMove(prev, indexToMove, targetIndex));
  };

  const selectedCaseListIds = useMemo(() => {
    return selectedCaseList.map((item) => item.uniqueId);
  }, [selectedCaseList]);

  return (
    <>
      <p className="examRulesOverviewSubContainerTitle">
        Cases Selected ({selectedCaseList.length}/10)
      </p>
      <div className="examRulesSubContainer examRulesOverviewSubContainer">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          autoScroll={true}
        >
          <SortableContext
            items={selectedCaseListIds}
            strategy={verticalListSortingStrategy}
          >
            {selectedCaseList.map((caseData, index) =>
              caseData.type === "category" ? (
                <OverviewCaseCard
                  title={`Category: ${caseData.category}`}
                  category={caseData.category}
                  caseId={null}
                  index={index}
                  type={caseData.type}
                  key={caseData.uniqueId}
                  id={caseData.uniqueId}
                />
              ) : (
                <OverviewCaseCard
                  title={caseData.Diagnosis}
                  category={caseData.CaseCategory}
                  caseId={caseData.CaseId}
                  index={index}
                  type={caseData.type}
                  key={caseData.uniqueId}
                  id={caseData.uniqueId}
                />
              )
            )}
          </SortableContext>
        </DndContext>
        {selectedCaseList.length === 0 && (
          <p className="examRulesOverviewNoElementsSelected">
            No Cases Selected
          </p>
        )}
        <button
          className="examRulesOverviewAddElementButton"
          onClick={() => setPageDisplayed("case")}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Cases
        </button>
      </div>
    </>
  );
};

export default SelectedCasesSubContainer;
