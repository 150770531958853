import React, { useContext, useState } from "react";
import "./UploadCSVModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileCirclePlus,
  faFileDownload,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import MDSpinner from "react-md-spinner";
import { OSCEAdminContext } from "../../../index.js";

const UploadCSVModal = ({ setShowUploadCSVModal, handleConfirmCSVUpload }) => {
  const [file, setFile] = useState(null);
  const [fetchOutstanding, setFetchOutstanding] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const { route, userData, handleExpiredUserSession } =
    useContext(OSCEAdminContext);

  const handleCSVUpload = async (e) => {
    setFile(e.target.files[0]);
    setFetchOutstanding(true);
    const formData = new FormData();
    formData.append("CSV", e.target.files[0]);
    await fetch(`${route}/verifycsv.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          handleExpiredUserSession();
          return false;
        } else {
          throw new Error("Error verifying CSV");
        }
      })
      .then((data) => {
        if (!data) return;
        setCsvData(data);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCsvData(null);
        setFile(null);
      })
      .finally(() => {
        setFetchOutstanding(false);
      });
  };

  return (
    <div className="modalPageWrapper">
      <div className="modalBackdrop" />
      <div className="modalContainer">
        <h2 className="modalHeader">Upload CSV</h2>
        <p className="modalParagraph">
          Upload a CSV with student information to append to your current list
          of students. Your CSV should be formatted like the following:
        </p>
        <div className="uploadCSVModalTextFormatContainer">
          <p className="uploadCSVModalTextFormatText">
            Student First Name,Student Middle Name (Optional),Student Last
            Name,Student Email,Number Of Total Cases,Break Time (In
            Minutes),Group Name (Name of your group),Case 1 (specify specific
            case or Random or Random specialty),Case 2,Case 3,Case 4,Case 5,Case
            6,Case 7,Case 8,Case 9,Case 10
          </p>
          <a
            className="uploadCSVModalTextFormatDownloadTemplateButton"
            title="Download CSV Template"
            href={`${route}/sample_import.csv`}
          >
            <FontAwesomeIcon icon={faFileDownload} />
          </a>
        </div>
        {!file ? (
          <button className="uploadCSVModalAddFileButton" tabIndex="-1">
            <FontAwesomeIcon icon={faFileCirclePlus} />
            Add a File
            <input
              type="file"
              className="fileUpload"
              accept=".csv"
              onChange={handleCSVUpload}
            />
          </button>
        ) : fetchOutstanding ? (
          <div className="modalSpinnerWrapper">
            <MDSpinner size={36} singleColor="#0B335D" />
          </div>
        ) : (
          <button
            className="uploadCSVModalRemoveFileButton"
            onClick={() => setFile(null)}
            title={file.name}
          >
            <FontAwesomeIcon icon={faX} />
            {file.name}
          </button>
        )}
        <div className="modalButtonsWrapper">
          <button
            className="modalCancelButton"
            onClick={() => setShowUploadCSVModal(false)}
          >
            Cancel
          </button>
          <button
            className={`modalConfirmButton ${!csvData ? "buttonDisabled" : ""}`}
            disabled={!csvData}
            onClick={() => handleConfirmCSVUpload(csvData, file)}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default UploadCSVModal;
