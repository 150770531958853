import React, { useContext, useEffect, useMemo, useState } from "react";
import "./StudentProfilePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import PrimaryProfileDetails from "./PrimaryProfileDetails/PrimaryProfileDetails";
import PreceptorsTable from "./PreceptorsTable/PreceptorsTable";
import ExamRules from "./ExamRules/ExamRules";
import GradesSection from "./GradesSection/GradesSection";
import { OSCEAdminContext } from "..";
import ConfirmationModal from "../Components/Modals/ConfirmationModal/ConfirmationModal";

const StudentProfilePage = () => {
  const {
    handleChangePage,
    prevPageProps,
    userData,
    route,
    handleExpiredUserSession,
    displayNotification,
    updateUserData,
  } = useContext(OSCEAdminContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const student = useMemo(
    () =>
      userData.Students.find(
        (student) => student.CustomerId === prevPageProps.studentId
      ),
    [userData, prevPageProps]
  );

  const handleDeleteStudent = async () => {
    await fetch(`${route}/deleteaccount.webapi`, {
      method: "POST",
      headers: {
        Token: userData.Token,
      },
      body: JSON.stringify({
        CustomerIds: [student.CustomerId],
      }),
    })
      .then((response) => {
        if (response.ok) {
          handleChangePage("Home");
          displayNotification(
            `${`${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`} deleted successfully.`
          );
          const updatedUserData = {
            ...userData,
            Students: userData.Students.filter(
              (tempStudent) => tempStudent.CustomerId !== student.CustomerId
            ),
          };
          updateUserData(updatedUserData);
        } else if (response.status === 401) {
          handleExpiredUserSession();
        } else {
          throw new Error(`${response.status} - ${response.statusText}`);
        }
      })
      .catch((error) => {
        displayNotification("An error occurred. Please try again.");
        console.log(error);
      });
  };

  const confirmDeleteStudent = () => {
    setShowDeleteConfirmation(true);
  };

  return (
    <div className="studentProfilePageContainer">
      <button
        className="studentProfilePageBackButton"
        onClick={() => handleChangePage("Home")}
      >
        Back to Home
      </button>
      <div className="studentProfilePageStudentNameContainer">
        <h1 className="studentProfilePageStudentName">{`${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}`}</h1>
        <button
          className="studentProfilePageDeleteButton"
          onClick={confirmDeleteStudent}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </div>
      <PrimaryProfileDetails student={student} />
      <PreceptorsTable student={student} />
      <ExamRules student={student} />
      <GradesSection student={student} />
      {showDeleteConfirmation && (
        <ConfirmationModal
          header="Delete Student"
          text={`Are you sure you want to delete ${student.CustomerFirstName} ${student.CustomerMiddleName} ${student.CustomerLastName}? This can only be reversed by contacting us.`}
          closeModal={() => setShowDeleteConfirmation(false)}
          confirmFunction={handleDeleteStudent}
        />
      )}
    </div>
  );
};

export default StudentProfilePage;
